import React from 'react'
import mainservice from '../../services/MainService'
import './BookAdminPropSwitcher.scss'

type Props = {
  parent: any
  name: string
  key1: string
  key2: string
  value1Arr: [string, string][]
  value1Current: string
  cb?: (cbValue?: string) => void
  className?: string
  kind?: 'matrix'
}

type State = {
  currentValue1: string,
  userEdit: boolean
}

export const DirectionMatrix: [string, string][] = [
  ['bgPositionTl', '↖'],
  ['bgPositionTc', '⬆'],
  ['bgPositionTr', '↗'],
  ['bgPositionMl', '⬅'],
  ['bgPositionMc', '🔆'],
  ['bgPositionMr', '➡'],
  ['bgPositionBl', '↙'],
  ['bgPositionBc', '⬇'],
  ['bgPositionBr', '↘'],
]

export default class BookAdminPropSwitcher extends React.Component<Props, State> {

  broadCastName: string = ''
  constructor(props: Props) {
    super(props)
    this.broadCastName = `propswitcher-${props.parent.id}-${props.key1}-${props.key2}`
    this.state = {
      currentValue1: props.value1Current,
      userEdit: false
    }
  }

  componentDidMount() {
    mainservice.registerToBroadcast(this.broadCastName, (key: string, _value: any) => {
      switch(key) {
        case 'thingHasBeenClicked':
          this.forceUpdate()
          break
      }
    }, this)
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast(this.broadCastName)
  }

  setValue1(val1: string) {
    this.setState({
      currentValue1: val1,
      userEdit: true
    })
    if (!this.props.parent.addProp) { return }
    this.props.parent.addProp({
      id: -1,
      key1: this.props.key1,
      key2: this.props.key2,
      value1: val1,
      value2: '-'
    }).then(() => {
      if (this.props.cb) {
        this.props.cb(val1)
      }
    })
  }

  renderDropDown() {
    const currentValue = (this.state.userEdit) ? this.state.currentValue1 : this.props.value1Current
    return <select
        value={currentValue}
        onChange={(event) => this.setValue1(event.target.value)}
      >
        {
          this.props.value1Arr.map(
            (o: [string, string], index: number) => <option
              key={index}
              value={o[0]}>{o[1]}</option>
          )
        }
      </select>
  }

  renderMatrix() {
    const currentValue = (this.state.userEdit) ? this.state.currentValue1 : this.props.value1Current
    return <div className='PropSelectorMatrix'>
        {
          this.props.value1Arr.map((o, i) => <div
              key={i}
              className={`PropSelectorMatrixField ${o[0] === currentValue ? 'active' : ''}`}
              onClick={() => {
                this.setValue1(o[0])
              }}
            >{o[1]}</div>
          )
        }
      </div>
  }

  render() {
    const content = () => {
      switch(this.props.kind) {
        case 'matrix':
          return this.renderMatrix()
        default:
          return this.renderDropDown()
      }
    }
    return <div className={`propSwitcher ${this.props.className || ''}`}>
      <div className="propSwitcherName">{this.props.name}</div>
      {
        content()
      }
    </div>

  }
}
