import React from 'react'

type Props = {
  name?: string
  className?: string
  type?: string
  onClick?: () => void
}

type State = {

}

export default class Icon extends React.Component<Props, State> {
  icon = ''
  type=''
  className = ''
  onClick = () => {}

  constructor(props: Props) {
    super(props)
    if (props.name) { this.icon = props.name }
    if (props.className) { this.className = props.className }
    if (props.type) { this.type = props.type }
  }

  render() {
    return <svg
      className={this.className}
      onClick={ () => {
        if (this.props.onClick) { this.props.onClick() }
      } }
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="xMinYMin"
    >
      <use xlinkHref={`#img-${this.icon}${this.type ? '-' + this.type : ''}`}></use>
    </svg>
  }
}

export class ArrowLeft extends Icon { icon = 'arrow-left' }
export class ArrowRight extends Icon { icon = 'arrow-right' }
export class BookmarkAdd extends Icon { icon = 'bookmark-add' }
export class BookmarkSubtract extends Icon { icon = 'bookmark-subtract' }
export class BurgerMenu extends Icon { icon = 'burger-menu' }
export class Dashboard extends Icon { icon = 'dashboard' }
export class Expand extends Icon { icon = 'expand' }
export class Flip extends Icon { icon = 'flip' }
export class Remove extends Icon { icon = 'remove' }
export class Shrink extends Icon { icon = 'shrink' }
export class Source extends Icon { icon = 'source' }
export class Share extends Icon { icon = 'share' }

export class IconDemo extends Icon {
  render() {
    const icons = [
      'arrow-left',
      'arrow-right',
      'bookmark-add',
      'bookmark-subtract',
      'burger-menu',
      'dashboard',
      'expand',
      'flip',
      'remove',
      'shrink',
      'source',
      'share',
    ]
    return <>
    {
      icons.map(i => <div className='demoIcon' style={{float: 'left', height: '80px', width: '200px', textAlign: 'center', paddingTop: '10px', backgroundColor: '#666', color: '#fff', border: '1px solid #fff'}}>
        <div className='icon'>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
            <use xlinkHref={`#img-${i}`}></use>
          </svg>
        </div>
        <div className='name'>{i}</div>
      </div>)
    }
    </>
  }
}
