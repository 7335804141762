import React from 'react'
// import config from '../../config.json'
// import UserDTO, { IUser } from '../../DTO/UserDTO'
// import { Getter, Setter } from '../../services/ComService'
import mainservice from '../../services/MainService'
import { LogOut, LogIn, User } from 'react-feather'
import './Login.scss'

enum LoginState {
  standard,
  wrongCredentials,
  waiting,
  loading,
  loggedin
}

type Props = {
}

type State = {
  name: string,
  pw: string,
  loginState: LoginState,
  email: string
}

export default class Login extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      name: '',
      pw: '',
      loginState: LoginState.loading,
      email: ''
    }
  }

  componentDidMount() {
    mainservice.registerToBroadcast('UserLogin', (key: string, value: any) => {
      // console.log('user das main menu listens to broadcast', key)
      switch(key) {
        case 'loginsuccess':
        case 'logoutsuccess':
          this.getData()
          break
      }
    }, this)
    this.getData()
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('UserLogin')
  }

  async getData() {
    const loggedIn = await mainservice.loginService.isLoggedIn()
    console.log('///Login LoggedIn:', loggedIn)
    if (loggedIn) {
      this.setState({
        loginState: LoginState.loggedin,
        email: mainservice.loginService.user.email
      })
      return
    }
    this.setState({
      loginState: LoginState.standard,
      email: ''
    })
  }

  sendResult() {
    this.setState({
      loginState: LoginState.waiting
    })
    mainservice.loginService.login(this.state.name, this.state.pw)
  }

  renderMessage() {
    if (this.state.loginState === LoginState.wrongCredentials) {
      return <div className='w3-pale-yellow w3-border w3-border-yellow w3-section'>
        Bitte prüfen Sie die Logindaten!
      </div>
    }
    return null
  }

  renderLogin() {
    return <div className='login'>
      <div
        className='cursorPointer linkButton'
        onClick={() => {
          mainservice.navTo([['view', 'login']])
        }}
      >
        <div className='liLogo'><LogIn /></div>
        <div className='liName'>Login</div>
      </div>
    </div>
  }

  renderLoggedIn() {
    return <div className='loggedIn'>
      <div
        className='cursorPointer linkButton'
        onClick={() => mainservice.navTo([['view', 'UserPreferences']])}
      >
        <div className='liLogo'><User /></div>
        <div className='liName'>{this.state.email}</div>
      </div>

      <div
        className='cursorPointer linkButton'
        onClick={() => {
          this.logout()
        }}
      >
        <div className='liLogo'><LogOut /></div>
        <div className='liName'>Logout</div>
      </div>
    </div>
  }

  async logout() {
    mainservice.quitAdmin()
    await mainservice.loginService.logout()
    this.setState({
      loginState: LoginState.standard
    })
    mainservice.navTo([['view', 'login']])
  }

  render() {
      return <div
        className={`UserLogin ${(this.state.loginState === LoginState.loggedin) ? 'statusLoggedIn' : 'statusLoggedOut'}`}
      >
        { this.renderLoggedIn() }

        {
          this.state.loginState === LoginState.standard &&
          this.renderLogin()
        }
      </div>
  }
}
