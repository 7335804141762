import { useEffect, useRef, useState } from "react";
import InputB from "../../../components/InputB/InputB";
import ItemPersonDTO from "../../DTO/ItemPersonDTO";
import { Plus } from "react-feather";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import Person from "./Person";
import Menu, { MItem } from "../Menu";
import ItemPersonsDTO from "../../DTO/ItemPersonsDTO";
import { PersonKind } from "./PersonKind";
import { EventDTO } from "../../DTO/EventDTO";
import mainservice from "../../../services/MainService";
import ArrayManager from "../../../services/ArrayManager";
import EventList from "../Event/EventList";
import { Setter } from "../../../services/ComService";
import PlaningToolRoot from "../PlaningToolRoot";
import { InfinitScroll } from "../../../services/InfinitScroll";

enum Tab {
  all,
  trainer,
}

const tabs = [
  {
    key: Tab.all,
    name: 'Alle',
  },
  {
    key: Tab.trainer,
    name: 'Trainer',
  }
]

export default function PersonList(props: {
  selected?: number,
  onSelect?: (id: number, person: ItemPersonDTO) => void,
  onSelected?: (personIds: number[]) => void,
  onTrash?: (id: number) => void,
  onRemove?: (id: number) => void,
  className?: string,
  eventId?: number,
  customerId?: number,
  customerLocationId?: number,
  standalone?: boolean,
  noSearch?: boolean,
  rerenderTrigger?: number,
  personKind?: PersonKind,
  propTrainerTagParent?: EventDTO,
  personTagParent?: EventDTO,
  noCount?: boolean,
  compact?: false | true | 'medium' | 'bubble' | 'joinedEvents',
  prefix?: string,
  persons?: ItemPersonDTO[],
  headline?: string,
}) {
  const [Needle, setNeedle] = useState('')
  const [status, setStatus] = useState(props.persons === undefined ? Status.loading : Status.standard)
  const [Filter, setFilter] = useState(0)
  const [rerender, setRerender] = useState(0)
  const [Items] = useState(new ItemPersonsDTO({
    getUrl: 'spt/person/list',
    trashUrl: 'spt/person/delete',
    eventId: props.eventId || -1,
    customerId: props.customerId || -1,
    customerLocationId: props.customerLocationId || -1,
    items: props.persons,
  }, {
    personKind: PersonKind[props.personKind || PersonKind.generic],
  }))
  const [selected] = useState(new ArrayManager())
  const [infinitScroll] = useState(new InfinitScroll({
    ref: useRef(null),
    cb: async () => {
      // What to do, if we are at the end of the list?
      const newPage = infinitScroll.incrementAndLock()
      if (newPage === -1) { return }
      setStatus(Status.loadMore)
      
      const newElementCount = await Items.getFromServer(infinitScroll.getPage())
      if (newElementCount) {
        infinitScroll.unlock()
      }
      setStatus(Status.standard)
      // Now load new content - then set Status to "standard" and unlock
      // If nothing is loaded - lock permanently
    }
  }))
  const className = `STPersonList flex flex-column ${props.className || ''}`
  useEffect(() => {
    if (!props.persons) {
      setStatus(Status.loading)
      Items.getFromServer().then(() => {
        setStatus(Status.standard)
      })
    }
  }, [props.rerenderTrigger])
  return <PlaningToolRoot standalone={props.standalone} menuItem={MItem.persons} mainContentClass={className}>
    {
      (props.standalone || props.headline) ?
      <div className='contentHeader'>
        <div
          className={`flex`}
        >
          {
            props.headline ? <h2 className='w3-margin-right'>{props.headline}</h2> : <h1 className='w3-margin-right'>Personen</h1>
          }
          {
            !props.noCount &&
            <div className='genericTag'>{Items.count()}</div>
          }
          { selected.count() > 0 &&
            <div className=''>Ausgewählt: {selected.count()},&nbsp;</div>
          }
          {
            selected.count() > 0 && props.onSelected &&
            <button
              className='positiveButton'
              onClick={() => {
                console.log('SELARR', selected.arr)
                if (props.onSelected) {
                  props.onSelected((selected.arr || []).map(p => p as number))
                }
                /*
                props.onSelected(selected.arr.map((person) => {
                  return {
                    id: person.id,
                    person: person,
                  }
                }))
                  */
              }}
            >
              Auswahl übernehmen
            </button>
          }
          {
            selected.count() > 0 && !props.onSelected &&
            <div className="w3-dropdown-hover">
              Teilnehmer senden
              <div className="w3-dropdown-content w3-card context-menu">
                <EventList
                  className='w3-margin-top'
                  selected={-1}
                  onSelect={async (id, event: EventDTO) => {
                    console.log('send ids to ', id)
                    const EventName = event.getName()
                    if (!window.confirm(`Sollen die Personen wirklich an die Veranstaltung ${EventName} gesendet werden?`)) {
                      return
                    }
                    await Setter('spt/event/addPersonsByIds', {
                      id: id,
                      personIds: selected.getAll().join(';')
                    })
                    if(window.confirm('Personen wurden an die Veranstaltung gesendet. Diese Veranstaltung jetzt aufrufen?')) {
                      window.location.href = `/view=spVeranstaltungsDetails/id=${id}/`
                    }
                  }}
                  noSearch={false}
                  compact
                />
              </div>
            </div> 
          }
          {
            selected.count() > 0 &&
            <div
              onClick={() => {
                selected.reset()
                setRerender(rerender + 1)
              }}
            >
              &nbsp;Auswahl aufheben
            </div>
          }
        </div>
        {
          !props.noSearch &&
          <InputB
            className='w3-border'
            returnVal={async (val) => {
              setStatus(Status.loading)
              await Items.setNeedle(val)
              setNeedle(val)
              setStatus(Status.standard)
            }}
            type='textarea'
            rows={1}
            placeholder='Tippen um zu suchen oder neu anzulegen ...'
          />
        }
        {
          Needle !== '' &&
          <button
            className='positiveButton'
            onClick={async () => {
              const item = new ItemPersonDTO({
                trainer: props.personKind === PersonKind.trainer ? 1 : -1
              })
              console.log('new thing')
              console.log(Needle)
              await item.infoAbsorber(Needle)
              // await item.setName(Needle)
              if (props.onSelect) {
                props.onSelect(item.id, item)
              } else {
                mainservice.navTo([['view', 'spPersonEdit'], ['id', item.id]])
                /*
                setStatus(Status.loading)
                await Items.getFromServer()
                setStatus(Status.standard)
                */
              }
            }}
          ><Plus /></button>
        }
        <div>
        {
          props.standalone &&
            tabs.map((t, index) => {
              return <button
                key={`plt-${index}`}
                className={`w3-button ${Filter === t.key ? 'w3-blue' : ''}`}
                onClick={async () => {
                  if (t.key === Tab.trainer) {
                    await Items.filterByTrainer(0)
                  } else {
                    await Items.filterByTrainer(-1)
                  }
                  setFilter(t.key)
                }}
              >{t.name}</button>
            })}
          
        </div>
      </div> : null
    }
    <div
      ref={infinitScroll.getRef()}
      className={`PersonList ${props.standalone ? 'scrollContent' : ''} ${props.className || ''}`}
    >
      { Items.count() > 0 &&
        <label className='EditFieldLabel'>
          {props.prefix}
        </label>
      }
      {
        status === Status.loading &&
        <Spinner />
      }
      {
        Items.getItems().map((i, index) => {
          return <Person
            key={`PersonListItem-${i.id}`}
            className={`hover ${props.selected === i.id ? 'mark' : ''}`}
            item={i}
            propTrainerTagParent={props.propTrainerTagParent}
            personTagParent={props.personTagParent}
            compact={props.compact}
            onClick={props.onSelect}
            prefix={index > 0 ? ', ' : ''}
            onTrash={props.onTrash || props.standalone ? async() => {
              if (!window.confirm(`${i.firstname} ${i.lastname} ${i.email} wirklich löschen?`)) { return }
              setStatus(Status.loading)
              await Items.trashItem(i.id)
              setStatus(Status.standard)
            } : undefined}
            onRemove={props.onRemove ? () => {
              if (props.onRemove) {
                props.onRemove(i.id)
              }
            } : undefined}
            selected={selected.has(i.id)}
            select={props.onSelect ? undefined : () => {
              console.log('PersonSelect', i.id)
              selected.toggle(i.id)
              setRerender(rerender + 1)
            }}
          />
        })
      }
      {
        status === Status.loadMore &&
        <div className='textCenter'>
          <Spinner mini={true} />
          <div>Lade weitere Elemente</div>
        </div>
      }
    </div>
  </PlaningToolRoot>
}
