import React from 'react'
import mainservice from '../../services/MainService'
// import {Getter} from '../../services/ComService'
import './UserWidget.scss'
import {Dashboard} from '../Icon/Icon'
import Tooltip from '../Tooltip/Tooltip'
import LockNavigationService from '../../services/LockNavigationService'

enum LoginState {
  standard,
  wrongCredentials,
  waiting,
  loading,
  loggedin
}

type Props = {
}

type State = {
  loginState: LoginState
}
// Zeigt Hamburgermenü und integriert Login und Logout Feld
// Zeigt Avatar - online-status
export default class UserWidget extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loginState: LoginState.standard
    }
  }

  async getData() {
    const isLoggedIn = await mainservice.loginService.isLoggedIn()
    if (isLoggedIn) {
      this.setState({loginState: LoginState.loggedin})
      return
    }
      this.setState({loginState: LoginState.standard})
  }

  componentDidMount() {
    this.getData()
  }

  componentWillUnmount() {
    // mainservice.unregisterBroadcast('userwidget')
  }

  render() {
    if (window.location.href.search(/fuehrungsstilanalyse/) > -1) {
      return null
    }
    return <div
      className={`UserWidget ${(this.state.loginState === LoginState.loggedin) ? 'loggedIn' : 'loggedOut'}`}
    >
      <div className={`dropdownbutton`}>
        <Tooltip content='zum Dashboard' />
        <Dashboard className='avatar' onClick={() => {
          // mainservice.broadcast('openBookMainMenu')
          if (LockNavigationService.isNotLocked()) {
            mainservice.navTo([['view', 'dashboard']])
          }
        }} />
      </div>
    </div>
  }
}
