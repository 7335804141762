import FileDTO, { IFile } from './FileDTO'

export default class StaticSiteParagraphPropDTO {
  id: number
  file: FileDTO | null = null
  key1: string
  key2: string
  value1: string
  value2: string

  constructor(data: IStaticSiteParagraphProp) {
    this.id = data.id
    if (data.file) { this.file = new FileDTO(data.file) }
    this.key1 = data.key1
    this.key2 = data.key2
    this.value1 = data.value1
    this.value2 = data.value2
  }

  newToServer() {}

  remove() {}

  set() {}

}

export type IStaticSiteParagraphProp = {
  id: number
  file?: IFile
  key1: string
  key2: string
  value1: string
  value2: string
}
