import { useEffect, useState } from "react"
import PageElementDTO from "../../../DTO/PageElementDTO"
import InputA from "../../InputA/InputA"
import './Cols.scss'
import mainservice from "../../../services/MainService"
import { X } from "react-feather"

enum Status {
  standard,
  loading,
  open,
}

export default function Cols (props: {
  pageElement: PageElementDTO,
  parent: any,
  setClass: (c: string) => void,
}) {
  // const [rerender, setRerender] = useState(0)
  const [status, setStatus] = useState(Status.standard)
  const cols = props.pageElement.getPropVal1('cols', 'count', '2')
  const overlayName = props.pageElement.getPropVal1('overlay', 'name', '')
  const broadcastKey = 'ColsOverlay-' + props.pageElement.id
  function makeColCountClass() { return 'col-count-' + cols }
  function setClass(newStatus?: Status) {
    if (newStatus === undefined) {
      newStatus = status
    }
    const overlayClass = overlayName === '' ? '' : ' overlayMaker'
    const overlayOpenState = overlayName && newStatus === Status.open ? ' overlayMakerOpen' : ''
    const newClass = `cols ${makeColCountClass() + overlayClass + overlayOpenState}`
    props.setClass(newClass)
  }
  if (broadcastKey) {
    mainservice.registerToBroadcast(broadcastKey, (key, value) => {
      if (key === 'triggerOverlay-' + overlayName) {
        if ((value?.mode || '').search(/open|edit/) > -1) {
          setStatus(Status.open)
          setClass(Status.open)
        } else if ((value?.mode || '').search(/close/) > -1) {
          // do not listen to self
        } else {
          setStatus(Status.standard)
          setClass(Status.standard)
        }
      }
    })
  }
  useEffect(() => {
    setClass()
  }, [])
  if (status === Status.loading) {
    return <div>Lade ...</div>
  }
  return <>
    {
      overlayName &&
      <button
        className='w3-button w3-btn w3-black colsClose'
        onClick={() => {
          setStatus(Status.standard)
          setClass(Status.standard)
          mainservice.broadcast('triggerOverlay-' + overlayName, {mode: 'close'})
        }}
      ><X /></button>
    }
    { props.parent.isAdmin() > 0 &&
      <>
        <div className='adminToolBar w3-margin-top'>
          <label>Spalten-Anzahl</label>
          <InputA
            value={cols}
            returnVal={async (v) => {
              setStatus(Status.loading)
              await props.pageElement.addProp({
                id: -1,
                key1: 'cols',
                key2: 'count',
                value1: v,
                value2: '-',
              })
              setStatus(Status.standard)
            }}
          />

          <label>Overlay Name</label>
          <InputA
            value={overlayName}
            returnVal={async (v) => {
              setStatus(Status.loading)
              await props.pageElement.addProp({
                id: -1,
                key1: 'overlay',
                key2: 'name',
                value1: v,
                value2: '-',
              })
              setStatus(Status.standard)
            }}
          />
        </div>
        <div>COLUMN-ERZEUGER</div>
      </>
    }
    <div className='col-holder'>&nbsp;</div>
  </>
}
