import React from 'react'
import './CollectibleItemA.scss'
import Tooltip from '../Tooltip/Tooltip'
// import CollectibleDTO, {CollectiblesDTO} from '../../DTO/CollectibleDTO'
import PageElementDTO from '../../DTO/PageElementDTO'
import CollectibleDTO from '../../DTO/CollectibleDTO'
import { Plus } from 'react-feather'
import { BookmarkAdd, BookmarkSubtract } from '../Icon/Icon'
import { Getter } from '../../services/ComService'
import mainservice from '../../services/MainService'
import './CollectibleItemA.scss'

enum Status {
  standard,
  chooseCollectible
}

type Props = {
  pageElement: PageElementDTO
}

type State = {
  isAdmin: number,
  status: Status,
  pageElement: PageElementDTO
}

export default class CollectibleItemB extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isAdmin: mainservice.isAdmin(),
      status: Status.standard,
      pageElement: props.pageElement
    }
  }

  async getData() {
    const data = await Getter(`PageElement/${this.state.pageElement.id}`)
    const newPageElement = new PageElementDTO(data)
    this.setState({pageElement: newPageElement})
  }

  renderCollectButton(c: CollectibleDTO) {
    // console.log('collected', c)
    if (!c.collected) {
      return <div
        className='collect'
        onClick={() => {
          c.collect({
            bookId: mainservice.nav.getVal('book') as number,
            doublePageId: mainservice.currentDoublePageId
          }).then(() => this.forceUpdate())
        }}
      >
        <Tooltip content='zum sammeln klicken' />
        <button
          className='w3-btn w3-ribbon lu-collect-button'
        >
          <BookmarkAdd />
        </button>
        Sammeln
      </div>
    }
    return <div
      className='collect'
      onClick={() => {
        c.ditch(mainservice.loginService.user.id).then(() => this.forceUpdate())
      }}
    >
      <Tooltip content='zum entfernen klicken' />
      <button
        className='w3-btn w3-ribbon lu-collect-button'
      >
        <BookmarkSubtract />
      </button>
      Gesammelt
    </div>
  }

  // show if it is collected
  // have a collect button (if not collected)
  // show text and background

  // Admin may select which collectible is to be used
  render() {
    /*
    if (this.state.status === Status.chooseCollectible) {
      return
    }
    */
    // console.log('PAGE ELEMENT', this.state.pageElement)
    const c = this.state.pageElement.collectible
    if (!c) { return }
    return <div className='show collectibleItem'>
      {
        this.state.isAdmin > 0 &&
        <button
          className='w3-btn w3-ripple w3-green'
          onClick={() => {
            mainservice.broadcast('element-collectible-selector', this.props.pageElement.id)
          }}
        >
          <Plus />
        </button>
      }
      <div className='w3-padding titel'>{c.title}</div>
      <div
        className={`collectible-content collectible-type-${c.type}`}
        dangerouslySetInnerHTML={{__html: c.getContent()}}
      ></div>
      { this.renderCollectButton(c)}

    </div>
  }
}
