import { useState } from "react";
import { parseStringToServer } from "../../services/TextConverter";
import { EditFieldKind, GenericDTO, GenericEditField } from "../DTO/GenericDTO";
import { EditField } from "../views/EditField";
import ItemPersonDTO from "../DTO/ItemPersonDTO";
import ItemLocationDTO from "../DTO/ItemLocationDTO";
import './EditFieldsPrinter.scss';

type EditBlock = {
  title: string,
  className: string,
  fields: GenericEditField[],
}

/**
 * Function to Render all Edit Blocks
 * @param props 
 * @returns 
 */
export default function EditFieldsPrinter (props: {
  fields: GenericEditField[],
  patch?: (efp: GenericEditField) => Promise<GenericEditField>,
  item: GenericDTO | ItemPersonDTO | ItemLocationDTO,
  editMode?: boolean,
  rerenderParent?: () => void,
}) {
  const [blocks] = useState(generateBlocks(props.fields))
  return <div className='editFieldsPrinter w3-row'>
    {
      blocks.map((block, blockIndex) => {
        const hasTitle = block.title !== ''
        return <div
          key={`block-${blockIndex}`}
          className={`editFieldPrinterBlock with-title-${hasTitle ? 'yes' : 'no'} ${block.className} w3-col w3-row`}
        >
          {
            hasTitle && <h2>{block.title}</h2>
          }
          {
            block.fields.map((ef, index) => {
              return <EditField
                key={`ef-${blockIndex}-${index}`}
                field={ef}
                patch={async (efp) => {
                  if (props.patch) {
                    await props.patch(efp)
                  } else {
                    await props.item.addProp(efp.key1, efp.key2, parseStringToServer(efp.value || ''))
                  }
                  return efp
                }}
                item={props.item}
                editMode={props.editMode === undefined ? true : props.editMode}
                rerenderParent={props.rerenderParent}
              />
            })
          }
        </div>
      })
    }
  </div>
}

function generateBlocks(fields: GenericEditField[]): EditBlock[] {
  let out: EditBlock[] = []
  let currentBlock: EditBlock = {title: '', className: '', fields: []}
  fields.forEach((f, index) => {
    if (f.kind === EditFieldKind.block) {
      if (index > 0) {
        out.push(currentBlock)
        currentBlock = {title: '', className: '', fields: []}
      }
      currentBlock.title = f.title
      currentBlock.className = f.className || ''
    } else {
      currentBlock.fields.push(f)
    }
  })
  out.push(currentBlock)
  return out
}
