export type ObjectKindKey = 'seminar' | 'day' | 'module' | 'trainingItem' | 'materialItem' | 'none' | 'tag'
export enum ObjectKind {
  none,
  seminar,
  seminarday,
  day,
  daymodule,
  module,
  moduletrainingItem,
  trainingItem,
  trainingItemmaterialItem,
  materialItem,
  genericMaterialItem,
  event, // This has to be at this position!!!
  tag, // This is a tag - not to be mixed with the rest!!
}
export type ObjectKindType = {
  id: ObjectKind,
  name: string,
  key: ObjectKindKey
}
export const objectKindNaming: {id: ObjectKind, name: string}[] = [
  {
    id: ObjectKind.seminar,
    name: 'Veranstaltungsvorlage',
  },
  {
    id: ObjectKind.seminarday,
    name: '',
  },
  {
    id: ObjectKind.day,
    name: 'Veranstaltungstag',
  },
  {
    id: ObjectKind.daymodule,
    name: '',
  },
  {
    id: ObjectKind.module,
    name: 'Modul',
  },
  {
    id: ObjectKind.moduletrainingItem,
    name: '',
  },
  {
    id: ObjectKind.trainingItem,
    name: 'Kollektion',
  },
  {
    id: ObjectKind.trainingItemmaterialItem,
    name: '',
  },
  {
    id: ObjectKind.materialItem,
    name: 'Asset',
  },
  {
    id: ObjectKind.genericMaterialItem,
    name: '',
  },
  {
    id: ObjectKind.event,
    name: 'Veranstaltung',
  },
]
export const objectKindsArr: ObjectKindType[] = [
  /*
  {
    id: ObjectKind.seminar,
    name: 'Seminare',
    key: 'seminar',
  },
  */
  {
    id: ObjectKind.day,
    name: 'Tage',
    key: 'day',
  },
  {
    id: ObjectKind.module,
    name: 'Sequenzen',
    key: 'module',
  },
  /*
  {
    id: ObjectKind.trainingItem,
    name: 'Kollektionen',
    key: 'trainingItem',
  },
  */
  {
    id: ObjectKind.materialItem,
    name: 'Assets',
    key: 'materialItem',
  },
]

export function objectKey2Id(key: ObjectKindKey) {
  return ObjectKind[key]
  // return objectKindsArr.find(e => e.key === key)?.id || -1
}

export function objectKind2Name(key: ObjectKind) {
  return objectKindNaming.find(k => k.id === key)?.name || ''
}