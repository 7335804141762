import config from '../config.json';
import axios from 'axios';
import mainservice from './MainService';

export type RetryItemKind = 'GET' | 'POST'

export type SetterParams = {
  // retryType?: RetryType
  retryName?: string
  kind?: RetryItemKind
  method?: string
}

export type RetryItem = {
  command: string,
  body: object,
  // type: RetryType,
  name: string,
  count: number,
  firstDate: Date,
  kind: RetryItemKind,
}

export class Catcher {
  retryWalkerActive: boolean = false;
  RetryList: RetryItem[] = [];

  constructor() {
    this.RetryList = JSON.parse(localStorage.getItem('ComRetryList') || '[]').map((r: { firstDate: string | Date; }) => {
      r.firstDate = new Date(r.firstDate);
      return r;
    }) || [];
  }

  public RetryFilter(name: string, kind?: RetryItemKind, command?: string) {
    const MAXTRIES = 100;
    if (kind === 'GET') {
      if (!name) { return; }
      this.RetryList = this.RetryList.filter(i => !(i.kind === 'GET' && i.name === name || i.count > MAXTRIES));
    } else {
      this.RetryList = this.RetryList.filter(i => !(i.command === command && i.name === name || i.count > MAXTRIES));
    }
    if (this.RetryList.length === 0) {
      mainservice.broadcast('noConnection', false);
    }
  }

  RetryUnique() {
    this.RetryList = this.RetryList.filter((v, i) => {
      for (let j = i + 1; j < this.RetryList.length; j++) {
        const r = this.RetryList[j];
        if (r.name === v.name &&
          (
            (v.kind === 'GET' && r.kind === 'GET') ||
            (v.command === r.command && v.kind !== 'GET' && r.kind !== 'GET')
          )
        ) {
          return false;
        }
      }
      return true;
    });
  }

  RetryAdd(command: string, body: object, params?: SetterParams) {
    console.log('Setter did not succeed getting ', command);
    let name = params?.retryName || '';
    if (!name) { return; } // no name no serve
    this.RetryFilter(name, params?.kind, command);
    this.RetryList.push({
      command: command,
      body: body,
      name: name,
      // type: t,
      count: 0,
      firstDate: new Date(),
      kind: params?.kind || 'POST'
    });
    mainservice.broadcast('noConnection', true);
    localStorage.setItem('ComRetryList', JSON.stringify(this.RetryList));
  }

  async RetryWalk(name?: string, kind?: RetryItemKind, command?: string) {
    if (name) {
      this.RetryFilter(name, kind, command)
    }
    if (this.retryWalkerActive) { return; }
    this.retryWalkerActive = true;
    // console.log('RetryList', this.RetryList);
    this.RetryUnique();
    for (let i = 0, m = this.RetryList.length; i < m; i++) {
      let item = this.RetryList.shift();
      await new Promise(resolve => {
        window.setTimeout(() => {
          resolve(true);
        }, 1000);
      });
      await new Promise((resolve, reject) => {
        if (item === undefined) { return; }
        if (item.kind === 'POST') {
          axios.post(
            `${config.apiPrefix}${item.command}`,
            item.body,
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }
          ).then(() => {
            console.log('RetryWalk did finish ' + item?.command);
            resolve(`${item?.name} has been successfull`);
          }).catch(() => {
            if (item === undefined) { return; }
            // if item is older then 3 days - do not try again!
            console.log('try again', item.firstDate);
            if (!item.firstDate || item.firstDate.getTime() < new Date().getTime() - 259200000) { return; }
            item.count += 1;
            console.log('RetryWalk still did not finish ' + item?.command);
            this.RetryFilter(item.name, item.kind, item.command);
            this.RetryList.push(item);
          });
        } else if (item.kind === 'GET') {
          axios.get(`${config.apiPrefix}${item.command}`).then(() => {
            console.log('RetryWalk did finish ' + item?.command);
            resolve(`${item?.name} has been successfull`);
          }).catch(() => {
            if (item === undefined) { return; }
            // if item is older then 3 days - do not try again!
            if (!item.firstDate || item.firstDate.getTime() < new Date().getTime() - 259200000) { return; }
            item.count += 1;
            console.log('RetryWalk still did not finish ' + item?.command);
            this.RetryFilter(item.name, item.kind, item.command);
            this.RetryList.push(item);
          });
        }
      });
    }
    this.RetryUnique();
    localStorage.setItem('ComRetryList', JSON.stringify(this.RetryList));
    this.retryWalkerActive = false;
  }
}

/*
const catcher = new Catcher()
export default catcher
*/