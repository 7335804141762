const Evaluation1EffectiveMatrix = [
  ['1', 'B', 'D', 'A', 'C'],
  ['2', 'D', 'B', 'C', 'A'],
  ['3', 'C', 'B', 'D', 'A'],
  ['4', 'A', 'D', 'B', 'C'],
  ['5', 'D', 'C', 'A', 'B'],
  ['6', 'D', 'B', 'A', 'C'],
  ['7', 'A', 'C', 'B', 'D'],
  ['8', 'C', 'A', 'D', 'B'],
  ['9', 'C', 'A', 'D', 'B'],
  ['10', 'D', 'B', 'A', 'C'],
  ['11', 'A', 'B', 'D', 'C'],
  ['12', 'B', 'C', 'D', 'A'],
  ['13', 'A', 'C', 'D', 'B'],
  ['14', 'A', 'D', 'C', 'B'],
  ['15', 'B', 'C', 'D', 'A'],
  ['16', 'D', 'B', 'C', 'A'],
  ['17', 'A', 'C', 'B', 'D'],
  ['18', 'B', 'D', 'A', 'C'],
  ['19', 'B', 'D', 'C', 'A'],
  ['20', 'D', 'C', 'A', 'B'],
]

const LookUpMatrix = new Map()
Evaluation1EffectiveMatrix.forEach((i) => {
  LookUpMatrix.set(`surveypart${i[0]}`, [i[1], i[2], i[3], i[4]])
})

class Evaluation1Effective {
  minPoints = 20
  maxPoints = 80
  public effective: number
  constructor(data?: {key2: string, value2: string}[]) {
    if (data) {
      this.effective = this.calculate(data)
    } else {
      this.effective = -1
    }
  }

  calculate(data: {key2: string, value2: string}[]) {
    let pointHolder = [0,0,0,0]
    data.forEach((item) => {
      const key = item.key2
      const v = item.value2
      const entry = LookUpMatrix.get(key)
      if (!entry) { return }
      const pointFor = entry.indexOf(v)
      pointHolder[pointFor] += 1
    })
    // All Points have been collected - now we have to calculate:
    const result = pointHolder[0] * 1 + pointHolder[1] * 2 + pointHolder[2] * 3 + pointHolder[3] * 4
    return Math.round((result - this.minPoints) / (this.maxPoints - this.minPoints) * 100)
  }
}


export const EffectiveRatingNumberic = (surveyPart: string, letter: string): number => {
  const question = LookUpMatrix.get(surveyPart)
  const pointFor = question.indexOf(letter)
  return pointFor
}

export const EffectiveRating = (surveyPart: string, letter: string): string  => {
  const pointFor = EffectiveRatingNumberic(surveyPart, letter)
  return PointToRatingString(pointFor)
}

export const EffectiveRatingColor = (surveyPart: string, letter: string): string => {
  const pointFor = EffectiveRatingNumberic(surveyPart, letter)
  switch(pointFor) {
    case 3:
      return 'green'
    case 2:
      return 'lime'
    case 1:
      return 'amber'
    case 0:
      return 'deep-orange'
  }
  return `${pointFor}`
}

export const PointToRatingString = (point: number) => {
  switch(point) {
    case 3:
      return 'Ausgezeichnet'
    case 2:
      return 'Gut'
    case 1:
      return 'Mäßig'
    case 0:
      return 'Unangemessen'
  }
  return `Keine Wertung`
}

export default Evaluation1Effective
