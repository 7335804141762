export default function ArrayAddUnique(mainArr: any[], addArr: any[], uniqueFields: string[]) {
  addArr.forEach((aa, index) => {
    let r = mainArr.find((a) => uniqueFields.every(u => aa[u] === a[u]))
    if (r) {
      r = aa
    } else {
      mainArr.push(aa)
    }
  })
  // return mainArr
}

export function MakeArrayItemsUnique(arr: {id: number}[]) {
  let gatheredIds: number[] = []
  let out: {id: number}[] = []
  for (let i = 0, m = arr.length; i < m; i++) {
    let a: {id: number} = arr[i]
    if (!gatheredIds.some(g => g === a.id)) {
      gatheredIds.push(a.id)
      out.push(a)
    }
  }
  return out
}
