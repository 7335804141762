import { Setter } from "../../services/ComService"
import { parseStringFromServer, parseStringToServer } from "../../services/TextConverter"
import { PersonPhoneEditFields, PersonProfEditFields } from "../editFields/PersonEditFields"
import { PublishKind } from "../models/publishKinds"
import { GenericEditField } from "./GenericDTO"
import PropDTO, { IProp } from "./PropDTO"
import { ITag, TagDTO, TagType } from "./TagDTO"

export default class ItemDTO {
  id: number = -1
  props: PropDTO[] = []
  srcAdd: string = ''
  srcGet: string = ''
  srcPatch: string = ''
  srcDel: string = ''
  srcAddProp: string = ''
  srcPatchProp: string = ''
  srcDelProp: string = ''
  srcConnectToGeneric: string = ''
  srcDisconnectFromGeneric: string = ''
  srcSetTag: string = ''
  srcUnsetTag: string = ''
  optionsGet: object = {}
  editFields: GenericEditField[] = []
  publishKind: PublishKind = PublishKind.isTemplate
  propOptions = {
    srcAddProp: '',
    srcPatchProp: '',
    srcDelProp: '',
  }

  name: string = ''
  description: string = ''
  tags: TagDTO[] = []
  parentName: string = ''
  parentId: number = -1

  constructor(o: IItem) {
    this.propOptions = {
      srcAddProp: this.srcAddProp,
      srcPatchProp: this.srcPatchProp,
      srcDelProp: this.srcDelProp,
    }
    this.init(o)
  }

  init(o: IItem) {
    if (!o) { return }
    this.id = o.id || -1
    this.name = o.name || ''
    this.parentId = o.parentId || -1
    this.parentName = o.parentName || ''
    this.description = o.description || ''
    if (o.props !== undefined) {
      this.props = o.props.map((prop) => new PropDTO(prop, this.propOptions))
    }
    if (o.tags !== undefined) {
      this.tags = o.tags.map((tag) => new TagDTO(tag))
    }
    this.afterInit(o)
  }

  afterInit(o: any) {

  }

  async getFromServer(): Promise<void> {
    return new Promise((success) => {
      if (this.id === -1) {
        success()
      }
      Setter(this.srcGet, {
        ...{
          id: this.id
        },
        ...this.optionsGet,
      }).then((data) => {
        this.init(data.item)
        success()
      }).catch(() => {
        success()
      })
    })
  }

  async saveToServer() {
    const url = this.id === -1 ? this.srcAdd : this.srcPatch
    const data = await Setter(url, {
      id: this.id,
      name: this.name,
      description: this.description,
    })
    this.init(data.item)
  }

  async setName(name: string) {
    this.name = name
    await this.saveToServer()
  }

  async setDescription(description: string) {
    this.description = description
    await this.saveToServer()
  }

  public async addProp(key1?: string, key2?: string, value1?: string, value2?: string, unique?:boolean, fileId?: number) {
    if (key1 === undefined || key2 === undefined) { return }
    const isUnique = (unique === undefined) ? true : unique
    let prop = undefined
    if (isUnique) {
      prop = this.props.find(prop => prop.key1 === key1 && prop.key2 === key2)
      if (prop) {
        prop.value1 = parseStringToServer(value1 || '')
        prop.value2 = parseStringToServer(value2 || '')
        return await prop.saveToServer()
      }
    }
    const url = this.srcAddProp
    prop = await Setter(url, {
      id: this.id,
      unique: isUnique,
      key1: key1,
      key2: key2,
      value1: parseStringToServer(value1 || ''),
      value2: parseStringToServer(value2 || ''),
      fileId: fileId,
    })
    if (prop) {
      this.props.push(new PropDTO(prop as IProp, this.propOptions))
    }
  }

  public propCanBeEdited(id: number): boolean
  {
    return this.props.some(p => p.id === id)
  }

  public async removeProp(id: number) {
    // Only remove Prop, if it is of THIS element! No removing of parent props!
    if (!this.propCanBeEdited(id)) { return }
    this.props = this.props.filter(p => p.id !== id)
    const url = this.srcDelProp
    const result = await Setter(url, {id: id})
    // REF Props löschen:
    this.removePropByKey1(`ref-${id}`)
  }

  public async removePropByKey1(key1: string) {
    const props = this.props.filter(p => p.key1 === key1)
    props.forEach(p => {
      this.removeProp(p.id)
    })
  }

  public getProp(key1: string, key2: string, value1?: string) {
    const prop = this.props.find(p => p.key1 === key1 && p.key2 === key2 && (!value1 || p.value1 === value1))
    return prop
  }

  public getProps(key1: string) {
    let props = this.props.filter(p => p.key1 === key1)
    return props
  }

  public getPropV1(key1?: string, key2?: string) {
    if (key1 === undefined || key2 === undefined) { return '' }
    const prop = this.getProp(key1, key2)
    return parseStringFromServer(prop?.value1 || '')
  }

  public getEditFields(kind?: string): GenericEditField[] {
    let editFields = this.editFields
    if (kind === 'personProf') {
      editFields = PersonProfEditFields
    } else if (kind === 'personPhone') {
      editFields = PersonPhoneEditFields
    }
    return editFields.map(mef => {
      return {...mef, value: this.getPropV1(mef.key1, mef.key2)}
    })
  }

  public getTagsByKind(kind: TagType, modifier?: string): TagDTO[] {
    console.log('getTagsByKind', kind, this.tags)
    modifier = modifier && modifier !== 'tag' ? modifier : ''
    if (!modifier) {
      return this.tags.filter(t => t.kind === kind)
    }
    return this.tags.filter(t => t.kind === kind)
  }

  public async setTag(tag: TagDTO, singleMode?: boolean, modifier?: string) {
    modifier = modifier && modifier !== 'tag' ? modifier : ''
    // Only do this, if the tag is not already there:
    if (this.tags.some(t => t.id === tag.id)) { return false }
    if (singleMode) {
      if (!modifier) {
        this.tags = this.tags.filter(t => t.kind !== tag.kind)
      } else {
        this.tags = this.tags.filter(t => t.kind !== tag.kind)
      }
    }
    if (!this.tags.some(t => t.id === tag.id)) {
      this.tags.push(tag)
    }
    await Setter(this.srcSetTag, {
      id: this.id,
      tagId: tag.id,
      singleMode: singleMode ? 1 : 0,
    })
  }

  public async unsetTag(tag: TagDTO, modifier?: string) {
    modifier = modifier && modifier !== 'tag' ? modifier : ''
    // Only do this, if the tag is not already there:
    this.tags = this.tags.filter(t => t.id !== tag.id)
    await Setter(this.srcUnsetTag, {
      id: this.id,
      tagId: tag.id,
    })
  }
}

export interface IItem {
  id?: number,
  name?: string,
  description?: string,
  props?: IProp[],
  tags?: ITag[],
  parentId?: number,
  parentName?: string,
}
