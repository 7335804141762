import { EditFieldKind, GenericEditField } from "../DTO/GenericDTO"

export const PersonProfEditFields: GenericEditField[] = [
  /* This is in the main object
  {
    title: 'EMail1',
    key1: 'data',
    key2: 'EMail1',
    kind: EditFieldKind.string,
  },
  */
  {
    title: 'EMail2',
    key1: 'data',
    key2: 'EMail2',
    kind: EditFieldKind.string,
  },
  {
    title: 'Internet',
    key1: 'data',
    key2: 'Internet',
    kind: EditFieldKind.string,
  },
]

export const PersonPhoneEditFields: GenericEditField[] = [
  {
    title: 'Telefon1',
    key1: 'data',
    key2: 'Telefon1',
    kind: EditFieldKind.string,
  },
  {
    title: 'Telefon2',
    key1: 'data',
    key2: 'Telefon2',
    kind: EditFieldKind.string,
  },
  {
    title: 'Mobil1',
    key1: 'data',
    key2: 'Mobil1',
    kind: EditFieldKind.string,
  },
  {
    title: 'Mobil2',
    key1: 'data',
    key2: 'Mobil2',
    kind: EditFieldKind.string,
  },
]

export const PersonEditFields: GenericEditField[] = [
  {
    title: 'Vertriebsinfo',
    key1: 'tag',
    key2: 'marketingRole',
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Vertriebsinfo',
    key1: 'data',
    key2: 'marketingInfo',
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Private Adresse',
    kind: EditFieldKind.block,
    className: 's12 m6 l6',
  },
  {
    title: 'Straße',
    key1: 'privateInfo',
    key2: 'street',
    kind: EditFieldKind.string,
    className: 's12 m8 l8',
  },
  {
    title: 'Hausnummer',
    key1: 'privateInfo',
    key2: 'streetNr',
    kind: EditFieldKind.string,
    className: 's12 m4 l4',
  },
  {
    title: 'Postleitzahl',
    key1: 'privateInfo',
    key2: 'postalcode',
    kind: EditFieldKind.string,
  },
  {
    title: 'Stadt',
    key1: 'privateInfo',
    key2: 'town',
    kind: EditFieldKind.string,
  },
  {
    title: 'Bundesland',
    key1: 'privateInfo',
    key2: 'federalstate',
    kind: EditFieldKind.string,
  },
  {
    title: 'Land',
    key1: 'privateInfo',
    key2: 'country',
    kind: EditFieldKind.string,
  },
  {
    title: 'Telefonnummer',
    key1: 'privateInfo',
    key2: 'phone',
    kind: EditFieldKind.string,
  },
  {
    title: 'zweite Telefonnummer',
    key1: 'privateInfo',
    key2: 'phone2',
    kind: EditFieldKind.string,
  },
  {
    title: 'Postfach',
    key1: 'privateInfo',
    key2: 'mailbox',
    kind: EditFieldKind.string,
    className: 's12 m6 l6',
  },
  {
    title: 'Postfach Plz',
    key1: 'privateInfo',
    key2: 'mailbox_postalcode',
    kind: EditFieldKind.string,
    className: 's12 m6 l6',
  },
  {
    title: 'Private URL',
    key1: 'privateInfo',
    key2: 'url',
    kind: EditFieldKind.string,
  },
  {
    title: 'Rohinput',
    key1: 'data',
    key2: 'input',
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Gruppeninfo',
    key1: 'tag',
    key2: 'groupInfo',
    kind: EditFieldKind.tag,
  },

  {
    title: 'Persöhnliche Informationen',
    kind: EditFieldKind.block,
    className: 's12 m6 l6',
  },

  {
    title: 'Geburtsdatum',
    kind: EditFieldKind.date, 
    key1: 'data',
    key2: 'birthdate',
  },

  {
    title: 'Akademischer Grad',
    key1: 'info',
    key2: 'academic_degree',
    kind: EditFieldKind.string,
  },

  {
    title: 'Geschlecht',
    key1: 'tag',
    key2: 'sex',
    kind: EditFieldKind.tag,
  },


  {
    title: 'Familienstand',
    key1: 'privateInfo',
    key2: 'marital_status',
    kind: EditFieldKind.string,
  },

  {
    title: 'Rufname',
    key1: 'info',
    key2: 'call_sign',
    kind: EditFieldKind.string,
  },
  {
    title: 'Anrede',
    key1: 'data',
    key2: 'salutation',
    kind: EditFieldKind.string,
  },
  {
    title: 'Privat Bemerkungen',
    key1: 'privateInfo',
    key2: 'description',
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Anmerkungen',
    key1: 'privateInfo',
    key2: 'conspicuous_info',
    kind: EditFieldKind.string,
  },
  {
    title: 'Bemerkungen',
    key1: 'info',
    key2: 'description',
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Marketing',
    kind: EditFieldKind.block,
    className: 's12 m6 l6',
  },
  {
    title: 'VIP',
    key1: 'sales',
    key2: 'vip',
    kind: EditFieldKind.checkTF,
  },
  {
    title: 'Newsletter',
    key1: 'sales',
    key2: 'newsletter',
    kind: EditFieldKind.checkTF,
  },
  {
    title: 'Sperre Mailing',
    key1: 'sales',
    key2: 'nomail',
    kind: EditFieldKind.checkTF,
  },
  {
    title: 'Weihnachtsbrief Check',
    key1: 'sales',
    key2: 'christmasletter_check',
    kind: EditFieldKind.checkTF,
  },
  {
    title: 'Kein Newsletter',
    key1: 'sales',
    key2: 'no_newsletter',
    kind: EditFieldKind.checkTF,
  },

  {
    title: 'Löschwunsch nach DSGVO',
    key1: 'dsgvo',
    key2: 'delete_check',
    kind: EditFieldKind.checkTF,
  },

  {
    title: 'Vertriebsherkunft',
    key1: 'tag',
    key2: 'sales_origin',
    kind: EditFieldKind.tag,
  },
  {
    title: 'Trainer Einstellungsdatum',
    key1: 'trainer',
    key2: 'staffed',
    kind: EditFieldKind.string,
    className: 's12 m8 l8',
  },
  {
    title: 'Personalnummer',
    key1: 'trainer',
    key2: 'personal_number',
    kind: EditFieldKind.string,
    className: 's12 m4 l4',
  },
  
]
