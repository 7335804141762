import React from 'react'
import mainservice from '../../services/MainService'
import Spinner from '../Spinner/Spinner'
// import UserDTO, {IUser} from '../../DTO/UserDTO'
// import { Getter } from '../../services/ComService'
import UserLogin from './UserLogin'
import './User.scss'

type Props = {
}

type State = {
  loading: boolean,
  loggedIn: boolean,
}

class CoreDashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      loggedIn: false
    }
  }
  async componentDidMount() {
    if (await mainservice.loginService.isLoggedIn()) {
      this.setLoggedIn()
    }
    this.setState({
      loading: false
    })
  }

  isAdminLike() {
    const user = mainservice.loginService.user
    console.log('ISADMINLIKE', user)
    return user.roles.some((r: string) => r.search(/(ADMIN|PLANER)/) > -1)
  }

  isAdmin() {
    const user = mainservice.loginService.user
    return user.roles.some((r: string) => r.search(/(ADMIN)/) > -1)
  }

  setLoggedIn() {
    if (this.isAdminLike()) {
      this.setState({
        loading: false,
        loggedIn: true
      })
      mainservice.becomeAdmin()
      const view: string = mainservice.nav.getVal('view') as string
      if (view.search(/base|login/) > -1) {
        mainservice.navTo([['view', 'spVeranstaltungsPlaner']])
      }
    } else {
      const view: string = mainservice.nav.getVal('view') as string
      if (view.search(/base|login/) > -1) {
        mainservice.navTo([['view', 'spVeranstaltungsPlaner']])
      }
    }
  }


  async setLoggedOut() {
    mainservice.quitAdmin()
    await mainservice.loginService.logout()
    this.setState({
      loggedIn: false,
    })
  }

  render() {
    if (this.state.loading) {
      return <div className="center">
        <Spinner />
      </div>
    }
    if (!this.state.loggedIn) {
      return <>
        <UserLogin
          setLoggedIn={ () => this.setLoggedIn() }
        />
        <footer className='loginFooter'>
          <a href='https://lubbers.de/impressum' target='_blank'>Impressum</a>&nbsp;
          <a onClick={() => {
            mainservice.navTo([['view', 'datenschutz']])
          }}>Datenschutz</a>
        </footer>
      </>
    }

    return <div className='admin-tool'>
      <h1>Core Admin</h1>
      <p className='description'>Eingeloggt als Admin.</p>
      <h2>Aktionen</h2>
      <ul>
        <li><a onClick={() => mainservice.navTo([['view', 'mediaprintdemo']])}>Druck Demo</a></li>
        <li><a onClick={() => mainservice.navTo([['view', 'spVeranstaltungsPlaner']])}>Veranstaltungsplaner</a></li>
        <li><a onClick={() => this.setLoggedOut()}>Ausloggen</a></li>
      </ul>
    </div>
  }
}

export default CoreDashboard
