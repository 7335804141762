import ObjGeneric, {DObj, OGProps, OGState, Status} from './ObjGeneric'

export default class ObjText extends ObjGeneric {
  constructor(props: OGProps) {
    super(props)
  }
  public render() {
    const obj = this.props.data
    let x, y, w, h
    if (obj.xStart < obj.xEnd) {
      x = obj.xStart
      w = obj.xEnd - obj.xStart
    } else {
      x = obj.xEnd
      w = obj.xStart - obj.xEnd
    }

    if (obj.yStart < obj.yEnd) {
      y = obj.yStart
      h = obj.yEnd - obj.yStart
    } else {
      y = obj.yEnd
      h = obj.yStart - obj.yEnd
    }

    const size = Math.min(w, h)

    return (
      <text
        key={obj.id}
        x={x}
        y={y}
        width={obj.locked ? size : w}
        height={obj.locked ? size : h}
        fill={obj.bg}
        onMouseDown={(event) => { this.onMouseDown(event) }}
        onMouseUp={(event) => { this.onMouseUp(event) }}
        fontSize={obj.fontSize}
      >
      {
        this.renderContent(x, y)
      }
      </text>
    )
  }

  public renderContent (xIn: number, yIn: number) {
    const text = (!this.state.text) ? ['empty'] : this.state.text.split('\n')
    return text.map((t, index) => <tspan
      key={index}
      x={xIn}
      y={yIn + this.state.lineHeight * index}
    >{t}</tspan>)
  }
}
