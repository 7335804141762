export default class ArrayManager {
  arr: (string | number)[] = []

  has(value: string | number) {
    return !!this.arr.find(a => a === value)
  }

  hasNot(value: string | number) {
    return !this.has(value)
  }

  add(value: string | number) {
    if (this.hasNot(value)) {
      this.arr.push(value)
    }
    return this.arr
  }

  remove(value: string | number) {
    this.arr = this.arr.filter(a => a !== value)
    return this.arr
  }

  toggle(value: string | number) {
    console.log('TOGGLE', this.arr)
    if (this.has(value)) {
      this.remove(value)
    } else {
      this.add(value)
    }
    console.log('TOGGLE', this.arr)
  }

  reset() {
    this.arr = []
    return this.arr
  }

  getAll() {
    return this.arr
  }

  count() {
    return this.arr.length
  }
}
