import { GenericDTO } from "../DTO/GenericDTO";
import { CountHolder } from "./CountHolder";

export function MakePrintLink(options: {
  flips: GenericDTO[],
  size: string,
  countHolder?: CountHolder,
  standardCountHolder?: Map<number, number>,
  option?: 'pdf' | 'fotoprotokoll'
  // , participantCount: number
}) {
  const flips = options.flips
  const size = options.size
  const countHolder = options.countHolder
  const standardCountHolder = options.standardCountHolder
  const option = options.option || 'pdf'
  const printOrders = flips.map(f => {
    const currentFileProp = f.getCurrentFile();
    const file = currentFileProp?.file;
    if (!file) { return ''; }
    const prefix = file.fileName.search(/\.pdf$/) > -1 ? 'p_' : 'j_';
    const fileRepresentation = prefix + file?.id + size;
    const count_raw = (countHolder && standardCountHolder) ? (countHolder.has(f.id) ? countHolder.get(f.id) : standardCountHolder.get(f.id)) : 1;
    const count: number = (count_raw === undefined) ? 1 : count_raw;
    let out = '';
    for (let i = 0; i < count; i++) {
      if (i > 0) {
        out += ';';
      }
      out += fileRepresentation;
    }
    return out;
  }).join(';');
  if (printOrders) {
    return `https://suha.duia.eu/lubberspdf/index.php?option=${option}&items=${printOrders}`;
  }
  return '';
}


