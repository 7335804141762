export type TaskFunction = () => Promise <any>
export type Task = {
  name: string,
  task: TaskFunction
}

export type Result = {
  name: string,
  result: any
}

/**
 * This functions takes multiple Async functions and waits for all to finish
 * It returns every result as an array
 * @param tasks A list of functions to execute
 * @param options optional - Object of options:
 * pureResultArray?: boolean, // if set it only returns the results as array
 * @returns Promises to return the result of all functions
 */

export default async function AsyncWaitForAll(tasks: (Task | TaskFunction)[], options?: {
  pureResultArray?: boolean
}): Promise <Result[]> {
  const ts: Task[] = tasks.map((t, index) => {
    if (typeof t === 'function') {
      return {name: `${index}`, task: t}
    }
    if (!t.name) {
      return {name: `${index}`, task: t.task}
    }
    return t
  })
  return new Promise((resolve, reject) => {
    let doneArr: Result[] = []
    const testDone = ((name: string, result: any) => {
      doneArr.push({name: name, result: result})
      if (ts.every(t => doneArr.some(d => d.name === t.name))) {
        if (options?.pureResultArray === true) {
          doneArr.map(d => d.result)
          return
        }
        resolve(doneArr)
      }
    })
    ts.forEach(t => {
      t.task().then((r) => {
        testDone(t.name, r)
      }).catch(err => reject(err))
    })
  })
}
