import React from 'react'
import config from '../../config.json'

type Props = {
  uploadCallBack?: any
  replaceFileId?: number
}

enum Status {
  standard,
  uploading
}

type State = {
  isSelected: boolean,
  selectedFile: any,
  status: Status
}

export default class FileUploader extends React.Component<Props, State> {
  uploadCallBack: any
  constructor(props: Props) {
    super(props)
    this.state = {
      isSelected: false,
      selectedFile: {},
      status: Status.standard
    }
    if (props.uploadCallBack) {
      this.uploadCallBack = props.uploadCallBack
    }
  }

  private changeHandler = (event: any) => {
    console.log('changeHandler', event.target.files[0])
    this.setState({
      selectedFile: event.target.files[0],
      isSelected: true
    })
  }

  handleSubmission = () => {

    const formData = new FormData();
    formData.append('FileName', this.state.selectedFile);
    console.log('FILENAME', this.state.selectedFile)
    if (this.state.selectedFile.size > 5000000 && !window.confirm('Diese Datei ist sehr groß (über 5 MB). Dies kann zu Fehlern führen. Trotzdem versuchen fortzufahren?')) {
      return
    }
    this.setState({
      status: Status.uploading
    })
    const target = `${config.apiPrefix}File/` + ((this.props.replaceFileId) ? `${this.props.replaceFileId}/replace` : `upload`)
    try {
      fetch(target, {
          method: 'POST',
          body: formData,
        }
      ).then((response) => {
        if (!response) {
          console.log('err!!!!')
        }
        return response.json()
      }).then((result) => {
        console.log('Success:', result);
        if (this.uploadCallBack) {
          this.uploadCallBack(result)
        }
      }).catch((error) => {
        console.error('Errör:', error);
        throw(error)
      })
    } catch(err: any) {
      console.log('did not work', err)
      alert('Fehler beim Upload!')
    }
  }

  render() {
    return  <div>
      <input type="file" name="file" onChange={this.changeHandler} />
      {this.state.isSelected ? (
        <div>
          <p>Filename: {this.state.selectedFile.name}</p>
          <p>Filetype: {this.state.selectedFile.type}</p>
          <p>Size in bytes: {this.state.selectedFile.size}</p>
          <p>
            lastModifiedDate:
            {new Date(this.state.selectedFile.lastModified).toString()}
          </p>
        </div>
      ) : (
        <p>Select a file to show details</p>
      )}
        <div>
          {this.state.status === Status.standard &&
            <button onClick={this.handleSubmission}>Submit</button>
          }
          {this.state.status === Status.uploading &&
            <div>Lade Hoch...</div>
          }
        </div>
    </div>
  }
}
