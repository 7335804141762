import React, {useState} from 'react'
import Spinner from '../Spinner/Spinner'
import { Getter, Setter } from '../../services/ComService'

enum Status {
  start,
  loading,
  mailFormatError,
  done
}

const DoRegister = async (emailRaw: string, firstname: string, surname: string, company: string) => {
  const emailArr = emailRaw.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
  if (!emailArr) {
    return Status.mailFormatError
  }
  const email = emailArr[0]
  const challenge: {
    timestamp: number,
    question: string
  } | any = await Setter('user/register/challenge', {
    email: email
  })
  if (!challenge) { return }
  const q = challenge.question
  const answer = `${challenge.timestamp * 3}${email[2]}${email[4]}${email[1]}${email[0]}${email[2]}${email[3]}_${q.substr(0,4)}_${challenge.timestamp * 2}`
  await Setter('user/register', {
    email: email,
    timestamp: challenge.timestamp,
    answer: answer,
    firstname: firstname,
    surname: surname,
    company: company
  }, {retryName: `${email}`})
  return Status.done
}

function ShowRegisterFormular (props: {
  setStatus: (s: Status) => void,
  status: Status
}) {
  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [surname, setSurname] = useState('')
  const [company, setCompany] = useState('')
  const [status, setStatus] = useState(props.status)
  return <div className='w3-container'>
      <label>Vorname</label>
      <input
        className='w3-input'
        onChange={(event) => {
          setFirstname(event.target.value)
        }}
        value={firstname}
      ></input>
      <label>Name</label>
      <input
        className='w3-input'
        onChange={(event) => {
          setSurname(event.target.value)
        }}
        value={surname}
      ></input>
      <label>Firma</label>
      <input
        className='w3-input'
        onChange={(event) => {
          setCompany(event.target.value)
        }}
        value={company}
      ></input>
      <label>E-Mail Adresse</label>
      <input
        className='w3-input'
        onChange={(event) => {
          setEmail(event.target.value)
        }}
        onKeyDown={async (event) => {
          if (event.key === 'Enter') {
            setStatus(Status.loading)
            const newStatus = await DoRegister(email, firstname, surname, company)
            if (!newStatus) { return }
            if (newStatus === Status.done) {
              props.setStatus(newStatus)
            }
            else {
              setStatus(newStatus)
            }
          }
        }}
      ></input>
      {
        status === Status.mailFormatError &&
        <div className='error-box'>
          Das Format der E-Mail Adresse ist falsch!
        </div>
      }
      <button
        className='w3-btn w3-ripple register-button'
        onClick={async () => {
          setStatus(Status.loading)
          const newStatus = await DoRegister(email, firstname, surname, company)
          if (!newStatus) { return }
          if (newStatus === Status.done) {
            props.setStatus(newStatus)
          }
          else {
            setStatus(newStatus)
          }
        }}
      >Registrieren</button>
    </div>
}

function UserRegister (props: {
  showAsElement?: boolean,
  className?: string
}) {
  const [status, setStatus] = useState(Status.start)
  const content = () => {
    switch(status) {
      case Status.start:
      case Status.mailFormatError:
        return <ShowRegisterFormular
            setStatus={(s: Status) => setStatus(s)}
            status={status}
          />
      case Status.loading:
        return <div className='w3-container'>
            <p>Dein Antrag wird bearbeitet.</p>
            <p className='w3-center'><Spinner
            mini={true}
            sizeClass='normalSize'
            /></p>
          </div>
      case Status.done:
        return <div className='w3-container'>
          <p>
            Dein Registrierungsantrag ist bei uns eingegangen.
          </p>
          <p className='w3-center fontSize8'>📬</p>
          <p>
            Um Dich zu verifizieren bitte auf den Bestätigungslink klicken, den wir Dir per Mail geschickt haben!
          </p>
        </div>
    }
  }
  if (props.showAsElement) {
    return <div className={`w3-card-4 lu-dialog ${props.className || ''}`}>
      <div className='w3-container lu-headline'>Registrieren</div>
      { content() }
    </div>
  }
  return <div className={`w3-row`}>
    <div className="w3-col l4 m3 s0">&nbsp;</div>
    <div className='w3-card-4 lu-dialog w3-col l4 m6 s12'>
      <div className='w3-container lu-headline'>Registrieren</div>
      { content() }
    </div>
  </div>

}

export default UserRegister
