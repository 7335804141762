import { useEffect, useState } from "react";
import PageElementDTO from "../../DTO/PageElementDTO";
import { WidgetKind } from "../../DTO/WidgetDTO";
import WidgetUserDTO from "../../DTO/WidgetUserDTO";
import Spinner from "../Spinner/Spinner";
import WidgetUserRating from "./WidgetUserRating";
import WidgetList from "./WidgetList"
import mainservice from "../../services/MainService";
import widgetService from "../../services/WidgetService";
import WidgetDiagram from "./WidgetDiagram";

enum Status {
  loading,
  done,
}
export default function WidgetUser(props: {
  id?: number,
  // widget?: WidgetUserDTO,
  pageElement?: PageElementDTO,
  rerender?: number,
}) {
  // const w = props.widget
  let broadCastStamp = 0
  /*
  const [w] = useState(props.widget || new WidgetUserDTO({
    id: props.id !== undefined ? props.id : -1,
    pageElement: props.pageElement,
  }))
  */
  const [w] = useState(widgetService.get({
    widgetId: props.id,
    pageElement: props.pageElement
  }))
  const widgetKey = 'widget-' + w.id
  const uniqueWidgetKey = 'widget-' + w.id + '-onpageelement-' + props.pageElement?.id
  const [status, setStatus] = useState(props.id !== undefined ? Status.loading : Status.done)
  // const [rerender, setRerender] = useState(props.rerender)
  const loadContent = () => {
    if (props.id !== undefined && props.id > -1) {
      setStatus(Status.loading)
      w.getFromServer().then(() => {
        setStatus(Status.done)
        // setRerender(new Date().getTime())
        // setRerender(props.rerender)
      })
    }
  }
  useEffect(() => {
    loadContent()
    w.setInteractionCb(() => {
      broadCastStamp = new Date().getTime()
      mainservice.broadcast(widgetKey, broadCastStamp)
    })
    mainservice.registerToBroadcast(uniqueWidgetKey, (key, value) => {
      if (key === widgetKey && value !== broadCastStamp) {
        loadContent()
      }
    })
  }, [])
  /*
  if (rerender !== props.rerender) {
    loadContent()
  }
  */
  if (status === Status.loading) {
    return <Spinner />
  }
  switch(w.getType()) {
    case WidgetKind.list:
      if (w.getPropV1({key1: 'globProp', key2: 'generalLayout'}) === 'diagram') {
        return <WidgetDiagram
            widget={w}
            pageElement={props.pageElement}
          />
      }
      return <WidgetList
        widget={w}
        pageElement={props.pageElement}
      />
    case WidgetKind.rating:
    default:
      return <WidgetUserRating
        widget={w}
        pageElement={props.pageElement}
      />
  }
}