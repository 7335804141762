import React, { useRef } from 'react'
import ReactDomServer from 'react-dom/server'
import { HexColorPicker } from 'react-colorful'
import { DObj } from './ObjGeneric'
import { moveIdToEndPosition, importFromRaw } from '../../DTO/SvgDTO'
import ObjCirc from './ObjCirc'
import ObjRect from './ObjRect'
import ObjText from './ObjText'
import InputA from '../InputA/InputA'
import { ChevronsUp, ChevronsDown, MousePointer, Save, Square, Circle, Type, Trash, ArrowDownRight } from 'react-feather'
import './SvgEditor.scss'

type Props = {
  onChange?: (c: string) => void,
  value?: string,
  backgroundImage?: string,
  backgroundImageStyle?: string,
  type?: string
}

type State = {
  tool: string,
  selectedObj: number | null,
  rectMove: number | null,
  rectResize: number | null,
  rectObject: number | null,
  dragStart: XY,
  objects: DObj[],
  textContent: string,
  currentColor: string,
  lastColors: string[],
  backgroundImage: string,
  backgroundImageStyle: string,
  type: string,
  currentFontSize: number,
  backgroundColor: string
}

type XY = {x: number, y: number}
type ClientXY = {clientX: number, clientY: number, shiftKey?: any}


export default class SvgEditor extends React.Component<Props, State> {
  svgRef: any = React.createRef()
  public broadcastReceiver = new Map()
  private svgCoordInfo = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    viewBoxWidth: 0,
    viewBoxHeight: 0,
    xRatio: 0,
    yRatio: 0
  }
  constructor(props: Props) {
    super(props)

    this.state = {
      tool: 'drag',
      selectedObj: null,
      rectMove: null,
      rectObject: null,
      rectResize: null,
      dragStart: {x: 0, y: 0},
      objects: [],
      textContent: '----empty----',
      currentColor: '#000', // TODO: Please remove
      lastColors: ['#000'],
      backgroundImage: props.backgroundImage || '',
      backgroundImageStyle: props.backgroundImageStyle || '',
      type: props.type || '',
      currentFontSize: 12,
      backgroundColor: '#fff'
    }
  }

  componentDidMount () {
    if (this.props.value !== undefined) {
      const newObjects = importFromRaw(this.props.value)
      this.setState({
        objects: newObjects || []
      })
    } else {
      try {
        this.setState(JSON.parse(localStorage.getItem("canvas") || '{}'))
      } catch (err) {
        console.warn("Failed to restore state", err)
      }
    }
    this.setSvgCoordInfo()
    window.setTimeout(() => {
      if (this.props.onChange) {
        const newContent = this.renderForSaving()
        console.log('newContent after deleting', newContent)
        this.props.onChange(newContent)
      }
    }, 100)
  }

  componentWillReceiveProps(props: Props) {
    this.setState({
      backgroundImageStyle: props.backgroundImageStyle || '',
      backgroundImage: props.backgroundImage || ''
    })
  }

  componentDidUpdate () {
    localStorage.setItem("canvas", JSON.stringify(this.state))
  }

  // Send Broadcast to all interested parties:
  public broadcast(key: string, value: any) {
    this.broadcastReceiver.forEach((bR, bRKey: string) => {
      if (bR && bR.fkt) {
        bR.fkt(key, value)
      } else {
        this.unregisterBroadcast(bRKey)
      }
    })
  }

  public registerToBroadcast(
    key: string,
    fkt: (key: string, value: any) => void
  ) {
    this.broadcastReceiver.set(key, {
      fkt: fkt
    })
  }

  public unregisterBroadcast(key: string) {
    this.broadcastReceiver.delete(key)
  }

  setSvgCoordInfo() {
    const { top, left } = this.svgRef.current.getBoundingClientRect()
    const {x: x, y: y} = this.getViewWidthHeight()
    const width = this.svgRef.current.clientWidth
    const height = this.svgRef.current.clientHeight
    this.svgCoordInfo = {
      top: top,
      left: left,
      width: width,
      height: height,
      viewBoxWidth: x,
      viewBoxHeight: y,
      xRatio: x / width,
      yRatio: y / height
    }
  }

  getCoords (o: ClientXY) {
    const i = this.svgCoordInfo
    return { x: (o.clientX - i.left) * i.xRatio, y: (o.clientY - i.top) * i.yRatio }
  }

  handleSelectTool (tool: string) {
    this.setState({tool: tool})
  }

  handleMouseDownObj (obj: DObj, e: {clientX: number, clientY: number}) {
    const { tool } = this.state
    this.setSvgCoordInfo()
    if (tool === 'drag' || tool === 'resize') {
      this.setState({
        rectMove: (tool === 'drag') ? obj.id : null,
        rectResize: (tool === 'resize') ? obj.id : null,
        selectedObj: obj.id,
        dragStart: this.getCoords(e),
        textContent: '----empty----',
        lastColors: this.pushColorToHistory(obj.bg),
        currentColor: obj.bg
      })
      this.broadcast('newColor', obj.bg)
    }
  }

  handleMouseUpObj (_obj: any, _e: any) {
    this.setState({ rectMove: null, rectResize: null })
    if (this.props.onChange) {
      this.props.onChange(this.renderForSaving())
    }
  }

  handleMouseDown (e: ClientXY) {
    const { shiftKey } = e
    const { x: xStart, y: yStart } = this.getCoords(e)
    this.setSvgCoordInfo()
    if (this.state.tool.search(/^(rect|ellipse|text)$/) > -1) {
      this.setState(s => {
        const obj = {
          id: Date.now(),
          type: this.state.tool,
          bg: this.state.currentColor,
          xStart,
          yStart,
          xEnd: xStart,
          yEnd: yStart,
          locked: shiftKey,
          content: ''
        }
        return {
          objects: [...s.objects, obj],
          rectObject: obj.id
        }
      })
    }
  }

  handleMouseMove (e: ClientXY) {
    const { rectObject, rectResize, rectMove, dragStart } = this.state;
    const currentPosition = this.getCoords(e);
    // This first part is for new items and resizing:
    if (rectObject || rectResize) {
      const ob = rectObject || rectResize
      if (ob) {
        const index = this.state.objects.findIndex(o => o.id === ob)
        const obj = {
          ...this.state.objects[index],
          xEnd: currentPosition.x,
          yEnd: currentPosition.y,
          locked: e.shiftKey
        }
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            obj,
            ...this.state.objects.slice(index + 1)
          ]
        })
      }
    }

    if (rectMove && dragStart) {
      const index = this.state.objects.findIndex(o => o.id === rectMove)
      const { x: xDragStart, y: yDragStart } = dragStart
      const xDelta = currentPosition.x - xDragStart
      const yDelta = currentPosition.y - yDragStart
      const obj = this.state.objects[index]
      this.setState({
        dragStart: currentPosition,
        objects: [
          ...this.state.objects.slice(0, index),
          {
            ...obj,
            xStart: obj.xStart + xDelta,
            xEnd: obj.xEnd + xDelta,
            yStart: obj.yStart + yDelta,
            yEnd: obj.yEnd + yDelta
          },
          ...this.state.objects.slice(index + 1)
        ]
      })
    }
  }

  handleMouseUp (_e: any) {
    this.setState({
      rectMove: null,
      rectObject: null,
      rectResize: null,
      tool: (this.state.tool === 'resize') ? 'resize' : 'drag'
    })
    if (this.props.onChange) {
      this.props.onChange(this.renderForSaving())
    }
  }

  renderCirc (obj: DObj, index: number) {
    return <ObjCirc
      key={`${obj.id}-${index}`}
      data={obj}
      onMouseUp={(obj, event) => {this.handleMouseUpObj(obj, event)}}
      onMouseDown={(obj, event) => {this.handleMouseDownObj(obj, event)}}
      editor={this}
    />
  }

  renderRect (obj: DObj, index: number) {
    return <ObjRect
      key={`${obj.id}-${index}`}
      data={obj}
      onMouseUp={(obj, event) => {this.handleMouseUpObj(obj, event)}}
      onMouseDown={(obj, event) => {this.handleMouseDownObj(obj, event)}}
      editor={this}
    />
  }

  renderText (obj: DObj, index: number) {
    return <ObjText
      key={`${obj.id}-${index}`}
      data={obj}
      onMouseUp={(obj, event) => {this.handleMouseUpObj(obj, event)}}
      onMouseDown={(obj, event) => {this.handleMouseDownObj(obj, event)}}
      editText={(obj) => { this.setState({textContent: obj.content})}}
      editor={this}
    />
  }

  renderSvgContent() {
    const renderObjects = this.state.objects.map((o, index) => {
      if (o.type === "rect") { return this.renderRect(o, index) }
      if (o.type === "ellipse") { return this.renderCirc(o, index) }
      if (o.type === "text") { return this.renderText(o, index) }
      return null
    })
    if (renderObjects.length > 0) {
      return renderObjects
    }
    return <rect x="0" y="0" width="0" height="0" fill="#fff"></rect>
  }

  renderForSaving() {
    return ReactDomServer.renderToString(<svg
        viewBox={this.setViewBox()}
        className={`collectible-graphic ${this.state.backgroundImageStyle}`}
        fill={this.state.backgroundColor}
        style={{
          //backgroundImage: this.state.backgroundImage,
          backgroundImage: 'BACKGROUNDIMGPLACEHOLDER',
          backgroundColor: this.state.backgroundColor
        }}
      >{this.renderSvgContent()}</svg>)
  }

  updateObject(updates: any, id?: number) {
    const i = (id === undefined) ? this.state.selectedObj : id
    this.setState({
      objects: this.state.objects.map((o) => {
        if (o.id === i) {
          o = {
            ...o,
            ...updates
          }
        }
        return o
      }),
      lastColors: this.pushColorToHistory(updates.bg),
      currentColor: updates.bg
    })
    this.broadcast('newColor', updates.bg)
  }

  pushColorToHistory(c: string) {
    return [c].concat(this.state.lastColors.filter((o) => o != c)).slice(0, 12)
  }

  renderColorPicker() {
    return <div className='colorPicker'>
      <InputA
        value={this.state.currentColor || '#000'}
        returnVal={(rv) => this.updateObject({bg: rv})}
        broadCastParent={this}
        newValBroadCastKey='newColor'
      />
      <HexColorPicker
        color={this.state.objects.find((o) => o.id === this.state.selectedObj)?.bg}
        onChange={(nc) => {
          this.updateObject({bg: nc})
        }}
      />
      <div className='lastColors'>
        {
          this.state.lastColors.map((c, index) => <div
            key={index}
            className='oldColor'
            style={{
              backgroundColor: c
            }}
            onClick={() => this.updateObject({bg: c})}
          ></div>)
        }
      </div>
    </div>
  }

  renderUpperToolBar() {
    const { objects, tool } = this.state
    return <div className='upperToolBar'>
    <div className="toolbar">
      {
        [
          {tool: 'rect', content: <Square />},
          {tool: 'ellipse', content: <Circle />},
          {tool: 'text', content: <Type />},
          {tool: 'drag', content: <MousePointer />},
          {tool: 'resize', content: <ArrowDownRight />}
        ].map(t => (
        <button key={t.tool}
                onClick={this.handleSelectTool.bind(this, t.tool)}
                className={`${(tool === t.tool) ? 'active' : ''}`}>
                {t.content}
        </button>
        ))
      }
      <button
        className={(!this.state.selectedObj) ? 'disabled' : ''}
        onClick={() => {
          const newObjects = this.state.objects.filter((o) => {
            return o.id != this.state.selectedObj
          })
          this.setState({
            objects: newObjects,
            selectedObj: null
          })
            window.setTimeout(() => {
              if (this.props.onChange) {
                const newContent = this.renderForSaving()
                this.props.onChange(newContent)
              }
            }, 100)
        }}
      ><Trash /></button>
      <button
        className={(!this.state.selectedObj) ? 'disabled' : ''}
        onClick={() => {
          this.setState({
            objects: []
          })
          window.setTimeout(() => {
            this.setState({
              objects: moveIdToEndPosition(objects, this.state.selectedObj, false)
            })
          }, 1)
        }}
      ><ChevronsUp /></button>
      <button
        className={(!this.state.selectedObj) ? 'disabled' : ''}
        onClick={() => {
          this.setState({
            objects: []
          })
          window.setTimeout(() => {
            this.setState({
              objects: moveIdToEndPosition(objects, this.state.selectedObj, true)
            })
          }, 1)

        }}
      ><ChevronsDown /></button>
    </div>
    </div>
  }

  setViewBox() {
    const {x: x, y: y} = this.getViewWidthHeight()
    return `0 0 ${x} ${y}`
  }

  getViewWidthHeight() {
    if (this.props.type === 'flashcard' || this.props.type === 'motto') {
      return {x: 500, y: 652}
    }
    return {x: 500, y: 500}
  }

  render () {
    const { objects, tool } = this.state
    return (
      <div className='SvgEditor'>
        {this.renderUpperToolBar()}
        <div className="canvas">



          <svg onMouseDown={this.handleMouseDown.bind(this)}
               onMouseUp={this.handleMouseUp.bind(this)}
               onMouseMove={this.handleMouseMove.bind(this)}
               className={`tool--${tool} collectible-graphic ${this.state.backgroundImageStyle}`}
               style={{
                 backgroundImage: this.state.backgroundImage,
                 backgroundColor: this.state.backgroundColor
               }}
               viewBox={this.setViewBox()}
               ref={this.svgRef}
          >
            {
              this.renderSvgContent()
            }
            <text className="typedemo" x="36" y="51" font-size="24">lernkarte</text>
          </svg>

        </div>

        <div className='w3-container bottomTools'>
          <div className='w3-half'>
            {
              this.renderColorPicker()
            }
          </div>
          <div className='w3-half'>
            {
              this.state.textContent != '----empty----' &&
              <InputA
                value={this.state.textContent}
                type={'textArea'}
                contentType='svg'
                returnVal={(rv) => {
                  // Put result into object
                  this.setState({
                    objects: this.state.objects.map((o) => {
                      if (o.id === this.state.selectedObj) {
                        o.content = rv
                      }
                      return o
                    }),
                    textContent: '----empty----'
                  })
                  this.broadcast('refresh-' + this.state.selectedObj, rv)
                  if (this.props.onChange) {
                    this.props.onChange(this.renderForSaving())
                  }
                }}
              />
            }
            {
              [6, 12, 18, 24, 36, 60].map((n) => <button
                key={n}
                onClick={() => {
                  this.setState({
                    objects: this.state.objects.map((o) => {
                      if (o.id === this.state.selectedObj) {
                        o.fontSize = n
                      }
                      return o
                    })
                  })
                  this.broadcast('refresh-font-size' + this.state.selectedObj, n)
                  if (this.props.onChange) {
                    this.props.onChange(this.renderForSaving())
                  }
                }}
              >
                {n}
              </button>)
            }
          </div>
        </div>

      </div>
    )
  }
}
