export enum DisplayKind {
  standard,
  gallerie
}

export type MaterialKind = {
  value: string,
  title: string,
  displayKind?: DisplayKind
}

export const Materialkinds = [
  {
    value: 'expandable',
    title: 'Verbrauchsmaterial',
    displayKind: DisplayKind.standard
  },
  {
    value: 'inventory',
    title: 'Bestand',
    displayKind: DisplayKind.standard
  },
  {
    value: 'digitalFlip',
    title: 'Flip',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'digitalHandout',
    title: 'Handout',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'digitalPoster',
    title: 'Plakat',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'digitalPin',
    title: 'Pin',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'digitalFile',
    title: 'Datei',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'collectible',
    title: 'Lernkarte',
    displayKind: DisplayKind.gallerie
  },
  {
    value: 'moderationCard',
    title: 'Moderationskarte',
    displayKind: DisplayKind.gallerie
  },
];
