import React from 'react'
import BookDTO, {Layer} from '../../DTO/BookDTO'
import DoublePageDTO from '../../DTO/DoublePageDTO'
import Spinner from '../Spinner/Spinner'
import FloatIcon from '../FloatIcon/FloatIcon'
import DoublePage from '../DoublePage/DoublePage'
import { X } from 'react-feather'
import BookAdminBar from './BookAdminBar'
import UserWidget from '../User/UserWidget'
import UserDashMainMenu from '../User/UserDashMainMenu'
import User from '../User/User'
import { Getter } from '../../services/ComService'
import mainservice from '../../services/MainService'
import TOC from './TOC'
// import BookSelector from '../Booklist/BookSelector'
import FullscreenButton from '../FullscreenButton/FullscreenButton'
// import { Setter } from '../../services/ComService'
// import InputA from '../InputA/InputA'
import DoublePageOverview from './DoublePageOverview'
import Branding from  './Branding'
import './Book.scss'
import CollectibleListA from '../Collectible/CollectibleListA'
import ProgressBar from '../ProgressBar/ProgressBar'
import { ArrowLeft, ArrowRight } from '../Icon/Icon'
import LockNavigationService from '../../services/LockNavigationService'

enum LDrawerMode {
  closed,
  open
}

enum RightStatus {
  visible,
  notLoggedIn,
  notAllowed
}

type Props = {
  selectFkt: any
}

type State = {
  loading: boolean,
  book?: BookDTO,
  isAdmin: number,
  currentDoublePage: number,
  doublePageId: number,
  adminEndlessMode: boolean,
  enableNextPage: boolean,
  doublePageSection: DoublePageSection[],
  dragging: boolean,
  interactionLayer: Layer,
  lDrawerMode: LDrawerMode,
  noscroll: boolean,
  showCollectibleSelect: number | null,
  rightStatus: RightStatus
  // refCollectionEarmark: number,
  // markedDoublePages: number[]
}

type DoublePageSection = {
  doublePage: DoublePageDTO,
  index: number,
  sliderClass: string,
  render: boolean
}

export default class Book extends React.Component<Props, State> {
  selectFkt: any
  state: State
  bookContentRef: any
  // activeDoublePageRef: number = -1
  doublePageRefIndex = new Map()
  nextPageBlockInterval: any
  dragStart: [number, number] = [0, 0]
  dragCurrent: [number, number] = [0, 0]
  dragMaxYOnXDrag: number = 20
  dragTriggerXOnXDrag: number = 100
  letKeyNavStatus: boolean = true
  listeners = [
    {
      name: 'keydown',
      cb: (e: any) => {
        if (!this.letKeyNavStatus) { return }
        if (e.keyCode === 37) {
          this.goToPrevPage()
          return
        }
        if (e.keyCode === 39) {
          this.goToNextPage()
          return
        }
      }
    },
    {
      name: 'popstate',
      cb: () => {
        mainservice.nav.getRoute()
        const newPage = mainservice.nav.getVal('page')
        if (newPage > 0) {
          this.changeCurrentPage(newPage as unknown as number)
          return
        }
        // If we did not find a page - we will navigate to the url described:
        // mainservice.nav.getRoute()
        mainservice.broadcast('reloadMain', true)
      }
    },
    {
      name: 'touchstart',
      cb: (e: any) => {
        if (!mainservice.pageFlipInteractive) { return }
        this.setState({dragging: true})
        const t = e.touches[0]
        this.dragStart = [t.clientX, t.clientY]
        this.dragCurrent = [0, 0]
      }
    },
    {
      name: 'touchmove',
      cb: (e: any) => {
        if (!mainservice.pageFlipInteractive) { return }
        if (!this.state.dragging) { return }
        const t = e.touches[0]
        const dc = [t.clientX, t.clientY]
        const ddc: [number, number] = [-this.dragStart[0] + dc[0], -this.dragStart[1] + dc[1]]
        this.dragCurrent = ddc
        if (ddc[1] > this.dragMaxYOnXDrag || ddc[1] < -this.dragMaxYOnXDrag) {
          this.setState({dragging: false})
          mainservice.currentDoublePageRef.current.style.transform = `translate3d(0, 0, 0)`
          return
        }
        mainservice.currentDoublePageRef.current.style.transform = `translate3d(${ddc[0]}px, 0, 0)`
      }
    },
    {
      name: 'touchend',
      cb: () => {
        if (!mainservice.pageFlipInteractive) { return }
        const ddc = this.dragCurrent
        this.setState({dragging: false})
        const current = mainservice.currentDoublePageRef.current

        if (ddc[0] > this.dragTriggerXOnXDrag) {
          if (this.goToPrevPage()) {
            current.className += ' toTheRight'
            current.style.transform = `translate3d(0, 0, 0)`
            return
          }
        }
        if (ddc[0] < -this.dragTriggerXOnXDrag) {
          if (this.goToNextPage()) {
            current.className += ' toTheLeft'
            current.style.transform = `translate3d(0, 0, 0)`
            return
          }
        }
        current.style.transform = `translate3d(0, 0, 0)`
      }
    },
    {
      name: 'touchcancel',
      cb: () => {
        if (!mainservice.pageFlipInteractive) { return }
        this.setState({dragging: false})
        mainservice.currentDoublePageRef.current.style.transform = `translate3d(0, 0, 0)`
      }
    },
  ]

  constructor(props: Props) {
    super(props)
    this.selectFkt = props.selectFkt
    this.bookContentRef = React.createRef()
    const currentDoublePage = mainservice.nav.getVal('page') as unknown as number || 1
    this.state = {
      loading: true,
      isAdmin: mainservice.isAdmin(),
      currentDoublePage: currentDoublePage,
      doublePageId: mainservice.nav.getVal('doublePageId') as unknown as number || 0,
      adminEndlessMode: false,
      enableNextPage: true,
      doublePageSection: [],
      dragging: false,
      interactionLayer: Layer.element,
      lDrawerMode: LDrawerMode.closed,
      noscroll: false,
      showCollectibleSelect: null,
      rightStatus: RightStatus.visible
      // refCollectionEarmark: -1,
      // markedDoublePages: []
    }
    mainservice.setCurrentBookView(this)
    mainservice.registerToBroadcast(
      'book',
      (key: string, value: any) => {
        switch(key) {
          case 'textEditBlur':
            this.letKeyNavStatus = true
            break
          case 'textEditFocus':
            this.letKeyNavStatus = false
            break
          case 'openBookMainMenu':
            this.setState({lDrawerMode: LDrawerMode.open})
            break
          case 'loginsuccess':
          // case 'logoutsuccess':
            this.getContent(true)
            this.setState({
              lDrawerMode: LDrawerMode.closed
            })
            break
          /*
          case 'bookSelectorBookSelected':
            this.setState({
              refCollectionEarmark: value
            })
            break
          */
          case 'enablescrollbook':
            this.setState({
              noscroll: value
            })
            break
          case 'element-collectible-selector':
            this.setState({showCollectibleSelect: value as number || null})
            break
          case 'nav':
            // Test, if book id has changed
            if (value.view !== 'book') { return }
            if (this.state.book?.id !== mainservice.nav.getVal('book')) {
              this.getContent()
            }
        }
      },
      this
    )
  }

  public async getContent(fresh?: boolean) {
    const bookId: number = mainservice.nav.getVal('book') as number
    const doublePageId: number = mainservice.nav.getVal('doublePageId') as number
    const sessionId: string = mainservice.nav.getVal('session') as string
    const percentage: number = mainservice.nav.getVal('percentage') as number
    type Status = {status: string}
    let pIndex: number = -1
    const result: BookDTO | Status = await mainservice.getBook(bookId, fresh)
    if (result && (result as Status).status === 'error') {
      // Check logged in:
      const isLoggedIn = await mainservice.loginService.isLoggedIn()
      if (!isLoggedIn) {
        this.setState({rightStatus: RightStatus.notLoggedIn})
        return
      }
      this.setState({rightStatus: RightStatus.notAllowed})
      return
    }
    const r = result as BookDTO
    const hash: string = await r.getSession(sessionId)
    mainservice.nav.setVal('session', hash, true)
    if (doublePageId) {
      pIndex = r.doublePages.findIndex(d => d.id === doublePageId)
    }
    // Fallback: If doublePageId is missing - place user on similar position:
    if (pIndex === -1 && percentage > 0) {
      const pageCount = r.doublePages.length
      pIndex = Math.round(percentage * pageCount * 0.01)
    }
    if (pIndex > -1) {
      this.setDoublePageSection({
        book: r,
        currentDoublePageIndex: Math.max(pIndex, 1)
      })
    } else {
      this.setDoublePageSection({book: r})
    }
  }

  public setInterationLayer(layer: Layer) {
    this.setState({
      interactionLayer: layer
    })
  }

  setDoublePageSection(options: {
    book?: BookDTO,
    currentDoublePageIndex?: number
  }) {
    const book = options.book || this.state.book
    let currentDoublePageIndex = options.currentDoublePageIndex || this.state.currentDoublePage || 1
    if (!book) { return }

    let enableNextPage: boolean = true
    let currentDoublePage: DoublePageDTO = new DoublePageDTO({
      id: -1, sortNumber: -1, pages: [], picture: {id: -1}
    })
    const doublePageSection = book.doublePages.map((dP, realIndex: number) => {
      const index = realIndex + 1
      let render = false
      let sliderClass = (!this.state.adminEndlessMode) ? 'doublePageSlider ' : 'doublePageEndless not'
      let sliderClassExtra = 'currentPage'
      // const doublePageId = (this.state.doublePageId && this.state.doublePageId > -1) ? this.state.doublePageId : 0
      const doublePageId = mainservice.nav.getVal('doublePageId') as unknown as number || 0
      if (
        (
          book.doublePages[index + 1] &&
          book.doublePages[index + 1].id === this.state.doublePageId
        ) ||
        (
          !doublePageId && index === currentDoublePageIndex + 1 &&
          this.state.isAdmin < 2
        )
      ) {
        sliderClassExtra = 'toTheRight'
        render = true
      }
      if (dP.id === doublePageId ||
        (!doublePageId && index === currentDoublePageIndex)
      ) {
        enableNextPage = dP.nextPageEnabled
        currentDoublePage = dP
        render = true
        this.state.currentDoublePage = index
        // why this?:
        this.state.doublePageId = 0
        currentDoublePageIndex = index
      }

      if (index === currentDoublePageIndex - 1 && this.state.isAdmin < 2) {
        sliderClassExtra = 'toTheLeft'
        render = true
      }

      sliderClass += sliderClassExtra

      sliderClass += (this.state.noscroll) ? ' no-scrolling' : ' scrolling-ok'

      // Add Style:

      sliderClass += ` layout-${dP.getLayout()}`
      return {
        doublePage: dP,
        index: index,
        sliderClass: sliderClass,
        render: render
      }
    })
    this.setState({
      enableNextPage: enableNextPage,
      doublePageSection: doublePageSection,
      loading: false,
      book: book,
      currentDoublePage: currentDoublePageIndex,
      rightStatus: RightStatus.visible
    })
    if (currentDoublePage) {
      mainservice.setCurrentDoublePage(currentDoublePage)
      // Fortschritt für den user speichern:
      const pageCount = book.doublePages.length
      // First Page has to be 0 % - therefore we substract 1 from count and current page:
      const percentage = (pageCount === 0 || currentDoublePageIndex <= 1) ? 0 :  100 / (pageCount - 1) * (currentDoublePageIndex - 1)
      if (book.furthestPosition && book.furthestPosition.percentage < percentage) {
        book.setSessionData(
          'user',
          'furthestPosition',
          `${currentDoublePage.id}`,
          `${percentage}`
        )
      }
      book.setSessionData(
        'user',
        'lastPosition',
        `${currentDoublePage.id}`,
        `${percentage}`
      )
    }
  }

  componentDidMount() {
    mainservice.setReloadFunction(() => this.getContent(true))
    this.getContent()
    this.nextPageBlockInterval = setInterval(() => {
      if (!mainservice.currentDoublePage) { return }
      const nextPageEnabled = this.state.enableNextPage
      const newState = mainservice.currentDoublePage.nextPageEnabled || this.state.isAdmin > 0
      if (nextPageEnabled !== newState) {
        this.setState({enableNextPage: newState})
      }
    }, 500)
    if (this.state.isAdmin === 0) {
      this.listeners.forEach((l) => window.addEventListener(l.name, l.cb))
    }
  }

  componentWillUnmount() {
    clearInterval(this.nextPageBlockInterval)
    if (this.state.isAdmin === 0) {
      this.listeners.forEach((l) => window.removeEventListener(l.name, l.cb))
    }
    mainservice.unregisterBroadcast('book')
  }

  componentDidUpdate() {

  }

  goToNextPage() {
    if (LockNavigationService.isLocked()) { return false }
    if (this.state.enableNextPage) {
      this.changeCurrentPage(this.state.currentDoublePage + 1)
      return true
    }
    return false
  }

  goToPrevPage() {
    if (LockNavigationService.isLocked()) { return false }
    const prevPage = this.state.currentDoublePage - 1
    const newPage = this.changeCurrentPage(prevPage)
    return (prevPage === newPage)
  }

  addDoublePage(position: number) {
    if (!this.state.book) { return }
    this.setState({loading: true})
    this.state.book.addDoublePage(position).then(() => {
      mainservice.setInteractionLayer(Layer.element)
      this.getContent(true)
    })
  }



  renderDoublePages() {
    const book = this.state.book
    if (!book) { return }
    if (this.state.isAdmin > 1 &&
      (!book.doublePages || book.doublePages.length === 0)
    ) {
      return <div className="addBlockRoot"><div className="addBlock" key={'AddPage-1'} onClick={() => this.addDoublePage(1)}>Erste Doppel-Seite hinzufügen</div></div>
    }
    if (this.state.isAdmin > 1 &&
      this.state.interactionLayer === Layer.doublePageOverview
    ) {
      // Show page Overview:
      return <DoublePageOverview
        book={book}
        changeCurrentPage={ (i: number) => this.changeCurrentPage(i) }
        refresh={ () => {this.getContent(true)} }
      />
    }
    return this.state.doublePageSection.map((dP) => {

      if (!dP.render) {
        return <div
          key={'doublePage' + dP.doublePage.id}
          ref={this.manageDPRef(dP.doublePage.id, this.state.currentDoublePage === dP.index)}
          style={{display: 'none'}}
        ></div>
      }
      return <div
        key={'doublePage' + dP.doublePage.id}
        ref={this.manageDPRef(dP.doublePage.id, this.state.currentDoublePage === dP.index)}
        className={`${dP.sliderClass} ${this.state.dragging ? '' : 'smooth'} dp-index-${dP.index}`}
      >
        <DoublePage doublePage={dP.doublePage} parent={this}/>
      </div>
    })
  }

  manageDPRef(id: number, current: boolean) {
    const ref = this.doublePageRefIndex.get(id)
    if (ref) {
      if (current) {
        mainservice.currentDoublePageRef = ref
      }
      return ref
    }
    const newRef = React.createRef()
    this.doublePageRefIndex.set(id, newRef)
    if (current) {
      mainservice.currentDoublePageRef = newRef
    }
    return newRef
  }

  pageAvailable(newPage: number): number {
    if (!this.state.book) { return 1 }
    if (newPage < 1) {
      return 1
    }
    if (newPage > this.state.book.doublePages.length - 1) {
      return this.state.book.doublePages.length
    }
    return newPage
  }

  changeCurrentPage(newPage: number) {
    mainservice.pageFlipInteractive = true
    newPage = this.pageAvailable(newPage)
    mainservice.nav.setPage(newPage)
    this.setDoublePageSection({currentDoublePageIndex: newPage})
    return newPage
  }

  public getPageOfDoublePageId(doublePageId: number): number {
    const section = this.state.doublePageSection.find((dPS) => dPS.doublePage.id === doublePageId)
    if (!section) { return 1 }
    return section.index
  }

  changeCurrentPageByDoublePageId(doublePageId: number) {
    const pageIndex = this.getPageOfDoublePageId(doublePageId)
    this.changeCurrentPage(pageIndex)
  }

  /*
  getBrandingScheme() {
    if (this.state.book) {
      return this.state.book.getProp('brand', 'scheme')
    }
    return 'black'
  }
  */

  getPropValue1(key1: string, key2: string, defaultValue: string): string {
    if (this.state.book) {
      const result = this.state.book.getProp(key1, key2)
      if (result) {
         return result.value1 || defaultValue
      }
    }
    return defaultValue
  }

  public async manageMedia(o: {mode: 'use', id: number} | {mode: 'get' | 'getId' | 'remove'}) {
    if (this.state.book) {
      switch (o.mode) {
        case 'use':
          await this.state.book.setPicture(o.id)
          break
        case 'remove':
          await this.state.book.removePicture()
          break
        case 'getId':
          return this.state.book.getPictureId()
        case 'get':
          return this.state.book.getPicture()
      }
      this.forceUpdate()
    }
  }

  renderNotAllowed() {
    return <div className='w3-row'>
      <div className='w3-col l4 m3 s0'>&nbsp;</div>
      <div className="w3-card-4 lu-dialog w3-col l4 m6 s12">
        <div className="w3-container lu-headline">Achtung</div>
        <div className="w3-container w3-padding">
            <p>Sie sind noch nicht berechtigt dieses Buch zu sehen.</p>
            <p>Sprechen Sie uns an! <a href='mailto:info@workbook.lubbers.de'>info@workbook.lubbers.de</a></p>
            <button
            className='w3-btn w3-ripple login-button'
            onClick={() => window.location.href = '/view=library'}
            >Zurück zum Dashboard</button>
        </div>
      </div>
    </div>

  }

  render() {
    if (this.state.rightStatus === RightStatus.notLoggedIn) {
      return <User />
    }
    if (this.state.rightStatus === RightStatus.notAllowed) {
      return this.renderNotAllowed()
    }
    if (this.state.showCollectibleSelect) {
      return <CollectibleListA
        onSelect={(id: number | null) => {
          if (id === null) {
            window.location.reload()
            return
          }
          Getter(`pageElement/${this.state.showCollectibleSelect}/collectable/add/${id}`).then(() => {
            // this.setState({showCollectibleSelect: null})
            window.location.reload(true)
          })
        }}
      />
    }
    const book = this.state.book
    if (!book) { return <Spinner />}
    const doublePages = this.renderDoublePages()
    let bookContentClassName = 'book-content '
    if (this.state.book) {
      bookContentClassName += this.state.book.getPropVal1('booklayout', 'theme', 'standard-theme')
    }
    if (this.state.isAdmin > 1) {
      bookContentClassName += ' admin'
    }
    //bookContentClassName += ' admin-mode-' + this.state.isAdmin
    bookContentClassName += ' i-layer-' + this.state.interactionLayer
    if (this.state.book) {
      bookContentClassName += ' book-id-' + this.state.book.id
    }
    // Fight scroll Bug in Admin:
    setTimeout(() => {
      if (!this.bookContentRef.current) { return }
      this.bookContentRef.current.scrollTo(0, 0)
    }, 500)
    const isFirstPage = this.pageAvailable(this.state.currentDoublePage - 1) != this.state.currentDoublePage - 1
    const nextPageButtonClass = `centerButton ${((this.state.enableNextPage) ? 'active' : 'disabled')} ${((isFirstPage) ? 'firstPage' : '')}`
    const nextPageContent = (isFirstPage) ? 'Start' : <ArrowRight />
    return <>
      <UserWidget />
      <FullscreenButton />
      <div className={`dashMainBar w3-sidebar LDrawer ${(this.state.lDrawerMode === LDrawerMode.open) ? 'open' : 'closed'}`}>
        <UserDashMainMenu />
        <X className='LDrawerCloseButton' onClick={() => {
          this.setState({lDrawerMode: LDrawerMode.closed})
        }}/>
      </div>
      {
        this.state.book &&
        this.state.book.getPropVal1('floatIcons', 'enable') === 'yes' &&
        <>
          <FloatIcon iconName={'A'} />
          <FloatIcon iconName={'B'} />
          <FloatIcon iconName={'C'} />
          <FloatIcon iconName={'D'} />
        </>
      }
      {
        this.state.book &&
        this.state.book.getPropVal1('tableOfContens', 'enable') === 'yes' &&
        <TOC bookView={this} book={this.state.book} />
      }
      <div ref={this.bookContentRef} className={bookContentClassName}>
        {doublePages}
      </div>
      <div className='footer w3-container'>
      {
        this.pageAvailable(this.state.currentDoublePage - 1) === this.state.currentDoublePage - 1 &&
        <div
          className='button back-button'
          onClick={() => this.goToPrevPage()}
        ><ArrowLeft /></div>
      }
      {
        this.pageAvailable(this.state.currentDoublePage - 1) != this.state.currentDoublePage - 1 &&
        <div className="legalInfo">
          <a href="https://lubbers.de/impressum" target='_blank'>Impressum</a>
          <a className="hideOnMobile" href="https://lubbers.de/datenschutz" target='_blank'>Datenschutz</a>
          <a className="hideOnMobile" href="https://lubbers.de/ueber-uns/kontakt" target='_blank'>Kontakt</a>
        </div>
      }
      {
        this.pageAvailable(this.state.currentDoublePage + 1) === this.state.currentDoublePage + 1 &&
        <button className={nextPageButtonClass} onClick={() => {
          this.goToNextPage()
        }}>
          <span className="no-pointer-events">{nextPageContent}</span>
        </button>
      }
      </div>
      {
      mainservice.isAdmin() > 0 &&
        <BookAdminBar />
      }
      {
        this.state.currentDoublePage > 1 &&
        <ProgressBar
        progress={this.state.currentDoublePage}
        absolut={this.state.book?.doublePages.length || 1}
        />
      }
      <Branding />
    </>
  }
}
