import { GenericEditField, DisplayScope, EditFieldKind } from "../DTO/GenericDTO";



export const DayEditFields: GenericEditField[] = [
  {
    title: 'Fertigstellungsstand',
    key1: 'tags',
    key2: 'workStateTag',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Sprache',
    key1: 'tags',
    key2: 'language',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Tagesziele / Erwünschtes Ergebnis',
    key1: 'data',
    key2: 'goals',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Übergeordnete Botschaft für diesen Tag',
    key1: 'data',
    key2: 'takeawaymessage',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Grob-Konzeption für diesen Tag',
    key1: 'data',
    key2: 'roughConcept',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Organisatorisches für diesen Tag',
    key1: 'data',
    key2: 'organizingNotes',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Tagesnotizen',
    key1: 'data',
    key2: 'notes',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
];
