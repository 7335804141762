import { Getter } from '../services/ComService'
import { Emoji2Unicode } from '../services/EmojiServices'
import PageElementDTO from './PageElementDTO'


export enum Unique {
  no = 0,
  yes = 1,
  sameHour = 2,
  sameDay = 3,
  sameMonth = 4,
}

export type UserData = {
  id: number,
  key1: string,
  key2: string,
  value1: string,
  value2: string,
  date?: Date | null,
}


export default class WidgetDTO {

  id: number = -1
  initCount: number = 0
  name: string = ''
  description: string = ''
  widgetType: number = -1
  status: number = 1
  props: Prop[] = []
  userData: UserData[] = []
  pageElement?: PageElementDTO
  
  // holds all sort positions
  sortPositions: {
    name: string,
    ids: number[]
  }[] = []
  
  constructor(data: IWidget) {
    this.init(data)
  }

  init(data: IWidget) {
    this.initCount += 1
    if (!data) { return }
    if (data.id) { this.id = data.id }
    if (data.name) { this.name = data.name }
    if (data.description) { this.description = data.description }
    if (data.type) { this.widgetType = data.type }
    if (data.status) { this.status = data.status }
    if (data.props) {
      this.props = data.props.map(p => {
        return {...p, ...{
          value1: Emoji2Unicode(p.value1),
          value2: Emoji2Unicode(p.value2),
        }}
      })
    }
    if (data.pageElement) { this.pageElement = data.pageElement }
    if (data.userData) {
      this.userData = data.userData.map((ud) => {
        return {
          id: ud.id,
          key1: ud.key1,
          key2: ud.key2,
          value1: Emoji2Unicode(ud.value1),
          value2: Emoji2Unicode(ud.value2),
          date: (ud.date.date) ? new Date(ud.date.date) : null
        }
      })
    }
    this.afterInit()
  }

  afterInit() {
    // Empty function, which can be filled in children - fires after init finishes
  }

  async getFromServer() {
    if (this.id > -1) {
      const data = await Getter(`user/widget/${this.id}`)
      if (data && data.data) {
        this.init(data.data)
      }
    }
  }

  getName() {
    return this.name
  }

  getDescription() {
    return this.description
  }

  getType() {
    return this.widgetType
  }

  getTypeName() {
    return WidgetTypes.find(wt => wt.id === this.widgetType)?.name || 'NA'
  }

  getStatus() {
    return this.status
  }

  /**
   * This has to check for pageElementProps first
   * @param prop object containing key1 and key2 to search for
   * @param onlyOwnProp optional parameter to only search own props
   * @returns The searched Prop or undefined
   */
  getProp(prop: {
    key1: string,
    key2: string
  }, onlyOwnProp?: boolean): Prop | undefined {
    if (!onlyOwnProp && this.pageElement) {
      const pProp = this.pageElement.getProp(prop.key1, prop.key2)
      const own = this.props.find(p => p.key1 === prop.key1 && p.key2 === prop.key2)
      if (pProp !== false) { return pProp as Prop }
    }
    return this.props.find(p => p.key1 === prop.key1 && p.key2 === prop.key2)
  }

  // This has to check for pageElementProps first, but also for props
  getProps(key1: string): Prop[] {
    let out: Prop[] = []
    if (this.pageElement) {
      const props = this.pageElement.getProps(key1)
      if (props.length > 0) {
        out = props as Prop[]
      }
    }
    return out.concat(this.props.filter(p => p.key1 === key1).filter(p => !out.some(o => o.key1 === p.key1 && o.key2 === p.key2)).sort((a, b) => (parseInt(a.value2, 10) < parseInt(b.value2, 10) ? -1 : 1)))
  }

  // get mix of element props and widget props. If a prop is in both categories only take the element one:
  getMixedProps(filterFunction: (p: Prop) => boolean): Prop[] {
    const elementProps = ((this.pageElement?.elementProps || []) as Prop[]).filter(filterFunction)
    return elementProps.concat(this.props.filter(filterFunction).filter(p => !elementProps.some(e => e.key1 === p.key1 && e.key2 === p.key2)))
  }

  getMixedProp(filterFunction: (p: Prop) => boolean): Prop | undefined {
    const elementProp = ((this.pageElement?.elementProps || []) as Prop[]).find(filterFunction)
    if (elementProp) { return elementProp }
    return this.props.find(filterFunction)
  }

  getMixPropV1(filterFunction: (p: Prop) => boolean): string {
    const elementProp = ((this.pageElement?.elementProps || []) as Prop[]).find(filterFunction)
    if (elementProp && elementProp.value1) { return elementProp.value1 }
    const prop = this.props.find(filterFunction)
    if (prop) { return prop.value1 }
    return ''
  }

  // This has to check for pageElementProps first
  getPropV1(prop: {
    key1: string,
    key2: string
  }, onlyOwnProp?: boolean): string {
    if (prop.key2 === '?') { return '' } // If key2 is variable - we do not know this
    const p = this.getProp(prop, onlyOwnProp)
    return p?.value1 || ''
  }

  // This has to check for pageElementProps first
  getPropId(prop: {
    key1: string,
    key2: string
  }): number {
    if (prop.key2 === '?') { return -1 } // If key2 is variable - we do not know this
    const p = this.getProp(prop)
    return p?.id || -1
  }

  getPropById(id: number | string) {
    id = parseInt(id + '', 10)
    return this.props.find(p => p.id === id)
  }
  getPropV1ById(id: number | string) {
    return this.getPropById(id)?.value1 || ''
  }

  getRawColumns() {
    return this.getProps('column').map(r => {
      const id = r.id
      const aKey = 'childAttribute' + id
      return {
        id: id,
        display: this.getPropV1({key1: aKey, key2: 'display'}),
        kind: this.getPropV1({key1: aKey, key2: 'kind'}),
        description: this.getPropV1({key1: aKey, key2: 'description'}),
        legendEmoji: this.getPropV1({key1: aKey, key2: 'legendEmoji'}),
        key1: aKey,
        key2: r.key2,
        value2: r.value2,
      }
    })
  }

}

export interface IWidget {
  id: number,
  name?: string,
  description?: string,
  type?: number,
  status?: number,
  props?: Prop[],
  userData?: {
    id: number,
    key1: string,
    key2: string,
    value1: string,
    value2: string,
    date: {date: string}
  }[]
  pageElement?: PageElementDTO
}

export type GlobPreference = {
  name: string,
  key1: string,
  key2: string,
  kind: prefKind,
  standard?: string,
  className?: string,
  options?: {
    key: string,
    name: string,
  }[],
  showIfValue?: {key2: string, value: string},
  children?: {
    name: string,
    key1: string,
    key2: string,
    kind: prefKind,
    standard?: string,
    className?: string,
    options?: {
      key: string,
      name: string,
    }[],
    showIfValue?: {key2: string, value: string},
  }[],
}

export type WidgetType = {
  id: WidgetKind,
  name: string,
  globPreferences: GlobPreference[]
}

export enum prefKind {
  string,
  text,
  dropDown,
  notUnique,
  check,
  hr,
  column,
  //stringTrigger,
}

export enum WidgetKind {
  rating = 1,
  list = 2,
}

export const WidgetTypes: WidgetType[] = [
  {
    id: WidgetKind.rating,
    name: 'Zufriedenheitswidget',
    globPreferences: [
      {
        name: 'Titel',
        key1: 'globProp',
        key2: 'title',
        kind: prefKind.string
      },
      {
        name: 'Statistik anzeigen',
        key1: 'globProp',
        key2: 'showStats',
        kind: prefKind.dropDown,
        options: [
          {
            key: 'ifDatapointsAvailable',
            name: 'Sobald Datenpunkte - immer',
          },
          {
            key: 'ifDatapointsAvailableShow',
            name: 'Sobald Datenpunkte - ausblendbar',
          },
          {
            key: 'ifDatapointsAvailableHide',
            name: 'Sobald Datenpunkte - einblendbar',
          },
          {
            key: 'never',
            name: 'nie',
          },
        ]
      },
      {
        name: 'Zeitliches Eintrage-Raster',
        key1: 'globProp',
        key2: 'dataRaster',
        kind: prefKind.dropDown,
        options: [
          {
            key: `${Unique.sameDay}`,
            name: 'täglich',
          },
          {
            key: `${Unique.no}`,
            name: 'sekündlich',
          },
          {
            key: `${Unique.sameHour}`,
            name: 'stündlich',
          },
          {
            key: `${Unique.sameMonth}`,
            name: 'monatlich',
          },
        ]
      },
      {
        name: 'Abstufungen',
        key1: 'globProp',
        key2: 'steps',
        kind: prefKind.dropDown,
        options: [
          {
            key: '2',
            name: '2',
          },
          {
            key: '3',
            name: '3',
          },
          {
            key: '5',
            name: '5',
          },
          {
            key: '9',
            name: '9',
          },
        ]
      },
      {
        name: 'Iconset',
        key1: 'globProp',
        key2: 'icons',
        kind: prefKind.dropDown,
        options: [
          {
            key: 'smilies',
            name: 'Emoji Smilies',
          },
          {
            key: '2022-12-emoji',
            name: '2022-12-emoji',
          },
        ]
      },
      {
        name: 'Zeitraumfilter',
        key1: 'globProp',
        key2: 'showTimeFilter',
        kind: prefKind.dropDown,
        options: [
          {
            key: 'never',
            name: 'Nein',
          },
          {
            key: 'yes',
            name: 'Ja',
          },
        ]
      },
      {
        name: 'Auf Dashboard zeigen',
        key1: 'globProp',
        key2: 'showOnDashboard',
        kind: prefKind.dropDown,
        options: [
          {
            key: 'never',
            name: 'Nein',
          },
          {
            key: 'ifDataAvailable',
            name: 'Sobald User etwas eingetragen hat',
          },
        ]
      },
      {
        name: 'Bewertung-1',
        key1: 'globTranslation',
        key2: 'v-1',
        kind: prefKind.string
      },
      {
        name: 'Bewertung-2',
        key1: 'globTranslation',
        key2: 'v-2',
        kind: prefKind.string
      },
      {
        name: 'Bewertung-3',
        key1: 'globTranslation',
        key2: 'v-3',
        kind: prefKind.string
      },
      {
        name: 'Bewertung-4',
        key1: 'globTranslation',
        key2: 'v-4',
        kind: prefKind.string
      },
      {
        name: 'Bewertung-5',
        key1: 'globTranslation',
        key2: 'v-5',
        kind: prefKind.string
      },
      {
        name: 'Bewertung-6',
        key1: 'globTranslation',
        key2: 'v-6',
        kind: prefKind.string
      },
      {
        name: 'Bewertung-7',
        key1: 'globTranslation',
        key2: 'v-7',
        kind: prefKind.string
      },
      {
        name: 'Bewertung-8',
        key1: 'globTranslation',
        key2: 'v-8',
        kind: prefKind.string
      },
      {
        name: 'Bewertung-9',
        key1: 'globTranslation',
        key2: 'v-9',
        kind: prefKind.string
      },
    ]
  },
  {
    id: WidgetKind.list,
    name: 'Aufgabeninventar',
    globPreferences: [
      {
        name: 'Darstellung',
        key1: 'globProp',
        key2: 'generalLayout',
        kind: prefKind.dropDown,
        standard: 'list',
        options: [
          {
            key: 'list',
            name: 'Liste',
          },
          {
            key: 'diagram',
            name: 'Diagramm',
          }
        ]
      },
      {
        name: 'X-Achse',
        key1: 'globProp',
        key2: 'xAxis',
        kind: prefKind.column,
        showIfValue: {
          key2: 'generalLayout',
          value: 'diagram'
        },
      },
      {
        name: 'Y-Achse',
        key1: 'globProp',
        key2: 'yAxis',
        kind: prefKind.column,
        showIfValue: {
          key2: 'generalLayout',
          value: 'diagram'
        },
      },
      {
        name: 'Größenattribut',
        key1: 'globProp',
        key2: 'zAxis',
        kind: prefKind.column,
        showIfValue: {
          key2: 'generalLayout',
          value: 'diagram'
        },
      },
      {
        name: 'Überschrift des Textinputs',
        key1: 'globProp',
        key2: 'inputHeadline',
        kind: prefKind.string
      },
      {
        name: 'Neue Elemente erlauben',
        key1: 'globProp',
        key2: 'enableNewItem',
        kind: prefKind.check,
        standard: 'check'
      },
      {
        name: 'Das Overlay mit diesem Namen schließen, wenn ein Eintrag erstellt wurde',
        key1: 'globProp',
        key2: 'closeOverlayName',
        kind: prefKind.string,
        standard: 'check'
      },
      {
        name: 'Liste verstecken',
        key1: 'globProp',
        key2: 'disableList',
        kind: prefKind.check,
        standard: 'uncheck'
      },
      {
        name: 'Overlay Name',
        key1: 'globProp',
        key2: 'overlayName',
        kind: prefKind.string,
        showIfValue: {
          key2: 'generalLayout',
          value: 'diagram'
        },
      },
      {
        name: 'Text über Widget',
        key1: 'globProp',
        key2: 'textAboveWidget',
        kind: prefKind.text
      },
      {
        name: 'Text unter Widget',
        key1: 'globProp',
        key2: 'textBelowWidget',
        kind: prefKind.text
      },
      {
        name: 'Layout der Punkte',
        key1: 'globProp',
        key2: 'itemLayout',
        kind: prefKind.dropDown,
        options: [
          {
            key: 'oneline',
            name: 'Einzeilig',
          },
          {
            key: 'card',
            name: 'Karte',
          }
        ]
      },
      {
        name: 'Anzeige, wenn Liste leer',
        key1: 'globProp',
        key2: 'emptyString',
        kind: prefKind.string
      },
      {
        name: 'Spalten Header aktivieren',
        key1: 'globProp',
        key2: 'enableColumnHeader',
        kind: prefKind.check
      },
      {
        name: 'Löschen erlauben',
        key1: 'globProp',
        key2: 'enableItemDelete',
        kind: prefKind.check,
        standard: 'check'
      },
      {
        name: 'Spalten',
        key1: 'column',
        key2: '?',
        kind: prefKind.notUnique,
        children: [
          {
            name: 'hr',
            key1: '',
            key2: '',
            kind: prefKind.hr
          },
          {
            name: 'Beschreibung',
            key1: 'childAttribute',
            key2: 'description',
            className: 'bold',
            kind: prefKind.string
          },
          {
            name: 'Legenden Emoji (Für Drop-Downs)',
            key1: 'childAttribute',
            key2: 'legendEmoji',
            kind: prefKind.string
          },
          {
            name: 'Spalten Typ',
            key1: 'childAttribute',
            key2: 'kind',
            kind: prefKind.dropDown,
            options: [
              {
                key: 'dummy',
                name: 'Bitte Spalten Typ auswählen',
              },
              {
                key: 'mainValue',
                name: 'Haupt-Text-Feld',
              },
              {
                key: 'freetext',
                name: 'Freitext',
              },
              {
                key: 'check',
                name: 'Checkbox',
              },
              {
                key: 'scale-smilies',
                name: '5erSmilies',
              },
              {
                key: 'scale-2022-12-emoji',
                name: '5erSmilies 22-12',
              },
              {
                key: 'scale-6',
                name: '6erSkala',
              },
              {
                key: 'year',
                name: 'Jahr',
              },
            ]
          },
          {
            name: 'Spalte anzeigen',
            key1: 'childAttribute',
            key2: 'display',
            kind: prefKind.check
          },
          {
            name: 'Spalten Wert beim Eintragen bereits abfragen',
            key1: 'childAttribute',
            key2: 'displayOnNew',
            kind: prefKind.check,
          },
          {
            name: 'Hiermit Liste sortieren',
            key1: 'childAttribute',
            key2: 'sortByCol',
            kind: prefKind.dropDown,
            showIfValue: {
              key2: 'kind',
              value: 'scale|year'
            },
            options: [
              {
                key: 'no',
                name: 'Nicht sortieren'
              },
              {
                key: 'increasing',
                name: 'Aufsteigend'
              },
              {
                key: 'decreasing',
                name: 'Absteigend'
              },
            ]
          },
          {
            name: 'Nicht filtern',
            key1: 'childAttribute',
            key2: 'donotfilterDDByCol',
            kind: prefKind.check,
            showIfValue: {
              key2: 'kind',
              value: 'scale'
            },
          },
          {
            name: 'Hiermit Liste filtern (Komma-getrennte Zahlen-Werte)',
            key1: 'childAttribute',
            key2: 'filterByCol',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale'
            },
          },
          {
            name: 'Dropdown genau nach diesen Werten filtern (Komma-getrennte Zahlen-Werte)',
            key1: 'childAttribute',
            key2: 'filterDropDownByTheseNumbers',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale'
            },
          },
          {
            name: 'Standardwert',
            key1: 'childAttribute',
            key2: 'defaultValue',
            kind: prefKind.string,
          },
          {
            name: 'Bewertung 9 Bester Wert',
            key1: 'childAttribute',
            key2: 'rating-name-9',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-9',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Icon 9 Bester Wert',
            key1: 'childAttribute',
            key2: 'rating-icon-9',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Bewertung 8',
            key1: 'childAttribute',
            key2: 'rating-name-8',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-8',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Icon 8',
            key1: 'childAttribute',
            key2: 'rating-icon-8',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Bewertung 7',
            key1: 'childAttribute',
            key2: 'rating-name-7',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-7',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Icon 7',
            key1: 'childAttribute',
            key2: 'rating-icon-7',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Bewertung 6',
            key1: 'childAttribute',
            key2: 'rating-name-6',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-6',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Icon 6',
            key1: 'childAttribute',
            key2: 'rating-icon-6',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Bewertung 5 Mittlerer Wert',
            key1: 'childAttribute',
            key2: 'rating-name-5',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-5',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Icon 5 Mittlerer Wert',
            key1: 'childAttribute',
            key2: 'rating-icon-5',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Bewertung 4',
            key1: 'childAttribute',
            key2: 'rating-name-4',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-4',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Icon 4',
            key1: 'childAttribute',
            key2: 'rating-icon-4',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: ''
            }
          },
          {
            name: 'Bewertung 3',
            key1: 'childAttribute',
            key2: 'rating-name-3',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji|scale-6'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-3',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji|scale-6'
            }
          },
          {
            name: 'Icon 3',
            key1: 'childAttribute',
            key2: 'rating-icon-3',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji|scale-6'
            }
          },
          {
            name: 'Bewertung 2',
            key1: 'childAttribute',
            key2: 'rating-name-2',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-2',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Icon 2',
            key1: 'childAttribute',
            key2: 'rating-icon-2',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Bewertung 1 - Schlechtester Wert',
            key1: 'childAttribute',
            key2: 'rating-name-1',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-name-1',
            className: 'w3-trigger-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Icon 1 - Schlechtester Wert',
            key1: 'childAttribute',
            key2: 'rating-icon-1',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-smilies|scale-2022-12-emoji'
            }
          },
          {
            name: 'Bewertung 1',
            key1: 'childAttribute',
            key2: 'rating-name-1',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger-1',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Icon 1',
            key1: 'childAttribute',
            key2: 'rating-icon-1',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Bewertung -1',
            key1: 'childAttribute',
            key2: 'rating-name--1',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger--1',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Icon -1',
            key1: 'childAttribute',
            key2: 'rating-icon--1',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Bewertung -2',
            key1: 'childAttribute',
            key2: 'rating-name--2',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger--2',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Icon -2',
            key1: 'childAttribute',
            key2: 'rating-icon--2',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Bewertung -3',
            key1: 'childAttribute',
            key2: 'rating-name--3',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'Trigger ([UserKey]=[Value])',
            key1: 'childAttribute',
            key2: 'rating-trigger--3',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
          {
            name: 'icon -3',
            key1: 'childAttribute',
            key2: 'rating-icon--3',
            className: 'w3-margin-left',
            kind: prefKind.string,
            showIfValue: {
              key2: 'kind',
              value: 'scale-6'
            }
          },
        ]
      },
    ]
  },
]

export function RatingvalueToName(value: string, ratingNames?: {key2: string, value1: string}[]) {
  if (ratingNames) {
    const name = ratingNames.find(rN => rN.key2 === 'rating-name-' + value)?.value1
    if (name) { return name }
  }
  switch(value) {
    case '1':
      return 'Sehr schlecht'
    case '5':
      return 'Mittel'
    case '9':
      return 'Sehr Gut'
  }
  return ''
}

export function RatingvalueToIcon(value: string, ratingNames?: {key2: string, value1: string}[]) {
  if (ratingNames) {
    const name = ratingNames.find(rN => rN.key2 === 'rating-icon-' + value)?.value1
    if (name) { return name }
  }
  return ''
}

export const WidgetStatus = [
  {
    id: 0,
    name: 'Versteckt'
  },
  {
    id: 1,
    name: 'Veröffentlicht'
  },
]

export type Prop = {
  id: number,
  key1: string,
  key2: string,
  value1: string,
  value2: string,
}

export function ScaleKindToMinMax(scaleName: string): {min: number, max: number} {
  switch (scaleName) {
    case 'scale-6':
      return {
        min: -3,
        max: 3
      }
    case 'scale-2022-12-emoji':
    case 'scale-smilies':
      return {
        min: 1,
        max: 9
      }
  }
  return {
    min: -6,
    max: 9,
  }
}
