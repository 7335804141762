import AsyncWaitForAll from "../services/AsyncWaitForAll";
import { Setter } from "../services/ComService";
import { Emoji2PlainText, Emoji2Unicode } from "../services/EmojiServices";
import WidgetDTO, { IWidget, Prop, Unique, UserData } from "./WidgetDTO";
import WidgetDiagramUserDTO from "./WidgetDiagramUserDTO";

export type Entry = {
  columns: {
    display: string;
    kind: string;
    description: string;
    legendEmoji: string;
    sortNumber: string;
    userValue: string;
    userValueInt: number;
    key1: string;
    key2: string;
  }[];
  id: number;
  key1: string;
  key2: string;
  value1: string;
  value2: string;
  date?: Date;
}

export default class WidgetUserDTO extends WidgetDTO {
  nCache: {key: string, value: number}[] = []
  nCacheLastKey: string = ''
  nCacheLastValue: number = -1
  diagram: WidgetDiagramUserDTO

  constructor(data: IWidget) {
    super(data)
    this.diagram = new WidgetDiagramUserDTO(this)
  }

  theInteractionCb: (() => void) | null = null

  setInteractionCb(cb: () => void) {
    this.theInteractionCb = cb
  }

  interactionCb() {
    if (this.theInteractionCb) {
      this.theInteractionCb()
    }
  }

  afterInit(): void {
    // This code is for widgetList:
    this.sortPositionsInitByKeys('listEntries', 'entry', 'main')
  }

  setNCache(key: string, value: number) {
    if (key === this.nCacheLastKey && value === this.nCacheLastValue) { return }
    const old = this.nCache.find(c => c.key === key)
    if (old) {
      old.value = value
      return
    }
    this.nCache.push({
      key: key,
      value: value,
    })
  }

  getNCache(key: string): number {
    return this.nCache.find(c => c.key === key)?.value || -1
  }

  unifyDate(unique: Unique | number, date?: Date): Date {
    if (!date) {
      date = new Date()
    }
    switch(unique) {
      case Unique.sameMonth:
        date.setDate(1)
        date.setMilliseconds(0)
        date.setSeconds(0)
        date.setMinutes(0)
        date.setHours(12)
        break
      case Unique.sameDay:
        date.setMilliseconds(0)
        date.setSeconds(0)
        date.setMinutes(0)
        date.setHours(12)
        break
      case Unique.sameHour:
        date.setMilliseconds(0)
        date.setSeconds(0)
        date.setMinutes(0)
        break
    }
    return date
  }

  getUserData(data: {key1: string, key2: string}, sort?: 'date' | 'manual' | 'dont') {
    const userData = this.userData.filter(p => p.key1 === data.key1 && p.key2 === data.key2)
    if (sort === 'dont') {
      return userData
    }
    if (sort === 'manual') {
      return userData.sort((a, b) => {
        const A: number = parseInt(a.value2, 10) || 0
        const B: number = parseInt(b.value2, 10) || 0
        return A < B ? -1 : 1
      })
    }
    return userData.sort((a, b) => {
      const A: number = a.date?.getTime() || 0
      const B: number = b.date?.getTime() || 0
      return A < B ? -1 : 1
    })
  }

  getUserDataValue1(u: {key1: string, key2: string}): string {
    return this.userData.find(p => p.key1 === u.key1 && p.key2 === u.key2)?.value1 || ''
  }

  getUserDataValue2ById(id: number): string {
    return this.userData.find(p => p.id === id)?.value2 || ''
  }

  getCurrentUserData(data: {id?: number, key1: string, key2: string}, unique: Unique | number, date?: Date) {
    date = this.unifyDate(unique)
    let old = undefined
    if (data.id && data.id > -1) {
      old = this.userData.find(ud => ud.id === data.id)
    } else if (unique === Unique.yes) {
      old = this.userData.find(ud => ud.key1 === data.key1 && ud.key2 === data.key2)
    } else if (unique !== Unique.no) {
      old = this.userData.find(ud => ud.key1 === data.key1 && ud.key2 === data.key2 && this.unifyDate(unique, ud.date || new Date()).getTime() === date?.getTime())
    }
    return old
  }

  getCurrentUserDataValue1(data: {key1: string, key2: string}, unique: Unique | number, date?: Date): string {
    const item = this.getCurrentUserData(data, unique, date)
    return item?.value1 || ''
  }

  async removeUserDataFromServer(p: {key1: string, key2: string, id?: number}) {
    const d = this.userData.find(i => (p.id && i.id === p.id) || (!p.id && i.key1 === p.key1 && i.key2 === p.key2))
    if (!d) { return }
    await Setter('user/widget/prop', {
      widgetId: this.id,
      id: d.id
    }, {method: 'DELETE'})
  }

  async removeUserDataList(ps: {key1: string, key2: string, id?: number}[]) {
    await AsyncWaitForAll(ps.map((p) => {
      return async () => {
        await this.removeUserDataFromServer(p)
      }
    }))
    this.userData = this.userData.filter(u => !ps.some(s => (s.id && u.id === s.id) || (!s.id && u.key1 === s.key1 && u.key2 === s.key2)))
    this.interactionCb()
  }

  async setUserData(data: UserData, unique: Unique | number, date?: Date) {
    date = this.unifyDate(unique, date)
    const old = this.getCurrentUserData(data, unique, date)
    const r = await Setter(
      'user/widget/prop',
      {...data, ...{
        id: old?.id || -1,
        widgetId: this.id,
        unique: unique,
        date: date,
        value1: Emoji2PlainText(data.value1),
        value2: Emoji2PlainText(data.value2),
      }},
      {method: 'PUT'}
    )
    // Use triggers if available:
    await this.setUserDataUseTrigger(data)
    // Set default Data, if entry is new:
    await this.setUserDataDefaultValue(data, parseInt(r.data.id, 10))
    if (old) {
      old.id = r.data.id
      old.value1 = Emoji2Unicode(r.data.value1)
      old.value2 = Emoji2Unicode(r.data.value2)
      old.date = date
    } else {
      if (!r) {
        return {id: -1, key1: '', key2: '', value1: '', value2: ''}
      }
      this.userData.push({
        id: r.data.id,
        key1: r.data.key1,
        key2: r.data.key2,
        value1: r.data.value1,
        value2: r.data.value2,
        date: date,
      })
    }
    this.interactionCb()
    return r.data as UserData
  }

  private async setUserDataUseTrigger(data: UserData) {
    const childAttributeScope = /childAttribute([-0-9]+)/

    if (data.key1.search(childAttributeScope) === -1) { return }
    const childId = (data.key1.match(childAttributeScope) || ['-1','-1'])[1]
    const thisColumn = this.getMixedProp((p: Prop) => p.id === parseInt(childId, 10))
    if (!thisColumn) { return }
    const thisColumnKey = thisColumn.key2
    const entryId = parseInt(data.key2.replace(thisColumnKey, ''), 10)
    const triggersRaw = this.getMixedProp((p: Prop) => {
      return p.key1 === data.key1 && p.key2 === `rating-trigger-${data.value1}`
    })
    if (!triggersRaw) { return }
    async function singleTrigger(triggerRaw: string, that: WidgetUserDTO) {
      const triggerScope = /([^=]+)=(.+)/
      const trigger = triggerRaw.match(triggerScope)
      if (!trigger) { return }
      const targetColumn = that.getMixedProp((p: Prop) => p.key1 === 'column' && p.key2 === trigger[1])
      if (!targetColumn) { return }
      const targetColumnId = targetColumn.id
      const childAttribute = `childAttribute${targetColumnId}`
      const setterResult = await Setter(
        'user/widget/prop',
        {
          date: new Date().toJSON(),
          id: -1,
          key1: childAttribute,
          key2: `${trigger[1]}${entryId}`,
          value1: trigger[2],
          value2: '',
          unique: 1,
          widgetId: that.id,
        },
        {method: 'PUT'}
      )
      // Make data available instantly:
      const old = that.getCurrentUserData(data, Unique.yes)
      if (old) {
        old.value1 = Emoji2Unicode(trigger[2])
      } else {
        that.userData.push({...setterResult.data, ...{
          userDataInt: parseInt(trigger[2], 10)
        }})
      }
    }
    const triggers = triggersRaw.value1.split(';')
    for (let i = 0, m = triggers.length; i < m; i++) {
      await singleTrigger(triggers[i], this)
    }
  }

  private async setUserDataDefaultValue(data: UserData, targetId: number) {
    if (!(data.key1 === 'entry' && data.id === -1)) { return }
    const defaultValues = this.getMixedProps((p: Prop) => {
      if (p.key2 === 'defaultValue' && p.value1) {
        return true
      }
      return false
    })
    for (let i = 0, m = defaultValues.length; i < m; i++) {
      const dV = defaultValues[i]
      const coumnKeyScope = /childAttribute([-0-9]+)/
      const columnIdRaw = dV.key1.match(coumnKeyScope)
      if (columnIdRaw) {
        const columnId = parseInt(columnIdRaw[1], 10)
        const column = this.getMixedProp((p: Prop) => {
          return p.id === columnId
        })
        if (column) {
          const newData = {
            date: new Date().toJSON(),
            id: -1,
            key1: dV.key1,
            key2: `${column.key2}${targetId}`,
            value1: dV.value1,
            value2: '',
            unique: 1,
            widgetId: this.id,
          }
          const result = await Setter(
            'user/widget/prop',
            newData,
            {method: 'PUT'}
          )
          // Save Value for instant use:
          const old = this.getCurrentUserData(newData, Unique.yes)
          if (old) {
            old.value1 = Emoji2Unicode(dV.value1)
          } else {
            this.userData.push({...result.data, ...{
              userValueInt: parseInt(dV.value1, 10),
            }})
          }
        }
      }
    }
  }

  // init sort position for name
  sortPositionsInit(name: string, items: {id: number, value2: string}[]) {
    const ids = items.sort((a,b) => parseInt(a.value2, 10) < parseInt(b.value2, 10) ? -1 : 1).map(i => i.id)
    if (!ids) { return }
    const save = this.sortPositions.find(sp => sp.name === name)
    if (save) {
      save.ids = ids
    } else {
      this.sortPositions.push({
        name: name,
        ids: ids
      })
    }
  }

  sortPositionsInitByKeys(name: string, key1: string, key2: string) {
    const items = this.userData.filter(ud => ud.key1 === key1 && ud.key2 === key2)
    if (items) {
      this.sortPositionsInit(name, items)
    }
  }

  // set item to sort position
  sortPositionsSetItemToPosition(name: string, id: number, position: number) {
    const save = this.sortPositions.find(sp => sp.name === name)
    if (!save) { return }
    let ids = save.ids.filter(i => i !== id)
    const first = ids.splice(0, position)
    const last = ids
    save.ids = first.concat([id], last)
  }

  sortPositionsGetItemPos(name: string, id: number) {
    const save = this.sortPositions.find(sp => sp.name === name)
    if (!save) { return -1}
    return save.ids.indexOf(id)
  }

  // move item to other item and prepends or appends to it
  sortPositionsMoveItemToTarget(name: string, id: number, targetItem: number, beforeAfter: number) {
    const save = this.sortPositions.find(sp => sp.name === name)
    if (!save) { return }
    const position = save.ids.indexOf(targetItem)
    let ids = save.ids.filter(i => i !== id)
    const first = ids.splice(0, position)
    const last = ids
    save.ids = first.concat([id], last)
    // save:
    this.sortPositionsSaveAll(name)
  }

  sortMoveUpDown(name: string, id: number, dir: -1 | 1) {
    const save = this.sortPositions.find(sp => sp.name === name)
    if (!save) { return }
    const position = save.ids.indexOf(id) + dir
    let ids = save.ids.filter(i => i !== id)
    const first = ids.splice(0, position)
    const last = ids
    save.ids = first.concat([id], last)
    this.sortPositionsSaveAll(name)
  }

  sortPositionsGet(name: string) {
    const save = this.sortPositions.find(sp => sp.name === name)
    if (!save) { return [] }
    return save.ids
  }

  sortPositionsGetItems(name: string) {
    const ids = this.sortPositionsGet(name)
    return ids.map(id => this.userData.find(ud => ud.id === id))
  }

  getEntries(): Entry[] {
    return this.sortPositionsGetItems('listEntries').map((entry) => {
      if (!entry) { return undefined }
      const columnsRaw = this.getRawColumns()
      return {
        ...entry,
        ...{
          columns: columnsRaw.map(r => {
            const id = r.id
            const aKey = 'childAttribute' + id
            const userValue = this.getUserDataValue1({key1: aKey, key2: r.key2 + entry.id})
            return {
              display: r.display,
              kind: r.kind,
              description: r.description,
              legendEmoji: r.legendEmoji,
              sortNumber: r.value2,
              userValue: userValue,
              userValueInt: parseInt(userValue, 10),
              key1: r.key1,
              key2: r.key2 + entry.id,
            }
          })
        }
      }
    }).filter(entry => entry !== undefined) as Entry[]
  }

  getEntryById(id: number): Entry | undefined {
    return this.getEntries().find(e => e.id === id)
  }

  // saves all sort positions
  async sortPositionsSaveAll(name: string) {
    const save = this.sortPositions.find(sp => sp.name === name)
    if (!save) { return }
    for(let i = 0, m = save.ids.length; i < m; i++) {
      let id = save.ids[i]
      let ud = this.userData.find(ud => ud.id === id)
      if (ud) {
        ud.value2 = `${(i + 1) * 10}`
        await Setter(
          'user/widget/prop',
          {...ud, ...{
            unique: Unique.no,
            widgetId: this.id,
            value1: Emoji2PlainText(ud.value1),
            value2: Emoji2PlainText(ud.value2),
          }},
          {method: 'PUT'}
        )
      }
    }
    this.interactionCb()
  }

  sortPositionsAddItem(name: string, id: number) {
    const save = this.sortPositions.find(sp => sp.name === name)
    if (!save) { return }
    save.ids.unshift(id)
  }
}
