import { DisplayScope, EditFieldKind, GenericEditField } from "../DTO/GenericDTO";


export const ModuleEditFields: GenericEditField[] = [
  {
    title: 'Bearbeitungsstand',
    key1: 'tag',
    key2: 'workStateTag',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Sprache',
    key1: 'tags',
    key2: 'language',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Fertigstellungspriorität',
    key1: 'tag',
    key2: 'priorityA',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Fertigstellungsdatum',
    key1: 'data',
    key2: 'dueDate',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.date,
  },
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Modulart',
    key1: 'tag',
    key2: 'moduleKind',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Ablaufhinweise',
    key1: 'data',
    key2: 'timingHints',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Ideen für Varianten',
    key1: 'data',
    key2: 'ideasForDifferentApproaches',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Modulziele / Erwünschtes Ergebnis',
    key1: 'data',
    key2: 'moduleGoals',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Übergeordnete Botschaft für dieses Modul',
    key1: 'data',
    key2: 'generalMessage',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Organisatorisches für dieses Modul',
    key1: 'data',
    key2: 'UnternehmenHints',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Medien',
    key1: 'file',
    key2: 'generic',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.files
  },
  {
    title: 'Manuskripte',
    key1: 'moderationCardSet',
    key2: 'A6',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.textAreaArray
  },
];
