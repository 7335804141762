import { PublishKind } from '../../models/publishKinds';
import DayView, { DisplayMode } from '../day/dayView';
import { DayDTO } from '../../DTO/DayDTO';
import { ObjectKind } from '../../models/objectKinds';
import ModuleView from '../moduleView';
import ModuleDTO from '../../DTO/ModuleDTO';
import { TrainingItemDTO } from '../../DTO/TrainingItemDTO';
import TrainingitemView from '../trainingitemView';
import { MaterialItemDTO } from '../../DTO/MaterialItemDTO';
import MaterialView from '../materialView';
import { X } from 'react-feather';
import mainservice from '../../../services/MainService';
import DayModuleDTO from '../../DTO/DayModuleDTO';
import { useEffect, useState } from 'react';
import { FilesUpload, SuccessItem } from '../FileManager/FileManager';
import { GenericDTO } from '../../DTO/GenericDTO';
import InputB from '../../../components/InputB/InputB';

enum Status {
  standard,
  single,
  chooseBulk,
  bulkDone,
}

function GetHeadline(objecKind: ObjectKind) {
  switch(objecKind) {
    case ObjectKind.day:
      return 'Neuen Tag anlegen'
    case ObjectKind.materialItem:
      return 'Neues Asset anlegen'
    case ObjectKind.module:
      return 'Neue Sequenz anlegen'
  }
  return 'Neues Item anlegen'
}

/*
id
: 
778
key1
: 
"data"
key2
: 
"materialKind"
unique
: 
true
value1
: 
"digitalFlip"
value2
: 
"__EMPTY__"
*/

const possibleMaterialKinds = [
  {name: 'Flip', key: 'digitalFlip'},
  {name: 'Handout', key: 'digitalHandout'},
  {name: 'Plakate', key: 'digitalPoster'},
  {name: 'Pin', key: 'digitalPin'},
  {name: 'Datei', key: 'digitalFile'},
]

export function EditItem(props: {
  close?: () => void,
  item: GenericDTO,
  objectKind: ObjectKind,
  broadcastKey?: string,
  rerenderRightSide?: () => void,
  setEditItem?: (module: ModuleDTO | DayModuleDTO) => void,
}) {
  const [status, setStatus] = useState(Status.standard)
  const [materialKind, setMaterialKind] = useState('digitalFlip')
  const [protocol, setProtocol] = useState('')
  const [item, setItem] = useState(props.item)
  const closeButton = <div className='w3-container'>
    <button
      className='floatRight modestButton'
      onClick={() => {
        // send update signal for all items with this id:
        mainservice.broadcast(`updateId-${item.id}`, {})
        console.log('editItemBroadcastChangeKey: ', props.broadcastKey)
        if (props.close) {
          props.close();
        }
        setStatus(Status.standard)
      }}
    ><X /></button>
  </div>
  useEffect(() => {
    setItem(props.item)
    setStatus(Status.standard)
  }, [props.item.id, props.item.objectKind, props.item.publishKind])
  async function setName(rv: string) {
    await item.setName(rv)
    setStatus(Status.single)
 }

 if (status === Status.chooseBulk || status === Status.bulkDone) {
  return <>
    {closeButton}
    <h2 className='textCenter w3-margin-top'>Asset Bulk Upload</h2>
    <div className='justify-content-center textCenter'>
      <div>
        <p>
          Welche Informationen wollen wir für die Elemente festlegen?
        </p>
      </div>

      <div>
        {possibleMaterialKinds.map((pmk, index) => {
          return <button
            key={`${pmk.key}`}
            className={`tab-button ${pmk.key === materialKind ? 'tab-active' : ''}`}
            onClick={() => {
              setMaterialKind(pmk.key)
            }}
          >{pmk.name}</button>
        })}
      </div>
      {
        status === Status.bulkDone &&
        <>
        <div className='justify-content-center textCenter'>
          Upload fertig
        </div>
        {protocol}
    </>
      }
      <div>
        <FilesUpload
          saveSingleFile={async (item: SuccessItem) => {
            let file = item
            let material = new MaterialItemDTO({
              id: -1,
              name: file.name.replace(/\...?.?.?$/, '') || file.name,
              publishKind: PublishKind.isTemplate,
              objectKind: ObjectKind.materialItem,
            })
            await material.saveToServer()
            const fileProp = await material.addProp('defaultfile', 'raw', '', '', true, file.id)
            await material.addProp('data', 'materialKind', materialKind)
            await material.addProp('filesInfo', 'defaultFile', `${fileProp.id}`)
          }}
          setNewFileInfos={async (items: SuccessItem[], fails: SuccessItem[]) => {
            const successMsg = items.length > 0 ? 'Erfolgreiche Uploads: ' + items.length : ''
            const failMsg = fails.length > 0 ? 'Fehlgeschlagene Uploads: ' + fails.length : ''
            setProtocol([successMsg, failMsg].filter(s => s).join(' - '))
            setStatus(Status.bulkDone)
          }}
        />
      </div>
    </div>
  </>
  }
  // console.log('we got a name', item, item.name)
  if (item.id === -1) {
    return <>
      {closeButton}
      <h2 className='textCenter w3-margin-top'>{GetHeadline(item.objectKind)}</h2>
      
      <div className='flex justify-content-center textCenter'>
        <div className='w3-padding'>
          <div>
            Namen eingeben um einzelnes neues Element zu erstellen
          </div>
          <div className='w3-padding'>
            <InputB
              placeholder='Name für neues Element'
              value={item.name}
              returnVal={setName}
              autofocus
              delay={-1}
              sendButton={`Speichern`}
            />
          </div>
        </div>
        { props.objectKind === ObjectKind.materialItem &&
          <div className='w3-padding'>
            <div>
              Oder... 
            </div>
            <div>
              <button
                className='neutralButton centerBox'
                onClick={() => {
                  setStatus(Status.chooseBulk)
                }}
              >Bulk Daten hochladen</button>
            </div>
          </div>
        }
      </div>
    </>
  }
  return <>
    {closeButton}
    { props.objectKind === ObjectKind.materialItem &&
      <MaterialView
        materialItem={item as MaterialItemDTO}
        parentObjectKind={ObjectKind.daymodule}
        parentPublishKind={PublishKind.isTemplate}
        displayMode={DisplayMode.edit}
        close={() => {
          if (props.close) {
            props.close();
          }
        }}
        trash={async () => {
          await item.templateTrash();
          if (props.close) {
            props.close();
          }
        }} />}
    { props.objectKind === ObjectKind.trainingItem &&
      <TrainingitemView
        trainingitem={item as TrainingItemDTO}
        parentObjectKind={ObjectKind.daymodule}
        parentPublishKind={PublishKind.isTemplate}
        displayMode={DisplayMode.edit}
        close={() => {
          if (props.close) {
            props.close();
          }
        }}
        trash={async () => {
          await item.templateTrash();
          if (props.close) {
            props.close();
          }
        }} />}
    { (props.objectKind === ObjectKind.module || props.objectKind === ObjectKind.daymodule) &&
      <ModuleView
        module={new ModuleDTO(item)}
        displayMode={DisplayMode.edit}
        close={() => {
          if (props.close) {
            props.close();
          }
        }}
        parentObjectKind={ObjectKind.daymodule}
        parentPublishKind={PublishKind.isTemplate}
        templateTrash={async () => {
          await item.templateTrash();
          if (props.close) {
            props.close();
          }
        }}
        addItem={(position: number, item: ModuleDTO, t: 'time' | 'position' | 'template2seminar' | 'duration') => {
          mainservice.broadcast(props.broadcastKey || 'nokey', {
            position: position,
            item: item,
            type: t,
          })
        }}
      />
    }
    { props.objectKind === ObjectKind.day &&
      <DayView
        day={item as DayDTO}
        displayMode={DisplayMode.edit}
        parentObjectKind={ObjectKind.seminarday}
        parentPublishKind={PublishKind.isTemplate}
        setEditItem={props.setEditItem}
        close={() => {
          if (props.close) {
            props.close();
          }
        }}
        templateTrash={async () => {
          await item.templateTrash();
          if (props.close) {
            props.close();
          }
        }}
        rerenderRightSide={props.rerenderRightSide} />}
  </>
}
