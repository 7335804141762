import { useState } from "react";
import { GenericDTO, TextArraySummaryItem } from "../../DTO/GenericDTO";
import { ArrowRight, CheckSquare, Eye, EyeOff, Printer, Square } from "react-feather";
import mainservice from "../../../services/MainService";
import { PaperOrientation, PaperSizes } from "../PaperSizes";
import { CountHolder } from "../CountHolder";
import InputB from "../../../components/InputB/InputB";


export function TextAreaArray(props: {
  AreaArray: TextArraySummaryItem,
  index: number,
  setShow: (index: number) => void,
  show: number,
  standalone?: boolean,
  item: GenericDTO,
  printCountHolder?: CountHolder,
  packCountHolder?: CountHolder,
}
) {
  const AreaArray = props.AreaArray;
  const index = props.index;
  const show = props.show;
  const item = props.item;
  const [packed, setPacked] = useState(item.getPropV1('checklist-packed', AreaArray.key2) === '1');
  const cardPaperSizeDetails = PaperSizes.find(ps => ps.key === AreaArray.paperFormat);
  const orientationDetails = PaperOrientation.find(ps => ps.key === AreaArray.orientation);
  const printCountHolder = props.printCountHolder
  const packCountHolder = props.packCountHolder
  const [rerender, setRerender] = useState(0)
  let credentials: string[] = (AreaArray.credentials || '').split(';').filter(c => c)
  // numeric id for work with count Holder:
  const id = parseInt(AreaArray.key2.replace(/[^0-9]/g, ''), 10)
  return <div className={`w3-padding w100 ${!props.standalone ? 'PTListItem' : ''}`}>
    
    {!props.standalone &&
      <div className={`w100 ${!props.standalone ? 'flex' : ''}`}>
        {
          printCountHolder &&
          <div
            className='mv-print-count'
            title="Druck Anzahl"
          ><InputB
              value={printCountHolder.has(id) ? printCountHolder.get(id) : 1 }
              returnVal={(value) => {
                if (value === '') {
                  printCountHolder.delete(id)
                  setRerender(rerender + 1)
                } else {
                  printCountHolder.set(id, parseInt(value, 10))
                  setRerender(rerender + 1)
                }
              }}
            /></div>
        }
        {
          packCountHolder &&
          <div
            className='mv-print-count'
            title="Pack Anzahl"
          ><InputB
              value={packCountHolder.has(id) ? packCountHolder.get(id) : 1 }
              returnVal={(value) => {
                if (value === '') {
                  packCountHolder.delete(id)
                  setRerender(rerender + 1)
                } else {
                  packCountHolder.set(id, parseInt(value, 10))
                  setRerender(rerender + 1)
                }
              }}
            /></div>
        }
        <div className={`dont-print flex-grow`}>
          <b>{AreaArray.name}</b> - <b>{AreaArray.deckName}</b><br />
          Format: {cardPaperSizeDetails?.name || 'NA'}, Orientierung: {orientationDetails?.name || 'NA'}
          
          <div className='credentials fontWeightNormal'>
            {
              credentials.map(
                (c, index) => <span
                  key={`credentials-${item.id}-${index}`}
                  className={`credential-type-generic`}
                >
                  { index > 1 &&
                    <ArrowRight size='12'/>
                  }
                  {index > 0 && c}
                </span>
              )
            }
          </div>
        </div>

        {<button
          title={packed ? 'Gepackt' : 'Noch nicht gepackt'}
          onClick={async () => {
            await item.addProp('checklist-packed', AreaArray.key2, packed ? '0' : '1');
            setPacked(!packed);
          }}
          className='w3-button dont-print'
        >
          {packed ? <CheckSquare /> : <Square />}
        </button>}
        <button
          className='w3-button dontprint'
          onClick={() => {
            if (index === show) {
              props.setShow(-1);
            } else {
              props.setShow(index);
            }
          }}
        >
          {index === show ? <Eye /> : <EyeOff />}
        </button>
        <button
          className='w3-button dontprint'
          onClick={() => {
            mainservice.navTo([['view', 'textAreaArray'], ['id', item.id], ['uid', AreaArray.key2], ['copies', (printCountHolder && printCountHolder.has(id)) ? printCountHolder.get(id) : 1 ]])
          }}
        >
          <Printer />
        </button>
      </div>
    }
    {(index === show || props.standalone) &&
      AreaArray.cards.map((card, jndex) => {
        return <div
          key={`taasi-${item.name}-${index}-${jndex}`}
          className={`TextArearArrayItemCard page-break paperSize-${AreaArray.paperFormat} ${AreaArray.orientation}`}
        >
          <div
            className={`page-number`}
          >{jndex + 1}</div>
          <div
            dangerouslySetInnerHTML={{ __html: card }}
          ></div>
          <div className='html2pdf__page-break'></div>
        </div>;
      })
    }

  </div>;
}
