import { useDrag } from "react-dnd";
import { ObjectKind } from "../models/objectKinds";
import { Copy, Edit, Eye, GitMerge, Trash, X } from "react-feather";
import { useEffect, useState } from "react";
import InputA from "../../components/InputA/InputA";
import { TrainingItemDTO } from "../DTO/TrainingItemDTO";
import { DisplayMode, Status } from "./day/dayView";
import { DropZone } from "../elements/DropZone";
import { parseStringToServer } from "../../services/TextConverter";
import { PublishKind } from "../models/publishKinds";
import MaterialsView from "./materialsView";
import { EditField } from "./EditField";
import './pt.scss'
import './trainingitemView.scss'
import './day/dayView.scss'
import Spinner from "../../components/Spinner/Spinner";
import EditFieldsPrinter from "../editFields/EditFieldsPrinter";



export default function TrainingitemView(props: {
  trainingitem: TrainingItemDTO,
  displayMode: DisplayMode,
  parentPublishKind: PublishKind,
  parentObjectKind: ObjectKind,
  trash?: (item: TrainingItemDTO) => void,
  rerender?: () => void,
  position?: number,
  addItem?: (position: number, item: TrainingItemDTO, type: 'position' | 'template2seminar') => void,
  fullScreenEdit?: () => void,
  close?: () => void,
  index?: number,
}) {
  // const item = props.trainingitem
  const [item, setItem] = useState(props.trainingitem)
  const [rerender, setRerender] = useState(0)
  const [rerenderChild, setRerenderChild] = useState(Status.standard)
  const [{isDragging}, drag] = useDrag(() => ({
    type: ObjectKind.trainingItem + '',
    item: item,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [42])
  const [status, setStatus] = useState(Status.standard)
  useEffect(() => {
    setStatus(Status.loading)
    if (props.displayMode === DisplayMode.edit) {
      props.trainingitem.getFromServer().then(() => {
        setItem(props.trainingitem)
        setStatus(Status.standard)
      })
    } else {
      setItem(props.trainingitem)
      setTimeout(() => {
        setStatus(Status.standard)
      }, 100)
    }
  }, [props.trainingitem.id])
  if (status === Status.loading) {
    return <Spinner />
  }
  return <div
    className={`TrainingItemView PTListItem ${(status === Status.edit) ? 'expand' : 'notexpanded'} ${(isDragging) ? 'dragging' : ''}`}
    title={item.id + ''}
  >
    <div className={'lineContent'}>
      <div className='stretch' ref={drag}>
        <span>{item.getName()}</span>
      </div>
      <div className='rightTools dont-print' data-html2canvas-ignore="true">
        {
          props.trash && item.id > -1 &&
          <Trash
            className='delButton'
            size='20px'
            onClick={async () => {
              if (item instanceof TrainingItemDTO && props.trash
                // && window.confirm('Abschnitt wirklich löschen?')
              ) {
                props.trash(item)
              }
            }}
          />
        }
        {
          props.fullScreenEdit && item.id > -1 && props.displayMode !== DisplayMode.leftSide &&
          <Edit
            className='editItem pointer'
            size='20px'
            onClick={() => {
              if (props.fullScreenEdit) {
                props.fullScreenEdit()
              }
            }}
          />
        }
        {
          (item.publishKind === PublishKind.isSeminar || props.parentPublishKind === PublishKind.isTemplate) && props.displayMode === DisplayMode.leftSide &&
          <>
            <Edit
              className='pointer makeFullscreenEdit'
              onClick={async () => {
                await item.getChildrenFromServer()
                setStatus(Status.edit)
                setRerender(rerender + 1)
              }}
            />
            <X
              className='closeFullscreenEdit pointer'
              size='20px'
              onClick={() => {
                setStatus(Status.standard)
              }}
            />
          </>
        }
        {
          ((item.publishKind === PublishKind.isTemplate && props.parentPublishKind === PublishKind.isSeminar)) &&
          <>
            <Copy
              className='pointer makeFullscreenEdit'
              onClick={async () => {
                if (props.addItem) {
                  await props.addItem(props.index || -1, item, 'template2seminar')
                }
              }}
            />
          </>
        }
        {
          props.displayMode !== DisplayMode.edit &&
          <Eye
            className='editItem pointer'
            size='20px'
            onClick={() => {
              if (status === Status.details) {
                setStatus(Status.standard)
              } else {
                setStatus(Status.details)
              }
            }}
          />
        }
        {
          props.displayMode !== DisplayMode.edit &&
          <GitMerge
            className='editItem pointer'
            size='20px'
            onClick={async () => {
              await item.trashPreflight('info')
            }}
          />
        }
      </div>
    </div>
    {
      (props.displayMode === DisplayMode.edit) &&
      <div className='info-block'>
        <div className='contentPart'>
          <div className='EditField'>
            <label className='EditFieldLabel'>Name</label>
            <InputA
              value={item.getName()}
              returnVal={async (rv) => {
                await item.setName(rv)
              }}
            />
          </div>
          <EditFieldsPrinter
            fields={item.getEditFields()}
            item={item}
            editMode={props.displayMode === DisplayMode.edit}
          />
          {
            status === Status.details &&
            <>
              <div dangerouslySetInnerHTML={{__html: item.getPropV1('data', 'description') || ''}}></div>
              <h3>Assets</h3>
              <MaterialsView
                items={item.getMaterialItems()}
                getItems={async () => {
                  await item.getChildrenFromServer()
                  return item.getMaterialItems()
                }}
                parentObjectKind={item.objectKind}
                parentPublishKind={item.publishKind}
                identKey={`item-view-eye-${item.id}-`}
                displayKind={Status.list}
              />
            </>
          }
          {
            
            <div>
              <h3>Assets:</h3>
                {
                  rerenderChild === Status.standard &&
                  <MaterialsView
                    items={item.getMaterialItems()}
                    getItems={async () => {
                      await item.getChildrenFromServer()
                      return item.getMaterialItems()
                    }}
                    identKey={`module-view-${module.id}-`}
                    parentObjectKind={item.objectKind}
                    parentPublishKind={item.publishKind}
                    addItem={async (position, jtem) => {
                      await item.addMaterialItem(position, jtem)
                      setRerenderChild(Status.loading)
                      setTimeout(() => {
                        setRerenderChild(Status.standard)
                      }, 200)
                    }}
                    displayKind={Status.standard}
                    trashItem={async (jtem) => {
                      await item.trashMaterialItem(jtem)
                      setRerender(new Date().getTime())
                    }}
                  />
                }
            </div>
          }
        </div>
      </div>
    }
    { (props.displayMode === DisplayMode.leftSide || props.displayMode === DisplayMode.agenda || props.displayMode === DisplayMode.agendaPlus) &&
      <DropZone
        label={``}
        position={props.position || 0}
        addItem={async (position, item) => {
          if (props.addItem) {
            props.addItem(position, item, 'position')
          }
        }}
        acceptKind={ObjectKind.trainingItem}
        className='ptdropzoneHorizontal ptdropTopGeneric'
      />
    }
  </div>
}