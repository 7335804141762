import React from 'react'
import './CollectibleItemA.scss'
import CollectibleDTO, {CollectiblesDTO} from '../../DTO/CollectibleDTO'
// import PageElementDTO from '../../DTO/PageElementDTO'
// import { ShoppingBag, Plus } from 'react-feather'
import mainservice from '../../services/MainService'
import './CollectibleItemA.scss'
import { Trash } from 'react-feather'
import { Flip, Source, Remove, ArrowRight, BookmarkAdd, BookmarkSubtract, Share } from '../Icon/Icon'
import Tooltip from '../Tooltip/Tooltip'

// import QRCode from 'qrcode.react'
var QRCode = require('qrcode.react');

enum Status {
  standard,
  chooseCollectible,
  showMore
}

type Props = {
  collectible: CollectibleDTO,
  onClick?: (id: number) => void,
  className?: string,
  singleMode?: boolean
}

type State = {
  status: Status,
  removed: boolean,
  showCopyToClipboardNotice: boolean,
  qrCodeInverter: boolean
}

export default class CollectibleItemC extends React.Component<Props, State> {
  showCopyToClipboardNoticeTimer: any
  constructor(props: Props) {
    super(props)
    this.state = {
      status: Status.standard,
      removed: false,
      showCopyToClipboardNotice: false,
      qrCodeInverter: true
    }
  }

  componentWillUnmount() {
    if (this.showCopyToClipboardNoticeTimer) {
      clearTimeout(this.showCopyToClipboardNoticeTimer)
    }
  }

  generateClassName() {
    if (this.props.className !== undefined) {
      return this.props.className
    }
    return 'show w3-col s12 m6 l4 xl3 ' + 'collectibleId-' + this.props.collectible.id
  }

  copyToClipBoard(link: string) {
    if (this.showCopyToClipboardNoticeTimer) {
      clearTimeout(this.showCopyToClipboardNoticeTimer)
    }
    navigator.clipboard.writeText(link);
    this.setState({showCopyToClipboardNotice: true})
    this.showCopyToClipboardNoticeTimer = setTimeout(() => {
      this.setState({showCopyToClipboardNotice: false})
    }, 1000)

  }

  getDirectLink() {
    return `https://${window.location.hostname}/view=collectible/uid=${this.props.collectible.uid}`
  }

  renderToggleMoreButton() {
    return <div
      className={`toggleMore pointer`}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({
          status: (this.state.status === Status.showMore) ? Status.standard : Status.showMore
        })
      }}
    ><Flip /></div>
  }

  renderButtons() {
    const c = this.props.collectible
    return <div className='optionbox-bottom-right'>
      {
        [
          {
            title: 'Gehe zu Quelle',
            onClick: () => {
              mainservice.navTo([
                ['view', 'book'],
                ['book', c.sourceBookId],
                ['doublePageId', c.sourceDoublePageId]
              ])
            },
            content: <Source />,
            condition: () => {
              return c.sourceBookId && c.sourceBookId > -1 &&
                c.sourceDoublePageId && c.sourceDoublePageId > -1
            }
          },
          {
            title: 'Aus Sammlung entfernen',
            onClick: async () => {
              await c.ditch(mainservice.loginService.user.id)
              this.forceUpdate()
            },
            content: <BookmarkSubtract />,
            condition: () => c.collected
          },
          {
            title: 'Einloggen um zur Sammlung hinzuzufügen',
            onClick: async () => {
              mainservice.broadcast('showLogin', true)
            },
            content: <BookmarkAdd />,
            condition: () => !c.collected && !mainservice.loginService.instantIsLoggedIn()
          },
          {
            title: 'Zu Sammlung hinzufügen',
            onClick: async () => {
              await c.collect({bookId: -1, doublePageId: -1})
              this.forceUpdate()
            },
            content: <BookmarkAdd />,
            condition: () => !c.collected && mainservice.loginService.instantIsLoggedIn()
          },
          {
            title: 'Kopiere Link in Zwischenablage',
            onClick: () => {this.copyToClipBoard(this.getDirectLink())},
            content: <>
              <Share />
              { this.state.showCopyToClipboardNotice &&
                <Tooltip content={`Link in Zwischenablage kopiert`} />
              }
            </>,
            condition: () => true
          },
          /*
          {
            title: 'Direkt Link',
            onClick: () => {},
            content: <a href={this.getDirectLink()}><ArrowRight /></a>
          }
          */
        ].map((e, i) => {
          if (!e.condition()) { return null }
          return <button
          key={i}
          onClick={() => {e.onClick()}}
          >
          <Tooltip content={e.title} />
          { e.content }
          </button>
        }
        )
      }
    </div>
  }

  // Admin may select which collectible is to be used
  render() {

    // console.log('Collectible ELEMENT', this.props.collectible)
    const c = this.props.collectible
    if (!c || this.state.removed) { return <></> }

    return <div className={`${this.generateClassName()}`}>
      <div className={`collectible-box ${this.state.status === Status.showMore ? 'showMore' : 'dontShowMore'}`}>
        <div
          onClick={() => {
            if (this.props.onClick) {
              this.props.onClick(c.id)
            }
          }}
          className={`collectibleItemInner collectible-content collectible-type-${c.type}`}
        >
          <div className='cardTypeString'>{c.typeName()}</div>
          <div
            className='cardContentFullSize'
            dangerouslySetInnerHTML={{__html: c.getContent()}}
            // style={{backgroundImage: `url("data:image/svg+xml;utf8,${c.content}")`}}
          >
          </div>
          { !this.props.singleMode && this.renderToggleMoreButton() }
        </div>
        <div className={`collectibleMore`}>
          <Remove
            onClick={() => {
              this.setState({
                status: Status.standard
              })
            }}
            className='closeButton'
          />
          <div className='title'>{c.title}</div>
          <QRCode
            className='qrCode'
            onClick={() => {
              this.setState({
                qrCodeInverter: !this.state.qrCodeInverter
              })
            }}
            value={this.getDirectLink()}
            renderAs='svg'
            width='500'
            height='500'
            includeMargin={true}
            fgColor={this.state.qrCodeInverter ? '#ffffff' : '#272d2f'}
            bgColor={this.state.qrCodeInverter ? '#272d2f' : '#ffffff'}
          />
          { this.renderButtons() }
        </div>
      </div>
      { this.props.singleMode && this.renderButtons() }
    </div>
  }
}
