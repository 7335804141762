import { Setter } from "../../services/ComService";
import { PersonEditFields } from "../editFields/PersonEditFields";
import ItemDTO, { IItem } from "./ItemDTO";
import PropDTO from "./PropDTO";
import { TagDTO } from "./TagDTO";

export default class ItemPersonDTO extends ItemDTO {
  personNumber?: number
  email: string = ''
  firstname: string = ''
  lastname: string = ''
  trainer: number = -1
  customers: {id: number, name: string}[] = []
  propOptions = {
    srcAddProp: '',
    srcPatchProp: 'spt/person/prop/patch',
    srcDelProp: '',
  }
  constructor(o: IItemPerson) {
    super(o)
    this.personNumber = o.personNumber
    this.srcAdd = 'spt/person/add'
    this.srcPatch = 'spt/person/patch'
    this.srcGet = 'spt/person/get'
    this.srcAddProp = 'spt/person/prop/add'
    this.srcPatchProp = 'spt/person/prop/patch'
    this.srcDelProp = 'spt/person/prop/del'
    this.srcSetTag = 'spt/person/setTag'
    this.srcUnsetTag = 'spt/person/unsetTag'
    this.editFields = PersonEditFields
    this.init(o)
  }

  init(o: IItemPerson) {
    this.id = o.id || -1
    this.firstname = o.firstname || ''
    this.lastname = o.lastname || ''
    this.email = o.email || ''
    this.trainer = o.trainer || -1
    this.personNumber = o.personNumber
    // this.description = o.description || ''
    if (o.props !== undefined) {
      this.props = o.props.map((prop) => new PropDTO(prop, this.propOptions))
    }
    if (o.customers) {
      this.customers = o.customers
    }
    if (o.tags !== undefined) {
      this.tags = o.tags.map((tag) => new TagDTO(tag))
    }
  }

  getPersonNumber() {
    return this.personNumber || 'NA'
  }

  async setName(name: string) {
    const parts = name.split(' ')
    let i = 0
    parts.forEach(p => {
      if (p.search('@') > -1) {
        this.email = p
      } else if(i === 0) {
        this.firstname = p
        i += 1
      } else {
        this.lastname = p
        i += 1
      }
    })
    await this.saveToServer()
  }

  async setEmail(email: string) {
    this.email = email
    await this.saveToServer()
  }

  async setFirstname(firstname: string) {
    this.firstname = firstname
    await this.saveToServer()
  }

  async setLastname(lastname: string) {
    this.lastname = lastname
    await this.saveToServer()
  }

  public getDisplayName() {
    let email = this.email
    if (email) {
      email = ' (' + email + ')'
    }
    return [this.firstname, this.lastname, email].filter(e => !!e).join(' ')
  }

  public getInitials() {
    let p = [this.firstname, this.lastname].filter(e => !!e)
    if (p.length === 0) {
      p = this.email.split('@')[0].split('.')
    }
    return p.map(t => t[0]).join('').toUpperCase()
  }

  public nameToColor() {
    const color = this.getPropV1('data', 'color')
    if (color) { return color }
    return '#9e9e9e'
  }

  async setTrainer(trainer: number) {
    this.trainer = trainer
    await this.saveToServer()
  }

  async saveToServer() {
    const url = this.id === -1 ? this.srcAdd : this.srcPatch
    const data = await Setter(url, {
      id: this.id,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      trainer: this.trainer,
    })
    this.init(data.item)
  }

  public async addCustomer(customerId: number) {
    await Setter('spt/person/addCustomer', {
      id: this.id,
      customerId: customerId,
    })
  }

  public async removeCustomer(customerId: number) {
    await Setter('spt/person/removeCustomer', {
      id: this.id,
      customerId: customerId,
    })
  }

  public async addCustomerLocation(customerLocationId: number) {
    await Setter('spt/person/addCustomerLocation', {
      id: this.id,
      customerLocationId: customerLocationId,
    })
  }

  public async removeCustomerLocation(customerLocationId: number) {
    await Setter('spt/person/removeCustomerLocation', {
      id: this.id,
      customerLocationId: customerLocationId,
    })
  }

  public async addEvent(eventId: number) {
    await Setter('spt/event/addPerson', {
      id: eventId,
      personId: this.id,
    })
  }

  public async infoAbsorber(i: string) {
    let lines = i.split(/\n/)
    if (lines.length === 1) {
      lines = i.split(' ')
    }
    let fields = {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNr: '',
      postalcode: '',
      town: '',
      url: '',
      company: '',
    }
    let fieldTests = [
      {
        key: 'name',
        active: true,
        works: 'once',
        scope: /([^ ].+) ([^ ]+)/,
        action: ((r: string[]) => {
          fields.firstname = r[1]
          fields.lastname = r[2]
        }),
      },
      {
        key: 'street',
        active: true,
        works: 'once',
        scope: /([^0-9(]*[^ 0-9])[^0-9]*(\d+[^ ]*)/,
        action: ((r: string[]) => {
          fields.street = r[1]
          fields.streetNr = r[2]
        }),
      },
      {
        key: 'town',
        active: true,
        works: 'once',
        scope: /([\d^+]+)[- ]*([A-Z].*)/,
        action: ((r: string[]) => {
          fields.postalcode = r[1]
          fields.town = r[2]
        }),
      },
      {
        key: 'email',
        active: true,
        works: 'once',
        scope: /([^ ]+@[^ ]+)/,
        action: ((r: string[]) => {
          fields.email = r[1]
          if (!fields.lastname) {
            fields.lastname = r[1]
          }
        }),
      },
      {
        key: 'url',
        active: true,
        works: 'once',
        scope: /(www\.\S+)/,
        action: ((r: string[]) => {
          fields.url = r[1]
        }),
      },
      {
        key: 'phone',
        active: true,
        works: 'once',
        scope: /([+()0-9][-() 0-9]+[0-9])/,
        action: ((r: string[]) => {
          fields.phone = r[1]
        }),
      },
      
      {
        key: 'firstname',
        active: true,
        works: 'once',
        scope: /([^ ].+)/,
        action: ((r: string[]) => {
          if (!fields.firstname) {
            fields.firstname = r[1]
          }
        }),
      },
      {
        key: 'lastname',
        active: true,
        works: 'once',
        scope: /([^ ].+)/,
        action: ((r: string[]) => {
          if (!fields.lastname) {
            fields.lastname = r[1]
          }
        }),
      },
    ]

    lines.forEach(l => {
      fieldTests.some(ft => {
        if (!ft.active) { return false }
        const r = l.match(ft.scope)
        if (!r) { return false }
        ft.action(r)
        ft.active = false
        return true
      })
    })
    
    this.email = fields.email
    this.firstname = fields.firstname
    this.lastname = fields.lastname
    await this.saveToServer()
    await this.addProp('data', 'street', fields.phone)
    await this.addProp('data', 'streetNr', fields.streetNr)
    await this.addProp('data', 'postalcode', fields.postalcode)
    await this.addProp('data', 'town', fields.town)
    await this.addProp('data', 'phone', fields.phone)
    await this.addProp('data', 'url', fields.url)
    await this.addProp('data', 'input', '<p>' + lines.join('<br />') + '</p>')
  }
}

export interface IItemPerson extends IItem {
  personNumber?: number,
  eventId?: number,
  customerId?: number,
  customerLocationId?: number,
  email?: string,
  firstname?: string,
  lastname?: string,
  trainer?: number,
  customers?: {id: number, name: string}[],
}

