import { useEffect, useState } from "react";
import { TagDTO, TagType, TagsDTO } from "../../DTO/TagDTO";
import './TagSelector.scss'
import { LightOrDarkColor } from "../../../services/LightOrDarkColor";

enum Status {
  loading,
  standard,
}

export function TagsColorsDisplay(props: {
  kind: TagType,
  selectedTags: TagDTO[],
  className?: string,
}) {
  const [currentTags, setCurrentTags] = useState(new TagsDTO({
    tags: props.selectedTags,
    kind: props.kind,
  }))
  useEffect(() => {
    setCurrentTags(new TagsDTO({
      tags: props.selectedTags,
      kind: props.kind,
    }))
  }, [props.selectedTags])
  return <div
    className={`${props.className || ''}`}
  >
    {
        currentTags.getTags().map((ct, index) => {
          return <div className="w3-padding-small tag-bubble w3-round-xxlarge " style={{
            backgroundColor: ct.color
          }}>&nbsp;</div>
        })
      }
  </div>
}

export function TagSelector(props: {
  kind: TagType,
  mode?: 'single' | 'list',
  selectedTags: TagDTO[],
  parentId: number,
  selectTag: (tag: TagDTO, single: boolean) => void,
  unsetTag: (tag: TagDTO) => void,
  readMode: boolean,
}) {
  const [allTags] = useState(new TagsDTO({
    kind: props.kind,
  }))
  const [currentTags, setCurrentTags] = useState(new TagsDTO({
    tags: props.selectedTags,
    kind: props.kind,
  }))
  const [status, setStatus] = useState(Status.standard)
  const [needle, setNeedle] = useState('')
  const [drawer, setDrawer] = useState(false)
  const [redraw, setRedraw] = useState(0)
  
  useEffect(() => {
    setCurrentTags(new TagsDTO({
      tags: props.selectedTags,
      kind: props.kind,
    }))
  }, [props.selectedTags])
  
  return <div
    title={`${props.parentId}`}
    className={`tag-selector w3-dropdown-hover`}
  >
    <div
      className={`current-tags ${status === Status.loading ? 'tags-loading' : ''}`}
      onClick={() => {
        if (props.readMode) { return }
        setStatus(Status.loading)
        allTags.getFromServerByKind(props.kind, true).then(() => {
          setDrawer(!drawer)
          setStatus(Status.standard)
        })
      }}
    >
      {
        currentTags.getTags().map((ct, index) => {
          return <Tag
            key={`current-tag-${ct.id}-${index}`}
            tag={ct}
            unset={() => {
              currentTags.removeTagLocal(ct.id)
              props.unsetTag(ct)
            }}
            readmode={props.readMode}
          />
        })
      }
      {
        currentTags.tags.length === 0 && props.readMode &&
        <span>keine</span>
      }
      {
        currentTags.tags.length === 0 && !props.readMode &&
        <span>Jetzt auswählen</span>
      }
    </div>
    {
      drawer &&
      <div className={`w3-card select-new-tags w3-dropdown-content w3-border w3-shadow`}>
        <div className='search-and-create-tag-row flex'>
          <input
            placeholder={`Tag Eingabe`}
            value={needle}
            onChange={(event) => {
              setNeedle(event.target.value)
            }}
          />
          <button
            className='tagSaveTag positiveButton'
            onClick={async () => {
              const newTag = await allTags.addTag(needle)
              currentTags.addTagLocal(newTag, props.mode === 'single')
              await props.selectTag(newTag, props.mode === 'single')
              setNeedle('')
            }}
          >Tag speichern</button>
        </div>
        {
          status === Status.loading &&
          <span>Lade ...</span>
        }
        {
          status !== Status.loading &&
          allTags.getByNeedle(needle, currentTags.getTags()).map((t, index) => {
            return <Tag
            key={`all-tag-${t.id}-${index}`}
              tag={t}
              onClick={async () => {
                currentTags.addTagLocal(t, props.mode === 'single')
                await props.selectTag(t, props.mode === 'single')
                setRedraw(redraw + 1)
              }}
            />
          })
        }
        
      </div>
    }
  </div>
}

export function Tag(props: {
  tag: TagDTO,
  onClick?: () => void,
  unset?: () => void,
  readmode?: boolean,
}) {
  return <button
    className={`tag-bubble w3-round-xxlarge tag-color-${LightOrDarkColor(props.tag.color)}`}
    style={{
      backgroundColor: props.tag.color
    }}
  >
    <span
      className={`w3-padding-small ${props.readmode ? '' : 'cursor'}`}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}
    >
      {props.tag.name}
    </span>
    {
      props.unset && !props.readmode && !props.tag.fixed &&
      <span
        className={`cursor deselect-tag w3-border-0`}
        onClick={() => {
          if (props.unset) {
            props.unset()
          }
        }}
      >&times;</span>
    }
  </button>
}
