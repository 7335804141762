import React from 'react'
import ReactDOM from 'react-dom'
import './static/styles/w3.css'
import './static/styles/vars.scss'
import './static/styles/base.scss'
import Main from './Main'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import {ReactComponent as IconMap20211101} from './static/gfx/2021-11-01-icon-map.svg'

ReactDOM.render(
  <React.StrictMode>
    <IconMap20211101 />
    <Main />
  </React.StrictMode>,
  document.getElementById('root')
)

const configuration: any = {
  onUpdate: (registration: any) => {
    if (registration && registration.waiting) {
      if (window.confirm('Neue Version verfügbar. Update durchführen?')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(configuration)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
