import { forEachChild } from "typescript";
import {Prop, ScaleKindToMinMax, UserData} from "./WidgetDTO";
import WidgetUserDTO, { Entry } from "./WidgetUserDTO";

const EntryWidth = 5
const EntriesPerPage = 15
type Position = {id: number, xVal: number | undefined, xPos: number | undefined}

export default class WidgetDiagramUserDTO {
  xMin: number = 0
  xMax: number = 0
  yMin: number = 0
  yMax: number = 0
  zMin: number = 0
  zMax: number = 0
  pageCount: number = 1
  minItemsperPage: number = 3
  entries: Entry[] = []

  widget: WidgetUserDTO
  positionArr: Position[] = []
  yArr: Position[] = []
  zArr: Position[] = []
  entryWidth = EntryWidth
  xColumn: Prop | undefined
  yColumn: Prop | undefined
  zColumn: Prop | undefined
  constructor(widget: WidgetUserDTO) {
    this.widget = widget
    this.initD()
  }

  initD () {
    const isDiagram = this.widget.getPropV1({key1: 'globProp', key2: 'generalLayout'}) === 'diagram'
    // Quit if this is not a diagram
    if (!isDiagram) { return }
    this.entries = this.widget.getEntries()
    this.pageCount = Math.max(1, Math.ceil(this.entries.length / EntriesPerPage))
    this.entryWidth = EntryWidth / this.pageCount
    // find out which are the colums for x and y
    const xColumn = this.widget.getProp({key1: 'globProp', key2: 'xAxis'})
    this.xColumn = xColumn
    const yColumn = this.widget.getProp({key1: 'globProp', key2: 'yAxis'})
    this.yColumn = yColumn
    const zColumn = this.widget.getProp({key1: 'globProp', key2: 'zAxis'})
    this.zColumn = zColumn
    const minXGap = this.entryWidth
    const maxXGap = this.entryWidth * 3
    // Get Min and max values:
    let xMin: number | undefined
    let xMax: number | undefined
    let yMin: number | undefined
    let yMax: number | undefined
    let zMin: number | undefined
    let zMax: number | undefined
    let lastXPos: number = -minXGap
    let positionArr: Position[] = []
    
    const yColumnKind = this.widget.getProp({key1: `childAttribute${yColumn?.value1 || ''}`, key2: 'kind'})?.value1 || ''
    const yColumnScale = ScaleKindToMinMax(yColumnKind)
    const zColumnKind = this.widget.getProp({key1: `childAttribute${zColumn?.value1 || ''}`, key2: 'kind'})?.value1 || ''
    const zColumnScale = {...ScaleKindToMinMax(zColumnKind), ...{targetMin: 40, targetMax: 90}}
    this.entries = this.entries.sort((a, b) => {
      const aVal = a.columns.find(c => c.key1 === `childAttribute${xColumn?.value1 || ''}`)?.userValueInt || 0
      const bVal = b.columns.find(c => c.key1 === `childAttribute${xColumn?.value1 || ''}`)?.userValueInt || 0
      if (aVal < bVal) { return -1 }
      if (aVal > bVal) { return 1 }
      return 0
    })
    this.entries.forEach(e => {
      let xVal: number | undefined
      if (xColumn) {
        xVal = getValueFromPoint(xColumn, e)
        xMin = maxMin(xMin, xVal, 'min')
        xMax = maxMin(xMax, xVal, 'max')
      }
      if (yColumn) {
        let yVal = getValueFromPoint(yColumn, e)
        if (yVal === undefined) {
          yVal = (yColumnScale.max - yColumnScale.min) / 2 + yColumnScale.min
        }
        yMin = maxMin(yMin, yVal, 'min')
        yMax = maxMin(yMax, yVal, 'max')
        this.yArr.push({id: e.id, xVal: yVal, xPos: scaleValue(yVal, yColumnScale)})
      }
      if (zColumn) {
        let zVal = getValueFromPoint(zColumn, e)
        if (zVal === undefined) {
          zVal = (zColumnScale.max - zColumnScale.min) / 2 + zColumnScale.min
        }
        zMin = maxMin(zMin, zVal, 'min')
        zMax = maxMin(zMax, zVal, 'max')
        this.zArr.push({id: e.id, xVal: zVal, xPos: scaleValue(zVal, zColumnScale)})
      } else {
        const zVal = (zColumnScale.max - zColumnScale.min) / 2 + zColumnScale.min
        this.zArr.push({id: e.id, xVal: zVal, xPos: scaleValue(zVal, zColumnScale)})
      }
      positionArr.push({id: e.id, xVal: xVal, xPos: undefined})
    })
    this.xMax = xMax || 0
    this.xMin = xMin || 0
    this.yMax = yMax || 0
    this.yMin = yMin || 0
    this.zMax = zMax || 0
    this.zMin = zMin || 0
    positionArr.map(p => {
      if (p.xVal && xMax !== undefined && xMin !== undefined) {
        p.xPos = Math.min(
          Math.max(
            (p.xVal - xMin) / (xMax - xMin) * (100 - this.entryWidth),
            lastXPos + minXGap
          ),
          lastXPos + maxXGap
        )
      } else {
        p.xPos = lastXPos + minXGap
      }
      lastXPos = p.xPos
      return p
    })
    positionArr = this.removeOverlapDown(positionArr)
    this.positionArr = this.removeOverlapUp(this.positionArr)
    this.positionArr = this.removeOverlapDown(this.positionArr)
    this.positionArr = this.removeOverlapUp(this.positionArr)
    this.positionArr = this.removeOverlapDown(this.positionArr)
    this.positionArr = this.removeOverlapUp(this.positionArr)
    this.positionArr = this.removeOverlapDown(this.positionArr)
    this.positionArr = this.removeOverlapUp(this.positionArr)
    this.positionArr = this.removeOverlapDown(this.positionArr)
    this.positionArr = this.removeOverlapUp(this.positionArr)
    this.positionArr = positionArr
  }


  getPosition(id: number) {
    return  this.positionArr.find(p => p.id === id)?.xPos
  }

  getValue(id: number) {
    return  this.positionArr.find(p => p.id === id)?.xVal
  }

  removeOverlapUp(list: Position[]): Position[] {
    let lastXPos = - this.entryWidth
    for (let i = 0, m = list.length; i < m; i++) {
      let p = list[i]
      let newMaxVal = lastXPos + this.entryWidth
      if (newMaxVal > (p.xPos || 0)) {
        list[i].xPos = newMaxVal
      }
      lastXPos = list[i].xPos || 0
    }
    return list
  }
  
  removeOverlapDown(list: Position[]): Position[] {
    let lastXPos = 100
    for (let i = list.length - 1; i >= 0; i--) {
      let p = list[i]
      let newMaxVal = lastXPos - this.entryWidth
      if (newMaxVal < (p.xPos || 0)) {
        list[i].xPos = newMaxVal
      }
      lastXPos = list[i].xPos || 0
    }
    return list
  }
}

function scaleValue(value: number | undefined, o?: {max?: number, min?: number, targetMin?: number, targetMax?: number}): number {
  const min = (o?.min !== undefined) ? o.min : -6
  const max = (o?.max !== undefined) ? o.max : 9
  const MIN = (o?.targetMin !== undefined) ? o.targetMin : 10
  const MAX = (o?.targetMax !== undefined) ? o.targetMax : 90
  const mm = max - min
  const MM = MAX - MIN
  if (value === undefined) { return 50 }
  return (value - min) * MM / mm + MIN
}

function getValueFromPoint(column: Prop, entry: Entry): number | undefined {
  const scope = 'childAttribute' + column.value1
  const c = entry.columns.find(c => c.key1 === scope)
  if (!c) { return undefined }
  return (isNaN(c.userValueInt)) ? undefined : c.userValueInt
}

function maxMin(current: number | undefined, next: number | undefined, maxOrMin: 'max' | 'min'): number | undefined {
  if (current === undefined) { return next }
  if (next === undefined) { return current }
  if (maxOrMin === 'max') { return Math.max(current, next) }
  return Math.min(current, next)
}
