import React from 'react'
import './CakeA.scss'

type DataProp = {
  name?: string,
  key: string,
  amount: number
}

type RenderData = {
  key: string,
  deg: number
}

type CakeAProps = {
  data: DataProp[],
  animate?: boolean
}

type CakeAState = {
  renderData: RenderData[],
  showAmount: number,
  full: number
}

class CakeA extends React.Component<CakeAProps, CakeAState> {
  state: CakeAState
  delay: any
  degreesInCircle = 360
  constructor(props: CakeAProps) {
    super(props)
    this.state = {
      renderData: this.generateRenderData(props.data),
      showAmount: 0,
      full: 0
    }
  }

  componentDidMount() {
    this.animate()
  }

  animate() {
    if (this.props.animate === false) {
      this.setState({showAmount: 365})
      return
    }
    clearTimeout(this.delay)
    this.delay = setTimeout(() => {
      let animate = setInterval(() => {
        this.setState({showAmount: this.state.showAmount + 5})
        if (this.state.showAmount > 365) {
          clearInterval(animate)
        }
      }, 10)
    }, 700)
  }

  componentWillReceiveProps(props: CakeAProps) {
    this.setState({
      renderData: this.generateRenderData(props.data),
      showAmount: 0
    })
    this.animate()
  }

  generateRenderData(data: DataProp[]): RenderData[] {
    // Calc Full Amount
    let full = 0
    let i = 0
    let result: RenderData[] = []
    data.forEach((d) => full += d.amount)
    this.setState({full: full})
    if (full === 0) {
      return []
    }
    // Set elements for all data:
    data.forEach((d) => {
      let j = 0
      const segments = d.amount / full * this.degreesInCircle
      while(j < segments && i < this.degreesInCircle) {
        result.push({
          key: d.key,
          deg: i
        })
        i += 1
        j += 1
      }
    })
    // Fill rest of cake with last value:
    while(i < this.degreesInCircle) {
      result.push({
        key: data[data.length - 1].key,
        deg: i
      })
      i += 1
    }
    return result
  }

  calcPercentage(amount:  number) {
    if (this.state.full === 0) {
      return 0
    }
    return Math.round(amount / this.state.full * 100)
  }

  render() {
    const showAmount = this.state.showAmount
    let i = 0

    return <div className='w3-row cakeA'>
      <div className='cakePlateHolder w3-col s12 m6 l6'>
        <div className='cakeAPlate'>
          {
            this.state.renderData.map((a) => {
              if (i > showAmount) {
                return
              }
              i += 1
              const style = {
                transform: `rotate(${a.deg}deg)`
              }
              return <div key={`cakePieceKey${a.deg}`} className={`cakeAPiece cakeAPieceKey-${a.key}`} style={style}></div>
            })
          }
        </div>
      </div>
      <div className='cakeALegend w3-col s12 m6 l6'>
        <table className='centerBox'>
          <tbody>
          {
            this.props.data.map((d, index) => {
              return <tr key={`cakePieceKey-${index}`}>
                <td key={d.key} className='cakeALegendEntry'>
                  <div className={`marker cakeAPieceKey-${d.key}`}></div>
                </td>
                <td className='description'>
                  {d.name}
                </td>
                <td className='amount'>
                  {this.calcPercentage(d.amount)} %
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  }
}

export default CakeA
