export type TPaperSize = {
  key: 'A4' | 'A5' | 'A6',
  name: string
}

export type TPaperOrientation = {
  key: 'portrait' | 'landscape',
  name: string
}

export const PaperSizes: TPaperSize[] = [
  {
    key: 'A4',
    name: 'Din A4'
  },
  {
    key: 'A5',
    name: 'Din A5'
  },
  {
    key: 'A6',
    name: 'Din A6'
  }
];

export const PaperOrientation: TPaperOrientation[] = [
  {
    key: 'portrait',
    name: 'Hochkant'
  },
  {
    key: 'landscape',
    name: 'Querformat'
  },
];
