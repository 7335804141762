import FileDTO, { IFile } from "../../DTO/FileDTO"
import { Setter } from "../../services/ComService"
import { parseStringFromServer } from "../../services/TextConverter"

export default class PropDTO {
  id: number = -1
  key1: string = ''
  key2: string = ''
  value1: string = ''
  value2: string = ''
  file?: IFile // So that it can be cloned
  File?: FileDTO
  validFromDate?: string
  srcAddProp: string = ''
  srcPatchProp: string = 'spt/patchProp'
  srcDelProp: string = 'spt/removeProp'

  constructor(o: IProp, options?: {
    srcAddProp: string
    srcPatchProp: string
    srcDelProp: string
  }) {
    if (options) {
      this.srcAddProp = options.srcAddProp
      this.srcPatchProp = options.srcPatchProp
      this.srcDelProp = options.srcDelProp
    }
    this.init(o)
  }

  private init(o: IProp) {
    this.id = o.id
    this.key1 = o.key1
    this.key2 = o.key2
    this.value1 = o.value1
    this.value2 = o.value2
    this.file = o.file
    if (o.file) {
      this.File = new FileDTO(o.file)
    }
    if (typeof o.validFromDate === 'string') {
      this.validFromDate = new Date(o.validFromDate).toJSON()
    } else if (o.validFromDate) {
      this.validFromDate = new Date((o.validFromDate as unknown as {date: string}).date || '').toJSON()
    } else {
      this.validFromDate = undefined
    }
  }

  public async saveToServer() {
    const url = this.srcPatchProp
    console.log('saveToServer', this)
    // if (this.id === -1) { return }
    return await Setter(url, this.get())
  }

  public async remove() {
    const url = this.srcDelProp
    if (this.id === -1) { return }
    await Setter(url, {id: this.id})
  }

  public get () {
    return {
      id: this.id,
      key1: this.key1,
      key2: this.key2,
      value1: this.value1,
      value2: this.value2,
      file: this.file,
      validFromDate: this.validFromDate,
    }
  }

  public getV1 () {
    return parseStringFromServer(this.value1)
  }

  public getV2 () {
    return parseStringFromServer(this.value2)
  }

  public async activateFile() {
    this.validFromDate = 'NOW'
    this.value1 = 'active'
    const data = await this.saveToServer()
    this.init(data.item)
  }
}

export interface IProp {
  id: number
  key1: string
  key2: string
  value1: string
  value2: string
  file?: IFile
  validFromDate?: string | {date: string}
}
