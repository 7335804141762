import { MakeArrayItemsUnique } from "../../services/ArrayAddUnique"
import { Setter } from "../../services/ComService"

export type TagType = 'sorting' | 'generic' | 'materialNameTag' | 'materialTags' | 'materialStorageLocation' | 'materialKindTag' | 'subjectTags' | 'language' | 'workStateTag' | 'eventKind' | 'purchaseStateTag' | 'eventLanguage' | 'priorityA' | 'contractState' | 'moduleKind' | 'marketingRole' | 'groupInfo' | 'targetAudience' | 'billingStatus' | 'participation'

export const TagTypes: {key: TagType, name: string}[] = [
  {
    key: 'materialNameTag',
    name: 'Material Namen'
  },
  {
    key: 'materialTags',
    name: 'Tags für Materialien'
  },
  {
    key: 'materialStorageLocation',
    name: 'Lagerorte'
  },
  {
    key: 'subjectTags',
    name: 'Themen'
  },
  {
    key: 'materialKindTag',
    name: 'Arten von Materialien'
  },
  {
    key: 'workStateTag',
    name: 'Bearbeitungsstände'
  },
  {
    key: 'language',
    name: 'Sprache'
  },
  {
    key: 'eventKind',
    name: 'Arten Veranstaltungen'
  },
  {
    key: 'purchaseStateTag',
    name: 'Arten von Auftragsständen'
  },
  {
    key: 'eventLanguage',
    name: 'Veranstaltungssprachen'
  },
  {
    key: 'priorityA',
    name: 'Priorität (Fertigstellung)'
  },
  {
    key: 'contractState',
    name: 'Auftragsklärung'
  },
  {
    key: 'moduleKind',
    name: 'Modulart'
  },
  {
    key: 'marketingRole',
    name: 'Vertriebsinfo (Person)'
  },
  {
    key: 'groupInfo',
    name: 'Gruppeninfo'
  },
  {
    key: 'targetAudience',
    name: 'Zielgruppen'
  },
  {
    key: 'billingStatus',
    name: 'Abrechnungsstatus'
  },
]

type Category = {
  name: string,
  tagType: TagType,
  tags: TagsDTO,
  singleActive?: boolean,
}

export class TagDTO {
  public id: number = -1
  public name: string
  public color: string
  public sortnumber: number
  public kind: TagType
  public fixed: boolean = false
  public special: string = ''
  constructor(data: ITag) {
    this.id = (data.id !== undefined) ? data.id : -1
    this.name = data.name
    this.kind = data.kind
    this.fixed = !!data.fixed
    this.color = data.color || '#eeeeee'
    this.sortnumber = data.sortnumber || -1
    this.special = data.special || ''
  }
  public async save() {
    const data = await Setter('spt/addTag', {
      id: this.id,
      name: this.name,
      kind: this.kind,
    })
    if (data.code === 1) {
      // Everything worked as expected!
      this.id = data.item.id
      return true
    }
    // The tag name and kind combination is already there!
    if (this.id === -1) {
      return false
    }
    if (window.confirm(`Tag mit Name ${this.name} ist bereits vorhanden. Tags kombinieren?`)) {
      const merge = await Setter('spt/mergeTag', {
        keepId: data.item.id,
        mergeId: this.id,
      })
      return false
    }
    return true
  }

  public async saveName(name: string): Promise<boolean> {
    this.name = name
    return await this.save()
  }

  public setKindLocal(kind: TagType) {
    this.kind = kind
  }

  public reset(kind?: TagType) {
    this.id = -1
    this.name = ''
    this.kind = kind || 'generic'
  }

  public async saveColor(c: string) {
    this.color = c
    Setter('spt/recolorTag', {
      id: this.id,
      color: c
    })
  }

  public async saveSpecial(c: string) {
    this.special = c
    Setter('spt/respecialTag', {
      id: this.id,
      special: c
    })
  }

  public async saveSortnumber(c: number) {
    this.sortnumber = c
    Setter('spt/resortnumberTag', {
      id: this.id,
      sortnumber: c
    })
  }
}

export interface ITag {
  id?: number,
  name: string,
  kind: TagType,
  fixed?: boolean,
  color?: string,
  sortnumber?: number,
  special?: string,
}

export class TagsDTO {
  public tags: TagDTO[] = []
  public currentKind: TagType = 'generic'
  public enableLoadFromServer: boolean = true
  constructor(data?: ITags) {
    if (!data) {
      return
    }
    if (data.tags) {
      this.enableLoadFromServer = false
      this.tags = data.tags.map(t => new TagDTO(t))
    }
    if (data.kind) {
      this.currentKind = data.kind
    }
  }

  public async getFromServerByKind(kind: TagType, always?: boolean) {
    if (this.currentKind !== kind || always) {
      const url = 'spt/getTags'
      const data = await Setter(url, {
        kind: kind
      })
      this.tags = data.items.map((t: ITag) => new TagDTO(t))
    }
  }

  public getByNeedle(needle: string, blacklist: TagDTO[]) {
    return this.getTags().filter(t => t.name.search(new RegExp(needle, 'gi')) > -1 && (!blacklist || !blacklist.some(b => b.id === t.id)))
  }

  public async addTag(name: string) {
    const url = 'spt/addTag'
    const data = await Setter(url, {
      name: name,
      kind: this.currentKind,
    })
    const newTag = new TagDTO(data.item)
    this.addTagLocal(newTag)
    return newTag
  }

  public addTagLocal(tag: TagDTO, singleMode?: boolean) {
    if (this.tags.some(t => t.id === tag.id || (t.name.toLowerCase === tag.name.toLocaleLowerCase && t.kind === tag.kind))) { return }
    if (singleMode) {
      this.tags = [tag]
    } else {
      this.tags.push(tag)
    }
  }

  public removeTagLocal(id: number) {
    this.tags = this.tags.filter(t => t.id !== id)
  }

  public async deleteTag(id: number) {
    this.removeTagLocal(id)
    const data = await Setter('spt/removeTag', {
      id: id,
    })
  }

  public getTags() {
    return (MakeArrayItemsUnique(this.tags) as TagDTO[]).sort((t1, t2) => {
      return t1.sortnumber < t2.sortnumber ? -1 : 1
    })
  }

  public async newPosition(id: number, newPosition: number) {
    const tags = this.getTags()
    console.log('tags', tags)
    let position = 1
    for (let i = 0, m = tags.length; i < m; i++) {
      let t = tags[i]
      // The item gets always the new position
      if (t.id === id) {
        await t.saveSortnumber(newPosition)
      }
      if (position === newPosition) {
        position += 1
      }
      // all wrong sort numbers will be corrected - but not for the old element
      if (position !== t.sortnumber && position !== newPosition && t.id !== id) {
        await t.saveSortnumber(position)
      }
      // the position has to be incremented - but not if we are jumping over old entry:
      if (!(t.id === id && position !== newPosition)) {
        position += 1
      }
    }
  }

}

export interface ITags {
  tags?: TagDTO[],
  kind?: TagType,
}

export class TagTypesDTO {
  tagTypes: TagType[] = []
  categories: Category[] = []
  activeTags: number[] = []
  constructor(o: ITagTypes) {
    this.tagTypes = o.tagTypes
    if (o.activeTagsString) {
      this.activeTags = o.activeTagsString.split(';').map(ti => parseInt(ti, 10))
    }
  }
  async getFromServer() {
    return new Promise(success => {      
      this.categories = this.tagTypes.map(tt => {
        const srcTagType = TagTypes.find(item => item.key === tt)
        const name = srcTagType?.name || ''
        return {
          name: name,
          tagType: tt,
          tags: new TagsDTO()
        }
      })
      // we have to get the data for all things, and then, we call for success
      let count = this.categories.length
      function done() {
        count -= 1
        if (count === 0) {
          success(true)
        }
      }
      this.categories.forEach(async (category) => {
        await category.tags.getFromServerByKind(category.tagType)
        done()
      })
    })
  }

  getCategories(page?: string) {
    let additionalTags = [
      new TagDTO({
        id: -3,
        name: 'Name',
        kind: 'sorting',
      }),
      new TagDTO({
        id: -6,
        name: 'Erstellungsdatum',
        kind: 'sorting',
      }),
    ]
    /*
    if (page === 'module') {
      additionalTags.push(
        new TagDTO({
          id: -4,
          name: 'Fertigstellungszeit',
          kind: 'sorting',
        })
      )
    }
    */
    return this.categories.concat([{
      name: 'Sortierung',
      tagType: 'sorting',
      singleActive: true,
      tags: new TagsDTO({
        tags: additionalTags
      })
    }])
  }

  async reset(tagTypes?: TagType[]) {
    this.activeTags = []
    if (tagTypes !== undefined) {
      this.tagTypes = tagTypes
    }
    await this.getFromServer()
  }

  hasActiveTag(ids: number[]) {
    return ids.some(id => this.isActiveTag(id))
  }

  isActiveTag(id: number) {
    return this.activeTags.some(at => at === id)
  }

  setActiveTag(id: number) {
    if (this.isActiveTag(id)) {
      return this.getActiveTagString()
    }
    this.activeTags.push(id)
    return this.getActiveTagString()
  }

  unsetActiveTag(id: number) {
    this.activeTags = this.activeTags.filter(at => at !== id)
    return this.getActiveTagString()
  }

  toggleActiveTag(id: number, category?: Category): string {
    if (category && category.singleActive === true) {
      category.tags.tags.forEach(t => this.unsetActiveTag(t.id))
    }
    if (this.isActiveTag(id)) {
      
      return this.unsetActiveTag(id)
    }
    return this.setActiveTag(id)
  }

  getActiveTagString() {
    return this.activeTags.join(';') || ''
  }
}

export interface ITagTypes {
  tagTypes: TagType[],
  activeTagsString?: string,
}
