import { useDrag } from "react-dnd";
import { ObjectKind } from "../models/objectKinds";
import { Anchor, Clock, Copy, Edit, GitMerge, Trash } from "react-feather";
import { useEffect, useRef, useState } from "react";
import ModuleDTO from "../DTO/ModuleDTO";
import DayModuleDTO from "../DTO/DayModuleDTO";
import TimeperiodSelector from "./TimeperiodSelector";
import './moduleView.scss'
import { Minutes2TimeString, TimeString2Minutes } from "../../services/DateTime";
import CONFIG from '../../config.json'
import { DisplayMode, Status } from "./day/dayView";
import { DropZone } from "../elements/DropZone";
import { PublishKind } from "../models/publishKinds";
import MaterialsView from "./materialsView";
import { EditField } from "./EditField";
import './day/dayView.scss'
import './pt.scss'
import { TagsColorsDisplay } from "./tags/TagSelector";
import { parseStringFromServer } from "../../services/TextConverter";
import mainservice from "../../services/MainService";
import { RightDrawerContent } from "./RightDrawer";
import Spinner from "../../components/Spinner/Spinner";
import H2Edit from "../elements/H2Edit";
import Credentials from "./Generic/Credentials";
import EditFieldsPrinter from "../editFields/EditFieldsPrinter";

export default function ModuleView(props: {
  module: ModuleDTO | DayModuleDTO,
  displayMode: DisplayMode,
  parentPublishKind: PublishKind,
  parentObjectKind: ObjectKind,
  // startStop?: {start: number, end: number},
  dayStart?: number,
  index?: number,
  triggerReload?: number,
  broadcastContext?: string,
  trash?: (item: DayModuleDTO) => void,
  templateTrash?: (item: ModuleDTO) => void,
  rerender?: () => void,
  addItem?: (position: number, item: ModuleDTO, type: 'time' | 'position' | 'template2seminar' | 'duration') => void,
  pin?: (module: ModuleDTO, unpin: boolean) => void,
  fullScreenEdit?: () => void,
  close?: () => void,
}) {
  const [module, setModule] = useState(props.module)
  // const module = props.module
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(module.getHeight())
  const [start, setStart] = useState(Minutes2TimeString(module.start || 0, ''))
  const [rerender, setRerender] = useState(0)
  const [rerenderChild, setRerenderChild] = useState(0)
  const [status, setStatus] = useState(Status.loading)
  const [{isDragging}, drag] = useDrag(() => ({
    type: ObjectKind.module + '',
    item: module,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag(monitor) {
      return !!props.fullScreenEdit
    },
  }), [42])
  const broadcastReloadKey = `${props.broadcastContext || ''}editModule${module.id}Done`
  if (props.close) {
    console.log('ICH BIN DER HAUPTACT', module)
  }
  const addItem = async (position: number, item: ModuleDTO) => {
    if (!props.addItem) { return }
    if (item.id === module.id) {
      props.addItem((module.start || 0) + (module.duration), item, 'time')
    } else {
      props.addItem(position, item, 'position')
    }
  }
  const top = (props.displayMode !== DisplayMode.template && props.displayMode !== DisplayMode.agenda && props.displayMode !== DisplayMode.agendaPlus) ? `${((module.start || 0) - (props.dayStart || 0)) * CONFIG.planningHeightFactor}px` : undefined
  const generateColorStyles = module.generateColorStyles()
  const heightStyle = props.displayMode === DisplayMode.leftSide ? (props.displayMode === DisplayMode.leftSide ? `${height}px` : '40px') : undefined
  const styles = {
    ...props.displayMode !== DisplayMode.edit ? {
    height: heightStyle,
    minHeight: heightStyle,
    top: top,
  } : undefined, ...{backgroundColor: generateColorStyles.lightColor, color: generateColorStyles.lightContrastColor}}
  const iconsize = '20px' // props.displayMode === DisplayMode.leftSide ? '16px' : '24px'
  useEffect(() => {
    setStart(Minutes2TimeString(props.module.start || 0, ''))
  }, [props.module.start])
  useEffect(() => {
    // Only perform this if content changed!
    setStatus(Status.loading)
    if (!!props.fullScreenEdit) {
      // This is a list item - so we do not want to reload content!!!
      setModule(props.module)
      setTimeout(() => {
        setStatus(Status.standard)
      }, 200)
    } else {
      console.log('LOADING...', props.module.id)
      // This is a full content - please reload ALL (aditional) content
      props.module.getFromServer().then(() => {
        setModule(props.module)
        setStatus(Status.standard)
      })
    }
  }, [props.module.id, props.module.publishKind, props.module.objectKind])
  mainservice.registerToBroadcast(`${props.broadcastContext || ''}module${module.id}`, async (key, value) => {
    if (key === `updateId-${module.id}`) {
      setStatus(Status.loading)
      await module.getFromServer()
      if (value.duration) {
        module.duration = value.duration
        setHeight(module.getHeight())
      }
      setStatus(Status.standard)
    }
  })
  const className = `ModuleView id-${module.id} display-mode-${props.displayMode} ${(props.displayMode === DisplayMode.leftSide || props.displayMode === DisplayMode.edit) ? '' : 'PTListItem'} ${(status === Status.edit) ? 'expand' : 'notexpanded'} ${(isDragging) ? 'dragging' : 'notDragging'} ${(props.index || 0) % 2 === 0 ? 'module-odd' : 'module-even'} ${props.displayMode === DisplayMode.leftSide ? 'display-mode-dayView' : 'display-mode-agenda'} ${!!module.pin ? 'w3-border-blue' : ''} ${(DisplayMode[props.displayMode] || 'NoDisplayMode')} ${module.isTiny() ? 'isTiny' : ''}`
  if (status === Status.loading) {
    return <div
      className={className + ' textCenter'}
      title={module.id + ' ' + (DisplayMode[props.displayMode] || 'NoDisplayMode')}
      style={styles}
      ref={scrollToRef}
    >
      <Spinner mini />
    </div>
  }
  return <div
  className={className}
    title={module.id + ' ' + (DisplayMode[props.displayMode] || 'NoDisplayMode')}
    style={styles}
    ref={scrollToRef}
  >
    {(props.displayMode === DisplayMode.leftSide) &&
      <div className='leftSideBar'
        style={{height: heightStyle, backgroundColor: generateColorStyles.color}}
      ></div>
    }
    <div className={'lineContent'}>
      { (props.displayMode === DisplayMode.agenda || props.displayMode === DisplayMode.agendaPlus) &&
        <div className='w3-margin-right ModuleViewTime'>
          <div>{start} Uhr</div>
          <div>{module.getDuration()} min</div>
        </div>
      }
      <div className='stretch flex' ref={drag}>
        
        {props.displayMode === DisplayMode.edit ?
          <H2Edit
            value={module.getName()}
            onChange={async (rv) => {
              setStatus(Status.editName)
              await module.setName(rv)
              setStatus(Status.standard)
            }}
            oldValue={module.getUpName(true)}
            resetToOldValue={async () => {
              await module.setName('')
              setRerender(rerender + 1)
            }}
          />
        :
          <>
            <span className='lightFont w3-margin-right' title="Dauer">{module.getDurationForHumanEyes()}</span>
            <span
              onClick={async () => {
                const rv = window.prompt('Namen ändern', module.getName())
                if (!rv) {
                  return
                }
                await module.setName(rv)
                setRerender(rerender + 1)
              }}
            >{module.getName()}</span>
          </>
        }
      </div>
      {
        module.getPropV1('data', 'dueDate') &&
        <div className='EditField'>
          <label className='EditFieldLabel'>FertigstellungsDatum</label>
          <input className='w3-yellow' value={module.getPropV1('data', 'dueDate')} disabled type={'date'}/>
        </div>
      }
      <div className='rightTools dont-print' data-html2canvas-ignore="true">
        {
          props.fullScreenEdit && props.addItem &&
          <Clock
            size={iconsize}
            className='pointer makeFullscreenEdit'
            onClick={() => {
              if (!props.addItem) {
                return
              }
              const rv = window.prompt('Neue Startzeit festlegen', start || '08:00')
              if (!rv) {
                return
              }
              const v = TimeString2Minutes(rv)
              props.addItem(v, module, 'time')

            }}
          />
        }
        {
          props.fullScreenEdit &&
          <>
            <TagsColorsDisplay
              kind={"workStateTag"}
              selectedTags={module.getTagsByKind("workStateTag")}
              className='w3-margin-right'
            />
            <Edit
              size={iconsize}
              className='pointer makeFullscreenEdit'
              onClick={() => {
                if (props.fullScreenEdit) {
                  props.fullScreenEdit()
                }
              }}
            />
          </>
        }
        <GitMerge
          className='editItem pointer'
          size={iconsize}
          onClick={async () => {
            await module.trashPreflight('info')
          }}
        />
        {
          (module.publishKind === PublishKind.isTemplate && props.parentPublishKind === PublishKind.isSeminar) &&
          <>
            <Copy
              size={iconsize}
              className='pointer makeFullscreenEdit'
              onClick={async () => {
                if (props.addItem) {
                  await props.addItem(props.index || -1, module, 'template2seminar')
                }
              }}
            />
          </>
        }
        {
          props.displayMode !== DisplayMode.edit && props.displayMode !== DisplayMode.agenda && props.displayMode !== DisplayMode.agendaPlus && props.displayMode !== DisplayMode.leftSide &&
          <Copy
            className='editItem pointer'
            size={iconsize}
            onClick={async () => {
              await module.clone(-1, undefined, `${module.name} Kopie`)
              if (props.rerender) {
                props.rerender()
              }
            }}
          />
        }
        {
          module.pin && module.start !== module.plannedStart &&
          <div className='w3-red'>{Minutes2TimeString(module.plannedStart || 0)}</div>
        }
        {
          props.pin &&
          <Anchor
            size={iconsize}
            className={`pointer pin-me ${module.pin ? 'w3-blue' : ''}`}
            onClick={() => {
              if (props.pin) {
                props.pin(module, !!module.pin)
              }
            }}
          />
        }
        {
          props.templateTrash && module.id > -1 &&
          <Trash
            className='delButton'
            size={iconsize}
            onClick={async () => {
              if (module instanceof ModuleDTO && props.templateTrash
                // && window.confirm(`Modul wirklich ${module.id} löschen??`)
              ) {
                props.templateTrash(module)
              }
            }}
          />
        }
        {
          props.trash &&
          <Trash
            className='pointer delDay'
            size={iconsize}
            onClick={async () => {
              if (module instanceof DayModuleDTO && props.trash && window.confirm(`Modul wirklich ${module.id} löschen?`)) {
                props.trash(module)
              }
            }}
          />
        }
      </div>
    </div>
    { props.displayMode === DisplayMode.agendaPlus &&
      <div className='info-block'>
        <div className='contentPart' dangerouslySetInnerHTML={{__html: parseStringFromServer(module.getPropV1('data', 'description') || '')}}></div>
        <div className='contentPart'>
          <h3>Assets:</h3>
          {
            <MaterialsView
              items={module.getMaterialItems()}
              getItems={async () => {
                await module.getChildrenFromServer()
                return module.getMaterialItems()
              }}
              getItemsAtInit={true}
              identKey={`module-view-${module.id}-`}
              parentObjectKind={module.objectKind}
              parentPublishKind={module.publishKind}
              parent={module}
              addItem={async (position, item) => {
                await module.addMaterialItem(position, item)
                setRerenderChild(rerenderChild + 1)
              }}
              displayKind={Status.standard}
              trashItem={async (item) => {
                await module.trashMaterialItem(item)
                setRerender(new Date().getTime())
              }}
              editItem={(item: any, objectKind?: ObjectKind) => {
                mainservice.broadcast('rightDrawerOpen', {
                  contentType: RightDrawerContent.editMaterial,
                  broadCastBackKey: broadcastReloadKey,
                  // item: item,
                  id: item.id,
                })
              }}
              reloadTrigger={rerenderChild}
            />
          }
        </div>
      </div>
    }
    {
      (props.displayMode === DisplayMode.edit) &&
      <div className='info-block moduleViewEditMode'>

        <div className='contentPart'>

          <div className='EditField'>
            <label className='EditFieldLabel'>Dauer</label>
            <TimeperiodSelector
              parentId={`${module.id}`}
              value={module.getDuration()}
              setValue={async (value) => {
                await module.setDuration(value)
                setRerender(rerender + 1)
              }}
            />
          </div>
          <EditFieldsPrinter
            fields={module.getEditFields()}
            item={module}
            editMode={props.displayMode === DisplayMode.edit}
            rerenderParent={() => {
              setRerender(rerender + 1)
            }}
          />
        </div>

        <div className='contentPart'>
          <h3>Assets:</h3>
          {
            <MaterialsView
              items={module.getMaterialItems()}
              getItems={async () => {
                await module.getChildrenFromServer()
                return module.getMaterialItems()
              }}
              getItemsAtInit={!props.fullScreenEdit}
              identKey={`module-view-${module.id}-`}
              parent={module}
              parentObjectKind={module.objectKind}
              parentPublishKind={module.publishKind}
              addItem={async (position, item) => {
                await module.addMaterialItem(position, item)
                setRerenderChild(rerenderChild + 1)
              }}
              displayKind={Status.standard}
              trashItem={async (item) => {
                console.log('Asset löschen - Module View')
                await module.trashMaterialItem(item)
                setRerender(new Date().getTime())
              }}
              editItem={(item: any, objectKind?: ObjectKind) => {
                mainservice.broadcast('rightDrawerOpen', {
                  contentType: RightDrawerContent.editMaterial,
                  broadCastBackKey: broadcastReloadKey,
                  // item: item,
                  id: item.id,
                })
              }}
              reloadTrigger={rerenderChild}
            />
          }
        </div>
        <Credentials item={module} />
      </div>
    }
    {
      (props.displayMode === DisplayMode.leftSide || props.displayMode === DisplayMode.agenda || props.displayMode === DisplayMode.agendaPlus) &&
      <DropZone
        label={Minutes2TimeString((module.start || 0) + module.duration)}
        position={(props.index || 0) + 1}
        addItem={addItem}
        acceptKind={ObjectKind.module}
        className='ptdropzoneHorizontal ptdropBottom'
      />
    }
  </div>
}
