import config from '../config.json'
import PagePropDTO from './PagePropDTO'
import { IProp } from './PropDTO'
import PageElementDTO, {IPageElement} from './PageElementDTO'
import FileDTO, {IFile} from './FileDTO'
import {parseStringToServer} from '../services/TextConverter'

export default class PageDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public sortNumber: number = 0
  public pageProps: PagePropDTO[] = []
  public pageElements: PageElementDTO[] = []
  public parentItem: any
  public picture: FileDTO | undefined
  constructor(o: IPage, parentItem?: any) {
    this.id = o.id
    this.initVars(o)
    if (parentItem) {
      this.parentItem = parentItem
    }
  }

  public initVars(o: IPage) {
    this.sortNumber = o.sortNumber * 0.1
    this.pageElements = o.pageElements.sort((a, b) => {
      return a.sortNumber < b.sortNumber ? -1 : 1
    }).map((p) => new PageElementDTO(p, this))
    this.pageProps = (o.pageProps || []).map(
      (eP: IProp) => new PagePropDTO(eP)
    )
    if (o.picture && o.picture.id > -1) {
      this.picture = new FileDTO(o.picture as IFile)
    }
  }

  public async loadFromServer() {
    const response = await fetch(`${this.apiPrefix}Page/${this.id}`)
    const responseJson = await response.json()
    this.initVars(responseJson);
    console.log('responseJson', responseJson)
  }

  public getDoublePageID(): number {
    if (!this.parentItem) { return -1 }
    return this.parentItem.id
  }

  public async setSortNumber(sortNumber: number) {
    if (sortNumber === undefined) { return }
    const correcture = (this.sortNumber < sortNumber) ? 1 : -1
    await fetch(`${this.apiPrefix}Page/${this.id}/setSortNumber/${(sortNumber * 10) + correcture}`)
  }

  public async remove() {
    await fetch(`${this.apiPrefix}Page/${this.id}/remove`)
  }

  public async newSortNumber(sortNumber: number) {
    if (sortNumber === undefined) { return }
    this.sortNumber = sortNumber
    // TODO - talk to API
  }

  public async addPageElement(elementTypeId: number, position: number) {
    await fetch(`${this.apiPrefix}Page/${this.id}/addPageElement/elementType/${elementTypeId}/sortNumber/${position * 10 - 1}`)
  }

  public getAllPageElements(): PageElementDTO[] {
    return this.pageElements
  }

  public async addPageProp(pageProp: PagePropDTO) {
    const e = pageProp.get()
    await fetch(`${this.apiPrefix}Page/${this.id}/addProp/${e.key1}/${e.key2}/${parseStringToServer(e.value1)}/${parseStringToServer(e.value2)}`)
  }

  public async addProp(d: IProp) {
    const prop = new PagePropDTO({
      id: -1,
      key1: d.key1,
      key2: d.key2,
      value1: d.value1,
      value2: d.value2
    })
    this.pageProps = this.pageProps.filter((pP) => !(pP.key1 === d.key1 && pP.key2 === d.key2))
    this.pageProps.push(prop)
    await this.addPageProp(prop)
  }

  public async removeProp(key1: string, key2: string) {
    this.pageProps = this.pageProps.filter((pP) => !(pP.key1 === key1 && pP.key2 === key2))
    await fetch(`${this.apiPrefix}Page/${this.id}/removeProp/${key1}/${key2}`)
  }

  public getProp(key1: string, key2: string) {
    return this.pageProps.find((pP) => pP.key1 === key1 && pP.key2 === key2)
  }

  public getPropValue1(key1: string, key2: string, standard?: string): string {
    return (this.getProp(key1, key2) || {value1: standard || ''}).value1
  }

  public getCssClass(): string {
    return this.pageProps.filter((pP) => pP.key1 === 'colorClass').map((pP) => `set-${pP.key2}-${pP.value1}`).join(' ') || ''
  }

  public async setPicture(id: number) {
    const sR = await fetch(`${this.apiPrefix}Page/${this.id}/setPicture/${id}`)
    const picture = await sR.json()
    this.picture = new FileDTO(picture)
  }

  public async removePicture() {
    await fetch(`${this.apiPrefix}Page/${this.id}/removePicture`)
    this.picture = undefined
  }

  public getCssBGPicture(): string | false {
    if (!this.picture) { return false }
    return this.picture.getCSSBackground()
  }

  public getPictureId() {
    if (this.picture) {
      return this.picture.id
    }
    return -1
  }

  public getPicture() {
    if (this.picture) {
      return this.picture
    }
    return false
  }

}

export interface IPage {
  id: number
  sortNumber: number
  pageElements: IPageElement[]
  pageProps?: IProp[]
  picture: IFile | {id: number}
}
