import ElementPropDTO, {IElementProp} from '../../../DTO/ElementPropDTO'
import PageElementDTO from '../../../DTO/PageElementDTO'
import config from '../../../config.json'

export type Side = '' | 'left' | 'right'

export default class CardDTO {
  public id: string
  public target: Side
  public title: string
  public picture: string
  public markup: string
  public alwaysShowTitle: boolean
  public spawn: boolean
  public userSide: Side = ''
  private pageElement: PageElementDTO
  public yPos: number = 0
  public timesRight: number = 0
  public timesWrong: number = 0

  constructor(d: ICard) {
    const props = d.pageElement.elementProps
    this.pageElement = d.pageElement
    this.id = d.id
    this.spawn = (!!d.target)
    this.target = d.target || this.setFromProps(props, 'target') as Side
    this.title = this.setFromProps(props, 'title')
    this.picture = this.setFromProps(props, 'picture')
    this.markup = this.setFromProps(props, 'markup')
    this.alwaysShowTitle = this.setFromProps(props, 'alwaysShowTitle') === 'true'
  }

  public switchSide() {
    if (this.target === 'left') {
      this.target = 'right'
    } else {
      this.target = 'left'
    }
  }

  public setFromProps(props: ElementPropDTO[], key: string): string {
    const prop = props.find((p) => p.key1 === key && p.key2 === this.id)
    if (prop) {
      return prop.value1 || ''
    }
    return ''
  }

  public async save(o: {
    title?: string,
    picture?: string
    alwaysShowTitle?: boolean
  }) {
    this.title = o.title || this.title
    this.picture = o.picture || this.picture
    this.alwaysShowTitle = (o.alwaysShowTitle !== undefined) ? o.alwaysShowTitle : this.alwaysShowTitle
    await this.pageElement.setPropVal('card', this.id, '-', '-')
    await this.pageElement.setPropVal('target', this.id, this.target || '', '-')
    await this.pageElement.setPropVal('title', this.id, this.title, '-')
    await this.pageElement.setPropVal('picture', this.id, this.picture, '-')
    await this.pageElement.setPropVal('markup', this.id, this.markup, '-')
    await this.pageElement.setPropVal('alwaysShowTitle', this.id, (this.alwaysShowTitle === true) ? 'true' : 'false', '-')
  }

  public trash() {
    console.log('trash card!')
    this.pageElement.removeElementProp('card', this.id)
    this.pageElement.removeElementProp('target', this.id)
    this.pageElement.removeElementProp('title', this.id)
    this.pageElement.removeElementProp('picture', this.id)
    this.pageElement.removeElementProp('markup', this.id)
  }

  public userSetSide(side: Side) {
    this.userSide = side
    if (this.isPlacingGood()) {
      this.timesRight += 1
    } else {
      this.timesWrong += 1
    }
  }

  public isPlacingGood() {
    return this.target === this.userSide
  }

  public getSideClass() {
    if (!this.userSide) {
      return 'position-none'
    }
    return 'position-' + this.userSide
  }

  public reset() {
    this.yPos = 0
    this.userSide = ''
  }

  public getAnalysisResult(): ICardAnalysis {
    return {
      id: this.id,
      title: this.title,
      timesRight: this.timesRight,
      timesWrong: this.timesWrong,
      userSide: this.userSide,
      target: this.target
    }
  }

  public getBgPicture(): string {
    if (this.picture && this.picture !== '-') {
      return `url(${config.mediaDir}${this.picture})`
    }
    return `none`
  }
}

export interface ICard {
  id: string,
  pageElement: PageElementDTO,
  target?: Side
}

export interface ICardAnalysis {
  id: string,
  title: string,
  timesRight: number,
  timesWrong: number,
  userSide: Side,
  target: Side
}

