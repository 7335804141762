import React from 'react'
// import {Maximize, Maximize2} from 'react-feather'
import './FullscreenButton.scss'
import { Expand, Shrink } from '../Icon/Icon'
import Tooltip from '../Tooltip/Tooltip'
import mainservice from '../../services/MainService'

type Props = {

}
type State = {
  fullscreenEnabled: boolean
}

export default class FullscreenButton extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      fullscreenEnabled: false
    }
  }

  testIfFullScreenAvailable(): boolean {
    let elem = document.documentElement
    return !(!elem.requestFullscreen)
  }

  enterFullScreen() {
    console.log('enter full screen')
    const elem = document.documentElement
    this.setState({fullscreenEnabled: true})
    elem.requestFullscreen();


    /* else if (elem['webkitRequestFullscreen']) {
      elem['webkitRequestFullscreen']();
    } else if (elem['msRequestFullscreen']) {
      elem['msRequestFullscreen']();
    }
    */
  }

  exitFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.setState({fullscreenEnabled: false})

    /*
     else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
    */
  }

  componentWillUnmount() {
    this.exitFullScreen()
  }

  toggleFullScreen() {
    if (!document.fullscreenElement) {
      this.enterFullScreen()
    } else {
      this.exitFullScreen()
    }
  }

  render() {
    if (mainservice.isAdmin() > 0) {
      return <></>
    }
    return <>
      <div
        className="fullScreenButton"
        onClick={() => {
          this.toggleFullScreen()
        }}
      >
      { this.testIfFullScreenAvailable() &&
        <>

          <div
            className="theFullScreenButton set-backgroundColor-special2"
            title="Klick für Vollbild Modus"
          >
            {
              !this.state.fullscreenEnabled &&
              <Expand />
            }
            {
              this.state.fullscreenEnabled &&
              <Shrink />
            }
            <Tooltip content='Klick für Vollbild Modus' />
          </div>

          <div className="theFullScreenTeaser">
            <div className="content w3-cursive">
              Hier klicken um im Vollbildmodus zu lesen!!<br /> &darr;
            </div>
          </div>
        </>
        }
      </div>
    </>
  }
}
