import { HelpCircle } from 'react-feather'
import './Help.scss'
import mainservice from '../../services/MainService'
export function Help(props: {
  children: React.ReactElement | (React.ReactElement | null | false)[],
}) {
  return <div
    className='TTHelp'
    onClick={() => {
      mainservice.broadcast('openmodal', {content: props.children})
    }}
  >
    <HelpCircle />
  </div>
}
