import { parseStringFromServer } from "../../services/TextConverter";
import { ObjectKind } from "../models/objectKinds";
import { GenericDTO, IGeneric, GenericEditField } from "./GenericDTO";
import { MaterialEditFields } from "../editFields/MaterialEditFields";

export class MaterialItemDTO extends GenericDTO {
  count: number = -1
  credentials: string[] = []
  appointments: string[] = []
  type: string = ''
  constructor(o: IMaterialItem) {
    super(o)
    this.objectKind = ObjectKind.materialItem
    if (o.info?.credentials) {
      this.credentials = o.info.credentials.split(';').filter(c => c)
      this.appointments = o.info.appointments.map(a => parseStringFromServer(a))
      this.type = o.info.type
    }
  }

  public getEditFields(): GenericEditField[] {
    return MaterialEditFields.map(mef => {
      return {...mef, value: this.getPropV1(mef.key1, mef.key2)}
    })
  }

  public setCountBasedOnReusableCount(times: number) {
    const reuse = parseInt(this.getPropV1('data', 'reusableTimes') || '-1', 10)
    if (reuse === -1) {
      this.count = 1
      return
    }
    this.count = Math.ceil(times / reuse)
  }
}

export interface IMaterialItem extends IGeneric {
  info?: {
    credentials: string,
    appointments: string[],
    type: string,
  }
}
