import React, { useState } from 'react'
import './Library.scss'
import BookDTO, {GetBooks} from '../../DTO/BookDTO'
// import {IRight} from '../../DTO/RightDTO'
// import {Getter, Setter} from '../../services/ComService'
import layoutConfig from '../../static/styles/layout-config.json'
import Spinner from '../Spinner/Spinner'
import mainservice from '../../services/MainService'
// import LoginService from '../../services/LoginService'
import { cart } from '../../DTO/ShopDTO'
import { Remove } from '../Icon/Icon'
import { ShoppingCart, Book as BookIcon } from 'react-feather'

export enum LibraryMode {
  default,
  user,
  shop
}

export enum OpenBookMode {
  standard,
  begin,
  lastPosition,
  furthestPosition
}

type Props = {
  mode?: LibraryMode
}

type State = {
  books: BookDTO[],
  openBookId: number,
  loading: boolean
}
// Zeigt Hamburgermenü und integriert Login und Logout Feld
// Zeigt Avatar - online-status
export default class Library extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      books: [],
      openBookId: -1,
      loading: true
    }
  }

  componentDidMount() {
    this.getData()
    // Broadcast receiver - if we login - fetch data
    mainservice.registerToBroadcast(
      'library',
      (key: string, value: any) => {
        switch(key) {
          case 'loginsuccess':
          case 'logoutsuccess':
            this.getData()
            break
          case 'nav':
            switch(value.view) {
              case 'library':
              case 'shop':
                this.getData()
                break
            }
            break
        }
      },
      this
    )
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('library')
  }

  async getData() {
    let books = await GetBooks()

    switch(this.props.mode) {
      case LibraryMode.shop:
        books = books.filter(b => b.teaser)
        break
      case LibraryMode.user:
        books = books.filter(b => !b.teaser)
        break
    }
    this.setState({books: books, loading: false})
  }

  openBook(book: BookDTO, mode: OpenBookMode) {
    const id = book.id
    mainservice.broadcast('libraryOpensBook', {bookId: id})
    this.setState({
      openBookId: id
    })
    setTimeout(() => {
      if (book.teaser) { return }
      if ((mode === OpenBookMode.standard || mode === OpenBookMode.lastPosition) && book.lastPosition.doublePageId) {
        mainservice.navTo([
          ['view', 'book'],
          ['book', book.id],
          ['doublePageId', book.lastPosition.doublePageId],
          ['percentage', book.lastPosition.percentage]
        ])
      } else if (mode === OpenBookMode.furthestPosition && book.furthestPosition.doublePageId) {
        mainservice.navTo([
          ['view', 'book'],
          ['book', book.id],
          ['doublePageId', book.furthestPosition.doublePageId],
          ['percentage', book.furthestPosition.percentage]
        ])
      } else {
        mainservice.navTo([
          ['view', 'book'],
          ['book', book.id],
          ['page', 1]
        ])
      }
    }, 900)
    /*

    */
  }

  render() {
    return <div
      className={`Library w3-row ${layoutConfig.tileParentClass} ${(this.state.openBookId > -1) ? 'openBook' : ''}`}
    >
      {!this.state.loading && this.state.books && this.state.books.length === 0 && <div className='noData'>
      <p>Keine Inhalte vorhanden</p>
      </div>}
      {this.state.loading && <Spinner />}
      {
        this.state.books.map((book: BookDTO, index) =>
          <Book
            key={`${book.id}-${index}`}
            book={book}
            index={index}
            active={this.state.openBookId === book.id}
            openBook={(book: BookDTO, mode: OpenBookMode) => {this.openBook(book, mode)}}
          />
        )
      }
      {
        this.props.mode === LibraryMode.shop &&
        <div className='w3-container w3-auto w3-margin-top'>
          <a
            onClick={() => {
              mainservice.navTo([['view', 'userCarts']])
            }}
          >Verlauf</a>
        </div>
      }
    </div>
  }
}

export function Book(props: {
    book: BookDTO,
    index: number,
    active: boolean,
    className?: string,
    openBook: (book: BookDTO, mode: OpenBookMode) => void,
    putIntoCartCb?: () => void,
  }) {
  enum Status {
    standard,
    open,
    startingPoints,
    startingPointsOpen
  }
  const [status, setStatus] = useState(Status.standard)
  const [isInCart, setIsInCart] = useState(cart.isBookInCart(props.book.id))

  let statusClass = ''
  if (status != Status.standard) {
    statusClass += ' flip'
  }
  if (status === Status.open || status === Status.startingPointsOpen) {
    statusClass += ' zoomIn'
  }
  if (status === Status.startingPoints || status === Status.startingPointsOpen) {
    statusClass += ' showStartingPoints'
  }
  if (status === Status.open) {
    statusClass += ' showOpen'
  }
  const book = props.book
  const bookInCart = mainservice.loginService.user.bookInCart(book.id)
  if (book.teaser && bookInCart) {
    // statusClass += ' w3-opacity-max'
  }
  // const [showBack, setShowBack] = useState(false)
  const index = props.index
  let className = (index === 0) ? layoutConfig.tileClassFirst : layoutConfig.tileClass
  if (props.className) {
    className = props.className
  }
  className += statusClass
  return <div
    key={`${book.id}-${index}`}
    className={className}
    title={bookInCart ? 'Bestellprozess bereits im Gange' : ''}
  >
    <div
      className={`book-box ${(props.active) ? 'open' : ''} w3-text-${book.getPropVal1('layout', 'coverFontColor', 'standard')}`}
      onMouseEnter={() => {
        // console.log('book.lastPosition', book.lastPosition)
      }}
    >
      <div
        className={
          `book`
        }
        onClick={() => {
          if (book.teaser) {
            // alert('Sie haben (noch) keinen Zugang zu diesem Buch. Kontaktieren Sie uns!')
            return
          }
          setStatus(Status.open)
          props.openBook(book, OpenBookMode.standard)
        }}
      >
        <div className='bookFront'>
          <div className='cardTypeString'>workbook</div>
          <div
            className='bookPicture'
            style={{backgroundImage: book.getCssBGPicture() || 'url()'}}
          ></div>
          {
            book.lastPosition.percentage > 0 && index === 0 &&
              <div className='lastSeenLabel'>Zuletzt angesehen:</div>
          }
          <div className={`bookTextContent`}>
            <div
              className='title'
              dangerouslySetInnerHTML={{__html: book.hyphenName || book.name}}
            ></div>
          </div>
          {
            book.lastPosition.percentage > 0 &&
            <div
              className='lastPosition'
              style={{'cursor': 'help'}}
              onClick={(e) => {
                e.stopPropagation()
                setStatus(Status.startingPoints)
              }}
            >
              <div className='lastPositionLabel'>
                {Math.round(book.lastPosition.percentage)} %
              </div>
            </div>
          }
          {
            book.teaser &&
            <div className='teaserBook w3-tag w3-blue'>
              Auch erhältlich!!!
            </div>
          }
          {
            book.teaser &&
            !cart.disabled &&
            <div
              className={`addToCart pointer ${isInCart || bookInCart ? 'bookInCart' : ''}`}
              onClick={async () => {
                // await book.addToCart()
                if (bookInCart) {
                  return window.confirm('Bestellprozess ist im Gange.')
                }
                await cart.addBooks([book.id])
                mainservice.broadcast('bookAddedToCart', book.id)
                setIsInCart(true)
                if (props.putIntoCartCb) {
                  props.putIntoCartCb()
                }
              }}
            >
              <ShoppingCart
                className={`cartIcon`}
              />
              <BookIcon className='bookIcon' />
              <span className='price'>
              {book.getCurrentPriceRender()}
              </span>
            </div>
          }
        </div>

        <div className='bookStartingPoints'
          onClick={(e) => {
            e.stopPropagation()
          }}
          >
          <button
          onClick={(e) => {
            e.stopPropagation()
            setStatus(Status.startingPointsOpen)
            props.openBook(book, OpenBookMode.begin)
          }}
          >Beginn</button>
          <button
          onClick={(e) => {
            e.stopPropagation()
            setStatus(Status.startingPointsOpen)
            props.openBook(book, OpenBookMode.standard)
          }}
          >Letzte Position</button>
          <button
          onClick={(e) => {
            e.stopPropagation()
            setStatus(Status.startingPointsOpen)
            props.openBook(book, OpenBookMode.furthestPosition)
          }}
          >Weiteste Position</button>
          <div
          className='closeButton'
          onClick={(e) => {
            e.stopPropagation()
            setStatus(Status.standard)
          }}
          ><Remove /></div>
        </div>
        <div
        className='bookFirstPageOpen'
        dangerouslySetInnerHTML={{__html: book.hyphenName || book.name}}
        ></div>
      </div>

    </div>
  </div>
}
