import React, { ReactElement } from 'react'
import mainservice from '../../services/MainService'
import PageElementDTO from '../../DTO/PageElementDTO'
import ElementPropDTO from '../../DTO/ElementPropDTO'
import {Layer} from '../../DTO/BookDTO'
import Evaluation1 from './Evaluation1/Evaluation1'
import Text from './Text/Text'
import TextArea from './TextArea/TextArea'
import IconMagnet from './IconMagnet/IconMagnet'
import MultipleChoice from './MultipleChoice/MultipleChoice'
import UserInputScale1 from './UserInputScale1/UserInputScale1'
import RadarChartA from './RadarChartA/RadarChartA'
import CardSortGameA from './CardSortGameA/CardSortGameA'
import CallToActionButton from './CallToActionButton/CallToActionButton'
import CollectibleItemB from '../Collectible/CollectibleItemB'
import Embed from './Embed/Embed'
import { Trash } from 'react-feather'
import BookAdminPropSwitcher from '../Book/BookAdminPropSwitcher'
import './PageElement.scss'
// import WidgetUserRating from '../widget/WidgetUserRating'
// import WidgetUser from '../widget/WidgetUser'
import WidgetElement from './Widget/WidgetElement'
import BookLink from './BookLink/BookLink'
import Tabs from './Tabs/Tabs'
import Cols from './Cols/Cols'


type Props = {
  selectFkt: any,
  pageElement: PageElementDTO,
  parent: any,
  hasFocus?: number,
  index?: number,
  layoutClasses: string,
  trailingElement?: ReactElement,
}

type State = {
  loading: boolean,
  pageElement: PageElementDTO,
  isAdmin: number,
  adminEdit: boolean,
  editValue1Status: string,
  editValue2Status: string,
  sortNumber: number,
  propKey: string,
  emptyProp: ElementPropDTO,
  focus: number,
  manualClass: string,
}

class PageElement extends React.Component<Props, State> {
  selectFkt: any
  state: State
  adminToolIconSize: string = '16px'
  constructor(props: Props) {
    super(props)
    this.selectFkt = props.selectFkt

    this.state = {
      loading: true,
      isAdmin: mainservice.isAdmin(),
      pageElement: props.pageElement,
      adminEdit: false,
      editValue1Status: props.pageElement.value1,
      editValue2Status: props.pageElement.value1,
      sortNumber: props.pageElement.sortNumber,
      propKey: '',
      emptyProp: new ElementPropDTO({id: -1, key1: '', key2: '', value1: '', value2: ''}),
      focus: props.hasFocus || 0,
      manualClass: '',
    }
  }
  componentDidMount() {
  }

  async getContent() {
    // TODO - please use nicer solution:
    // window.location.reload()
    // mainservice.reload([])
    await this.state.pageElement.loadFromServer()
    this.forceUpdate()
  }

  trash() {
    if (!window.confirm('Element wirklich löschen?')) { return }
    this.state.pageElement.remove().then(() => this.props.parent.getContent())
  }

  rerenderParent() {
    // mainservice.currentDoublePageRef.forceUpdate()
    mainservice.broadcast('rerenderDoublePage-' + mainservice.currentDoublePageId, {})
  }

  renderToolBar() {
    return <div className="adminToolBar editMode">
      <span className="w3-tag">Element:</span>
      <input className='narrowNumberField' value={this.state.sortNumber} onChange={(event) => this.setState({sortNumber: parseInt(event.target.value, 10) || 0})}/>
      <button onClick={() => this.state.pageElement.setSortNumber(this.state.sortNumber).then(() => {
        this.props.parent.getContent(true)
      })}>ok</button>
      <button onClick={() => this.trash()}><Trash /></button>
      <span className='w3-margin-left w3-margin-right'>{this.state.pageElement.elementType.name}</span>

      {
        this.state.pageElement.elementType.name === 'text' &&
        <span>
          <span>Kapitel: </span>
          <select
            value={this.state.pageElement.getPropVal1('section', 'type') || 'standard'}
            onChange={
              (event) => {
                this.state.pageElement.setPropVal('section', 'type', event.target.value).then(() => {
                  this.forceUpdate()
                })
              }
            }
          >
            {
              [
                {key: 'standard', name: 'nein'},
                {key: 'section', name: 'Kapitel'},
                {key: 'subsection', name: 'Unterkapitel'}
              ].map((o) => <option
                  key={o.key}
                  value={o.key}
                >
                  {o.name}
                </option>
              )
            }
          </select>
        </span>
      }
      {
        this.state.pageElement.elementType.name === 'text' &&
        <>
          <BookAdminPropSwitcher
            key={`layoutManualColumns`}
            parent={this.state.pageElement}
            name='Spalten'
            key1='layout'
            key2='manualColumns'
            value1Arr={[
              ['single', '1/1'],
              ['half', '1/2'],
              ['third', '1/3'],
              ['twothird', '2/3']
            ]}
            value1Current={this.state.pageElement.getPropVal1('layout', 'manualColumns', 'single')}
            cb={() => {
              this.rerenderParent()
            }}  
          />
          <BookAdminPropSwitcher
            key={`layoutKind`}
            parent={this.state.pageElement}
            name='Art'
            key1='layout'
            key2='kind'
            value1Arr={[
              ['standard', 'standard'],
              ['pageBox', 'SeitenBox'],
              ['quoteBox', 'ZitatBox'],
              ['hugeText', 'Groß-Text']
            ]}
            value1Current={this.state.pageElement.getPropVal1('layout', 'kind', 'standard')}
            cb={() => {
              this.rerenderParent()
            }}
          />
          <BookAdminPropSwitcher
            key={`absolutePositionSwitcher`}
            parent={this.state.pageElement}
            name='Absolute Position'
            key1='layout'
            key2='absPosition'
            value1Arr={[
              ['standard', 'standard'],
              ['cm', 'Mitte'],
              ['ct', 'Mitte Oben'],
              ['cb', 'Mitte Unten'],
              ['lt', 'Links Oben'],
              ['lm', 'Links Mitte'],
              ['lb', 'Links Unten'],
              ['rt', 'Rechts Oben'],
              ['rm', 'Rechts Mitte'],
              ['rb', 'Rechts Unten']
            ]}
            value1Current={this.state.pageElement.getPropVal1('layout', 'absPosition', 'standard')}
            cb={() => {
              this.rerenderParent()
            }}
          />
          <BookAdminPropSwitcher
            key={`colorSwitcher`}
            parent={this.state.pageElement}
            name='Farbe'
            key1='layout'
            key2='color'
            value1Arr={[
              ['standard', 'standard'],
              ['black', 'schwarz'],
              ['white', 'Weiß'],
              ['mint', 'Mint'],
              ['purple', 'Violett'],
              ['darkGreen', 'Dunkel-Grün'],
              ['lightGreen', 'Hell-Grün']
            ]}
            value1Current={this.state.pageElement.getPropVal1('layout', 'color', 'standard')}
            cb={() => {
              this.rerenderParent()
            }}
          />
        </>
      }
    </div>
  }

  public gainEditFocus() {
    this.setState({
      isAdmin: 3
    })
  }

  public gainFocus() {
    this.setState({
      focus: 1
    })
  }
  public stopFocus() {
    this.setState({
      focus: 0
    })
  }

  render() {
    if (!this.isAdmin() &&
      this.state.pageElement.value1 === '' &&
      this.state.pageElement.elementType.name === 'text'
    ) {
      return null
    }
    return <div
    className={`pageElementHolder pageElement-index-${this.props.index} ${this.props.layoutClasses} ${this.state.manualClass}`}
      >
      <div
        id={`pageElement-${this.state.pageElement.id}`}
        className={`pageElement w3-container focus-${this.state.focus}`}
        onClick={() => mainservice.thingHasBeenClicked(Layer.element, this)}
      >
        {
          this.isAdmin() && this.state.focus === 0 &&
          <div className="adminCover"></div>
        }
        {this.isAdmin() && this.renderToolBar()}
        {this.renderElement()}
      </div>
      {this.props.trailingElement}
    </div>
  }

  setClass(newClass: string) {
    this.setState({manualClass: newClass})
  }

  isAdmin(): boolean {
    return this.state.isAdmin > 0 && mainservice.currentDoublePage.anchorId === -1
  }

  renderElement() {
    switch(this.state.pageElement.elementType.name) {
      case 'multiplechoice':
        return <MultipleChoice pageElement={this.state.pageElement} />
      case 'text':
        return <Text
          index={this.props.index}
          pageElement={this.state.pageElement}
          parent={this}
          />
      case 'textarea':
        return <TextArea

          pageElement={this.state.pageElement}
          parent={this}
          />
      case 'embed':
        return <Embed

          pageElement={this.state.pageElement}
          parent={this}
          />
      case 'Tabs':
        return <Tabs
          pageElement={this.state.pageElement}
          parent={this}
          setClass={(c: string) => {
            this.setClass(c)
          }}
          />
      case 'Cols':
        return <Cols
          pageElement={this.state.pageElement}
          parent={this}
          setClass={(c: string) => {
            this.setClass(c)
          }}
          />
      case 'iconmagnet':
        return <IconMagnet pageElement={this.state.pageElement} />
      case 'evaluation1':
        return <Evaluation1 pageElement={this.state.pageElement} />
      case 'userInputScale':
        return <UserInputScale1 pageElement={this.state.pageElement} />
      case 'RadarChartA':
        return <RadarChartA pageElement={this.state.pageElement} />
      case 'CardSortGameA':
        return <CardSortGameA pageElement={this.state.pageElement} />
      case 'Collectible':
        return <CollectibleItemB pageElement={this.state.pageElement} />
      case 'CallToActionButton':
        return <CallToActionButton pageElement={this.state.pageElement} />
      case 'showSessionId':
        return <div className="center">
            <div className="sessionId">
              {mainservice.nav.getVal('session')}
            </div>
          </div>
      case 'Widget':
        return <WidgetElement pageElement={this.state.pageElement} />
      case 'BookLink':
        return <BookLink pageElement={this.state.pageElement} parent={this} />
    }
  }
}

export default PageElement
