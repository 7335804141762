import { useEffect, useState } from "react";
import InputB from "../../../components/InputB/InputB";
import ItemLocationDTO from "../../DTO/ItemLocationDTO";
import { Plus } from "react-feather";
import ItemsDTO from "../../DTO/ItemsDTO";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import Location from "./Location";
import Menu, { MItem } from "../Menu";
import PlaningToolRoot from "../PlaningToolRoot";

export default function LocationList(props: {
  selected?: number,
  onSelect?: (id: number, location: ItemLocationDTO) => void,
  className?: string,
  standalone?: boolean,
  headline?: string,
}) {
  const [Needle, setNeedle] = useState('')
  const [status, setStatus] = useState(Status.loading)
  const [Items] = useState(new ItemsDTO({
    getUrl: 'spt/location/list',
    trashUrl: 'spt/location/delete'
  }))
  const className = `STLocationList flex flex-column ${props.className || ''}`
  useEffect(() => {
    Items.getFromServer().then(() => {
      setStatus(Status.standard)
    })
  }, [])
  return <PlaningToolRoot standalone={props.standalone} menuItem={MItem.locations} mainContentClass={className}>
    {
      (props.standalone || props.headline) ?
      <div className='contentHeader'>
        {
          props.headline ? <h2>{props.headline}</h2> : <h1>Veranstaltungsorte</h1>
        }
        <InputB
          className='w3-border'
          returnVal={async (val) => {
            setStatus(Status.loading)
            await Items.setNeedle(val)
            setNeedle(val)
            setStatus(Status.standard)
          }}
          placeholder='Tippen um zu suchen oder neu anzulegen ...'
        />
        {
          Needle !== '' &&
          <button
            className='positiveButton'
            onClick={async () => {
              const item = new ItemLocationDTO({})
              await item.setName(Needle)
              if (props.onSelect) {
                props.onSelect(item.id, item)
              } else {
                setStatus(Status.loading)
                await Items.getFromServer()
                setStatus(Status.standard)
              }
            }}
          ><Plus /></button>
        }
      </div> : null
    }
    <div
      className={`LocationList ${props.standalone ? 'scrollContent' : ''}`}
    >
      {
        status === Status.loading &&
        <Spinner />
      }
      {
        Items.getItems().map((i, index) => {
          return <Location
              key={`LocationListItem-${i.id}`}
              className={`${props.selected === i.id ? 'mark' : ''}`}
              item={i}
              onClick={props.onSelect}
              onTrash={props.standalone ? async () => {
                if (!window.confirm(`${i.name} wirklich löschen?`)) { return }
                setStatus(Status.loading)
                await Items.trashItem(i.id)
                setStatus(Status.standard)
              } : undefined}
            />
        })
      }
    </div>
  </PlaningToolRoot>
}
