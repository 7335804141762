import React from 'react'
import {Getter} from '../../services/ComService'
import BookDTO, {IBook} from '../../DTO/BookDTO'
import RightDTO, {IRight} from '../../DTO/RightDTO'
// import Spinner from '../Spinner/Spinner'
import InputA from '../InputA/InputA'
import { DragAndDropTypes } from '../../Models/dnd/dragAndDropTypes'
import { useDrag } from 'react-dnd'

import './BookSelector.scss'

enum TabType {
  secret,
  book,
  page
}

type Props = {
  bookBlackList?: number[]
}

type State = {
  books: BookDTO[],
  selectedBookId: number,
  filterString: string,
  tabType: TabType
}

function Book(props: {b: BookDTO, i: number}) {
  const b = props.b
  const i = props.i
  const [{isDragging}, drag] = useDrag(() => ({
    type: DragAndDropTypes.Book,
    item: {bookId: b.id},
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [42])

  return <div
    key={`${i}-${b.id}`}
    ref={drag}
    className={`bookThumb ${(isDragging) ? 'dragging' : ''}`}
    onClick={() => {
      window.open(`/view=book/book=${b.id}/`, '_blank')
    }}
    dangerouslySetInnerHTML={{__html: b.name}}
  ></div>
}

class BookSelector extends React.Component<Props, State> {

  books: BookDTO[] = []
  constructor(props: Props) {
    super(props)
    this.state = {
      books: [],
      selectedBookId: -1,
      filterString: '',
      tabType: TabType.secret
    }
  }

  getContent() {
    Getter('books').then((r: {books: IBook[], rights: IRight[]}) => {
      this.books = r.books.map((b) => new BookDTO(b))
      this.setState({
        books: this.books
      })
    })
  }

  componentDidMount() {
    this.getContent()
  }

  renderBookList() {

  }


  render() {
    const bookList = this.state.books.filter((b: BookDTO) => {
      // negate if Collection
      if (
        this.state.tabType === TabType.secret &&
        (b.right.value !== 2227 || b.doublePageCount === 1)
      ) {
        return false
      }
      // negate if standard collection page
      if (
        this.state.tabType === TabType.page &&
        (b.right.value !== 2227 || b.doublePageCount !== 1)
      ) {
        return false
      }
      // negate if book
      if (
        this.state.tabType === TabType.book &&
        b.right.value === 2227
      ) {
        return false
      }
      if (this.props.bookBlackList?.some((bbli) => bbli === b.id)) {
        return false
      }
      const needles = this.state.filterString.split(' ')
      return needles.some((n) => b.name.search(n) > -1)
    })
    const activeClass = 'w3-blue'
    const filterTabs = [
      {
        name: 'Kollektionen',
        tabType: TabType.secret,
      },
      {
        name: 'Standardseiten',
        tabType: TabType.page,
      },
      {
        name: 'Bücher',
        tabType: TabType.book,
      }
    ]
    console.log('this.state.books', this.state.books)
    return <div className='bookSelector'>
      <div className={`w3-bar w3-black`}>
        {
          filterTabs.map((f, i) => <button
            key={i}
            className={`w3-bar-item ${f.tabType === this.state.tabType ? activeClass : ''}`}
            onClick={() => {
              this.setState({tabType: f.tabType})
            }}
          >
            {f.name}
          </button>)
        }
        <InputA
        className={`w3-bar-item`}
        value={this.state.filterString}
        returnVal={(rv) => {
          this.setState({filterString: rv})
        }}
        />
      </div>
      {
        bookList.map((b, i) => <Book key={`${b.id}-${i}`} b={b} i={i} />)
      }
    </div>
  }
}

export default BookSelector
