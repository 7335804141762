import { useEffect, useRef, useState } from "react";
import { DisplayMode, Status } from "./day/dayView";
import { DropZone } from "../elements/DropZone";
import { ObjectKind } from "../models/objectKinds";
import { MaterialItemDTO } from "../DTO/MaterialItemDTO";
import MaterialView from "./materialView";
import { GenericMaterialItemDTO } from "../DTO/GenericMaterialItemDTO";
import { ArrowRight, CheckSquare, Eye, File as FileIcon, Printer, Square } from "react-feather";
import { PublishKind } from "../models/publishKinds";
import Spinner from "../../components/Spinner/Spinner";
import { GenericDTO } from "../DTO/GenericDTO";
import InputB from "../../components/InputB/InputB";
import { Setter } from "../../services/ComService";
import { File } from "./FileManager/FileManager";
import './MaterialsView.scss';
import mainservice from "../../services/MainService";
import { RightDrawerContent } from "./RightDrawer";
import { EventDTO } from "../DTO/EventDTO";
import { TextAreaArraySummary } from "./Event/TextAreaArraySummary";
import { CountHolder } from "./CountHolder";
import { MakePrintLink } from "./MakePrintLink";

export default function MaterialsView(props: {
  items: MaterialItemDTO[],
  identKey: string,
  parentPublishKind: PublishKind,
  parentObjectKind: ObjectKind,
  getItems?: () => Promise<MaterialItemDTO[]>,
  getItemsAtInit?: boolean,
  addItem?:(position: number, item: MaterialItemDTO) => void,
  trashItem?: (item: GenericMaterialItemDTO) => void,
  displayKind: Status,
  parent?: GenericDTO,
  checklists?: {key1: string, title: string}[],
  reloadTrigger?: number,
  rerenderRightSide?: () => void,
  editItem?: (item: any, objecKind: ObjectKind) => void,
}) {
  const mounted = useRef(false);
  const loadItemsOnInit = props.getItems && props.getItemsAtInit !== false
  const [status, setStatus] = useState((props.getItems) ? Status.loading : Status.standard)
  const [items, setItems] = useState(loadItemsOnInit ? [] : props.items)
  const [rerender, setRerender] = useState(0)
  const [checklists, setChecklists] = useState(props.checklists)
  const [participantCount, setParticipantCount] = useState(0)
  const [appointment, setAppointment] = useState('')
  const [printCountHolder] = useState(new CountHolder({
    item: props.parent,
    key1: 'materialsCount',
    key2: 'print'
  }))
  const [packCountHolder] = useState(new CountHolder({
    item: props.parent,
    key1: 'materialsCount',
    key2: 'pack'
  }))
  function init() {
    if (status !== Status.loading) {
      setStatus(Status.loading)
    }
    if (loadItemsOnInit && props.getItems) {
      props.getItems().then((items: MaterialItemDTO[]) => {
        if (!mounted.current) { return }
        setItems(items)
        if (props.parent?.id) {
          Setter('spt/event/personcount', {id: props.parent.id}).then(result => {
            setParticipantCount(result.participantCount)
            setStatus(Status.standard)
          })
        } else {
          if (!mounted.current) { return }
          setStatus(Status.standard)
        }
      })
    } else {
      if (props.parent?.id) {
        Setter('spt/event/personcount', {id: props.parent.id}).then(result => {
          setParticipantCount(result.participantCount)
          setStatus(Status.standard)
        })
      } else {
        setStatus(Status.standard)
      }
    }
  }

  useEffect(() => {
    // Component is mounted, set the mounted.current value to true
    mounted.current = true;

    // Cleanup function when the component is unmounted
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    init()
  }, [props.reloadTrigger])
  
  if (status === Status.loading) {
    return <Spinner />
  }
  let appointments: {key2: string, name: string}[] = []
  if (props.parent && props.parent instanceof EventDTO) {
    appointments = [...[{key2: '', name: 'Alle'}], ...(props.parent as EventDTO).getAppointments().map((a, index) => {
      return {
        key2: a.key2,
        name: `Zeitraum ${index + 1}`
      }
    })]
  }

  if (props.displayKind === Status.list) {
    const filtered = items.filter(i => {
      if (!appointment) {
        return true
      }
      if (i.appointments.length === 0 || i.appointments.some(a => a === appointment)) {
        return true
      }
      return false
    })
    const flips = filtered.filter(i => i.getPropV1('data', 'materialKind') === 'digitalFlip')
    const handouts = filtered.filter(i => i.getPropV1('data', 'materialKind') === 'digitalHandout')
    const posters = filtered.filter(i => i.getPropV1('data', 'materialKind') === 'digitalPoster')
    const pins = filtered.filter(i => i.getPropV1('data', 'materialKind') === 'digitalPin')
    const collectibles = filtered.filter(i => i.getPropV1('data', 'materialKind') === 'collectible')
    const other = filtered.filter(i => 
      !flips.some(x => x.id === i.id) &&
      !handouts.some(x => x.id === i.id) &&
      !posters.some(x => x.id === i.id) &&
      !collectibles.some(x => x.id === i.id) &&
      !pins.some(x => x.id === i.id)
    )
    return <>
      {appointments.map((a, index) => {
        return <button
          title={a.key2}
          key={`appointment-tab-${a.key2}-${index}`}
          onClick={() => {
            setAppointment(a.key2)
          }}
          className={`tab-button-small ${a.key2 === appointment ? 'tab-active' : 'tab-not-active'}`}
        >{a.name}</button>
      })}
      { (!filtered || filtered.length === 0) &&
        <div className='empty'>Keine Materialien verfügbar</div>
      }
      <RenderList
        participantCount={participantCount}
        items={flips}
        headline={'Flips'}
        format={'_670x990'}
        checklists={checklists}
        setChecklists={(newChecklists) => setChecklists}
        parent={props.parent}
        printCountHolder={printCountHolder}
        packCountHolder={packCountHolder}
      />
      <RenderList
        participantCount={participantCount}
        items={handouts}
        headline={'Handouts'}
        format={'_210x297'}
        checklists={checklists}
        setChecklists={(newChecklists) => setChecklists}
        parent={props.parent}
        printCountHolder={printCountHolder}
        packCountHolder={packCountHolder}
      />
      <RenderList
        participantCount={participantCount}
        items={posters}
        headline={'Plakate'}
        format={'_594x420'}
        checklists={checklists}
        setChecklists={(newChecklists) => setChecklists}
        parent={props.parent}
        printCountHolder={printCountHolder}
        packCountHolder={packCountHolder}
      />
      <RenderList
        participantCount={participantCount}
        items={pins}
        headline={'Pins'}
        format={'_670x990'}
        checklists={checklists}
        setChecklists={(newChecklists) => setChecklists}
        parent={props.parent}
        printCountHolder={printCountHolder}
        packCountHolder={packCountHolder}
      />
      <RenderList
        participantCount={participantCount}
        items={collectibles}
        headline={'Lernkarten'}
        format={'_500x500'}
        checklists={checklists}
        setChecklists={(newChecklists) => setChecklists}
        parent={props.parent}
        printCountHolder={printCountHolder}
        packCountHolder={packCountHolder}
      />
      <RenderList
        participantCount={participantCount}
        items={other}
        headline={'Sonstiges'}
        format={''}
        checklists={checklists}
        setChecklists={(newChecklists) => setChecklists}
        parent={props.parent}
        printCountHolder={printCountHolder}
        packCountHolder={packCountHolder}
      />
      {
        props.parent &&
        <TextAreaArraySummary
          item={props.parent}
          appointment={appointment}
        />
      }
    </>
  }
  return <div className='MaterialsView'>
    <DropZone
      label={``}
      position={10000}
      addItem={async (position, item) => {
        if (props.addItem) {
          await props.addItem(position, item)
          if (props.getItems) {
            const items: MaterialItemDTO[] = await props.getItems()
            setItems(items)
          } else {
            setRerender(rerender + 1)
          }
        }
      }}
      acceptKind={ObjectKind.materialItem}
      className='ptdropzoneHorizontal ptdropBottomGeneric'
    />
    {
      items.length === 0 &&
      <div>leer</div>
    }
    {
      items.map((item, index) => {
        return <MaterialView
          key={`material-items-view-${item.id}`}
          materialItem={item}
          parentObjectKind={props.parentObjectKind}
          parentPublishKind={props.parentPublishKind}
          displayMode={DisplayMode.leftSide}
          position={index}
          trash={async () => {
            console.log('trash material - materials view')
            if (props.trashItem) {
              await props.trashItem(item as GenericMaterialItemDTO)
              if (props.getItems) {
                props.getItems().then((items: MaterialItemDTO[]) => {
                  setItems(items)
                  setStatus(Status.standard)
                })
              }
            }
          }}
          addItem={async (position, item) => {
            if (props.addItem) {
              await props.addItem(position, item)
            }
          }}
          fullScreenEdit={() => {
            if (props.editItem) {
              props.editItem(item, item.objectKind);
            }
          }}
          rerenderRightSide={props.rerenderRightSide}
        />
      })
    }
  </div>
}

function RenderList(props: {
  items: MaterialItemDTO[],
  headline: string,
  format: string,
  participantCount: number,
  printCountHolder: CountHolder,
  packCountHolder: CountHolder,
  setChecklists: (newCheckList: {key1: string, title: string}[]) => void,
  parent?: GenericDTO,
  checklists?: {key1: string, title: string}[],
}) {
  const [rerender, setRerender] = useState(0)
  // const [countHolder] = useState(new Map())
  const printCountHolder = props.printCountHolder
  const packCountHolder = props.packCountHolder
  const standardCountHolder = new Map()
  const list = props.items.map((item, index) => {
    const hints = item.getPropV1('data', 'hints')
    const transportWarning = item.getPropV1('data', 'transportHints')
    const productionHints = item.getPropV1('data', 'productionHints')
    // props.participantCount
    const userFactor = parseInt(item.getPropV1('data', 'copiesPerPerson') || '0', 10)
    const itemOccurenceFactor = 1 // item.count // This was original supposed to be the number of occurances. Turns out we want "1"
    const count = userFactor > 0 ? (userFactor * (props.participantCount || 0) + 2) * itemOccurenceFactor : item.count
    console.log(`userFactor = ${userFactor}, participantCount = ${props.participantCount}`)
    standardCountHolder.set(item.id, count)
    const currentFile = item.getCurrentFile()
    // const className='PTListItem flex flex-wrap justify-content-space-between'
    const className='PTListItem materialsView'
    return <tr
        key={`materialsList-${index}-${item.id}`}
        className={className}
      >
        { count !== -1 &&
          <>
            <td
              className='w3-padding mv-print-count'
              title='berechnete Anzahl'
            >{count}</td>
            <td
              className='w3-padding mv-print-count'
              title='Druck-Anzahl'
            >
              <InputB
                value={printCountHolder.has(item.id) ? printCountHolder.get(item.id) : count }
                returnVal={(value) => {
                  if (value === '') {
                    printCountHolder.delete(item.id)
                    setRerender(rerender + 1)
                  } else {
                    printCountHolder.set(item.id, parseInt(value, 10))
                    setRerender(rerender + 1)
                  }
                }}
              />
            </td>
            <td
              className='w3-padding mv-print-count'
              title='Pack-Anzahl'
            >
              <InputB
                value={packCountHolder.has(item.id) ? packCountHolder.get(item.id) : count }
                returnVal={(value) => {
                  if (value === '') {
                    packCountHolder.delete(item.id)
                    setRerender(rerender + 1)
                  } else {
                    packCountHolder.set(item.id, parseInt(value, 10))
                    setRerender(rerender + 1)
                  }
                }}
              />
            </td>
          </>
        }
        <td className='flex-grow w3-padding'>
          <div className='bold'>{item.getFirstTagNameByKind('materialNameTag')}</div>
          <div>{item.getName()}</div>
          <div className='credentials fontWeightNormal w3-margin-top'>
            {
              item.credentials.map(
                (c, index) => <span
                  key={`credentials-${item.id}-${index}`}
                  className={`credential-type-${item.type}`}
                >
                  { index > 1 &&
                    <ArrowRight size='12'/>
                  }
                  {index > 0 && c}
                </span>
              )
            }
          </div>
        </td>
        <td>
        { currentFile &&
            <File
              key={`single-file-view-${currentFile.id}`}
              showBiggerPreview={false}
              prop={currentFile}
              className={`flex-column w3-padding textRight`}
            />
          }
        </td>
        <td className='mv-last-column'>
          {
            transportWarning &&
            <button className='w3-button w3-padding w3-yellow'
              title='Transporthinweise beachten'
            >
              🚚
            </button>
          }
          {
            productionHints &&
            <button className='w3-button w3-padding w3-purple'
              title='Produktionshinweise beachten'
            >🔨</button>
          }
          {
            hints &&
            <button className='w3-button w3-padding w3-blue'
              title='Hinweise beachten'
            >⚠️</button>
          }
        
        {
          props.parent?.objectKind === ObjectKind.event &&
          props.checklists && props.parent &&
          props.checklists.map((c, index) => {
            const state = props.parent?.getPropV1(c.key1, `${item.id}`) === '1'
            const icon = state ? <CheckSquare /> : <Square />
            return <button
              title={c.title}
              key={`checklist-${c.key1}-${item.id}`}
              className='w3-button'
              onClick={async () => {
                await props.parent?.addProp(c.key1, `${item.id}`, state ? '0' : '1')
                setRerender(rerender + 1)
              }}
            >{icon}</button>
          })
        }
        
          <button
            className='w3-button'
            onClick={() => {
              mainservice.broadcast('rightDrawerOpen', {
                id: item.id,
                contentType: RightDrawerContent.viewMaterial,
              })
            }}
          ><Eye /></button>
        </td>
      </tr>
  })
  if (list.length === 0) {
    return null
  }
  const printLink = MakePrintLink({
    flips: props.items,
    size: props.format,
    countHolder: printCountHolder,
    standardCountHolder: standardCountHolder
  })
  return <div className='contentPart'>
    <div className='contentHeader'>
      <h2>{props.headline}</h2>
    </div>
    <table className='w100'>
      {list}
    </table>
    {
      props.format &&
      <div className='flex'>
        <div className='flex-grow'></div>
        <a
          title={`${printLink ? props.headline + ' drucken' : 'Keine druckbaren Elemente'}`}
          className={`${printLink ? 'neutralButton' : 'disabledButton'} `}
          href={printLink || '#'} target='_blank' rel='noreferrer'
        >
          <FileIcon /><Printer />
        </a>
      </div>
    }
  </div>
}
