import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import { GenericDTO } from "../DTO/GenericDTO";
import { GenericMaterialItemDTO } from "../DTO/GenericMaterialItemDTO";
import { MaterialItemDTO } from "../DTO/MaterialItemDTO";
import { ObjectKind } from "../models/objectKinds";
import { PublishKind } from "../models/publishKinds";
import { Status } from "./day/dayView";
import { EventDTO } from "../DTO/EventDTO";
import { MakePrintLink } from "./MakePrintLink";
import { DisplayDateTimeFromString } from "../../services/DateTime";
import PropDTO from "../DTO/PropDTO";
import FileManager from "./FileManager/FileManager";
import ItemDTO from "../DTO/ItemDTO";

export default function FotoprotokollView (props: {
  items: MaterialItemDTO[],
  identKey: string,
  parentPublishKind: PublishKind,
  parentObjectKind: ObjectKind,
  getItems?: () => Promise<MaterialItemDTO[]>,
  getItemsAtInit?: boolean,
  addItem?:(position: number, item: MaterialItemDTO) => void,
  trashItem?: (item: GenericMaterialItemDTO) => void,
  displayKind: Status,
  parent?: GenericDTO,
  checklists?: {key1: string, title: string}[],
  reloadTrigger?: number,
  rerenderRightSide?: () => void,
  editItem?: (item: any, objecKind: ObjectKind) => void,
}) {
  const loadItemsOnInit = props.getItems && props.getItemsAtInit !== false
  const [status, setStatus] = useState((props.getItems) ? Status.loading : Status.standard)
  const [items, setItems] = useState(loadItemsOnInit ? [] : props.items)
  const [appointment, setAppointment] = useState('')
  const [appointmentStatus] = useState(new Map())
  const [rerender, setRerender] = useState(0)
  function init() {
    if (status !== Status.loading) {
      setStatus(Status.loading)
    }
    if (loadItemsOnInit && props.getItems) {
      props.getItems().then((items: MaterialItemDTO[]) => {
        setItems(items)
        setStatus(Status.standard)
      })
    } else {
      setStatus(Status.standard)
    }
  }
  
  useEffect(() => {
    init()
  }, [props.reloadTrigger])

  let appointments: {key2: string, name: string}[] = []
  if (props.parent && props.parent instanceof EventDTO) {
    appointments = [...[{key2: '', name: 'Alle'}], ...(props.parent as EventDTO).getAppointments().map((a, index) => {
      return {
        key2: a.key2,
        name: `Zeitraum ${index + 1}`
      }
    })]
  }
  if (status === Status.loading) {
    return <Spinner />
  }
  const filtered = items.filter(i => {
    if (!appointment) {
      return true
    }
    if (i.appointments.length === 0 || i.appointments.some(a => a === appointment)) {
      return true
    }
    return false
  })
  const flips = filtered.filter(i => i.getPropV1('data', 'materialKind') === 'digitalFlip')
  const flipCreationLink = MakePrintLink({
    flips: flips,
    size: '_670x990',
    option: 'fotoprotokoll'
  })
  
  let lastProtokoll = ''
  const lastProtokollRaw = props.parent?.getProp('fpFile', appointment)
  if (lastProtokollRaw) {
    const protocolFile = new PropDTO(lastProtokollRaw)
    lastProtokoll = protocolFile.File?.getURL() || ''
  }
  console.log('flips ', flips, ' link ', flipCreationLink)
  return <div>
    {appointments.map((a, index) => {
      return <button
        title={a.key2}
        key={`appointment-tab-${a.key2}-${index}`}
        onClick={() => {
          setAppointment(a.key2)
        }}
        className={`tab-button-small ${a.key2 === appointment ? 'tab-active' : 'tab-not-active'}`}
      >{a.name}</button>
    })}
    { (!flips || flips.length === 0) ?
      <div className='empty'>Keine Flips verfügbar</div>
      :
      <div>
        <div>
          <h2>Fotoprotokoll erstellen</h2>
          <button
            className={`w3-button ${appointmentStatus.has(appointment) ? `w3-grey` : `w3-green`}`}
            onClick={() => {
              if (appointmentStatus.has(appointment)) {
                alert('Prozess schon aktiviert!')
                return
              }
              appointmentStatus.set(appointment, true)
              setRerender(rerender + 1)
              props.parent?.addProp('fpStatus', appointment, 'generating', new Date().toJSON())
              console.log('')
              // for demo:
              // fetch(`http://localhost:8003/index.php?option=fotoprotokoll&items=p_1428_670x990&backId=${props.parent?.id || -1}&key1=fpFile&key2=${appointment}`)
              // Correct code:
              fetch(`${flipCreationLink}&backId=${props.parent?.id || -1}&key1=fpFile&key2=${appointment}`)
            }}
          >{appointmentStatus.has(appointment) ? 'Protokoll wird erstellt' : 'Fotoprotokoll erstellen'}</button>
        </div>
        <div>
          <h2>Status</h2>
          <div>
            {
              appointmentStatus.has(appointment) ? 'Wird generiert' : GenerationStatus(props.parent?.getPropV1('fpStatus', appointment) || '')} {DisplayDateTimeFromString(props.parent?.getPropV2('fpStatus', appointment) || '')
            }</div>
        </div>
        <div>
          <h2>Letztes generiertes Fotoprotokoll herunterladen</h2>
          {lastProtokoll ?
          <a href={lastProtokoll || ''} download="fotoprotokoll.pptx" target="_blank">Protokoll herunterladen</a> : <div>Kein Protokoll vorhanden</div>}
        </div>
      </div>
    }
    <FileManager
      title='Bearbeitete Protokolle'
      item={props.parent as GenericDTO}
      readMode={false}
      className={`w3-container`}
      showBiggerPreview={true}
      fileKindKey={`fotoprotokoll-${appointment}`}
    />
  </div>
}

function GenerationStatus(s: string) {
  switch(s) {
    case 'generating':
      return 'Wird generiert ...'
    case 'done':
      return 'Fertig generiert'
    default:
      return 'Noch nicht generiert'
  }
}
