import { createRef, useRef, useState } from "react"
import Spinner from "../Spinner/Spinner"
import WidgetUserDTO from "../../DTO/WidgetUserDTO"
import WidgetDiagramUserDTO from "../../DTO/WidgetDiagramUserDTO"
import './WidgetDiagram.scss'
import PageElementDTO from "../../DTO/PageElementDTO";
import { CornerLeftUp, CornerUpLeft, Edit2, X } from "react-feather"
import { ScaleButton } from "./WidgetList"
import { Unique, UserData } from "../../DTO/WidgetDTO"
import mainservice from "../../services/MainService"
import { DisplayRow } from "./WidgetList"
import { Entry } from "../../DTO/WidgetUserDTO"

enum Status {
  loading,
  standard,
  overlay,
}
// const CanvasWidthFactor = 4

function calcDiagramHeight(pe?: PageElementDTO) {
  const elementId = pe?.id || -1
  const pageId = pe?.parentItem.id
  const pageElement = document.getElementById(`pageId-${pageId}`)
  let out: string = '40%'
  if (pageElement) {
    const positionInfo = pageElement.getBoundingClientRect();
    const height = positionInfo.height;
    out = `${Math.max(height - 450, 150)}px`
  }
  return out
}

function calcCanvasWidthFactor(diagramHeight: string, pe?: PageElementDTO) {
  const pageId = pe?.parentItem.id
  const pageElement = document.getElementById(`pageId-${pageId}`)
  let out: number = 4
  if (pageElement && diagramHeight.search('%') === -1) {
    out = pageElement.clientWidth / parseInt(diagramHeight, 10)
  }
  return out
}

export default function WidgetDiagram(props: {
  widget: WidgetUserDTO,
  pageElement?: PageElementDTO
}) {
  const [status, setStatus] = useState(Status.standard)
  const [rerenderCounter, setRerenderCounter] = useState(0)
  const [editEntry, setEditEntry] = useState(-1)
  
  const [dragging, setDragging] = useState(false)
  const [xDownPos, setXDownPos] = useState(0)
  const [xNowPos, setXNowPos] = useState(0)
  const [diagramHeight, setDiagramHeight] = useState(calcDiagramHeight(props.pageElement))
  // const CanvasWidthFactor = 4
  const CanvasWidthFactor = calcCanvasWidthFactor(diagramHeight, props.pageElement)
  function onMouseDown(e: {clientX: number}) {
    if (dragging) { return }
    setDragging(true)
    setXDownPos(e.clientX)
    const el = document.getElementById(`${w.id}-scroller`)
    if (!el) { return }
    setXNowPos(el.scrollLeft)
  }
  function onMouseUp() {
    setDragging(false)
  }
  function onMouseMove(e: {clientX: number}) {
    if (!dragging) { return }
    const newPos = xNowPos + xDownPos - e.clientX
    const el = document.getElementById(`${w.id}-scroller`)
    if (!el) { return }
    el.scrollTo(newPos, 0)
  }
  const w = props.widget
  const diagram = new WidgetDiagramUserDTO(w)
  let entries = diagram.entries
  function rerender() {
    setRerenderCounter(rerenderCounter + 1)
  }
  if (status === Status.loading) {
    return <Spinner />
  }
  const overlayName = w.getPropV1({key1: 'globProp', key2: 'overlayName'})
  const dotConnectorLine = entries.map(e => {
    const x = diagram.positionArr.find(c => c.id === e.id)?.xPos || 0
    const y = diagram.yArr.find(c => c.id === e.id)?.xPos || 0
    return `${(x * CanvasWidthFactor + ((0.5 * CanvasWidthFactor) * diagram.entryWidth))},${100 - y}`
  }).join(' ')
  return <div className='widgetDiagram'>
    <div className='wd-new-button-holder'>
      {
        overlayName &&
        <button
          className='primary-button'
          onClick={() => {
            mainservice.broadcast('triggerOverlay-' + overlayName, {mode: 'open', id: -1})
          }}
        >
          + Ereignis hinzufügen
        </button>
      }
    </div>
    <div
      className='widgetDiagramScroller'
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      // ref={myRef}
      id={`${w.id}-scroller`}
    >
      <div
        className={`widgetDiagramFooter`}
        style={{width: `${100 * diagram.pageCount}%`}}
      >
        {
          entries.map((e, index) => {
            if (!e) { return null}
            const yColumn = e.columns.find(c => c.key1 === `childAttribute${diagram.yColumn?.value1}`)
            const zColumn = e.columns.find(c => c.key1 === `childAttribute${diagram.zColumn?.value1}`)
            const leftPercent = diagram.getPosition(e.id) || 0
            return <div
              key={`e-${index}`}
              style={{
                left: `${leftPercent}%`,
                width: `${diagram.entryWidth}%`,
              }}
              className={`diagramEntry`}
            >
              <div className='wd-growing-separator'></div>
              <div
                className={`diagramEntryValue`}
              >
                {
                  diagram.getValue(e.id) || '?'
                }
              </div>
              <div className={`tooltip tooltip-bottom small ${(leftPercent > 50) ? 'right-edge' : 'left-edget'}`}>
                <div className="tooltipBody">
                  <div className="tttriangle"></div>
                  <div
                    className="tooltipContent"
                  >


              <div
                className={`diagramEntryTitle`}
              >
                {
                  e.value1
                }
              </div>
              <div className='wd-dd-holder'>
                <button
                  className='wd-edit cursor'
                  onClick={() => {
                    // setEditEntry(e.id)
                    // setStatus(Status.overlay)
                    mainservice.broadcast('triggerOverlay-' + overlayName, {mode: 'edit', id: e.id})
                  }}
                >
                  <Edit2 size={14}/>
                </button>
                {
                  yColumn &&
                  <div
                    className='wd-y-dd'
                  >
                    <ScaleButton
                      widget={w}
                      rerender={rerender}
                      headline={yColumn.description}
                      key1={yColumn.key1}
                      key2={yColumn.key2}
                      value1={yColumn.userValue}
                      kind={yColumn.kind}
                      setUserData={async (data: UserData, unique: Unique) => {
                        const result = await w.setUserData(data,unique)
                        return result
                      }}
                      placeholder={yColumn.legendEmoji}
                    />
                  </div>
                }
                {
                  zColumn &&
                  <div
                    className='wd-z-dd'
                  >
                    <ScaleButton
                      widget={w}
                      rerender={rerender}
                      headline={zColumn.description}
                      key1={zColumn.key1}
                      key2={zColumn.key2}
                      value1={zColumn.userValue}
                      kind={zColumn.kind}
                      setUserData={async (data: UserData, unique: Unique) => {
                        const result = await w.setUserData(data,unique)
                        return result
                      }}
                      placeholder={zColumn.legendEmoji}
                    />
                  </div>
                }
              </div>


                  </div>
                </div>
              </div>
              
            </div>
          })
        }
      </div>
      {entries.length === 0 &&
        <div className='w3-center w3-padding w3-margin fontSize4'>
          <p>Dieses Diagramm ist leer.</p>
          {
            overlayName &&
            <button
              className='primary-button'
              onClick={() => {
                mainservice.broadcast('triggerOverlay-' + overlayName, {mode: 'open', id: -1})
              }}
            >
              + Ereignis hinzufügen
            </button>
          }
          <p><CornerLeftUp size="80" />Bitte füge Einträge hinzu!</p>
        </div>
      }
      <div
        className={`widgetDiagramCanvas`}
        style={{
          width: `${100 * diagram.pageCount}%`,
          paddingTop: diagramHeight,
          //paddingTop: `${40 * diagram.pageCount}%`
        }}
      >
        <svg className='wd-bg' width="100%" height="100%" viewBox={`0 0 ${CanvasWidthFactor * 100} 100`} preserveAspectRatio="none">
        <polyline
            className='wd-middle-line'
            points={`0 50 ${CanvasWidthFactor * 100} 50`}
          />
          <polyline
            className='wd-dot-connector'
            points={dotConnectorLine}
          />
        </svg>
        {
          entries.map((e, index) => {
            if (!e) { return null}
            const yPos = diagram.yArr.find(a => a.id === e.id)?.xPos
            const scale = (diagram.zArr.find(a => a.id === e.id)?.xPos || 0) / 100
            return <div
              key={`e-${index}`}
              style={{
                left: `${diagram.getPosition(e.id)}%`,
                width: `${diagram.entryWidth}%`

              }}
              className={`diagramEntry`}
            >
              <div className='wd-entry-highlighter'></div>
              <div
                className={`diagramDot`}
                style={{bottom: `${yPos}%`}}
              >
                <div
                  className={`diagramDotDrawing`}
                  style={{transform: `scale(${scale})`}}
                ></div>
              </div>
              <div
                className={`diagramDotSuspension`}
                style={{bottom: `${yPos}%`}}
              >
              </div>
            </div>
          })
        }
      </div>
    </div>
    {
      status === Status.overlay && editEntry > -1 &&
      <div className='wd-overlay w3-container UserListWidget'>
        <div className='w3-close-button-container'>
          <button
            className='w3-button w3-btn w3-white wd-close-button'
            onClick={() => {
              setStatus(Status.standard)
            }}
          ><X /></button>
        </div>
        <div>
          <DisplayRow
            className='w3-container w3-white w3-row w3-padding'
            widget={w}
            rerender={() => {
              // setStatus(Status.standard)
            }}
            rerenderValue={editEntry}
            showTitleTagWithClass={'w3-col s4 w3-padding'}
            valueClass={`w3-row w3-padding`}
            entry={
              { 
                ...entries.find(e => e.id === editEntry) as Entry,
                ...{columns: w.getRawColumns().map(e => {
                  const userValue = w.getUserDataValue1({key1: e.key1, key2: e.key2 + editEntry})
                  return {
                    ...e,
                    ...{
                      key2: e.key2 + editEntry,
                      userValue: userValue,
                      userValueInt: parseInt(userValue, 10),
                      sortNumber: e.value2,
                    }
                  }
                })}
              }
            }
          />
          <div className='w3-close-button-container'>
            <button
              className='w3-button w3-btn w3-white wd-close-button'
              onClick={() => {
                setStatus(Status.standard)
              }}
            >Okay</button>
          </div>
        </div>
      </div>
    }
  </div>
}
