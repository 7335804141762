import { useState } from "react";
import { ArrowDown, ArrowUp, CheckSquare, Eye, Plus, Settings, Square, Tool, Trash } from "react-feather";
// import PageElementDTO from "../../DTO/PageElementDTO";
import WidgetAdminDTO from "../../DTO/WidgetAdminDTO";
import { GlobPreference, prefKind, WidgetTypes } from "../../DTO/WidgetDTO";
import InputA from "../InputA/InputA";
import WidgetUser from "./WidgetUser";

enum Status {
  display,
  editAttributes,
  editPreferences,
}

export enum Enviroment {
  pageElement,
  widgetAdmin,
}

export default function WidgetAdmin (props: {
  widget: WidgetAdminDTO,
  enviroment?: Enviroment,
  setWidget?: () => void,
}) {
  const w = props.widget
  const [status, setStatus] = useState(Status.display)
  const content = () => {
    switch(status) {
      case Status.display:
        return <>
          <div className='w3-col s6'>{w.getTypeName()}</div>
          <div className='w3-col s6'>{w.getStatusName()}</div>
          <WidgetUser
            id={w.id}
            rerender={status}
          />
        </>
      case Status.editAttributes:
        return <EditWidget
          widget={w}
          success={() => {
            setStatus(Status.display)
          }
        } />
      case Status.editPreferences:
        return <WidgetPropsAdmin
          widget={w}
          success={() => {
            setStatus(Status.display)
          }}
          enviroment={props.enviroment}
        />
    }
  }
  return <div
    className={`w3-card w3-margin w3-padding w3-row`}
  >
    <div className='w3-col s10'><b>{w.name}</b></div>
    <div className='w3-col s2 w3-right'>
      <div>
        <button
          className='w3-btn w3-grey'
          onClick={() => {
            setStatus(Status.editPreferences)
          }}
        ><Settings /></ button>
        <button
          className='w3-btn w3-grey w3-right'
          onClick={() => {
            setStatus(Status.editAttributes)
          }}
        ><Tool /></ button>
        {
          props.setWidget &&
          <button
            className='w3-btn w3-grey w3-right'
            onClick={() => {
              if (!props.setWidget) { return }
              props.setWidget()
            }}
          ><Eye /></ button>
        }
      </div>
    </div>
    {content()}
  </div>
}

export function EditWidget (prop: {
  success: () => void,
  widget?: WidgetAdminDTO,
}) {
  const [widget, setWidget] = useState(prop.widget || new WidgetAdminDTO({id: -1}))
  const [rerender, setRerender] = useState(0)
  const [errMsg, setErrMsag] = useState('')
  return <>
    <label>Name</label>
    <InputA
      value={widget.getName()}
      returnVal={(v) => widget.setName(v)}
    />
    <label>Beschreibung</label>
    <InputA
      value={widget.getDescription()}
      returnVal={(v) => widget.setDescription(v)}
    />
    <label>Typ</label>
    <div className='w3-row'>
      {
        WidgetTypes.map(WT => <div
          key={'widgetTypeSelectItem' + WT.id}
          className={`w3-col s12 m4 l3 w3-padding w3-margin w3-card pointer ${WT.id === widget.getType() ? 'w3-green' : ''}`}
          onClick={() => {
            widget.setType(WT.id)
            setRerender(rerender + 1)
          }}
        >
          { WT.name }
        </div>)
      }
    </div>
    { errMsg &&
      <div className='errMsg'>
        {errMsg}
      </div>
    }
    <button
      className='w3-button w3-green'
      onClick={async () => {
        const result = await widget.save()
        if (result) {
          setErrMsag(result)
          return
        }
        prop.success()
      }}
    >
      Speichern
    </button>
    <button
      className='w3-button w3-grey'
      onClick={() => {
        setWidget(new WidgetAdminDTO({id: -1}))
        prop.success()
      }}
    >
      Abbrechen
    </button>
  </>
}

export function WidgetPropsAdmin(props: {
  success: () => void,
  widget?: WidgetAdminDTO,
  enviroment?: Enviroment,
}) {
  const w = props.widget
  const [rerender, setRerender] = useState(0)
  if (!w) { return null }
  const ps = w.getPreferenceManifest()
  return <div>
    {ps.map((p, index) => {
      return <WidgetPropAdmin
        key={`widgetPropsAdminItem-${w.id}-${props.enviroment}-${p.key1}-${p.key1}-${index}`}
        success={props.success}
        doRerender={() => {
          setRerender(new Date().getTime())
        }}
        widget={w}
        pref={p}
        enviroment={props.enviroment}
      />
    })}
    <button
      className='w3-button w3-green'
      onClick={() => {
        props.success()
      }}
    >Okay</button>
  </div>
}

export function WidgetPropAdmin(props: {
  success: () => void,
  doRerender: () => void,
  widget: WidgetAdminDTO,
  pref: GlobPreference,
  enviroment?: Enviroment,
}) {
  const w = props.widget
  const p = props.pref
  const [rerender, setRerender] = useState(0)
  const value = w.getPropV1(p)
  const origValue = w.getPropV1(p, true)
  let currentOptionName = 'NA'
  const childProps = p.children || []
  const parentId = (p.children) ? w.getPropId(p) : -1
  // const colKind = childProps.find(cP => cP.key2 === 'kind')
  function doRerender () {
    props.doRerender()
    setRerender(new Date().getTime())
  }
  function TestShowIfValue(showIfValue?: {key2: string, value: string}) {
    if (!showIfValue) { return true }
    if (!showIfValue.value) { return false }
    const newP = {...p, ...{key2: showIfValue.key2}}
    const value = w.getPropV1(newP)
    if (!value) { return false }
    if (value.search(new RegExp(showIfValue.value)) > -1) { return true }
    return false
  }
  if (p.key2 === '?') {
    const SiblingProps = w.getProps(p.key1)
    return <>
      {
        props.enviroment === Enviroment.widgetAdmin &&
        <button
          className='w3-button w3-green w3-margin-top w3-margin-bottom'
          onClick={async () => {
            await w.setProp({
              key1: p.key1,
              key2: Math.random().toString(36).substring(9),
              value1: '',
              value2: w.getNewPropSortNumber(p.key1) + ''
            })
            setRerender(rerender + 1)
          }}
        ><Plus /></button>
      }
      {
        SiblingProps.map(prop => {
          const pref = {...p, ...{key2: prop.key2}}
          return <WidgetPropAdmin
            key={`sibling-prop-${prop.key1}-${prop.key2}`}
            success={props.success}
            widget={w}
            pref={pref}
            doRerender={doRerender}
            enviroment={props.enviroment}
          />  
        })
      }
    </>

  }

  if (p.options && p.options.length > 0) {
    currentOptionName = p.options?.find(o => o.key === value)?.name || p.options[0].name
  }
  return <div
    className={`w3-row ${p.className || ''}`}
    key={`${p.key1}-${p.key2}`}
  >
    {
      p.kind === prefKind.notUnique && props.enviroment === Enviroment.widgetAdmin &&
        <div>
          <button><ArrowUp
            onClick={async () => {
              await w.moveSibling(p, -1)
              props.doRerender()
            }}
          /></button>
          <button><ArrowDown
            onClick={async () => {
              await w.moveSibling(p, 1)
              props.doRerender()
            }}
          /></button>
          <button
            onClick={async () => {
              if (!window.confirm('Einstellung wirklich löschen?')) {
                return
              }
              await w.removeProp(p)
              doRerender()
            }}
          ><Trash /></button>
          <details>
            <summary title='Key, nachdem diese Einstellung beim User abgespeichert wird (muss normalerweise nicht verändert werden). Wenn er verändert wird, werden die bisherigen Usereinstellungen unter dem neuen Key gesucht! Alte usereinträge sind dann scheinbar weg.'>UserKey</summary>
            <InputA
              value={p.key2}
              returnVal={(v) => {
                w.setPropKey2(p, v)
                doRerender()
              }}
            />
          </details>
        </div>
    }
    {
      p.kind === prefKind.string && TestShowIfValue(p.showIfValue) &&
      <>
        <label className='w3-col s12 m12 l12'>{p.name}</label>
        <InputA
          returnVal={(v) => {
            w.setProp({...p, ...{value1: v}})
          }}
          value={value}
        />
      </>
    }
    {
      p.kind === prefKind.text && TestShowIfValue(p.showIfValue) &&
      <>
        <label className='w3-col s12 m12 l12'>{p.name}</label>
        <InputA
          returnVal={(v) => {
            w.setProp({...p, ...{value1: v}})
          }}
          type={'wysiwyg'}
          value={value}
        />
      </>
    }
    {
      p.kind === prefKind.dropDown && TestShowIfValue(p.showIfValue) &&
      <>
        <label className='w3-col s12 m12 l12'>{p.name}</label>
        <div className='w3-col s12 m12 l12'>
          <div className='w3-dropdown-hover w3-border'>
            <button className="w3-button">{currentOptionName}</button>
            <div className="w3-dropdown-content w3-bar-block  w3-border">
              {
                p.options?.map(o => <button
                  key={`${o.key}`}
                  className={`${o.key === value ? 'w3-green' : ''} w3-button w3-block w3-border-none`}
                  onClick={async () => {
                    await w.setProp({...p, ...{value1: o.key}})
                    setRerender(rerender + 1)
                  }}
                >{o.name}</button>)
              }
            </div>
          </div>
        </div>
      </>
    }
    {
      p.kind === prefKind.column && TestShowIfValue(p.showIfValue) &&
      <>
        <label className='w3-col s12 m12 l12'>{p.name}</label>
        <div className='w3-col s12 m12 l12'>
          <div className='w3-dropdown-hover w3-border'>
            <button className="w3-button">{w.getRawColumns().find(r => r.id + '' === value)?.description || 'Bitte wählen '}</button>
            <div className="w3-dropdown-content w3-bar-block  w3-border">
            <button
              
              className={`${'-1' === value ? 'w3-green' : ''} w3-button w3-block w3-border-none`}
              onClick={async () => {
                await w.setProp({...p, ...{value1: '-1'}})
                setRerender(rerender + 1)
              }}
            >
              Abwählen
            </button>
              {
                w.getRawColumns().map((c, index) => {
                  return <button
                    key={`${c.key1}-${c.key2}-${index}`}
                    className={`${c.id + '' === value ? 'w3-green' : ''} w3-button w3-block w3-border-none`}
                    onClick={async () => {
                      await w.setProp({...p, ...{value1: c.id + ''}})
                      setRerender(rerender + 1)
                    }}
                  >
                    {c.description || c.kind || 'Keine Beschreibung'}
                  </button>
                })
              }
            </div>
          </div>
        </div>
      </>
    }
    {
      p.kind === prefKind.check && TestShowIfValue(p.showIfValue) &&
      <div
        className='pointer'
        onClick={async () => {
          const currentVal = (!value) ? (p.standard ? p.standard : 'uncheck') : value
          const newValue = (currentVal === 'check') ? 'uncheck' : 'check'
          await w.setProp({...p, ...{value1: newValue}})
          props.doRerender()
        }}
      >
        <div className='w3-col s3 m2 l1'>
        {
          (value === 'check' || (value !== 'uncheck' && p.standard === 'check')) &&
          <CheckSquare />
        }
        {
          (value === 'uncheck' || (value !== 'check' && p.standard !== 'check')) &&
          <Square />
        }
        </div>
        <label className='w3-col s9 m10 l11'>{p.name}</label>
      </div>
    }
    {
      p.children && p.children.length > 0 &&
      <>
        {
          childProps.map((cp, index) => {
            const childProp = {...cp, ...{key1: cp.key1 + parentId}}
            return <WidgetPropAdmin
              key={`pref-kind-children-${w.id}-${index}-${p.key1}-${p.key2}-${childProp.key1}-${childProp.key2 + parentId}`}
              doRerender={props.doRerender}
              success={props.success}
              widget={w}
              pref={childProp}
              enviroment={props.enviroment}
            />
          })
        }
      </>
    }
    {value !== origValue ? ('Original-Wert: ' + origValue) : ''}
  </div>
}
