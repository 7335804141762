import React from 'react'
import SvgEditor from './SvgEditor'

export enum Status {
  normal, editText
}

type ClientXY = {clientX: number, clientY: number, shiftKey: any}

export type DObj = {
  id: number,
  locked: number,
  xStart: number,
  xEnd: number,
  yStart: number,
  yEnd: number,
  bg: string,
  type: string,
  content: string,
  fontSize?: number
}

export type OGProps = {
  data: DObj,
  onMouseDown?: (obj: DObj, event: any) => void,
  onMouseUp?: (obj: DObj, event: any) => void,
  editText?: (obj: DObj) => void,
  editor: SvgEditor
}

export type OGState = {
  text: string,
  status: Status,
  lineHeight: number
}

export default class ObjGeneric extends React.Component<OGProps, OGState> {
  xStart: number = 0
  yStart: number = 0
  xEnd: number = 0
  yEnd: number = 0
  notMovedThreshold = 10
  thingId: string = ''

  constructor(props: OGProps) {
    super(props)
    this.state = {
      status: Status.normal,
      text: props.data.content,
      lineHeight: 30
    }
    this.thingId = `ObjGeneric-${props.data.id}`
  }

  componentDidMount() {
    this.props.editor.registerToBroadcast(
      this.thingId,
      (key: string, value: any) => {
        switch(key) {
          case `refresh-${this.props.data.id}`:
            this.setState({text: value})
        }
      }
    )
  }

  componentWillUnmount() {
    this.props.editor.unregisterBroadcast(this.thingId)
  }

  public render () {
    return <>Leer</>
  }

  setStartCoords(event: ClientXY) {
    this.xStart = event.clientX
    this.yStart = event.clientY
  }

  setEndCoords(event: ClientXY) {
    this.xEnd = event.clientX
    this.yEnd = event.clientY
  }

  didNotMove(event: ClientXY) {
    return Math.abs(event.clientX - this.xStart) < this.notMovedThreshold &&
      Math.abs(event.clientY - this.yStart) < this.notMovedThreshold
  }

  onMouseDown(event: ClientXY) {
    if (!this.props.onMouseDown) { return }
    this.setStartCoords(event)
    this.props.onMouseDown(this.props.data, event)
  }
  onMouseUp(event: ClientXY) {
    if (!this.props.onMouseUp) { return }
    if (this.didNotMove(event)) {
      if (this.props.editText) {
        this.props.editText(this.props.data)
      }
      this.setState({status: Status.editText})
    }
    this.props.onMouseUp(this.props.data, event)
  }
}
