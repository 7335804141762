import config from '../config.json'
import { Getter, Setter } from '../services/ComService'
import ElementPropDTO, {IElementProp} from './ElementPropDTO'
import CollectibleDTO, { ICollectible } from './CollectibleDTO'
import {parseStringToServer, parseStringFromServer} from '../services/TextConverter'
import WidgetUserDTO from './WidgetUserDTO'
import { IWidget } from './WidgetDTO'

export type ElementType = {
  id: number,
  name: string,
  propKeyList: string[]
}

export default class PageElementDTO {
  apiPrefix = config.apiPrefix
  public id: number = 0
  public sortNumber: number = 0
  public elementProps: ElementPropDTO[] = []
  public elementType: ElementType = {id: 0, name: '', propKeyList: []}
  public value1: string = ''
  public value2: string = ''
  public parentItem: any
  public collectible: undefined | CollectibleDTO
  public widget?: WidgetUserDTO
  public manualClass: string = ''

  constructor(o: IPageElement, parentItem?: any) {
    if (parentItem) {
      this.parentItem = parentItem
    }
    this.initVars(o)
  }

  public initVars(o: IPageElement) {
    this.id = o.id
    this.sortNumber = o.sortNumber * 0.1
    this.value1 = parseStringFromServer(o.value1)
    this.value2 = parseStringFromServer(o.value2)
    this.elementProps = o.elementProps.map(
      (eP: IElementProp) => new ElementPropDTO(eP)
    )
    this.elementType = o.type
    if (o.collectable) {
      this.collectible = new CollectibleDTO(o.collectable)
    }
    if (o.widget) {
      this.widget = new WidgetUserDTO(o.widget)
    }
  }

  public async loadFromServer() {
    const response = await fetch(`${this.apiPrefix}PageElement/${this.id}`)
    const responseJson = await response.json()
    this.initVars(responseJson)
  }

  elementBlockNextPage(block: boolean) {

    if (
      this.parentItem &&
      this.parentItem.parentItem &&
      this.parentItem.parentItem.blockNextPage
    ) {
      this.parentItem.parentItem.blockNextPage(this.id, block)
    }
  }

  public getDoublePageID(): number {
    if (!this.parentItem) { return -1 }
    return this.parentItem.getDoublePageID()
  }

  public async remove() {
    await fetch(`${this.apiPrefix}PageElement/${this.id}/remove`)
  }

  public async setSortNumber(sortNumber: number) {
    if (sortNumber === undefined) { return }
    const correcture = (this.sortNumber < sortNumber) ? 1 : -1
    await fetch(`${this.apiPrefix}PageElement/${this.id}/setSortNumber/${(sortNumber * 10) + correcture}`)
  }

  public async setValue1(v: string) {
    this.value1 = v
    const transferValue = parseStringToServer(v)
    await fetch(`${this.apiPrefix}PageElement/${this.id}/setValue1`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({value1: transferValue})
    })
  }

  public async setValue2(v: string) {
    this.value2 = v
    await fetch(`${this.apiPrefix}PageElement/${this.id}/setValue2/${parseStringToServer(v)}`)
  }

  public async addElementProp(elementProp: IElementProp) {
    const e = elementProp
    // find prop, if present:
    const pExists = this.elementProps.find((eP) => e.key1 === eP.key1 && e.key2 === eP.key2)
    if (pExists) {
      await pExists.update(e)
    }
    const newProp = await fetch(`${this.apiPrefix}PageElement/${this.id}/addProp`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        key1: e.key1,
        key2: e.key2,
        value1: parseStringToServer(e.value1),
        value2: parseStringToServer(e.value2),
      })
    })
    const raw = await newProp.json()
    if (!pExists) {
      this.elementProps.push(new ElementPropDTO(raw))
    }
  }

  public async addProp(elementProp: IElementProp) {
    return await this.addElementProp(elementProp)
  }

  public getProp(key1: string, key2: string): ElementPropDTO | false {
    return this.elementProps.find((eP) => eP.key1 === key1 && eP.key2 === key2) || false
  }

  public getProps(key1: string): ElementPropDTO[] {
    return this.elementProps.filter(ep => ep.key1 === key1)
  }

  public getPropVal1(key1: string, key2: string, standard?: string): string {
    const prop = this.getProp(key1, key2)
    if (standard === undefined) { standard = ''}
    if (!prop) { return standard }
    return prop.value1 || standard
  }

  public async removeElementProp(key1: string, key2: string) {
    const prop = this.getProp(key1, key2)
    if (!prop) { return }
    this.elementProps = this.elementProps.filter(
      (p) => !(p.key1 === key1 && p.key2 === key2)
    )
    await prop.remove()
  }

  public async setPropVal(key1: string, key2: string, val1: string, val2?: string) {
    return await this.addElementProp({
      key1: key1,
      key2: key2,
      value1: val1,
      value2: val2 || '-'
    } as IElementProp)
  }

  public getPropVal2(key1: string, key2: string): string {
    const prop = this.getProp(key1, key2)
    if (!prop) { return '' }
    return prop.value2 || ''
  }

  public getValue1(): string {
    return this.value1 || ''
  }

  public async addCollectible(id: number) {
    await Getter(`pageElement/${this.id}/collectable/add/${id}`)
  }

  public async setWidget(id: number) {
    const r = await Setter(`PageElement/${this.id}/widget`, {id: id}, {method: 'PUT'})
    this.initVars((r as {data: any}).data)
  }

}

export interface IPageElement {
  id: number
  sortNumber: number
  value1: string
  value2: string
  elementProps: IElementProp[]
  type: ElementType,
  collectable: ICollectible,
  widget?: IWidget,
}
