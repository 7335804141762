import { GenericDTO } from "../DTO/GenericDTO";

export class CountHolder {
  item?: GenericDTO;
  key1: string;
  key2: string;
  values = new Map();
  constructor(o: {
    item?: GenericDTO;
    key1: string;
    key2: string;
  }) {
    this.item = o.item;
    this.key1 = o.key1;
    this.key2 = o.key2;
    // Get old values - if available:
    const v12 = this.item?.getPropV1(this.key1, this.key2);
    // remember old values
    if (v12) {
      v12.split(' ').forEach(s => {
        const parts = s.split(';');
        this.values.set(parseInt(parts[0], 10), parseInt(parts[1], 10));
      });

    }
  }

  private async save() {
    let arr: string[] = [];
    this.values.forEach((value: number, key: number) => {
      arr.push(`${key};${value}`);
    });
    await this.item?.addProp(this.key1, this.key2, arr.join(' '));
  }

  public has(id: number) {
    return this.values.has(id);
  }

  public async set(id: number, value: number) {
    this.values.set(id, value);
    await this.save();
  }

  public get(id: number) {
    return this.values.get(id);
  }

  public async delete(id: number) {
    this.values.delete(id);
    await this.save();
  }
}
