// This service will prevent navigation if user is making unchanged entries.
// Each field will lock this service with a key
// The user is prevented in proceeding, but he can ensist!

class LockNavigation {
  lockList: string[] = []
  lockInfo = new Map()
  public lock(key: string, name?: string): void {
    console.log('LockNavigationService lock ', key, name)
    if (this.lockList.indexOf(key) === -1) {
      this.lockList.push(key)
      this.lockInfo.set(key, {
        name: name || ''
      })
    }
    console.log('LockNavigationService lock list', this.lockList)
  }

  public unlock(key: string): void {
    this.lockList = this.lockList.filter(i => i !== key)
  }

  public isLocked(): boolean {
    return !this.isNotLocked()
  }

  public isNotLocked(): boolean {
    console.log('LockNavigationService isNotLocked', this.lockList)
    if (this.lockList.length === 0) { return true }
    const lockInfo = this.lockInfo.get(this.lockList[0])
    let lockInfoString = ''
    if (lockInfo?.name) {
      lockInfoString = ` z.B. in Feld "${lockInfo.name}"`
    }
    console.log('LockNavigationService isNotLocked', 'ask')
    const msg = `Achtung: Im Moment sind deine Daten NOCH NICHT GESPEICHERT${lockInfoString}. Speichere deine Daten mit Klick auf OK und SPEICHERN. Wenn du ohne zu speichern fortsetzen möchtest, klicke auf Abbrechen.`
    if (window.confirm(msg)) { return false }
    this.lockList = []
    this.lockInfo = new Map()
    console.log('LockNavigationService isNotLocked', 'okay')
    return true
  }
}

const LockNavigationService = new LockNavigation()
export default LockNavigationService
