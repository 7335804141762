import { Getter, Setter } from '../services/ComService'
import FileDTO, {IFile} from './FileDTO'

type dataVersion = 'user' | 'admin'

export enum CollectibleType {
  flashcard = 'flashcard',
  poster = 'poster',
  motto = 'motto'
}

export default class CollectibleDTO {
  id: number = -1
  uid: string = ''
  title: string = ''
  date: Date = new Date()
  content: string = ''
  type: CollectibleType = CollectibleType.flashcard
  tags: string[] = []
  picture?: undefined | FileDTO
  pictureStyle?: string
  collected: boolean = false
  sourceBookId: number = -1
  sourceDoublePageId: number = -1

  constructor(o?: ICollectible) {
    if (o) {
      this.initData(o)
    }
  }

  public initData(o: ICollectible) {
    this.id = o.id || -1
    this.uid = o.uid || ''
    this.title = o.title || ''
    if (o.date?.date) {
      this.date = new Date(o.date.date)
    }
    this.content = o.content || ''
    this.type = o.type || CollectibleType.flashcard
    this.tags = o.tags || []
    this.picture = o.picture ? new FileDTO(o.picture) : undefined
    this.pictureStyle = o.pictureStyle || ''
    this.collected = o.isCollected || false
    this.sourceBookId = o.sourceBookId || -1
    this.sourceDoublePageId = o.sourceDoublePageId || -1
  }

  public async save(o: ICollectible) {
    if (o.title != undefined) {
      this.title = o.title
    }
    if (o.content != undefined) {
      this.content = o.content
    }
    if (o.type != undefined) {
      this.type = o.type
    }
    if (o.pictureStyle != undefined) {
      this.pictureStyle = o.pictureStyle
    }
    let res = undefined
    if (this.id === -1) {
      res = await Setter('collectable/add', this.get(), {retryName: `${this.title}`})
    } else {
      res = await Setter(`collectable/${this.id}/edit`, this.get(), {retryName: `${this.id}`})
    }
    if ((res as any)?.id) {
      this.id = (res as any).id
      return true
    }
    return false
  }

  public async getFromServer() {
    if (this.id < 0 && !this.uid) {
      const add = await Setter('collectable/add', this.get(), {retryName: `${this.title}`})
      if (!add) { return }
      this.id = (add as {id: number}).id
      return {status: 'redirect', id: (add as {id: number}).id}
    }
    const method = (this.uid) ? `collectable/${this.uid}` : `collectable/getById/${this.id}`
    const data = await Getter(method)
    this.initData(data.collectable)
    return {status: 'okay'}
  }

  public get() {
    return {
      id: this.id,
      uid: this.uid,
      title: this.title,
      date: this.date,
      content: this.content,
      type: this.type,
      tags: this.tags,
      picture: this.picture?.get(),
      pictureStyle: this.pictureStyle,
      collected: this.collected,
      sourceBookId: this.sourceBookId,
      sourceDoublePageId: this.sourceDoublePageId
    }
  }

  public typeName() {
    switch(this.type) {
      case CollectibleType.flashcard:
        return 'lernkarte'
      case CollectibleType.motto:
        return 'sinnspruch'
      case CollectibleType.poster:
        return 'plakat'
    }
  }

  public async delete() {
    await Getter(`collectable/${this.id}/remove`)
  }

  public async setPicture(id: number) {
    await Getter(`collectable/${this.id}/setPicture/${id}`)
    await this.getFromServer()
  }

  public getCssPicture(): string {
    const result = this.picture?.getCSSBackground() || 'none'
    return result
  }

  public getContent(): string {
    const content = this.content + ''
    const bg = this.getCssPicture()
    if (content.search('BACKGROUNDIMGPLACEHOLDER') > -1) {
      const result = content.replace(/BACKGROUNDIMGPLACEHOLDER/, bg)
      // console.log('CSSBG:', result, bg)
      // return content.replace('BACKGROUNDIMGPLACEHOLDER', this.getCssPicture())
      return result
    }
    return this.content
  }

  public async addTag(tagName: string) {
    if (this.tags.indexOf(tagName) === -1)  {
      this.tags.push(tagName)
    }
    await Setter(`collectable/${this.id}/tag/add`, {tagName: tagName}, {retryName: `${this.id},${tagName}`})
  }

  public async removeTag(tagName: string) {
    this.tags = this.tags.filter((t) => t != tagName)
    return await Setter(`collectable/${this.id}/tag/remove`, {
      tagName: tagName
    }, {retryName: `${this.id},${tagName}`})
  }

  public async collect(o: {
    bookId: number,
    doublePageId: number
  }) {
    this.collected = true
    await Getter(`pageElement/${o.bookId}/${o.doublePageId}/collectable/${this.id}/collect`)
  }

  public async ditch(userId: number) {
    this.collected = false
    await Getter(`collectable/${userId}/${this.id}/ditch`)
  }
}


export class CollectiblesDTO {
  private collectibles: CollectibleDTO[] = []
  constructor(o: ICollectibles) {
    if (o.collectibles) {
      this.collectibles = o.collectibles.map((c) => new CollectibleDTO(c))
    } else if (o.refresh) {
      this.getFromServer(o.refresh, o.version)
    }
  }

  async getFromServer(refreshFkt?: () => void, version?: dataVersion) {
    const g = await Getter((version === 'admin') ? 'collectable/showAllAdmin' : 'collectable/showAll')
    let rememberIds = new Map()
    this.collectibles = g?.collectables?.filter((c: ICollectible) => {
      // We dont want doublicates:
      if (rememberIds.has(c.id)) {
        return false
      }
      rememberIds.set(c.id, true)
      return true
    }).map((c: ICollectible) => new CollectibleDTO({...c, ...{isCollected: true}})) || []
    if (refreshFkt) {
      refreshFkt()
    }
  }

  getCollectibles(cs: CollectibleDTO[]) {
    return cs.map((c) => c.get())
  }

  get(o?: any) {
    const filteredCollectibles = this.collectibles.filter((c) =>
      !o ||
      c.type === o.type
    )
    return filteredCollectibles
  }

  getById(id: number) {
    return this.collectibles.find(c => c.id === id)
  }

  async setPicture(o: {
    pictureId: number,
    collectibleId: number
  }) {
    if (o.pictureId < 0 || o.collectibleId < 0) { return false }
    const c = this.collectibles.find((c) => c.id === o.collectibleId)
    if (!c) { return false }
    return await c.setPicture(o.pictureId)
  }

  async addTags(o: {
    ids: number[],
    tags: string[]
  }) {
    for (let i = 0, m = o.ids.length; i < m; i++) {
      let c = this.collectibles.find((c) => c.id === o.ids[i])
      for (let j = 0, n = o.tags.length; j < n; j++) {
        await c?.addTag(o.tags[j])
      }
    }
  }

}

export interface IFilterCollectibles {
  type: string
}

export interface ICollectible {
  id?: number,
  uid?: string,
  title?: string,
  date?: {date: string},
  content?: string,
  type?: CollectibleType,
  tags?: string[],
  picture?: IFile,
  pictureStyle?: string,
  isCollected?: boolean,
  sourceBookId?: number,
  sourceDoublePageId?: number
}

export interface ICollectibles {
  collectibles?: ICollectible[]
  refresh?: () => void,
  version?: dataVersion
}
