import React from 'react'
import mainservice from '../../../services/MainService'
import PageElementDTO from '../../../DTO/PageElementDTO'
import InputA from '../../InputA/InputA'
import './TextArea.scss'
import LockNavigationService from '../../../services/LockNavigationService'

type DisplayMode = 'input' | 'output'

type Props = {
  parent: any
  pageElement: PageElementDTO
}

type State = {
  userContent: string
  isAdmin: number
  displayMode: DisplayMode
  askToSave: boolean
  userIsInputting: boolean
}

/*
value1: Saves how this element is supposed to behave (let user change content, or show what the user typed)
value2: Identifier for this specific user-input/output
when talking to the session value1 is allways "userTextInput"
*/
export default class TextArea extends React.Component<Props, State> {
  generalButtonStyle = 'w3-btn w3-white'
  constructor(props: Props) {
    super(props)
    let displayMode: DisplayMode = 'input'
    if (this.props.pageElement.value1 === 'output') {
      displayMode = 'output'
    }

    this.state = {
      userContent: this.getSessionItem(),
      isAdmin: mainservice.isAdmin(),
      displayMode: displayMode,
      askToSave: false,
      userIsInputting: false
    }
  }

  getKey(): string {
    return this.props.pageElement.value2 || this.props.pageElement.id + '' || '42'
  }

  getSessionItem() {
    const sessionItem = mainservice.getSessionData('userTextInput', this.getKey())
    let userContent = (sessionItem && sessionItem.value1) ? sessionItem.value1 : ''
    return userContent.replace(/:BREAK:/g, '\n').replace(/:EMPTY:/g, '')
  }

  saveUserContent() {
    let uC = this.state.userContent.replace(/\n/g, ':BREAK:')
    if (uC === '') {
      uC = ':EMPTY:'
    }
    LockNavigationService.unlock(`${this.props.pageElement.id}`)
    mainservice.setSessionData('userTextInput', this.getKey(), uC, '-').then(() => {
      this.setState({
        askToSave: false,
        userIsInputting: false
      })
      /*
      const currentBook = mainservice.getCurrentBookView()
      if (currentBook) {
        currentBook.getContent(true)
      }
      */
    })
  }

  renderAdmin() {
    if (this.state.isAdmin === 0) {
      return <></>
    }
    return <div className="editMode" style={{textAlign: 'left'}}>
      <h4>Textarea Einstellungen</h4>
      <table>
        <tbody>
          <tr>
            <td>Modus:</td>
            <td>
              <select
                value={this.state.displayMode}
                onChange={(event) => {
                  this.props.pageElement.setValue1(event.target.value)
                  this.setState({
                    displayMode: event.target.value as DisplayMode
                  })
                }}
              >
                <option value="input">input</option>
                <option value="output">output</option>
              </select>
            </td>
          </tr>
          <tr>
            <td title="Um später hier drauf referenzieren zu können">Key:</td>
            <td>
              <InputA
                returnVal={(newValue: string, self: any) => {
                  self.props.pageElement.setValue2(newValue)
                }}
                value={this.getKey()}
                parent={this}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }

  render() {
    const value = (this.state.userIsInputting) ? this.state.userContent : this.getSessionItem()
    return <>
      { this.renderAdmin() }
      <textarea
        className="textarea displayMode"
        value={value}
        autoFocus={false}
        onChange={(event) => {
          LockNavigationService.lock(`${this.props.pageElement.id}`)
          this.setState({
            userContent: event.target.value,
            askToSave: true,
            userIsInputting: true
          })
        }}
        disabled={this.state.displayMode === 'output'}
      />
      {
        this.state.displayMode !== 'output' &&
        <button className={`displayMode textareaOkButton ${this.generalButtonStyle} ${(this.state.askToSave) ? 'pleaseSave' : 'disabled'}`} onClick={() => this.saveUserContent()}>Speichern</button>
      }
    </>
  }
}
