import React, { useEffect, useState } from 'react';
import { Status } from '../day/dayView';
import { TagType, TagTypesDTO } from '../../DTO/TagDTO';

export function TagFilter(props: {
  tagTypes: TagType[];
  onChange: (tagIdsString: string) => void;
  bbpage?: string,
  tagIds?: string;
}) {
  const [status, setStatus] = useState(Status.loading);
  // const [activeTags, setActiveTags] = useState(props.tagIds?.split(';').map(ti => parseInt(ti, 10)) || [])
  const [rerender, setRerender] = useState(0);
  const [tags] = useState(new TagTypesDTO({ tagTypes: props.tagTypes, activeTagsString: props.tagIds }));
  const [show, setShow] = useState(false)
  // Find all tags
  useEffect(() => {
    tags.getFromServer().then(() => {
      setStatus(Status.standard);
    });
  }, []);
  useEffect(() => {
    console.log('___ resetTagTyeFilter', props.tagTypes)
    tags.reset(props.tagTypes).then(() => {
      setRerender(rerender + 1)
    })
    // setTags(new TagTypesDTO({ tagTypes: props.tagTypes, activeTagsString: props.tagIds }))
  }, [props.bbpage])
  
  /*
  useEffect(() => {
    console.log('### props.tagTypes', props.tagTypes)
    // tags.reset(props.tagTypes)
  }, [props.tagTypes])
  */
  if (status === Status.loading) { return null; }
  return <div className='flex w100 flex-wrap'>
    {tags.getCategories(props.bbpage).map((c, index) => {
      return <div
        key={`tf-${c.tagType}-${index}`}
        className='w3-dropdown-hover'
      >
        <button
          className={`w3-button ${tags.hasActiveTag(c.tags.tags.map(t => t.id)) ? 'w3-blue' : ''}`}
          onClick={() => {
            setShow(!show)
          }}
        >{c.name}</button>
        {
          show &&
          <div
            className='w3-dropdown-content w3-bar-block w3-border'
          >
            {c.tags.tags.map((t, jndex) => {
              return <button
                key={`tf-${c.tagType}-${index}-${t.id}-${jndex}`}
                className={`w3-button w100 textLeft ${tags.isActiveTag(t.id) ? 'w3-blue' : ''}`}
                onClick={() => {
                  const newTagIds = tags.toggleActiveTag(t.id, c);
                  console.log('DOING', newTagIds)
                  setRerender(rerender + 1);
                  props.onChange(newTagIds);
                }}
              >{t.name}</button>;
            })}
          </div>
        }
      </div>;
    })}
  </div>;
}
