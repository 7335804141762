import { GenericEditField, DisplayScope, EditFieldKind } from "../DTO/GenericDTO";



export const EventEditFields: GenericEditField[] = [
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Zielgruppen',
    key1: 'tags',
    key2: 'targetAudience',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Fertigstellungsstand',
    key1: 'tag',
    key2: 'workStateTag',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Art der Veranstaltung',
    key1: 'tag',
    key2: 'eventKind',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  /*
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  }
  */
  {
    title: 'Ausgangslage / Grundidee',
    key1: 'data',
    key2: 'startingSituation',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Ziele (der Unternehmen) / Erwünschtes Ergebnis',
    key1: 'data',
    key2: 'goals',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Übergeordnete Botschaft',
    key1: 'data',
    key2: 'takeawaymessage',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Grob-Konzeption',
    key1: 'data',
    key2: 'roughConcept',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Organisatorisches',
    key1: 'data',
    key2: 'organizingNotes',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Passende Workbooks',
    key1: 'data',
    key2: 'suitableWorkbooks',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
];
