import { useState } from "react";
import { CartesianGrid, LabelList, Rectangle, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import PageElementDTO from "../../DTO/PageElementDTO";
// import { UserData } from "../../DTO/WidgetDTO";
import WidgetUserDTO from "../../DTO/WidgetUserDTO";
import { DisplayDate, DisplayMonthYear, DisplayYear } from "../../services/DateTime";
import './WidgetUserRating.scss'

enum RatingItemType {
  class,
  emoji,
}

export default function WidgetUserRating(props: {
  widget: WidgetUserDTO,
  pageElement?: PageElementDTO,
}) {
  const w = props.widget
  const title = w.getPropV1({key1: 'globProp', key2: 'title'})
  const [rerender, setRerender] = useState(0)
  return <div className='w3-container UserRatingWidget'>
    <h2 className='WUR-title'>{title}</h2>
    <RenderRatingSelectBar
      widget={props.widget}
      pageElement={props.pageElement}
      rerender={() => {
        setRerender(rerender + 1)
      }}
    />
    <DrawGraph widget={w} />
  </div>
}

function GetValueString(w: WidgetUserDTO, v: number): string {
  return w.getPropV1({key1: 'globTranslation', key2: `v-${v}`}) || ''
}

function RenderRatingSelectBar(props: {
  widget: WidgetUserDTO,
  pageElement?: PageElementDTO,
  rerender: () => void,
}) {
  const w = props.widget
  const iconset = w.getPropV1({key1: 'globProp', key2: 'icons'})
  const stepcount = w.getPropV1({key1: 'globProp', key2: 'steps'})
  const dataRaster = parseInt(w.getPropV1({key1: 'globProp', key2: 'dataRaster'}) || '3', 10) 
  const currentUserValue = w.getCurrentUserDataValue1({key1: 'datapoint', key2: 'timeValue'}, dataRaster)
  const icons = GetRatingRaster(iconset, stepcount)
  
  return <div className={`WUR-RRSB-Container rating-icon-type-${iconset}`}>
    {
      icons.map((i, index) => {
        const active = currentUserValue === (i?.value + '')
        const activeClass = active ? 'ri-active' : ''
        return <div
          key={`rating-item-${i?.value}-${index}`}
          className={`rating-item rating-item-${i?.value} ${activeClass}`}
          onClick={async () => {
            await w.setUserData({
              id: -1,
              key1: 'datapoint',
              key2: 'timeValue',
              value1: `${i?.value || 0}`,
              value2: '',
            }, dataRaster)
            props.rerender()
          }}
        >
          <div className={`rating-icon rating-icon-${i?.value}`}>
            {i?.key}
          </div>
          <div className='rating-item-label'>
            {GetValueString(w, i?.value || 0)}
          </div>
        </div>
      })
    }
  </div>
}

export function GetRatingRaster(iconsetkey: string, steps: string) {
  const ratingItem = RatingItems.find(ri => ri.key === steps) || RatingItems[3]
  const values = ratingItem.values
  const iconset = RatingIcons.find(ri => ri.key === iconsetkey) || RatingIcons[0]
  return values.map(v => iconset.icons.find(i => i.value === v))
}

function calcDisplayMode(minDate: Date, maxDate: Date) {
  const diff = (maxDate.getTime() - minDate.getTime()) / 86400000
  if (diff < 60) {
    return 'day'
  }
  if (diff < 365) {
    return 'month'
  }
  return 'year'
}

function DrawGraph(props: {widget: WidgetUserDTO}) {
  const w = props.widget
  const iconset = w.getPropV1({key1: 'globProp', key2: 'icons'})
  const stepcount = w.getPropV1({key1: 'globProp', key2: 'steps'})
  const showStats = w.getPropV1({key1: 'globProp', key2: 'showStats'})
  const icons = GetRatingRaster(iconset, stepcount)
  const rawData = w.getUserData({key1: 'datapoint', key2: 'timeValue'})
  if (rawData.length < 2 || showStats === 'never') { return null }
  const minDate = new Date(rawData[0].date || 0) // || new Date()
  const maxDate = new Date(rawData[rawData.length - 1].date || new Date())
  let intervalDivider = 86400000 // = one day
  const xTickDisplayMode = calcDisplayMode(minDate, maxDate)
  if (xTickDisplayMode === 'month' || xTickDisplayMode === 'year') {
    minDate.setMilliseconds(0)
    minDate.setSeconds(0)
    minDate.setMinutes(0)
    minDate.setHours(12)
    minDate.setDate(1)
    maxDate.setMilliseconds(0)
    maxDate.setSeconds(0)
    maxDate.setMinutes(0)
    maxDate.setHours(12)
    maxDate.setMonth(maxDate.getMonth() + 1)
    maxDate.setDate(1)
    maxDate.setDate(maxDate.getDate() - 1)
    
  }
  if (xTickDisplayMode === 'month') {
    intervalDivider = 86400000 * (365 / 6) // approx two months
  }
  if (xTickDisplayMode === 'year') {
    minDate.setMonth(1)
    intervalDivider = 86400000 * 365 // approx year
  }
  function date2Days (d: Date): number {
    return (d.getTime() - minDate.getTime()) / intervalDivider
  }
  function days2Date (d: number): Date {
    return new Date(d * intervalDivider + minDate.getTime())
  }
  
  function xTicFormatter (days: number): string {
    const d = days2Date(days)
    
    switch(xTickDisplayMode) {
      default:
      case 'day':
        return DisplayDate(d)
      case 'month':
        return DisplayMonthYear(d)
      case 'year':
        return DisplayYear(d)
    }
  }

  function yTicFormatter (item: number) {
    const icon = icons.find(i => i?.value === item)
    if (icon?.kind === RatingItemType.class) {
      // return <Rectangle className={`${icon.key}`}></Rectangle>
      return icon?.value + ''
    }
    return icon?.key || ''
  }
  const data = rawData.map(d => {
    return {
      x: date2Days(d.date || new Date()),
      y: parseInt(d.value1, 10)
    }
  })
  const tickCountCalc = () => {
    const l = Math.min(Math.ceil(data[data.length - 1]?.x || 0) + 1, 6)
    return l
  }
  // const xData = makeGoogTicks(rawData)
  const graph = <ScatterChart
    width={600}
    height={300}
    margin={{
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    }}
  >
    <CartesianGrid
      stroke="#ccc"
      // strokeDasharray="5 5"
      vertical={false}
    />
    <XAxis
      dataKey="x"
      label=""
      type='number'
      tickCount={tickCountCalc()}
      tickFormatter={xTicFormatter}
      tickMargin={13}
      axisLine={false}
      allowDecimals={false}
    />
    <YAxis
      dataKey="y"
      label={''}
      domain={[1, 9]}
      axisLine={false}
      type="number"
      tickFormatter={yTicFormatter}
      ticks={icons.map(i => i?.value || 0)}
      tickMargin={13}
    />
    <Scatter
      name=''
      data={data}
      fill='#fcea17'
      line={{strokeWidth: 4}}
    ></Scatter>
    <LabelList dataKey="x" />
    <Tooltip
      position={{x: 10, y: 10}}
      formatter={(value, name, props) => {
        switch(name) {
          case 'x':
            return DisplayDate(days2Date(value as number))
          case 'y':
            return yTicFormatter(value as number)
        }
        return '-'
      }}
    />
  </ScatterChart>
  switch (showStats) {
    case 'ifDatapointsAvailableShow':
      return <details open>
        <summary>Zeitverlauf</summary>
        {graph}
      </details>
    case 'ifDatapointsAvailableHide':
      return <details>
        <summary>Zeitverlauf</summary>
        {graph}
      </details>
    default:
      return graph
  }
  
}
/*
function makeGoogTicks(data: UserData[]): number[] {
  if (data.length <= 2) {
    return data.map(i => )
  }
  const first = 
}
*/
const RatingItems = [
  {
    key: '2',
    values: [1, 9]
  },
  {
    key: '3',
    values: [1, 5, 9]
  },
  {
    key: '5',
    values: [1, 3, 5, 7, 9]
  },
  {
    key: '6',
    values: [-3, -2, -1, 1, 2, 3]
  },
  {
    key: '9',
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9]
  },
]

const RatingIcons = [
  {
    key: 'smilies',
    icons: [
      {
        value: 1,
        kind: RatingItemType.emoji,
        key: '😥'
      },
      {
        value: 2,
        kind: RatingItemType.emoji,
        key: '😒'
      },
      {
        value: 3,
        kind: RatingItemType.emoji,
        key: '😟'
      },
      {
        value: 4,
        kind: RatingItemType.emoji,
        key: '🙁'
      },
      {
        value: 5,
        kind: RatingItemType.emoji,
        key: '😐'
      },
      {
        value: 6,
        kind: RatingItemType.emoji,
        key: '🙂'
      },
      {
        value: 7,
        kind: RatingItemType.emoji,
        key: '😄'
      },
      {
        value: 8,
        kind: RatingItemType.emoji,
        key: '😃'
      },
      {
        value: 9,
        kind: RatingItemType.emoji,
        key: '😀'
      },
    ]
  },
  {
    key: '6',
    icons: [
      {
        value: -3,
        kind: RatingItemType.emoji,
        key: '-3'
      },
      {
        value: -2,
        kind: RatingItemType.emoji,
        key: '-2'
      },
      {
        value: -1,
        kind: RatingItemType.emoji,
        key: '-1'
      },
      {
        value: 1,
        kind: RatingItemType.emoji,
        key: '+1'
      },
      {
        value: 2,
        kind: RatingItemType.emoji,
        key: '+2'
      },
      {
        value: 3,
        kind: RatingItemType.emoji,
        key: '+3'
      },
    ]
  },
  {
    key: '2022-12-emoji',
    icons: [
      {
        value: 1,
        kind: RatingItemType.class,
        key: ''
      },
      {
        value: 2,
        kind: RatingItemType.class,
        key: ''
      },
      {
        value: 3,
        kind: RatingItemType.class,
        key: ''
      },
      {
        value: 4,
        kind: RatingItemType.class,
        key: ''
      },
      {
        value: 5,
        kind: RatingItemType.class,
        key: ''
      },
      {
        value: 6,
        kind: RatingItemType.class,
        key: ''
      },
      {
        value: 7,
        kind: RatingItemType.class,
        key: ''
      },
      {
        value: 8,
        kind: RatingItemType.class,
        key: ''
      },
      {
        value: 9,
        kind: RatingItemType.class,
        key: ''
      },
    ]
  },
]