// import {DObj} from './ObjGeneric'

export type DObj = {
  id: number,
  locked: number,
  xStart: number,
  xEnd: number,
  yStart: number,
  yEnd: number,
  bg: string,
  type: string,
  content: string,
  fontSize?: number
}

export function importFromRaw(raw: string) {
  const parts = raw.split(/<svg[^>]+>|<\/ellipse>|<\/rect>|<\/text>/)
  console.log('PARTS', parts)
  return parts.map((p, index) => {
    return importFromRawObject(p, index)
  }).filter((o) => o.id != -1)
}

function scopeExtractor(raw: string, query: any, index?: number) {
  if (raw.search(query) > -1) {
    const res = raw.match(query)
    if (res) {
      return res[index || 1]
    }
  }
  return ''
}

export function importFromRawObject(raw: string, index: number) {
  const objType = scopeExtractor(raw,/<([^ >]+)/)
  if (objType.search(/rect|ellipse|text/) === -1) {
    index = -1
  }
  const content = scopeExtractor(raw, />(.*)/)
  console.log('--CONTENT', content)
  let out = {
    id: index,
    locked: 0,
    xStart: 0,
    xEnd: 0,
    yStart: 0,
    yEnd: 0,
    bg: scopeExtractor(raw, / fill=\"([^\"]+)\"/),
    type: objType,
    content: content.replace(/<tspan[^>]+>/g, '').replace(/<\/tspan>/g, '\n') || '',
    fontSize: 12
  }

  const x = parseFloat(scopeExtractor(raw, / x=\"([\.0-9]+)\"/))
  const cx = parseFloat(scopeExtractor(raw, / cx=\"([\.0-9]+)\"/))
  const rx = parseFloat(scopeExtractor(raw, / rx=\"([\.0-9]+)\"/))
  const width = parseFloat(scopeExtractor(raw, / width=\"([\.0-9]+)\"/))
  const y = parseFloat(scopeExtractor(raw, / y=\"([\.0-9]+)\"/))
  const cy = parseFloat(scopeExtractor(raw, / cy=\"([\.0-9]+)\"/))
  const ry = parseFloat(scopeExtractor(raw, / ry=\"([\.0-9]+)\"/))
  const height = parseFloat(scopeExtractor(raw, / height=\"([\.0-9]+)\"/))
  const fontSize = parseFloat(scopeExtractor(raw, / font-size=\"([\.0-9]+)\"/))


  if (objType === 'ellipse') {
    out.xStart = cx - rx
    out.yStart = cy - ry
    out.xEnd = cx + rx
    out.yEnd = cy + ry
  } else {
    out.xStart = x
    out.yStart = y
    out.xEnd = width + x
    out.yEnd = height + y
  }
  if (objType === 'text') {
    out.fontSize = fontSize || 12
  }

  console.log('from ', raw, ' to ', out)
  return out
}

export function moveIdToEndPosition(objs: DObj[], id: number | null, front?: boolean) {
  if (id === null) {
    return objs
  }
  const o = objs.find((ob) => ob.id == id)
  objs = objs.filter((ob) => ob.id != id)
  if (!o) {
    return objs
  }
  if (front) {
    return [o].concat(objs)
  }
  return objs.concat([o])
}
