import config from '../config.json'
import { Getter, Setter } from '../services/ComService'
import StaticSiteParagraphDTO, { IStaticSiteParagraph } from './StaticSiteParagraphDTO'

export default class StaticSiteDTO {
  id: number
  url: string
  public: number
  name: string
  date: Date
  paragraphs: StaticSiteParagraphDTO[]

  constructor(data: IStaticSite) {
    this.id = data.id
    this.url = data.url || ''
    this.public = data.public || 0
    this.name = data.name || ''
    this.date = (data.date) ? new Date(data.date) : new Date()
    this.paragraphs = (data.paragraphs) ? data.paragraphs.sort((a, b) => a.sortNumber - b.sortNumber).map(p => new StaticSiteParagraphDTO(p)) : []
  }

  setUrl(url: string) {
    this.url = url
  }

  setName(name: string) {
    this.name = name
  }

  get() {}

  public getParagraphs(): StaticSiteParagraphDTO[] {
    return this.paragraphs || []
  }

  async saveToServer() {
    console.log('CUrrent State:', this)
    if (this.id === -1) {
      await Setter('staticsite/new', {
        name: this.name,
        url: this.url,
        public: this.public,
      }, {retryName: `${this.name},${this.url}`})
    } else {
      await Setter('staticsite/edit', {
        id: this.id,
        name: this.name,
        url: this.url,
        public: this.public,
      }, {retryName: `${this.id}`})
    }
  }

  async remove() {
    await Setter('staticsite/remove', {
      id: this.id
    }, {retryName: `${this.id}`})
  }

  async newParagraph(props: {position: number, type: string}) {
    await Setter('staticsite/paragraph/new', {
      id: this.id,
      position: props.position,
      type: props.type
    }, {retryName: `${this.id}`})
  }
}

export interface IStaticSite {
  id: number
  url?: string
  public?: number
  name?: string
  date?: Date
  paragraphs?: IStaticSiteParagraph[]
}
