import React from 'react'
// enable drag and drop:
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Wifi } from 'react-feather'
// import ReactDOM from 'react-dom'
import { Getter } from './services/ComService'
import mainservice from './services/MainService'
// import { NavState } from './services/NavService'
// import Booklist from './components/Booklist/Booklist'
import Book from './components/Book/Book'
// import SetPasswordByToken from './components/User/SetPasswordByToken'
import { IBook } from './DTO/BookDTO'
// import CollectibleDTO from './DTO/CollectibleDTO'
import { IRight } from './DTO/RightDTO'
// import CakeA from './components/CakeA/CakeA'
import User from './components/User/User'
import UserDashboard from './components/User/UserDashboard'
// import Login from './components/User/Login'
// import Spinner from './components/Spinner/Spinner'
// import CoachA from './components/CoachA/CoachA'
// import CoachALegacy from './components/CoachA/CoachALegacy'
// import BookSurveyA from './components/BookSurveyA/BookSurveyA'
// import TextGeneratorTest from './components/tests/TextGeneratorTest'
// import FileUploader from './components/MediaManager/FileUploader'
import FileList, { FileListMode } from './components/MediaManager/FileList'
// import BulkGenerateUsers from './components/User/BulkGenerateUsers'
// import FallbackPage from './components/FallbackPage/FallbackPage'
// import UnitList from './components/Unit/UnitList'
// import UserRegister from './components/User/UserRegister'
// import UserEndorse from './components/User/UserEndorse'
// import CollectibleListA from './components/Collectible/CollectibleListA'
// import CollectibleSingle from './components/Collectible/CollectibleSingle'
import SvgEditor from './components/svg/SvgEditor'
// import CollectibleEditor from './components/Collectible/CollectibleEditor'
// import { IconDemo } from './components/Icon/Icon'
// import StartPage from './components/staticPages/startPage'
// import StaticPageAdmin from './components/staticPages/StaticPageAdmin'
// import Cart from './components/shop/cart'
// import Carts from './components/shop/carts'
// import UserCarts from './components/shop/UserCarts'
// import Preferences from './components/Admin/Preferences'

import config from './config.json'
// import WidgetsAdmin from './components/widget/widgetsAdmin'
import { PrintPdf } from './components/MediaManager/PrintPdf'
import TagOrganizer from './planningTool/views/tags/TagOrganizer'
import EventList from './planningTool/views/Event/EventList'
import EventEdit from './planningTool/views/Event/EventEdit'
import CustomerEdit from './planningTool/views/Customer/CustomerEdit'
import CustomerList from './planningTool/views/Customer/CustomerList'
import CustomerLocationList from './planningTool/views/CustomerLocation/CustomerLocationList'
import PersonList from './planningTool/views/Person/PersonList'
import LocationList from './planningTool/views/Location/LocationList'
import LocationEdit from './planningTool/views/Location/LocationEdit'
import PersonEdit from './planningTool/views/Person/PersonEdit'
import { ObjectKind } from './planningTool/models/objectKinds'
import { TextAreaArraySummary } from './planningTool/views/Event/TextAreaArraySummary'
// import UserList from './components/User/UserList'
import ClientsList from './components/Clients/ClientsList'
import CoreDashboard from './components/User/CoreDashboard'
import BuildingBlockEdit from './planningTool/views/Event/BuildingBlockEdit'
import { PublishKind } from './planningTool/models/publishKinds'

type Props = {

}

type State = {
  view: string
  fileListOverlay: null | {broadcastName: string}
  noConnection: boolean
}

class Main extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    // console.log('+++ Main starting')
    if (window.location.href.search('fuehrungsstilanalyse') > -1) {
      document.title = 'Führungsstilanalyse'
    }
    this.state = {
      view: 'loading',
      fileListOverlay: null,
      noConnection: false
    }
  }

  componentDidMount() {
    /*
    // Wait for navigation until we got the book list:
    Getter('books').then((r: {
      books: IBook[],
      rights: IRight[],
      staticUrls: string[]
    }) => {
      mainservice.nav.init(r.books, r.staticUrls)
      mainservice.setNavToFunction (() => {
        this.nav()
      })
      // mainservice.setNavToFunction (this.nav)
      mainservice.nav.getRoute()
      this.setState({
        view: this.checkForRedirect()
      })
    }).catch(() => {
      this.setState({noConnection: true, view: 'noConnection'})
      // alert('Verbindung fehlgeschlagen. Probiere es später noch einmal.')
    })
    //*/

    mainservice.nav.init([], [])
    mainservice.setNavToFunction (() => {
      this.nav()
    })
    mainservice.nav.getRoute()
    this.setState({
      view: this.checkForRedirect()
    })
    mainservice.registerToBroadcast('main', (key: string, value: any) => {
      switch(key) {
        case 'reloadMain':
          // console.log('reloadMain')
          this.setState({
            view: this.checkForRedirect()
          })
          break
        case 'fileListOpen':
          this.setState({fileListOverlay: {broadcastName: value.broadcastName}})
          break
        case 'fileListClose':
          this.setState({fileListOverlay: null})
          break
        case 'fileListSelect':
          this.setState({fileListOverlay: null})
          break
        case 'loginsuccess':
          this.forceUpdate()
          break
        case 'noConnection':
          this.setState({noConnection: value})
      }
    }, this)
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('main')
  }

  nav() {
    this.setState({
      view: this.checkForRedirect()
    })
  }

  checkForRedirect(): string {
    let permalink = mainservice.nav.getVal('permalink') as string || ''
    if (permalink) {
      const pageId = permalink.match(/page-([0-9]+)/)
      const doublePageId = permalink.match(/doublePage-([0-9]+)/)
      const pageElementId = permalink.match(/pageElement-([0-9]+)/)
      let refUrl = ''
      if (pageId && pageId[1]) {
        refUrl = `${config.apiPrefix}Page/${pageId[1]}/getRefs`
      } else if (doublePageId && doublePageId[1]) {
        refUrl = `${config.apiPrefix}DoublePage/${doublePageId[1]}/getRefs`
      } else if (pageElementId && pageElementId[1]) {
        refUrl = `${config.apiPrefix}PageElement/${pageElementId[1]}/getRefs`
      }
      if (refUrl) {
        fetch(refUrl).then((response) => {
          response.json().then((responseJson) => {
            mainservice.nav.setRoute([
              ['view', 'book'],
              ['book', responseJson.bookId],
              ['doublePageId', responseJson.doublePageId],
            ])
            this.nav()
          })
        })
      }
    }
    return mainservice.nav.getVal('view') as string
  }

  selectBook(id: number) {
    mainservice.nav.setRoute([
      ['view', 'book'],
      ['book', id],
      ['page', 1]
    ])
    this.nav()
  }

  goHome() {
    mainservice.nav.setRoute([
      ['view', 'home'],
    ])
    this.nav()
  }

  renderDatenschutz() {
    return <>
    <div className='w3-padding'>
      <a href='/view=login'><button>Zurück zur Loginseite</button></a>
      <h1>Datenschutz</h1>
      <p>Beim Klick auf "Einloggen" stimmen Sie der Datenverarbeitung zu.</p>
      <p>
        Wir erheben ausschließlich Daten die der Verwaltung und Funktion der Lerninhalte dienen.<br />
        Hierzu zählen:
        <ul>
          <li>Login Credentials</li>
          <li>Welche Lerninhalte Ihnen zugeteilt sind</li>
          <li>Den Fortschritt der Bearbeitung</li>
          <li>Die Ergebnisse von Lernspielen und Interaktionen.</li>
        </ul>
      </p>
      <p>
        Wir nutzen die Daten wie folgt:
        <ul>
          <li>
            Auf Wunsch können bestimmte Daten mit einem Coach analysiert werden.<br />
            Dem Coach muss zu diesem Zweck die Session ID der Arbeitssitzung mitgeteilt werden. Der Coach kann dann ausschließlich die Daten dieser Sitzung einsehen.
          </li>
          <li>Wir fassen alle eingegebenen Daten zu einer interne anonymen Statistik zu Verbesserung und Analyse zusammen.</li>
          <li>Eingegebene Texte - z.B. Kommentare zu Lerninhalten - werden von uns ohne Personenbezug analysiert.</li>
          <li>Wir geben Ihre Daten nicht an dritte weiter!</li>
        </ul>
      </p>
      Wir setzen beim Login ein technisches Cookie, welches ausschließlich für die Loginfunktion benötigt wird.<br />

    </div>
    <footer className='loginFooter'>
      <a href='https://lubbers.de/impressum' target='_blank'>Impressum</a>&nbsp;
    </footer>
    </>
  }

  render() {
    return <DndProvider backend={HTML5Backend}>
      { this.state.fileListOverlay &&
        <FileList
          broadcastName={this.state.fileListOverlay.broadcastName}
          mode={FileListMode.selectPicture}
        />
      }
      { this.renderContent() }
      { this.state.noConnection &&
        <Wifi className='noConnection' />
      }
    </DndProvider>
  }

  renderContent() {
    const view = this.state.view
    const privateViews = ['library', 'dashboard', 'shop', 'collectibleFlashcard', 'collectibleMotto', 'userList', 'UserPreferences']
    // TODO: In case of private view go to login page if we are not logged in!
    // console.log('LOGINSERVICE:', view, privateViews.some((pV) => pV === view), mainservice.loginService.instantIsLoggedIn())
    if (privateViews.some((pV) => pV === view) && mainservice.loginService.instantIsLoggedIn() === false) {
      return <User />
    }
    switch(view) {
      case 'noConnection':
        return <div className='warning-box'>🔌😞 Keine Verbindung zum Server.</div>
      case 'accountdeleted':
        return <AccountDeleted />
      case 'svgeditor':
        return <SvgEditor />
      case 'library':
      case 'shop':
      case 'collectibleFlashcard':
      case 'collectiblePoster':
      case 'collectibleMotto':
      case 'UserPreferences':
      case 'static':
        return <UserDashboard />
      case 'login':
      case 'dashboard':
      case 'adminlogin':
        return <CoreDashboard />
      case 'logout':
        {
          mainservice.quitAdmin()
        }
        return <>Logged out!</>
      case 'book':
        return <>
          <Book selectFkt={(command: string) => {
            if (command === 'goHome') {
              this.goHome()
            }
          }}/>
        </>
      case 'mediaprintdemo':
        return <PrintPdf />
      case 'spBausteine':
        return <BuildingBlockEdit />
      case 'spVeranstaltungsDesigner':
        // return <EventDesignView />
        return <EventEdit isTemplate />
      case 'spVeranstaltungsDesigns':
        return <EventList key='EventList-seminar' standalone objectKind={ObjectKind.seminar} publishKind={PublishKind.isTemplate} />
      case 'spVeranstaltungsPlaner':
        return <EventList key='EventList-event' standalone objectKind={ObjectKind.event} publishKind={PublishKind.isSeminar} minDateFilter={true} leads='noleads' />
      case 'spVeranstaltungsLeads':
        return <EventList key='EventList-leads' standalone objectKind={ObjectKind.event} publishKind={PublishKind.isSeminar} minDateFilter={true} leads='leads'/>
      case 'spVeranstaltungsDetails':
        return <EventEdit />
      case 'textAreaArray':
        return <TextAreaArraySummary
          standalone
          appointment={''}
        />
      case 'spCustomerEdit':
        return <CustomerEdit />
      case 'spTagverwaltung':
        return <TagOrganizer />
      case 'spPapierkorb':
        return <EventList 
        key='Eventlist-trash-event'
        standalone objectKind={ObjectKind.event}
        publishKind={PublishKind.isSeminar}
        headline={`Papierkorb`}
        mode='trash'
        ignoreDate='ignore'
      />
      case 'spPapierkorbDesigns':
        return <EventList key='Eventlist-trash-seminar' standalone objectKind={ObjectKind.seminar} publishKind={PublishKind.isTemplate} headline={`Papierkorb`} mode='trash' />
      case 'spCustomers':
        return <CustomerList standalone />
      case 'spCustomerLocations':
        return <CustomerLocationList standalone />
      case 'spLocations':
        return <LocationList standalone />
        case 'spLocationEdit':
          return <LocationEdit />
      case 'spPersons':
        return <PersonList standalone />
      case 'spPersonEdit':
        return <PersonEdit />
      case 'clients':
        return <ClientsList />
      default:
        return <CoreDashboard />
    }

  }
}

function AccountDeleted() {
  return <div className='AccountDeleted notice-box'>
    <p>Dein Account wurde erfolgreich gelöscht!</p>
    <p><a href="/"><button className='primary-button'>Zurück zur Startseite</button></a></p>
  </div>
}

export default Main
