import mainservice from "../../services/MainService"
import { PersonKind } from "../views/Person/PersonKind"
import { RightDrawerContent } from "../views/RightDrawer"

export default function BroadcastRightButton(props: {
  contentType: RightDrawerContent,
  broadCastBackKey: string,
  title: string,
  personKind?: PersonKind,
  validateBeforeClick?: () => boolean,
}) {
  return <div className='padding-box'>
    <button
      className='positiveButton'
      onClick={() => {
        if (props.validateBeforeClick) {
          if (!props.validateBeforeClick()) {
            return
          }
        }
        mainservice.broadcast('rightDrawerOpen', {
          contentType: props.contentType,
          broadCastBackKey: props.broadCastBackKey,
          personKind: props.personKind,
        })
      }}
    >
      {props.title}
    </button>
  </div>
}
