import { Emoji2PlainText, Emoji2Unicode } from "./EmojiServices"

export const replacements: [any, string, string?][] = [
  [/\?/g, 'QUESTIONMARK', '?'],
  [/!/g, 'EXPLANATIONMARK', '!'],
  [/;/g, 'SEMICOLONMARK', ';'],
  [/:/g, 'DOUBLEPOINTMARK', ':'],
  [/%/g, 'PERCENTMARK', '%'],
  [/\//g, 'SLASHMARK', '/'],
  [/"/g, 'DOUBLEQUOTEMARK', '"'],
  [/-/g, 'MINUSMARK', '-'],
  [/\+/g, 'PLUSMARK', '+'],
  [/\$/g, 'DOLLARMARK', '$'],
  [/§/g, 'PARAGRAPHMARK', '§'],
  [/=/g, 'EQUALMARK', '='],
  [/\(/g, 'PARANTHESISLEFTMARK', '('],
  [/\)/g, 'PARANTHESISRIGHTMARK', ')'],
  [/#/g, 'HASHMARK', '#'],
  [/\n/g, '__NEWLINE__', '\n'],
]

export const parseStringToServer = (v: string) => {
  let result = (Emoji2PlainText(v) || '__EMPTY__').replace(/\n/g, ' ')
  if (result) {
    console.log('parseStringToServer', result)
  }
  replacements.forEach((r) => {
    result = result.replace(r[0], r[1])
  })
  return result
}

export const parseStringFromServer = (v: string) => {
  let result = (Emoji2Unicode(v) || '').replace(/__EMPTY__/g, '').replace(/\n/g, ' ').replace(/\\n/g, ' ')
  replacements.forEach((r) => {
    result = result.replace(new RegExp(r[1], 'g'), r[2] || r[0])
  })
  return result
}

export function prittifyStringFromServer(v: string) {
  v = parseStringFromServer(v)
  let list = v.split('</p>')
  v = list.map(i => i.replace(/<br \/>/g, '').replace(/<br \/><br \/>/g, '<br />')).join('</p>')
  return v
}

export const html2Text = (text: string): string => {
  return text.replace(/<[^>]*>/g, ' ');
}

export const extractFirstHeadline = (t: string): string => {
  const headlineScope = /<h[123456789][^>]*>(.*)<\/h[123456789]>/
  const result = t.match(headlineScope)
  if (result && result[1]) {
    return html2Text(result[1])
  }
  return html2Text(t.split(/(\n|<br \/>|<\/p>)/)[0])
}
