import { useEffect, useState } from "react"
import { GenericDTO } from "../../DTO/GenericDTO"
import config from '../../../config.json'
import './FileManager.scss'
import PropDTO from "../../DTO/PropDTO"
import { Edit, File as FileFeather, Repeat, SkipBack, Trash } from "react-feather"
import { regenerateThumb } from "../../../DTO/FileDTO"
import { Status } from "../day/dayView"
import InputB from "../../../components/InputB/InputB"
import Spinner from "../../../components/Spinner/Spinner"

export const PT_FILETYPE = 3
export default function FileManager(props: {
  item: GenericDTO,
  readMode: boolean,
  className?: string,
  showBiggerPreview?: boolean,
  fileKindKey?: string,
  title?: string,
}) {
  const [item, setItem] = useState(props.item)
  const [rerender, setRerender] = useState(0)
  const [files, setFiles] = useState(item.getFiles(props.fileKindKey))
  const [fileKindKey, setFileKindKey] = useState(props.fileKindKey || 'defaultFile')
  const [status, setStatus] = useState(Status.loading)
  const title = props.title || 'Dateien'
  useEffect(() => {
    setFileKindKey(props.fileKindKey || 'defaultFile')
    setFiles(item.getFiles(props.fileKindKey))
    setRerender(rerender + 1)
    setStatus(Status.standard)
  }, [props.fileKindKey])
  useEffect(() => {
    setItem(props.item)
    setFiles(item.getFiles(props.fileKindKey))
    setRerender(rerender + 1)
    setStatus(Status.standard)
  }, [props.item.id])
  console.log('FileManagerItem', item)
  if (status === Status.loading) {
    return <div><Spinner mini /></div>
  }
  if (props.readMode) {
    // return null
    
    const currentFileIdSingle = item.getCurrentFileId()
    const fileProp = item.getPropById(currentFileIdSingle)
    return <>
      {
        fileProp ?
        <File
          key={`single-file-view-${fileProp?.id || -1}`}
          showBiggerPreview={props.showBiggerPreview}
          className={props.className}
          prop={fileProp}
        /> : null
      }
    </>
    
  }

  const currentFileId = item.getCurrentFileId()
  return <div>
    <h2>{title}</h2>
    {
      files.map((f, index) => {
        return <File
            key={`${index}-file-${f.id}`}
            prop={f}
            isStandard={f.id === currentFileId}
            showDelete={item.propCanBeEdited(f.id)}
            even={index % 2 === 0}
            delete={async () => {
              setStatus(Status.loading)
              await item.removeProp(f.id)
              setFiles(files.filter(fi => fi.id !== f.id))
              setStatus(Status.standard)
              // setRerender(rerender + 1)
            }}
            activate={async () => {
              await f.activateFile()
              if (item.localPropId('filesInfo', fileKindKey) === -1 && item.getUpProp('filesInfo', fileKindKey) && !window.confirm('Standard vom Original überschreiben?')) {
                return
              }
              await item.setCurrentFile(f.id)
              setRerender(rerender + 1)
            }}
            parent={item}
            showBiggerPreview={props.showBiggerPreview}
            resetActivation={async () => {
              await item.removePropByKeys('filesInfo', fileKindKey)
              setRerender(rerender + 1)
            }}
          />
      })
    }
    <FileUpload
      setNewFileInfo={async (file) => {
        const answer = await item.addProp(fileKindKey, 'raw', files.length === 0 ? 'active': '', '', false, file)
        await item.addProp('filesInfo', 'defaultFile', `${answer.id}`)
        setFiles(item.getFiles(fileKindKey))
        setRerender(rerender + 1)
      }}
    />
  </div>
}

export function File(props: {
  prop: PropDTO
  delete?: () => void,
  showDelete?: boolean,
  even?: boolean,
  activate?: () => void,
  isStandard?: boolean,
  className?: string,
  parent?: GenericDTO,
  showBiggerPreview?: boolean,
  resetActivation?: () => void,
  noTitle?: boolean,
}) {
  const [prop, setProp] = useState(props.prop)
  const thumbnail = prop.File?.getThumbURL()
  const origFile = prop.File?.getURL()
  const [reload, setReload] = useState(0)
  const [status, setStatus] = useState(Status.standard)
  console.log('FileManagerFile currentFileProp', prop)
  useEffect(() => {
    setProp(props.prop)
    console.log('FileManagerFile currentFileProp2', props.prop)
  }, [props.prop])
  return <><div
      className={`${props.className || 'flex flex-middle'} ${!!props.even ? 'w3-light-gray' : ''}`}
      title={`${prop.File?.id || '-1'}`}
    >
    <div className={props.showDelete ? 'stfmthumb flex-middle' : 'stfmthumbPreview'}>
      {
        thumbnail !== '' &&
        <div className="w3-dropdown-hover">
          <a href={origFile} target="_blank" rel="noreferrer">
            <img className='inlinePreview' alt='Vorschau' src={thumbnail} loading="lazy" />
          </a>
          {
            !(props.showBiggerPreview === false) &&
            <div className="w3-dropdown-content w3-card">
              <img className='biggertPreview' alt='Vorschau' src={thumbnail} loading="lazy" />
            </div>
          }
        </div> 
      }
      {
        thumbnail === '' &&
        <FileFeather />
      }
      <Repeat
        className={`regenerateThumb`}
        onClick={async () => {
          await prop.File?.regenerateThumb()
          setReload(reload + 1)
        }}
      />
    </div>
    <div className='designation filename flex-grow' style={{minHeight: '16px'}}>
      {props.parent?.getPropV1(`ref-${prop.id}`, 'designation') || prop.file?.displayName || ''}
    </div>
    {
      props.parent &&
      <button
        className={`modestButton`}
        onClick={() => {
          setStatus(status === Status.standard ? Status.edit : Status.standard)
        }}
        title={`Edit`}
      ><Edit /></button>
    }
    {
      props.activate &&
      <button
        className={`modestButton ${props.isStandard ? 'w3-green' : ''}`}
        onClick={() => {
          if (props.activate) {
            props.activate()
          }
        }}
        title={`Standard`}
      >S</button>
    }
    {
      props.resetActivation && props.parent && props.parent.localPropId('filesInfo', 'defaultFile') > -1 &&
      <button
        className={`modestButton `}
        onClick={() => {
          if (props.resetActivation) {
            props.resetActivation()
          }
        }}
        title={`Standard`}
      ><SkipBack /></button>
    }
    {
      props.showDelete &&
      <button
        className={`modestButton`}
        onClick={() => {
          if (props.delete && window.confirm(`Datei ${prop.File?.displayName} wirklich löschen?`)) {
            props.delete()
          }
        }}
      >
        <Trash />
      </button>
    }
    </div>
    {
      props.parent && status === Status.edit &&
      <table className='w3-card w100'>
        <tr>
          <td>Bezeichnung:</td><td>
            <InputB
              returnVal={(newValue: string) => {
                props.parent?.addProp(`ref-${prop.id}`, 'designation', newValue)
              }}
              value={
                props.parent?.getPropV1(`ref-${prop.id}`, 'designation') || ''
              }
            />
          </td>
        </tr>
        <tr>
          <td>Beschreibung:</td><td>
          <InputB
              returnVal={(newValue: string) => {
                props.parent?.addProp(`ref-${prop.id}`, 'description', newValue)
              }}
              value={
                props.parent?.getPropV1(`ref-${prop.id}`, 'description') || ''
              }
              type='textarea'
            />
          </td>
        </tr>
      </table>
    }
    </>
}

export function FileUpload(props: {
  setNewFileInfo: (id: number) => void
}) {
  // const [file, setFile] = useState(File)
  function changeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (!(event.target?.files)) { return }
    const f = event.target.files[0]
    if (!f) { return }
    // setFile(event.target.files[0])
    const formData = new FormData();
    formData.append('FileName', f);
    formData.append('kind', `${PT_FILETYPE}`);
    const target = `${config.apiPrefix}File/upload`
    if (f.size > 5000000 && !window.confirm('Diese Datei ist sehr groß (über 5 MB). Dies kann zu Fehlern führen. Trotzdem versuchen fortzufahren?')) {
      return
    }
    try {
      fetch(target, {
          method: 'POST',
          body: formData,
        }
      ).then((response) => {
        if (!response) { console.log('err!!!!') }
        return response.json()
      }).then(async (result: {id: number, sizeInfo: number, status: string, details?: string}) => {
        if (result.status === 'failed') {
          console.log('FAILED Upload', result.details)
          const err = 'Upload hat nicht funktioniert und endete mit dieser Fehlermeldung: ' + result.details
          alert(err)
          throw(err)
        }
        console.log('Success:', result);
        try {
          await regenerateThumb(result.id)
        } catch(error) {
          console.log('failed to generate new Thumb')
        }
        console.log('new file info', result)
        props.setNewFileInfo(result.id)
      })
      .catch((error) => {
        console.error('Errör:', error);
        throw(error)
      })
    } catch(err: any) {
      console.log('did not work', err)
      alert('Fehler beim Upload!')
    }
  }
  return <div>
    <label className='file-upload-button w3-button'>
      <input className='file-upload-input' type="file" name="file" onChange={changeHandler} />
      Weitere Datei hochladen
    </label>
  </div>
}

export type SuccessItem = {
  id: number,
  success: boolean,
  name: string,
}

export function FilesUpload(props: {
  setNewFileInfos: (items: SuccessItem[], failed: SuccessItem[]) => void,
  saveSingleFile: (item: SuccessItem) => Promise <void>,
}) {
  const [status, setStatus] = useState(Status.standard)
  const [protocol, setProtocol] = useState([''])
  // const [file, setFile] = useState(File)
  async function changeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setStatus(Status.loading)
    let protocolList = ['Lade Hoch']
    let successArr: SuccessItem[] = []
    setProtocol(protocolList)
    if (!(event.target?.files)) { return }
    const files = event.target.files
    if (!files || files.length === 0) { return }
    console.log('FILES', files)
    const allCount = files.length
    for (let i = 0, m = allCount; i < m; i++) {
      let f = files[i]
      const formData = new FormData();
      formData.append('FileName', f);
      formData.append('kind', `${PT_FILETYPE}`);
      const target = `${config.apiPrefix}File/upload`
      let position = `${i+1}/${allCount}`
      let name = f.name
      try {
        setProtocol([...protocolList, `↻ ${position}: ${name} - Lade hoch`])
        let response = await fetch(target, {
            method: 'POST',
            body: formData,
          }
        )
        let result: {id: number, sizeInfo: number, status: string} = await response.json()
        setProtocol([...protocolList, `↻ ${position}: ${name} - Lade Thumb`])
        try {
          await regenerateThumb(result.id)
          protocolList.push(`🍏 ${position}: ${name} - Fertig `)
        } catch(err: any) {
          protocolList.push(`⭕ ${position}: ${name} - Thumb fehlt`)
        }
        let item = {id: result.id, success: true, name: name || ''}
        await props.saveSingleFile(item)
        successArr.push(item)
        setProtocol(protocolList)
      } catch(err: any) {
        console.log('did not work', err)
        successArr.push({id: -1, success: false, name: name || ''})
        protocolList.push(`🔴 ${position}: ${name} - Fehler `)
        setProtocol(protocolList)
        if (window.confirm('Fehler beim Upload. Abbrechen?')) { return }
      }
    }
    props.setNewFileInfos(successArr.filter(sai => sai.success), successArr.filter(sai => !sai.success))
    setStatus(Status.standard)
  }
  return <div>
    {
      status === Status.standard &&
      <label className='file-upload-button w3-button'>
        <input className='file-upload-input' type="file" name="file" onChange={changeHandler} multiple />
        Dateien hochladen
      </label>
    }
    <div className='w3-padding'>{protocol.map((entry, index) => <div key={`upload-${index}`} className='PTListItem textLeft w3-padding'>{entry}</div>)}</div>
  </div>
}