import { Setter } from "../../services/ComService";
import { ObjectKind } from "../models/objectKinds";
import { PublishKind } from "../models/publishKinds";
import { IMaterialItem, MaterialItemDTO } from "./MaterialItemDTO";

export class GenericMaterialItemDTO extends MaterialItemDTO {
  constructor(o: IMaterialItem) {
    super(o)
    this.objectKind = ObjectKind.genericMaterialItem
  }

  // TODO: Other type possible
  public async addParentId(parentId: number) {
    const url = 'spt/setChild'
    this.parentId = parentId
    const data = await Setter(url, {
      id: parentId,
      childId: this.id
    })
    this.parentId = data.item.id
  }

  public async addUp(up: MaterialItemDTO) {
    const url = 'spt/setUp'
    let upId = up.id
    if (upId === -1) {
      const newUp = new MaterialItemDTO({...up, ...{
        publishKind: PublishKind.isSeminar
      }})
      await newUp.saveToServer()
      upId = newUp.id
    }
    this.upId = parseInt(up.id + '', 10)
    const id = this.id
    const data = await Setter(url, {
      id: id,
      jd: upId
    })
    this.up.push({
      id: this.upId,
      name: up.name
    })
  }
}

export interface IGenericMaterialItem extends IMaterialItem {

}