import { useEffect, useState } from "react";
import { ArrowDownCircle, ArrowUpCircle, CheckSquare, Square, Trash } from "react-feather";
import PageElementDTO from "../../DTO/PageElementDTO";
import WidgetDTO, { Prop, RatingvalueToIcon, RatingvalueToName, Unique, UserData } from "../../DTO/WidgetDTO";
import WidgetUserDTO from "../../DTO/WidgetUserDTO";
import { GetRatingRaster } from "./WidgetUserRating";
import { useDrag, useDrop } from 'react-dnd'
import './WidgetList.scss'
import InputA from "../InputA/InputA";
import mainservice from "../../services/MainService";
import ValueHolder from "../../services/ValueHolder";
import { parseStringFromServer } from '../../services/TextConverter'

type Column = {
  display: string;
  kind: string;
  description: string;
  legendEmoji?: string;
  sortNumber: string;
  userValue: string;
  userValueInt: number;
  key1: string;
  key2: string;
}

type Entry = {
  id: number;
  key1: string;
  key2: string;
  value1: string;
  value2: string;
}

type EntryExtended = {
  id: number;
  key1: string;
  key2: string;
  value1: string;
  value2: string;
  columns: Column[];
}

enum Status {
  standard,
  loading,
  sorting,
}

export default function WidgetUserRating(props: {
  widget: WidgetUserDTO,
  pageElement?: PageElementDTO,
}) {
  const w = props.widget
  const [rerender, setRerender] = useState(0)
  const [editThis, setEditThis] = useState(-2)
  function doRerender() {
    setRerender(rerender + 1)
  }
  // Listen, if we want to edit a single item:
  const closeOverlayName = w.getPropV1({key1: 'globProp', key2: 'closeOverlayName'})
  if (closeOverlayName) {
    const ownKey = 'widgetList-' + (props.pageElement?.id || w.id) // force singlaton!
    mainservice.registerToBroadcast(ownKey, (key, value) => {
      const listenForKey = 'triggerOverlay-' + closeOverlayName
      if (key === listenForKey && (value?.mode || '').search(/edit|open/) > -1) {
        const editThisItem = value.id
        setEditThis(editThisItem)
      } else if (key === listenForKey && (value?.mode || '').search(/close/) > -1) (
        setEditThis(-2)
      )
    })
  }
  const headContent = w.getMixPropV1(p => p.key1 === 'globProp' && p.key2 === 'textAboveWidget')
  const bottomContent = w.getMixPropV1(p => p.key1 === 'globProp' && p.key2 === 'textBelowWidget')
  let className = 'w3-container-fluid UserListWidget'
  className += ' itemLayout-' + w.getMixPropV1(p => p.key1 === 'globProp' && p.key2 === 'itemLayout')
  if (editThis > -2) {
    return EditRow({
      widget: w,
      editId: editThis ,
      cb: () => {
        setEditThis(-2)
      }
    })
  }
  return <div className={className}>
    { headContent &&
      <p dangerouslySetInnerHTML={{__html: headContent}}></p>
    }
    { w.getMixPropV1(p => p.key1 === 'globProp' && p.key2 === 'enableNewItem') !== 'uncheck' &&
      <NewRow
        widget={w}
        success={doRerender}
      />
    }
    {
      w.getMixPropV1(p => p.key1 === 'globProp' && p.key2 === 'disableList') !== 'check' &&
      <>
        <DisplayRows
          widget={w}
          rerenderValue={rerender}
          rerender={doRerender}
        />
        <DisplayLegend
          widget={w}
        />
      </>
    }
    { bottomContent &&
      <p dangerouslySetInnerHTML={{__html: bottomContent}}></p>
    }
  </div>
}

function DisplayLegend(props: {
  widget: WidgetUserDTO
}) {
  const w = props.widget
  const columnsRaw = w.getProps('column')
  const columns = columnsRaw.map(r => {
    const id = r.id
    const aKey = 'childAttribute' + id
    return {
      display: w.getPropV1({key1: aKey, key2: 'display'}),
      kind: w.getPropV1({key1: aKey, key2: 'kind'}),
      description: w.getPropV1({key1: aKey, key2: 'description'}),
      legendEmoji: w.getPropV1({key1: aKey, key2: 'legendEmoji'}),
      sortNumber: r.value2,
      key1: aKey,
    }
  })
  if (!columns.some(c => !(!c.legendEmoji ||c.display !== 'check'))) {
    // No content to be displayed:
    return null
  }
  return <div
    className='w3-padding'
  >
    {
      columns.map(c => {
        if (
          !c.legendEmoji ||
          c.display !== 'check'
        ) { return null }
        return <div
          key={'legend-item-' + c.key1}
        >
          <span>{c.legendEmoji}</span>
          <span>{c.description}</span>
        </div>
      })
    }
  </div>
}

function EditRow(props: {
  widget: WidgetUserDTO,
  editId: number,
  cb: () => void,
}) {
  const entry = props.widget.getEntryById(props.editId)
  return <>
    <NewRow
      widget={props.widget}
      success={() => {
        props.cb()
      }}
      entry={entry}
    />
  </>
}

function NewRow(props: {
  widget: WidgetUserDTO,
  success: () => void,
  entry?: Entry
}) {
  const w = props.widget
  const [mainVal, setMainVal] = useState(props.entry?.value1 || '')
  const [valueHolder, setValueHolder] = useState(new ValueHolder())
  const [rerender, setRerender] = useState(0)
  const [status, setStatus] = useState(0)
  const entry = props.entry
  async function newEntry() {
    const newUD = await w.setUserData({
      id: entry?.id || -1,
      key1: 'entry',
      key2: 'main',
      value1: mainVal,
      value2: '0',
    }, Unique.no)
    let newId = ''
    if (!entry) {
      w.sortPositionsAddItem('listEntries', newUD.id)
      newId = newUD.id + ''
    }
    // Save the rest of the information:
    for (let i = 0, m = valueHolder.values.length; i < m; i ++) {
      let value = valueHolder.values[i]
      await w.setUserData({
        id: -1,
        key1: value.key1,
        key2: value.key2 + newId,
        value1: value.value1,
        value2: value.value2,
      }, Unique.yes)
    }
    valueHolder.reset()
    // setValueHolder(new ValueHolder())
    setStatus(2)
    setMainVal('')
    props.success()
    setStatus(0)
    const closeOverlayName = w.getPropV1({key1: 'globProp', key2: 'closeOverlayName'})
    if (closeOverlayName) {
      mainservice.broadcast('triggerOverlay-' + closeOverlayName, false)
    }
  }

  if (status === 2) {
    return <div>Übertrage...</div>
  }

  const columnsRaw = w.getProps('column')
  const columns = makeColumnData({
    columnsRaw: columnsRaw,
      w: w,
      entryId: entry?.id || -1,
  }).filter(c => c.displayOnNew === 'check' || c.kind === 'mainValue')

  return <div className='widget-liste-new-row'>
      <label className='wlnr-new-headline' dangerouslySetInnerHTML={{__html: w.getPropV1({key1: 'globProp', key2: 'inputHeadline'}) || 'Aufgabe hinzufügen:'}}></label>
      {
        columns.map((c, index) => {
          if (c.kind === 'mainValue') {
            return <div
              key={`${index}-${c.key1}-${c.key2}`}
                className='wlnr-input'
              >
                <input
                  className='wlnr-text-input'
                  value={mainVal}
                  onChange={event => {
                    setMainVal(event.target.value)
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      newEntry()
                    }
                  }}
                  onFocus={() => {
                    mainservice.broadcast('textEditFocus', true)
                  }}
                  onBlur={() => {
                    mainservice.broadcast('textEditBlur', true)
                  }}
                ></input>
              </div>
          }
          return <div key={`${w.id}-header-input-${index}`}>
            <label className='wlnr-new-label'>{c.description}</label>
            <DisplayColumn
              column={c}
              entry={entry || {id: -1, key1: c.key1, key2: c.key2, value1: valueHolder.getValue1(c.key1, c.key2), value2: ''}}
              editMode={-1}
              widget={w}
              setUserData={async (item: UserData, unique: Unique) => {
                return await valueHolder.addValue(item)
              }}
              setEditMode={(em: number) => {
              }}
              rerender={()=> {
                setRerender(rerender + 1)
              }}
            />
          </div>
        })
      }
      <div className='wlnr-ok'>
        <button
          className='primary-button'
          onClick={newEntry}
        >Speichern</button>
      </div>
      { entry?.id && entry?.id > -1 &&
        <div className='wlnr-ok w3-right'>
          <button
            className='secondary-button'
            onClick={async () => {
              if (window.confirm('Eintrag wirklich löschen?')) {
                await w.removeUserDataList([entry])
              }
              valueHolder.reset()
              // setValueHolder(new ValueHolder())
              setStatus(2)
              setMainVal('')
              props.success()
              setStatus(0)
              const closeOverlayName = w.getPropV1({key1: 'globProp', key2: 'closeOverlayName'})
              if (closeOverlayName) {
                mainservice.broadcast('triggerOverlay-' + closeOverlayName, false)
              }
            }}
          >Löschen</button>
        </div>
      }
    </div>
}

function IsValueInAllowedList(value: number, list: string): boolean {
  if (!list || list === '' || isNaN(value)) { return true }
  const filterArr = list.replace(/[^-,; 0-9]/g, '').split(/[, ;]+/)
  const arr = filterArr.filter(a => a !== '').map(i => parseInt(i, 10))
  if (arr.length === 0 || arr.find(a => a === value) !== undefined) { return true }
  return false
}

function DisplayRows(props: {
  widget: WidgetUserDTO,
  rerender: () => void,
  rerenderValue: number,
}) {
  const [status, setStatus] = useState(Status.standard)
  const w = props.widget
  const columnsRaw = w.getProps('column')
  // compile entries:
  let entries = w.sortPositionsGetItems('listEntries').map(entry => {
    if (!entry) { return undefined }
    if (status === Status.sorting) { return {...entry, ...{
      columns: []
    }} }
    const columns = makeColumnData({
      columnsRaw: columnsRaw,
      w: w,
      entryId: entry.id,
    })
    return {...entry, ...{
      columns: columns
    }}
  }).filter(entry => entry !== undefined)
  const sortCols = w.getMixedProps(p => p.key2 === 'sortByCol' && p.value1 !== 'no')
  if (status !== Status.sorting) {
    const filterCols = w.getMixedProps(p => p.key2 === 'filterByCol' && p.value1 !== 'no')
    entries = entries.filter(e => filterCols.every(f => {
      if (!e) {
        return false
      }
      const dir = f.value1
      const column = e.columns.find(c => c.key1 === f.key1)
      if (!column) {
        return false
      }
      const userValue = column.userValueInt
      return IsValueInAllowedList(userValue, dir)
    }))
    if (sortCols.length > 0) {
      entries = entries.sort((a, b) => {
        return sortCols.some(f => {
          const dir = f.value1 === 'decreasing' ? false : true
          if (!a) { return !dir }
          if (!b) { return dir }
          const columnA = a.columns.find(c => c.key1 === f.key1)
          if (!columnA) { return !dir }
          const columnB = b.columns.find(c => c.key1 === f.key1)
          if (!columnB) { return dir }
          const userValueA = columnA.userValueInt
          const userValueB = columnB.userValueInt
          return (userValueA < userValueB) ? dir : !dir
        }) ? -1 : 1
      })
    }
  }

  const columns = columnsRaw.map(c => {
    const id = c.id
    const aKey = 'childAttribute' + id
    return {
      aKey: aKey,
      legendEmoji: w.getPropV1({key1: aKey, key2: 'legendEmoji'}),
      display: w.getPropV1({key1: aKey, key2: 'display'}),
      kind: w.getPropV1({key1: aKey, key2: 'kind'}),
      description: w.getPropV1({key1: aKey, key2: 'description'}),
    }
  })
  
  return <div className='widget-liste-items'>
    {
      entries.length === 0 &&
      <div className='w3-padding' dangerouslySetInnerHTML={{__html: w.getPropV1({key1: 'globProp', key2: 'emptyString'}) || 'Die Liste ist leer'}}></div>
    }
    { w.getMixPropV1(p => p.key1 === 'globProp' && p.key2 === 'enableColumnHeader') === 'check' &&
      <div className='item-row'>
        {
          columns.map((c, i) => {
            let kindClass = 'item-row-col'
            if (c.kind === 'mainValue') {
              kindClass = 'mainValue'
            } else if (c.kind === 'freetext') {
              kindClass = 'noDisplay'
            }

            if (c.display !== 'check') { return null }
            return <div
              key={`column-head-${w.id}-${c.aKey}-${i}`}
              className={`${kindClass}`}
              title={c.description}
            >
              {c.legendEmoji}
            </div>
          })
        }
        <div className='touch-only right-col up-holder'><ArrowUpCircle className='hide' /></div>
        <div className='touch-only right-col down-holder'><ArrowUpCircle className='hide' /></div>
        { w.getMixPropV1(p => p.key1 === 'globProp' && p.key2 === 'enableItemDelete') !== 'uncheck' &&
          <div className='right-col trash-holder'><Trash className='hide' /></div>
        }
      </div>
    }
    { status !== Status.sorting &&
      entries.map((e, index) => {
        if (!e) { return null }
        return <DisplayRow
          className='wl-item'
          key={`displayRow-${e.id}`}
          widget={w}
          userSortOk={sortCols.length === 0}
          rerender={(info) => {
            if (info === 1) {
              setStatus(Status.sorting)
              setTimeout(() => {
                setStatus(Status.standard)
              }, 1)
            } else {
              props.rerender()
            }
            // setLocalRerender(new Date().getTime())
          }}
          rerenderValue={props.rerenderValue}
          entry={e}
        />
      })
    }
  </div>
}

type HoverItem = {
  id: number,
}

export function DisplayRow(props: {
  className: string,
  widget: WidgetUserDTO,
  rerender: (info?: number) => void,
  rerenderValue: number,
  userSortOk?: boolean,
  entry: EntryExtended,
  showTitleTagWithClass?: string,
  valueClass?: string,
}) {
  const w = props.widget
  const entry = props.entry
  // const columnsRaw = w.getProps('column')
  const [editMode, setEditMode] = useState(0)
  const [showColumns, setShowColumns] = useState(true)
  function hoverBeforeOrAfter(): string {
    const ownSortNumber = w.sortPositionsGetItemPos('listEntries', entry.id)
    const hoveringSortNumber = w.getNCache('hoveringSortNumber')
    if (Math.max(0, hoveringSortNumber) < ownSortNumber) {
      return 'after'
    }
    if (ownSortNumber < hoveringSortNumber) {
      return 'before'
    }
    return ''
  }
  const [{isDragging}, drag] = useDrag(() => {
    return {
      type: 'listItem',
      item: {
        id: entry.id
      },
      collect: monitor => {
        return{
          isDragging: !!monitor.isDragging(),
        }
      },
    }
  }, [43])
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'listItem',
    canDrop: (e, monitor) => {
      const item = monitor.getItem() as HoverItem
      w.setNCache('hoveringSortNumber', w.sortPositionsGetItemPos('listEntries', item.id))
      return true
    },
    drop: (e, monitor) => {
      const item = monitor.getItem() as HoverItem
      w.sortPositionsMoveItemToTarget('listEntries', item.id, props.entry.id, hoverBeforeOrAfter() === 'before' ? -1 : 1)
      props.rerender(1)
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  }), [entry.value2])
  return <div
    className={`${props.className} ${(isDragging) ? 'dragging' : ''}`}
    ref={drag}
  >
    <div
      ref={drop}
      className={`wl-dropzone show-drop-zone-${(isOver && props.userSortOk) ? hoverBeforeOrAfter() : ''} ${(canDrop) ? 'wl-canDrop' : ''}`}
    >
      <div className='item-row'>
        { showColumns &&
          entry.columns.map((c, index) => {
            const key = `${entry.key1}-${entry.key2}-${index}`
            if (c.display !== 'check') { return null }
            const column = <DisplayColumn
              key={key + '-column'}
              column={c}
              entry={entry}
              editMode={editMode}
              widget={w}
              // className={props.valueClass}
              className={props.showTitleTagWithClass}
              // setUserData={w.setUserData}
              setUserData={async (data: UserData, unique: Unique) => {
                const result = await w.setUserData(data,unique)
                /*
                setShowColumns(false)
                setTimeout(() => {
                  setShowColumns(true)
                }, 1)
                */
                return result
              }}
              setEditMode={setEditMode}
              rerender={props.rerender}
            />
            if (props.showTitleTagWithClass) {
              return <div key={key} className={props.valueClass}>
                <div
                  key={key + '-title'}
                  className={props.showTitleTagWithClass}
                >
                  { c.description }
                </div>
                { column }
              </div>
            }
            return column
          })
        }
        <div
          className='touch-only right-col up-holder'
          onClick={() => {
            w.sortMoveUpDown('listEntries', entry.id, -1)
            props.rerender()
          }}
        >
          <ArrowUpCircle />
        </div>
        <div
          className='touch-only right-col down-holder'
          onClick={() => {
            w.sortMoveUpDown('listEntries', entry.id, 1)
            props.rerender()
          }}
        >
          <ArrowDownCircle />
        </div>
        { w.getMixPropV1(p => p.key1 === 'globProp' && p.key2 === 'enableItemDelete') !== 'uncheck' &&
          <div
            className='right-col trash-holder'
            onClick={async () => {
              if (!window.confirm(`Aufgabe "${entry.value1}" wirklich löschen?`)) {
                return
              }
              await w.removeUserDataList(([] as {key1: string, key2: string}[]).concat([props.entry], entry.columns))
              props.rerender()
            }}
          ><Trash /></div>
        }
        </div>
    </div>
  </div>
}

function DisplayColumn(props: {
  column: Column,
  entry: Entry,
  editMode: number,
  widget: WidgetUserDTO,
  setUserData: (item: UserData, unique: Unique)  => Promise<UserData>,
  setEditMode: (i: number) => void,
  rerender: () => void,
  className?: string,
}) {
  const c = props.column
  const editMode = props.editMode
  const entry = props.entry
  const setEditMode = props.setEditMode
  const w = props.widget
  const [value, setValue] = useState(c.userValue)
  switch(c.kind) {
    case 'mainValue':
      return <div
          className={`mainValue ${props.className || ''}`}
          title={c.description}
        >
          { editMode !== -1 && editMode !== 1 &&
            <div
              className='mainValueContent'
              onClick={() => {
                setEditMode(1)
              }}
            >{entry.value1}</div>
          }
          { editMode === 1 &&
            <InputA
              value={entry.value1}
              autofocus={true}
              className={`entryInput ${props.className || ''}`}
              sendOnBlur={true}
              returnVal={async (v) => {
                await props.setUserData({
                  id: entry.id,
                  key1: entry.key1,
                  key2: entry.key2,
                  value1: v,
                  value2: '-',
                }, Unique.yes)
                setEditMode(0)
                props.rerender()
              }}
            />
          }
        </div>
    case 'check':
      return <div
          className={`item-row-col check ${props.className || ''}`}
          title={c.description}
          onClick={async () => {
            await props.setUserData({
              id: -1,
              key1: c.key1,
              key2: c.key2,
              value1: (value === 'check') ? '' : 'check',
              value2: '-',
            }, Unique.yes)
            props.rerender()
          }}
        >
          {
            value === 'check' &&
            <CheckSquare />
          }
          {
            value !== 'check' &&
            <Square />
          }
        </div>
    case 'year':
      return <div
        className={`item-row-col year ${props.className || ''}`}
      >
        { editMode !== -1 && editMode !== 3 &&
            <div
              title={c.description}
              className='w3-border-bottom year-content'
              onClick={() => {
                setEditMode(3)
              }}
              dangerouslySetInnerHTML={{__html: value || '&nbsp;'}}
            ></div>
          }
          { (editMode === -1 || editMode === 3) &&
            <InputA
              value={value}
              autofocus={true}
              placeholder={c.description}
              sendOnBlur={true}
              equalizeFkt={(value: string) => {
                const scope4 = /([1-2][0-9][0-9][0-9])/
                const scope2 = /([0-9][0-9])/
                const now = new Date()
                const match4 = value.match(scope4)
                if (match4) {
                  setValue(match4[1])
                  return match4[1]
                }
                const match2 = value.match(scope2)
                if (!match2) {
                  setValue('')
                  return ''
                }
                const dig = parseInt(match2[1], 10)
                // setLastValue(dig + '')
                let newVal = ''
                if (dig + 2000 <= now.getFullYear() + 1) {
                  newVal = `${dig + 2000}`
                } else {
                  newVal = `${dig + 1900}`
                }
                setValue(newVal)
                return newVal
              }}
              className='entryInput'
              returnVal={async (v) => {
                await props.setUserData({
                  id: -1,
                  key1: c.key1,
                  key2: c.key2,
                  value1: v,
                  value2: '-',
                }, Unique.yes)
                setEditMode(0)
                setValue(v)
                props.rerender()
              }}
            />
          }
      </div>
    case 'freetext':
      return <div
        className={`freetext ${props.className || ''}`}
      >
        { editMode !== -1 && editMode !== 4 &&
            <div
              className='freetext-content w3-border-bottom'
              onClick={() => {
                setEditMode(4)
              }}
              title={c.description}
              dangerouslySetInnerHTML={{__html: value || '&nbsp;'}}
            ></div>
          }
          { (editMode === -1 || editMode === 4) &&
            <InputA
              value={value}
              autofocus={true}
              className='entryInput'
              type={'textArea'}
              placeholder={c.description}
              sendOnBlur={true}
              returnVal={async (v) => {
                await props.setUserData({
                  id: -1,
                  key1: c.key1,
                  key2: c.key2,
                  value1: v,
                  value2: '-',
                }, Unique.yes)
                setEditMode(0)
                setValue(v)
                props.rerender()
              }}
            />
          }
      </div>
    default: // 'scale-*'
      return <div
          title={c.description}
          className={`item-row-col scale ${props.className || ''}`}
        >
          <ScaleButton
            widget={w}
            rerender={props.rerender}
            headline={c.description}
            key1={c.key1}
            key2={c.key2}
            value1={value}
            kind={c.kind}
            setUserData={async (data: UserData, unique: Unique) => {
              setValue(data.value1)
              const result = await props.setUserData(data,unique)
              return result
            }}
            placeholder={c.legendEmoji}
          />
        </div>
  }
}

export function ScaleButton(props: {
  widget: WidgetUserDTO,
  rerender: () => void,
  headline?: string,
  key1: string,
  key2: string,
  value1: string,
  setUserData: (item: UserData, unique: Unique)  => Promise<UserData>,
  kind: string,
  placeholder?: string,
  className?: string,
}) {
  let iconset = props.kind.replace('scale-', '')
  let steps = '5'
  if (iconset === '6') {
    steps = iconset
  }
  const filterCols = props.widget.getMixedProps(p => p.key1 === props.key1 && p.key2 === 'filterByCol' && p.value1 !== 'no')
  const donotFilterByCols = props.widget.getMixPropV1(p => p.key1 === props.key1 && p.key2 === 'donotfilterDDByCol')
  const filterDropDownByTheseNumbers = props.widget.getMixPropV1(p => p.key1 === props.key1 && p.key2 === 'filterDropDownByTheseNumbers')
  const rating = GetRatingRaster(iconset, steps).filter(r => {
    if (!r) { return false }
    if (donotFilterByCols === 'check') { return true }
    if (filterDropDownByTheseNumbers) {
      return IsValueInAllowedList(r.value, filterDropDownByTheseNumbers)
    }
    return !filterCols.some(f => {
      return !IsValueInAllowedList(r.value, f.value1)
    })
  }).reverse()
  const currentRating = rating.find(r => {
    return r?.value + '' === props.value1 + ''
  })
  const currentDisplayValue = currentRating ? currentRating.key : (props.placeholder || '?')
  const key1Props = props.widget.getMixedProps(p => p.key1 === props.key1)
  let currentIcon = <span className='emoji'>{currentDisplayValue}</span>
  const individualIcon = parseStringFromServer(RatingvalueToIcon(currentRating?.value + '', key1Props))
  if (individualIcon) {
    currentIcon = <span>{individualIcon}</span>
    // const currentIcon =  || <i className={`rating-icon rating-icon-${currentRating?.value}`}></i>
  }
  return <div className={`w3-dropdown-hover rating-icon-type-${iconset} ${props.className || ''}`}>
    <div className="w3-button">
    { currentIcon }
    <span>
      {RatingvalueToName(currentDisplayValue)}
    </span>
    </div>
    <div className="w3-dropdown-content w3-bar-block w3-border">
      { props.headline &&
        <div className="w3-bar-item w3-button"><b>{props.headline}</b></div>
      }
      {
        rating.map((r, index) => {
          const value = r?.value + ''
          const name = parseStringFromServer(RatingvalueToName(value, key1Props))
          const iconString = parseStringFromServer(RatingvalueToIcon(value, key1Props))
          const icon = iconString || <i className={`rating-icon rating-icon-${value} w3-margin-right`}></i> 
          const activeClass = (r?.value + '' === props.value1 + '') ? 'scaleButtonActiveItem' : ''
          let displayString = ''
          if (iconString === '') {
            displayString = r?.key || ''
          }
          return <div
            key={'scale' + props.key1 + props.key2 + index}
            className={`w3-bar-item w3-button ${activeClass}`}
            onClick={async () => {
              await props.setUserData({
                id: -1,
                key1: props.key1,
                key2: props.key2,
                value1: value,
                value2: '-',
              }, Unique.yes)
              props.rerender()
            }}
          >
            { displayString }
            { icon }
            <span
              dangerouslySetInnerHTML={{__html: name}}
            ></span>
          </div>
        })
      }
    </div>
  </div> 
}

function makeColumnData(d: {
  columnsRaw: Prop[],
  w: WidgetUserDTO,
  entryId: number,
}) {
  const columnsRaw = d.columnsRaw
  const w = d.w
  const entryId = d.entryId
  return columnsRaw.map(r => {
    const id = r.id
    const aKey = 'childAttribute' + id
    const userValue = w.getUserDataValue1({key1: aKey, key2: r.key2 + entryId})
    const newKey2 = (entryId === -1 || r.key2.search(entryId + '') > -1) ? r.key2 : r.key2 + entryId
    const f2 = w.userData.find(f => {
      return f.key1 === aKey && f.key2 === r.key2 + entryId
    })
    return {
      display: w.getPropV1({key1: aKey, key2: 'display'}),
      displayOnNew: w.getPropV1({key1: aKey, key2: 'displayOnNew'}),
      kind: w.getPropV1({key1: aKey, key2: 'kind'}),
      description: w.getPropV1({key1: aKey, key2: 'description'}),
      legendEmoji: w.getPropV1({key1: aKey, key2: 'legendEmoji'}),
      sortNumber: r.value2 || '0',
      userValue: userValue || '',
      userValueInt: parseInt(userValue, 10) || 0,
      key1: aKey,
      key2: newKey2,
    }
  })
}
