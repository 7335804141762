export default class TagDTO {
  // private apiPrefix = config.apiPrefix
  public id: number = -1
  public name: string = ''

  constructor(tag: ITag) {
    this.id = tag.id
    this.name = tag.name
  }

  public get() {
    return {
      id: this.id,
      name: this.name
    }
  }
}

export interface ITag {
  id: number,
  name: string
}
