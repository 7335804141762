import React from 'react'
import mainservice from '../../../services/MainService'
import PageElementDTO from '../../../DTO/PageElementDTO'
import InputA from '../../InputA/InputA'

type Props = {
  pageElement: PageElementDTO
}

enum Status {
  normal,
  edit,
  openedMail
}

type State = {
  status: Status
}

const propList = [
  {
    key: 'buttonContent',
    name: 'Knopftext',
    fallbackValue: 'Schreib uns'
  },
  {
    key: 'subject',
    name: 'Betreff der E-Mail',
    fallbackValue: 'Lass uns über meine Session ##SESSION## reden'
  },
  {
    key: 'body',
    name: 'Inhalt der E-Mail',
    fallbackValue: 'Bitte kontaktieren Sie mich um mit mir über meine Session ##SESSION## zu sprechen',
    type: 'textArea'
  },
  {
    key: 'programOpened',
    name: 'Hinweis, dass sich das Mailprogramm geöffnet hat',
    fallbackValue: 'Dein E-Mail Programm sollte sich geöffnet haben - bitte suche das Fenster! Sollte das nicht klappen schreib uns eine Mail an digital@lubbers.de mit Deiner Session ID ##SESSION##',
    type: 'textArea'
  },
]

export default class CallToActionButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      status: Status.normal
    }
  }

  getByKey(key: string, conv?: boolean) {
    const i = propList.find(i => i.key === key)
    const raw = this.props.pageElement.getPropVal1(key, '-', i?.fallbackValue || '')
    if (conv) {
      return this.conv(raw)
    }
    return raw
  }

  conv(s: string) {
    const session = `${mainservice.nav.getVal('session')}`
    const sessionRegex = /##SESSION##/gi
    return s.replace(sessionRegex, session).replace(/\n/g, '%0A').replace(/\<br \/\>/g, '%0A')
  }

  RenderButton() {
    const targetMail = 'digital@lubbers.de'
    const subject = this.getByKey('subject', true)
    const body = this.getByKey('body', true)
    console.log('BODY', body)
    const buttonContent = this.getByKey('buttonContent', true)
    const alignClass = this.getByKey('alignClass')
    // space: %20
    return <div className={`callToActionButton ${alignClass}`}>
        <a
          href={`mailto:${targetMail}?subject=${subject}&body=${body}`}
          onClick={() => this.setState({status: Status.openedMail})}
        >
          <button
            className='primary-button'
            dangerouslySetInnerHTML={{__html: buttonContent}}
          >
          </button>
        </a>
        {
          this.state.status === Status.openedMail &&
          <div>
          <div className='notice-box inline-block textCenter'
            dangerouslySetInnerHTML={{__html: this.getByKey('programOpened', true)}}
          >
          </div></div>
        }
      </div>
  }

  RenderAdmin() {
    return <div className='CallToActionButton'>
      <div className='w3-row editMode'>
        <div className='w3-col s6'>
          <div className='notice-box'>
            Um die Session ID einzubauen folgenden String verwenden: <pre>##SESSION##</pre>
          </div>
          {
            propList.map((p, _i) => <div key={p.key}>
              {p.name}
              <InputA
                value={this.getByKey(p.key)}
                returnVal={rv => {
                  this.props.pageElement.setPropVal(p.key, '-', rv)
                  this.forceUpdate()
                }}
                type={p.type || ''}
              />
            </div>)
          }
          <div>
            <div>Ausrichtung:</div>
            {
              [
                {key: '', name: 'Ohne'},
                {key: 'textLeft', name: 'Links'},
                {key: 'textCenter', name: 'Mitte'},
                {key: 'textRight', name: 'Rechts'},
              ].map(a => <button
                  className={(a.key === this.getByKey('alignClass')) ? 'primary-button' : 'secondary-button'}
                  onClick={async () => {
                    await this.props.pageElement.setPropVal('alignClass', '-', a.key)
                    this.forceUpdate()
                  }}
                >{a.name}</button>
              )
            }
          </div>
        </div>
        <div className='w3-col s6'>
        {
          this.RenderButton()
        }
        </div>
      </div>
      <div className='displayMode'>
      {
        this.RenderButton()
      }
      </div>
    </div>
  }

  render() {
    if (mainservice.isAdmin()) {
      return this.RenderAdmin()
    }
    return this.RenderButton()
  }
}
