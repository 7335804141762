import Evaluation1TextGenerator, {EvaluationTextType} from './TextGenerator'

const Evaluation1FlexibleMatrix = [
  ['1', 'A', 'C', 'D', 'B'],
  ['2', 'A', 'C', 'B', 'D'],
  ['3', 'C', 'B', 'A', 'D'],
  ['4', 'C', 'B', 'D', 'A'],
  ['5', 'A', 'B', 'C', 'D'],
  ['6', 'D', 'A', 'C', 'B'],
  ['7', 'A', 'C', 'D', 'B'],
  ['8', 'B', 'D', 'A', 'C'],
  ['9', 'B', 'D', 'A', 'C'],
  ['10', 'D', 'B', 'A', 'C'],
  ['11', 'B', 'C', 'D', 'A'],
  ['12', 'B', 'C', 'D', 'A'],
  ['13', 'A', 'C', 'B', 'D'],
  ['14', 'B', 'C', 'D', 'A'],
  ['15', 'D', 'A', 'C', 'B'],
  ['16', 'D', 'B', 'A', 'C'],
  ['17', 'A', 'C', 'B', 'D'],
  ['18', 'B', 'D', 'A', 'C'],
  ['19', 'B', 'D', 'C', 'A'],
  ['20', 'A', 'B', 'C', 'D'],
]
const LookUpMatrix = new Map()
Evaluation1FlexibleMatrix.forEach((i) => {
  LookUpMatrix.set(`surveypart${i[0]}`, [i[1], i[2], i[3], i[4]])
})

class Evaluation1Flexible {
  maxPoints = 30

  public flexiblePoints: number = 0
  public pointsByCategory: [number, number, number, number] = [0,0,0,0]
  public text: string[] = []
  constructor(data: {key2: string, value2: string}[]) {

    this.calculate(data)
  }

  calculate(data: {key2: string, value2: string}[]) {
    let pointHolder: [number, number, number, number] = [0,0,0,0]
    data.forEach((item) => {
      const key = item.key2
      const v = item.value2
      const entry = LookUpMatrix.get(key)
      if (!entry) { return }
      const pointFor = entry.indexOf(v)
      pointHolder[pointFor] += 1
    })
    this.pointsByCategory = pointHolder
    // All Points have been collected - now we have to calculate:
    const reducedPoints = pointHolder.map((pH) => Math.abs(pH - 5))
    const sum = reducedPoints.reduce((a, b) => a + b)
    const result = 30 - sum
    this.flexiblePoints = Math.round(result / this.maxPoints * 100)
    let textGenerator = new Evaluation1TextGenerator(pointHolder)
    this.text = textGenerator.getText()
  }

  getAllTextAndValidIndex(): {index: number, texts: EvaluationTextType[]} {
    const textGenerator = new Evaluation1TextGenerator(this.pointsByCategory)
    return textGenerator.getAllTextAndValidIndex()
  }

  /*printTextCondition(orList: OrList) {
    return orList.map((oI: AndList) => {
      return oI.map((aI: EvalConfig) => {
        if (aI.key && aI.keys && aI.keysOperator) {
          return aI.key + ' hat ' + aI.keysOperator + ' von ' + aI.keys.join(', ')
        } else if (aI.keys && aI.keysOperator) {
          return aI.keys.join(` ${aI.keysOperator} `) + ` ${aI.operator} ${aI.value}`
        } else {
          return `${aI.key} ${aI.operator} ${aI.value}`
        }
      }).join(' und ')
    }).join(' oder ')
  }*/

}

export const FlexRatingLetter2Number = (letter: string): number => {
  switch (letter) {
    case 'A': return 0
    case 'B': return 1
    case 'C': return 2
    case 'D': return 3
    default: return -1
  }
}

export const FlexRatingLetter2Name = (letter: string): string => {
  switch (letter) {
    case 'A': return 'Anweisend'
    case 'B': return 'Trainierend'
    case 'C': return 'Coachend'
    case 'D': return 'Delegierend'
    default: return '-'
  }
}

export const FlexRating = (surveyPart: string, answerIndex: number, mode?: 'number' | 'string' | 'letter') => {
  console.log('flexRating ', surveyPart, answerIndex)
  const answerOptions = LookUpMatrix.get(surveyPart)
  const answer = answerOptions[answerIndex]
  if (mode === 'letter') { return answer }
  console.log(answerOptions)
  if (mode === 'number') {
    return FlexRatingLetter2Number(answer)
  }
  return FlexRatingLetter2Name(answer)
}
export default Evaluation1Flexible
