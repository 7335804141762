import React from 'react'
import mainservice from '../../services/MainService'
import Login from './Login'
import CartMenuIcon from '../shop/cartMenuIcon'
import { AlignJustify, User, CreditCard, Book, MessageSquare, Grid } from 'react-feather'
import './UserDashMainMenu.scss'

type Props = {}

type State = {
  dropDownActive: boolean,
  dropDownOpened: boolean,
  loggedIn: boolean
}

enum PublicState {
  public,
  user,
  all
}

export default class UserDashMainMenu extends React.Component<Props, State> {
  openedDelayTimer: any
  leaveDelayTimer: any
  constructor(props: Props) {
    super(props)

    this.state = {
      dropDownActive: false,
      dropDownOpened: false,
      loggedIn: mainservice.loginService.instantIsLoggedIn()
    }
  }

  enableJustClicked() {
    this.setState({dropDownActive: true})
    setTimeout(() => {
      this.setState({dropDownActive: false})
    }, 1000)
  }

  async componentDidMount() {
    mainservice.registerToBroadcast('UserDashMainMenu', (key: string, _value: any) => {
      // console.log('user das main menu listens to broadcast', key)
      switch(key) {
        case 'loginsuccess':
          this.setState({
            loggedIn: true
          })
          break
        case 'logoutsuccess':
          this.setState({
            loggedIn: false
          })
          break
      }
    }, this)
    const isLoggedIn = await mainservice.loginService.isLoggedIn()
    this.setState({
      loggedIn: isLoggedIn
    })
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('UserDashMainMenu')
  }

  openDropDown() {
    this.setState({dropDownActive: true})
    if (this.openedDelayTimer) {
      clearTimeout(this.openedDelayTimer)
    }
    this.openedDelayTimer = setTimeout(() => {
      this.setState({dropDownOpened: true})
    }, 1000)
  }

  closeDropDown() {
    if (this.openedDelayTimer) {
      clearTimeout(this.openedDelayTimer)
    }
    if (this.leaveDelayTimer) {
      clearTimeout(this.leaveDelayTimer)
    }
    this.setState({dropDownActive: false, dropDownOpened: false})
  }

  toggleDropDown() {
    if (this.state.dropDownActive) {
      this.closeDropDown()
    } else {
      this.openDropDown()
    }
  }

  renderUserButton() {
    return <div
      className={`lwb-dropdown-hover w3-right ${(this.state.dropDownActive) ? 'dropDownActive' : ''}`}
      onClick={() => {
        this.toggleDropDown()
      }}
      onMouseLeave={() => {
        this.leaveDelayTimer = setTimeout(() => {
          this.closeDropDown()
        }, 1000)
      }}
      onMouseEnter={() => {
        clearTimeout(this.leaveDelayTimer)
      }}
    >
      <div className={`liLogo avatar w3-button ${this.state.loggedIn ? 'loggedin' : ''}`}><User className='avatar' /></div>
      <div
        className={`w3-dropdown-content w3-bar-block w3-card-4`}
        style={{
          right: 0,
          maxHeight: (!this.state.dropDownActive) ? 0 : `${window.innerHeight - 60}px`,
          overflow: (this.state.dropDownOpened) ? 'auto' : 'hidden'
        }}
      >
        { this.renderNavPoints('showNavMobile', true) }
        <div className={`linkButton`}>
          <div className={`liLogo`}>
            <AlignJustify />
          </div>
          <div className={`liName`}><a href='https://lubbers.de/impressum' target='_blank' style={{textDecoration: 'none'}}>Impressum</a></div>
        </div>
        <Login />
      </div>

    </div>
  }

  renderNavPoints(additionalClass: string, envDropDown: boolean) {
    const nav = mainservice.nav
    const barItems = [
      {
        name: 'Mein Dashboard',
        iconContent: <Grid />,
        onClick: () => {
          mainservice.navTo([['view', 'dashboard']])
        },
        active: nav.isActive([['view', 'dashboard']]),
        scope: PublicState.user
      },
      {
        name: 'Workbooks',
        iconContent: <Book />,
        onClick: () => {
          mainservice.navTo([['view', 'library']])
        },
        active: nav.isActive([['view', 'library']]),
        scope: PublicState.user
      },
      {
        iconClass: 'collectibleFlashCard',
        name: 'Lernkarten',
        iconContent: <CreditCard />,
        onClick: () => {
          mainservice.navTo([['view', 'collectibleFlashcard']])
        },
        active: nav.isActive([['view', 'collectibleFlashcard']]),
        scope: PublicState.user
      },
      /*
      {
        iconClass: 'collectiblePoster',
        name: 'Poster',
        iconContent: <Image />,
        onClick: () => {
          mainservice.navTo([['view', 'collectiblePoster']])
        },
        active: nav.isActive([['view', 'collectiblePoster']]),
        scope: PublicState.user
      },
      */
      {
        iconClass: 'collectibleMotto',
        name: 'Sinnsprüche',
        className: 'w3-opacity',
        iconContent: <MessageSquare />,
        onClick: () => {
          // mainservice.navTo([['view', 'collectibleMotto']])
        },
        active: nav.isActive([['view', 'collectibleMotto']]),
        scope: PublicState.user
      },
      {
        name: 'Store (Beta)',
        iconContent: <Book />,
        onClick: () => {
          mainservice.navTo([['view', 'shop']])
        },
        active: nav.isActive([['view', 'shop']]),
        scope: PublicState.user
      },
    ]
    return barItems.map((b, index) =>  {
      if (b.scope === PublicState.user && !this.state.loggedIn) {
        return
      }
      return <div
          key={index}
          className={`linkButton inlineBlock ${additionalClass} ${(b.active) ? 'active ' : ' '} ${b.className || ''}`}
          onClick={() => {
            if (envDropDown) {
              this.enableJustClicked()
            }
            if (b.onClick) { b.onClick() }}
          }
        >
          <div className={`liLogo ${b.iconClass}`}>
            {(b.iconContent) ? b.iconContent : ''}
          </div>
          <div className={`liName`}>{b.name}</div>
        </div>
    })
  }

  render() {

    return <div className='userDashMainMenu w3-bar'>
      <div className='home-item linkButton'
        onClick={() => {
          mainservice.navTo([['view', 'dashboard']])
        }}
      >
        <div className={`liLogo dLeaderShipOs`}></div>
        <div className='liName'>Leadership Essentials</div>
      </div>
      { this.renderNavPoints('showNavDesktop', false) }
      
      { this.renderUserButton() }
      <CartMenuIcon />
    </div>
  }
}
