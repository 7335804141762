import { Getter, Setter } from "../services/ComService"
import UserDTO, { IUser } from "./UserDTO"

export default class GroupDTO {
  id: number = -1
  name: string = ''
  admins: UserDTO[] = []
  constructor(data: IGroup) {
    this.id = data.id
    this.name = data.name
  }
  public async delete() {
    await Getter(`group/${this.id}/remove`)
  }
  public async rename(name: string) {
    await Getter(`group/${this.id}/rename/${name}`)
  }

  public async getAdminsFromServer() {
    const data = await Getter(`group/${this.id}/admin/list`)
    this.admins = data.items.map((u: IUser) => new UserDTO(u))
    console.log('getAdmins', this.admins, data)
  }

  public getAdmins(): UserDTO[] {
    return this.admins
  }

  public async addAdminByEMail(email: string) {
    const data = await Setter(`group/${this.id}/admin/add`, {email: email})
  }

  public async removeAdmin(id: number) {
    const data = await Setter(`group/${this.id}/admin/remove`, {userId: id})
  }
}

export interface IGroup {
  id : number,
  name: string,
}
