import { GenericEditField, DisplayScope, EditFieldKind } from "../DTO/GenericDTO";

export const LocationEditFields: GenericEditField[] = [
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Adresse',
    kind: EditFieldKind.block,
    className: 's12 m6 l6',
  },
  {
    title: 'Straße',
    key1: 'data',
    key2: 'street',
    kind: EditFieldKind.string,
  },
  {
    title: 'Hausnummer',
    key1: 'data',
    key2: 'streetNr',
    kind: EditFieldKind.string,
  },
  {
    title: 'Postleitzahl',
    key1: 'data',
    key2: 'postalcode',
    kind: EditFieldKind.string,
  },
  {
    title: 'Stadt',
    key1: 'data',
    key2: 'town',
    kind: EditFieldKind.string,
  },
  {
    title: 'Bundesland',
    key1: 'data',
    key2: 'federalstate',
    kind: EditFieldKind.string,
  },
  {
    title: 'Land',
    key1: 'data',
    key2: 'country',
    kind: EditFieldKind.string,
  },
  {
    title: 'Kontakt',
    kind: EditFieldKind.block,
    className: 's12 m6 l6',
  },
  {
    title: 'Telefonnummer',
    key1: 'data',
    key2: 'phone',
    kind: EditFieldKind.string,
  },
  {
    title: 'zweite Telefonnummer',
    key1: 'data',
    key2: 'phone2',
    kind: EditFieldKind.string,
  },
  {
    title: 'eMail',
    key1: 'data',
    key2: 'email1',
    kind: EditFieldKind.string,
  },
  {
    title: 'zweite eMail',
    key1: 'data',
    key2: 'email2',
    kind: EditFieldKind.string,
  },
  {
    title: 'Url',
    key1: 'data',
    key2: 'url',
    kind: EditFieldKind.string,
  },
  {
    title: 'Postfach',
    key1: 'data',
    key2: 'mailbox',
    kind: EditFieldKind.string,
    className: 's12 m6 l6',
  },
  {
    title: 'Postfach Plz',
    key1: 'data',
    key2: 'mailbox_postalcode',
    kind: EditFieldKind.string,
    className: 's12 m6 l6',
  },
  {
    title: 'Postfach Ort',
    key1: 'data',
    key2: 'mailboxtown',
    kind: EditFieldKind.string,
  },
];
