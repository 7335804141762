import React from 'react'
import './BookAdminBar.scss'
import BookDTO, {Layer} from '../../DTO/BookDTO'
import FileList, {FileListMode} from '../MediaManager/FileList'
import { Trash, Image, ArrowLeft, ArrowRight, FilePlus } from 'react-feather'
import InputA from '../InputA/InputA'
import BookAdminPropSwitcher, { DirectionMatrix } from './BookAdminPropSwitcher'
import Book from './Book'
import mainservice from '../../services/MainService'

enum Status {
  standard,
  loading,
}

type Props = {

}

type State = {
  layer: Layer,
  showFileSelector: boolean,
  status: Status,
}

export default class BookAdminBar extends React.Component<Props, State> {
  bookView: Book
  bookDTO: BookDTO | undefined
  reloadTimer = -1
  constructor(props: Props) {
    super(props)
    mainservice.BookAdminBar = this
    this.state = {
      layer: Layer.element,
      showFileSelector: false,
      status: Status.standard,
    }
    this.bookView = mainservice.getCurrentBookView()
    this.bookDTO = this.bookView.state.book
    mainservice.registerToBroadcast(
      'BookAdminBar',
      (key: string, _value: any) => {
        switch(key) {
          case 'currentDoublePageSet':
            this.setState({status: Status.loading})
            clearTimeout(this.reloadTimer)
            this.reloadTimer = window.setTimeout(() => {
              // this.forceUpdate()
              this.setState({status: Status.standard})
            }, 1000)
            break
        }
      },
      this
    )
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('BookAdminBar')
  }

  public closeFileList() {
    this.setState({showFileSelector: false})
  }

  public setInteractionLayer(layer: Layer) {
    this.setState({layer: layer})
  }

  renderColorSelector(colorPrefix: string, headline: string) {
    if (mainservice.currentDoublePage.anchorId > -1) { return }
    const className = (this.bookDTO) ?
      this.bookDTO.getPropVal1('booklayout', 'theme', 'standard-theme') :
      ''
    const colorSelector = [
      {value: 'delete', name: 'löschen'},
      {value: 'light', name: 'hell'},
      {value: 'dark', name: 'dunkel'},
      {value: 'special1', name: 'akzent 1'},
      {value: 'special2', name: 'akzent 2'},
      {value: 'special3', name: 'akzent 3'},
      {value: 'special4', name: 'akzent 4'},
    ].map((c, index) => <div
      key={index}
      className={`color-box set-${colorPrefix}-${c.value} color-box-mode-${colorPrefix}`}
      onClick={() => {
        if (mainservice.interactionThingRel &&
          mainservice.interactionThingRel.setColorClass
        ) {
          mainservice.interactionThingRel.setColorClass(colorPrefix, c.value)
        }
      }}
    >
      {c.name}
    </div>)
    return <div className={className}>
      <h4>{headline}</h4>
      {colorSelector}
    </div>
  }
  renderLayerSelector() {
    const activeClass = 'w3-leftbar w3-border-blue'
    const inactiveClass = 'w3-leftbar'
    const buttons =  [
      {name: 'Element', layer: Layer.element},
      {name: 'Seite', layer: Layer.page},
      {name: 'Doppelseite', layer: Layer.doublePage},
      {name: 'Buch', layer: Layer.book},
      {name: 'Seitenübersicht', layer: Layer.doublePageOverview}
    ].map((item: {name: string, layer: Layer}, index: number) => {
      return <div
        key={index}
        className={`pointer ${(item.layer === this.state.layer) ? activeClass : inactiveClass}`}
        onClick={() => {
          this.setState({layer: item.layer})
          mainservice.setInteractionLayer(item.layer)
        }}
      >{item.name}</div>
    })
    return <>
      <h4>Layer</h4>
      <div className="layerSelector w3-border w3-padding">
        {buttons}
      </div>
    </>
  }

  getRealPageIndex() {
    return mainservice.currentDoublePage.origSortNumber
    // return this.bookDTO?.realDPPosition(mainservice.currentDoublePage.id) || -1
  }

  reload() {
    window.location.href = window.location.href
  }

  renderDoublePageControl() {
    if (mainservice.currentDoublePage
      // && mainservice.currentDoublePage.anchorId === -1
    ) {
      const dp = mainservice.currentDoublePage
      const currentValue = dp.getPropValue1('brand', 'scheme', 'black')
      const currentId = (dp.anchorId === -1) ? dp.id : dp.anchorId // we do this, so the linked double pages will not be altered
      
      return <>
      {
        mainservice.currentDoublePage.anchorId === -1 &&
        <>
          <BookAdminPropSwitcher
            key={`BAPS-brand-scheme`}
            parent={dp}
            name='Branding'
            key1='brand'
            key2='scheme'
            value1Arr={[
              ['black', 'dunkel'],
              ['white', 'hell'],
              ['color', 'bunt'],
              ['none', 'ohne']
            ]}
            value1Current={currentValue}
            cb={(newSchemeName) => {
              mainservice.broadcast('setBranding', newSchemeName as string)
            }}
          />
          <BookAdminPropSwitcher
            key={`BAPS-double-page-layout`}
            parent={dp}
            name='Mehrspalten Layout'
            key1='layout'
            key2='columns'
            value1Arr={[
              ['inherit', 'wie Buch'],
              ['oneColumn', 'einspaltig'],
              ['twoColumn', 'zweispaltig'],
            ]}
            value1Current={dp.getPropValue1('layout', 'columns', 'inherit')}
            cb={() => {
              mainservice.broadcast('rerenderDoublePage-' + dp.id, '')
            }}
          />
          <BookAdminPropSwitcher
            key={`image-position-layout`}
            parent={dp}
            name='Bild-Position'
            key1='layout'
            key2='picturePosition'
            value1Arr={DirectionMatrix}
            value1Current={dp.getPropValue1('layout', 'picturePosition', 'bgPositionInherit')}
            cb={() => {
              mainservice.broadcast('rerenderDoublePage-' + dp.id, '')
            }}
            kind={'matrix'}
          />
          <BookAdminPropSwitcher
            key={`BAPS-breadcrumb-show`}
            parent={dp}
            name='BreadCrumb'
            key1='breadcrumb'
            key2='show'
            value1Arr={[
              ['yes', 'Zeigen'],
              ['no', 'Verstecken']
            ]}
            value1Current={dp.getPropValue1('breadcrumb', 'show', 'yes')}
            cb={() => {
              mainservice.broadcast('rerenderDoublePage-' + dp.id, '')
            }}
          />
        </>
      }
        <button className="tool" onClick={() => mainservice.currentBookView.addDoublePage(this.getRealPageIndex() - 1)}>
          <ArrowLeft />
          <FilePlus />
        </button>
        <button className="tool" onClick={() => mainservice.currentBookView.addDoublePage(this.getRealPageIndex() + 1)}>
          <FilePlus />
          <ArrowRight />
        </button>
        <button className="tool" onClick={() => dp.clone(mainservice.getCurrentBookId(), this.getRealPageIndex() + 1).then(() => {
          mainservice.setInteractionLayer(Layer.element)
          mainservice.broadcast('rerenderDoublePage-' + dp.id, '')
          this.reload()
        })}>
          <FilePlus />
          <FilePlus />
        </button>
        <div className="">
          Seite Verschieben ({this.getRealPageIndex()}):
          <button
            className="tool"
            onClick={async () => {
              // await mainservice.currentDoublePage.setSortNumber(mainservice.currentDoublePage.sortNumber - 1)
              if (this.getRealPageIndex()) {
                await dp.setSortNumber(this.getRealPageIndex() - 1, -1, currentId)
                this.reload()
              } else {
                console.log('could not get current book or page')
              }
            }}
          >
            <ArrowLeft />
          </button>
          <button
            className="tool"
            onClick={async () => {
              // await mainservice.currentDoublePage.setSortNumber(mainservice.currentDoublePage.sortNumber + 1)
              if (this.getRealPageIndex()) {
                await dp.setSortNumber(this.getRealPageIndex() + 1, this.getRealPageIndex(), currentId)
                this.reload()
              } else {
                console.log('could not get current book or page')
              }
            }}
          >
            <ArrowRight />
          </button>
          <InputA
            returnVal={async (rv: string) => {
              // await mainservice.currentDoublePage.setSortNumber(parseInt(rv, 10))
              const wantedPageHR = parseInt(rv, 10);
              await dp.setSortNumberReal(wantedPageHR, this.bookDTO?.doublePages, currentId)
              /*
              const currentDifference = dp.sortNumber - this.getRealPageIndex()

              if (this.getRealPageIndex()) {
                const newPage = wantedPageHR - currentDifference
                await dp.setSortNumber(newPage, -1, currentId)
                this.reload()
              } else {
                console.log('could not get current book or page')
              }
                */
              this.reload()
            }}
            value={`${dp.sortNumber}`}
          />

        </div>
      </>
    }
    return <></>
  }
  renderBookControl() {
    const bookView = mainservice.getCurrentBookView()
    const bookDTO = bookView.state.book
    const options = [
      {
        name: 'Inhaltsverzeichnis aktivieren',
        key1: 'tableOfContens',
        key2: 'enable',
        options: [
          ['yes', 'ja'],
          ['no', 'nein']
        ],
        standard: 'no'
      },
      {
        name: 'Inhaltsverzeichnis naming',
        key1: 'tableOfContens',
        key2: 'chapter-style',
        options: [
          ['chapter-style-clean', 'Überschrift'],
          ['chapter-style-number', '4.2 Überschrift'],
          ['chapter-style-chapter', 'Kapitel 4.2 Überschrift'],
        ],
        standard: 'chapter-style-clean'
      },
      {
        name: 'FloatIcons aktivieren',
        key1: 'floatIcons',
        key2: 'enable',
        options: [
          ['yes', 'ja'],
          ['no', 'nein']
        ],
        standard: 'no'
      },
      {
        name: 'Buch Layout',
        key1: 'booklayout',
        key2: 'theme',
        options: [
          ['fuehrungsstilanalyse-theme', 'Führungsstilanalyse'],
          ['standard-theme', 'Original'],
          ['standard-theme-2021-09', 'Standard 2021-09']
        ],
        standard: 'standard-theme'
      }
    ]
    return options.map((o, index) => <BookAdminPropSwitcher
      key={index}
      parent={bookDTO}
      name={o.name}
      key1={o.key1}
      key2={o.key2}
      value1Arr={o.options as [string, string][]}
      value1Current={bookDTO.getPropVal1(o.key1, o.key2, '') || o.standard}
      cb={() => {bookView.forceUpdate()}}
    />)
  }

  renderDroplets() {

    const elements = mainservice.getElementTypes().map((e: {name: string, id: number}) => {
      return {
        name: e.name,
        id: e.id,
        layer: Layer.dropElement
      }
    })
    return <div>
      <h4>Neues Element</h4>
      {
        elements.map((item: {name: string, id: number, layer: Layer}, index: number) => {
          return <div
            key={index}
            className="w3-border w3-padding w3-margin"
            onMouseDown={() => {
              mainservice.droplet = item
              mainservice.setInteractionLayer(item.layer)
            }}
            onDragStart={() => false}
          >
            {item.name}
          </div>
        })
      }
    </div>
  }

  renderPageControl() {
    if (
      mainservice.currentDoublePage.anchorId === -1 &&
      mainservice.interactionThingRel &&
      this.state.layer === Layer.page
    ) {
      const page = mainservice.interactionThingRel.state.page
      return <div>
        <BookAdminPropSwitcher
          key={`BAPS-double-page-layout`}
          parent={page}
          name='Mehrspalten Layout'
          key1='layout'
          key2='columns'
          value1Arr={[
            ['inherit', 'wie Doppelseite'],
            ['oneColumn', 'einspaltig'],
            ['twoColumn', 'zweispaltig'],
          ]}
          value1Current={page.getPropValue1('layout', 'columns', 'inherit')}
          cb={() => {
            mainservice.broadcast('rerenderPage-' + page.id, true)
          }}
        />
        <BookAdminPropSwitcher
          key={`image-position-layout`}
          parent={page}
          name='Bild-Position'
          key1='layout'
          key2='picturePosition'
          value1Arr={DirectionMatrix}
          value1Current={page.getPropValue1('layout', 'picturePosition', 'bgPositionInherit')}
          cb={() => {
            mainservice.broadcast('rerenderPage-' + page.id, true)
          }}
          kind={'matrix'}
        />
      </div>
    }
    return null
  }

  render() {
    if (this.state.status === Status.loading) {
      return <div>Lade ...</div>
    }
    return <>
      <div className="activateAdminBar leftAdminActivator"></div>
      <div className="activateAdminBar rightAdminActivator"></div>
      <div className={`adminBar w3-sidebar w3-bar-block w3-light-grey w3-card book-id-${mainservice.getCurrentBookId()}`}>
        <button onClick={() => mainservice.navTo([['view', 'adminlogin']])}>Dashboard</button>
        <button onClick={() => mainservice.navTo([['view', 'overview']])}>Buchübersicht</button>
        {this.renderLayerSelector()}
        {
          this.state.layer != Layer.element &&
          mainservice.interactionThingRel &&
          mainservice.interactionThingRel.setColorClass &&
          <>
            {this.renderColorSelector('backgroundColor', 'Hintergrundfarbe')}
            {this.renderColorSelector('fontColor', 'Schriftfarbe')}
          </>
        }
        {
          mainservice.interactionThingRel &&
          mainservice.interactionThingRel.trash &&
          mainservice.currentDoublePage.anchorId === -1 &&
          <button className="tool" onClick={() => {
            if (mainservice.interactionThingRel &&
              mainservice.interactionThingRel.trash
            ) {
              mainservice.interactionThingRel.trash()
            }
          }}>
            <Trash/>
          </button>
        }
        {
          this.renderPageControl()
        }
        {
          mainservice.interactionThingRel &&
          mainservice.interactionThingRel.manageMedia &&
          mainservice.currentDoublePage.anchorId === -1 &&
          <button className="tool" onClick={() => {
            if (mainservice.interactionThingRel &&
              mainservice.interactionThingRel.manageMedia
            ) {
              this.setState({showFileSelector: true})
            }
          }}>
            <Image />
          </button>
        }
        {
          this.state.layer === Layer.book &&
          this.renderBookControl()
        }
        {
          this.state.layer === Layer.doublePage &&
          this.renderDoublePageControl()
        }
        {
          this.state.layer === Layer.element &&
          <>

          {
            mainservice.currentDoublePage.anchorId === -1 &&
            this.renderDroplets()
          }
          </>
        }
      </div>
      {
        this.state.showFileSelector &&
        <FileList
          parent={this}
          mode={FileListMode.selectPicture}
        />
      }
    </>
  }
}
