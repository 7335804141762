import { GenericEditField, DisplayScope, EditFieldKind } from "../DTO/GenericDTO";



export const EventSpecificEditFields2: GenericEditField[] = [
  {
    title: 'Ausgangslage',
    key1: 'data',
    key2: 'startingSituation',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Ziele (der Unternehmen) / Erwünschtes Ergebnis',
    key1: 'data',
    key2: 'goals',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Organisatorisches',
    key1: 'data',
    key2: 'organizingNotes',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Logbuch',
    key1: 'file',
    key2: 'eventlogbook',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.files
  },
];
