import { Setter } from "../../services/ComService";
import { EqualizeItemListAndSortString, PutIdAtPosition } from "../../services/SortString";
import { TrainingItemEditFields } from "../editFields/TrainingItemEditFields";
import { ObjectKind } from "../models/objectKinds";
import { PublishKind } from "../models/publishKinds";
import { GenericDTO, GenericEditField, IGeneric } from "./GenericDTO";
import { GenericMaterialItemDTO, IGenericMaterialItem } from "./GenericMaterialItemDTO";
import { IMaterialItem, MaterialItemDTO } from "./MaterialItemDTO";

export class TrainingItemDTO extends GenericDTO {
  materialItems: GenericMaterialItemDTO[] = []
  materialItemSorting: string = ''
  constructor(o: ITrainingItem) {
    super(o)
    this.objectKind = ObjectKind.trainingItem
  }

  public async getChildrenFromServer() {
    const url = 'spt/getChildren'
    if (this.id === -1) { return }
    const data = await Setter(url, {id: this.id})
    this.materialItems = this.mixOwnUpChildren(data, ObjectKind.genericMaterialItem).map((item: IGenericMaterialItem) => new GenericMaterialItemDTO(item))
  }

  public async materialItemReplace(oldId: number, newId: number) {
    const materialItemSorting = PutIdAtPosition(this.materialItemSorting, oldId, newId)
    this.materialItemSorting = materialItemSorting
    await this.addProp('materialItem', 'sorting', materialItemSorting)
  }

  public getMaterialItems(): GenericMaterialItemDTO[] {
    // Sort the output
    const sorted = EqualizeItemListAndSortString(this.materialItemSorting, this.materialItems)
    this.materialItemSorting = sorted.sort
    return sorted.items as GenericMaterialItemDTO[] || []
  }

  public async addMaterialItem(position: number, item: MaterialItemDTO) {
    // Do sorting - if item is of type seminar and already there:
    if (item.objectKind === ObjectKind.genericMaterialItem) {
      this.materialItemSorting = PutIdAtPosition(this.materialItemSorting, item.id, position)
      this.addProp('materialItem', 'sorting', this.materialItemSorting)
      if (this.materialItems.find(ti => ti.id === item.id)) {
        return
      }
    }
    // Add item
    const respons = await Setter('spt/linkTargetToParent', {
      parentId: this.id,
      targetId: item.id,
      objectKind: ObjectKind.genericMaterialItem,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar,
    })
    const newItem = new GenericMaterialItemDTO(respons.item)
    /*
    const newItem = new GenericMaterialItemDTO({
      id: -1,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar
    })
    await newItem.saveToServer()
    await newItem.addParentId(this.id)
    await newItem.addUp(item)
    */
    this.materialItems.push(newItem)
  }

  public async trashMaterialItem(item: GenericMaterialItemDTO) {
    // Remove from local list
    this.materialItems = this.materialItems.filter(ti => ti.id !== item.id)
    if (this.publishKind === PublishKind.isSeminar && item.publishKind === PublishKind.isTemplate) {
      this.addToBlacklist(item.id)
    } else {
      const replaceIdRaw = item.getPropV1('link', 'replace')
      if (replaceIdRaw !== '') {
        const replaceId = parseInt(replaceIdRaw, 10)
        this.materialItemReplace(item.id, replaceId)
        // Load the original Data and append it to the modules:
        const url = 'spt/getInfo'
        const data = await Setter(url, {id: replaceId})
        const oldModule = new GenericMaterialItemDTO(data.item as unknown as IMaterialItem)
        this.materialItems.push(oldModule)
      }
    }
    // Remove from Server:
    await item.seminarTrash()
  }

  public getEditFields(): GenericEditField[] {
    return TrainingItemEditFields.map(mef => {
      return {...mef, value: this.getPropV1(mef.key1, mef.key2)}
    })
  }
}

export interface ITrainingItem extends IGeneric {

}

