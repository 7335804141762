import { GenericEditField, DisplayScope, EditFieldKind } from "../DTO/GenericDTO";


export const TrainingItemEditFields: GenericEditField[] = [
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  }
];
