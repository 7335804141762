import ObjGeneric, {DObj, OGProps, OGState} from './ObjGeneric'

export default class ObjCirc extends ObjGeneric {
  constructor(props: OGProps) {
    super(props)
  }
  public render() {
    const obj = this.props.data
    let x, y, w, h
    if (obj.xStart < obj.xEnd) {
      x = obj.xStart
      w = obj.xEnd - obj.xStart || 0
    } else {
      x = obj.xEnd
      w = obj.xStart - obj.xEnd || 0
    }

    if (obj.yStart < obj.yEnd) {
      y = obj.yStart
      h = obj.yEnd - obj.yStart || 0
    } else {
      y = obj.yEnd
      h = obj.yStart - obj.yEnd || 0
    }

    const size = Math.min(w, h) || 0
    return (
      <rect
        key={obj.id}
        x={x || 0}
        y={y || 0}
        width={obj.locked ? size : w}
        height={obj.locked ? size : h}
        fill={obj.bg}
        onMouseDown={(event) => {
          if (!this.props.onMouseDown) { return }
          this.props.onMouseDown(obj, event)
        }}
        onMouseUp={(event) => {
          if (!this.props.onMouseUp) { return }
          this.props.onMouseUp(obj, event)
        }}
      />
    )
  }
}
