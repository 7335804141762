import { useEffect, useState } from "react";
import { TagDTO, TagType, TagTypes, TagsDTO } from "../../DTO/TagDTO";
import { CheckSquare, Plus, Square, Trash } from "react-feather";
import Spinner from "../../../components/Spinner/Spinner";
import { MItem } from "../Menu";
import { HexColorPicker } from "react-colorful";
import InputB from "../../../components/InputB/InputB";
import './TagOrganizer.scss'
import PlaningToolRoot from "../PlaningToolRoot";

enum Status {
  loading,
  standard,
  add,
}

export default function TagOrganizer() {
  const [kind, setKind] = useState('subjectTags')
  const [tags] = useState(new TagsDTO())
  const [status, setStatus] = useState(Status.loading)
  const [newTag, setNewTag] = useState(new TagDTO({id: -1, name: '', kind: 'generic', }))
  const [hasError, setHasError] = useState(false)
  useEffect(() => {
    tags.getFromServerByKind(kind as TagType).then(() => {
      setStatus(Status.standard)
    })
  }, [])
  return <PlaningToolRoot standalone={true} menuItem={MItem.tags} mainContentClass="flex flex-column">
    <div className='contentHeader'>
      <h1>Tagverwaltung</h1>
      <div className='tag-organize-select-kind flex flex-wrap justify-content-space-between'>
        {
          TagTypes.map((tt, index) => {
            return <button
              key={`tag-organizer-select-kind-button-${tt.key}`}
              className={`tab-button ${(tt.key === kind) ? 'tab-active' : 'tab-inactive'}`}
              onClick={async () => {
                setKind(tt.key)
                setStatus(Status.loading)
                await tags.getFromServerByKind(tt.key)
                setStatus(Status.standard)
              }}
            >{tt.name}</button>
          })
        }
      </div>
    </div>
    <div className={`tag-oragnizer-add w3-margin-bottom`}>
      {
        status !== Status.add &&
        <button
          className='positiveButton'
          onClick={() => {
            newTag.reset(kind as TagType)
            setStatus(Status.add)
          }}
        >
          <Plus />
        </button>
      }
      {
        status === Status.add &&
        <div className={`${hasError ? 'w3-yellow' : 'w3-green'} w3-padding`}>
          <Tag
            tag={newTag}
            save={(tag) => {
              setHasError(false)
              tags.addTagLocal(tag)
              setNewTag(new TagDTO({id: -1, name: '', kind: 'generic', }))
              setStatus(Status.standard)
            }}
            error={() => {
              setHasError(true)
            }}
          />
          {
            hasError &&
            <div>Name Bereits vorhanden!</div>
          }
        </div>
      }
    </div>
    <div className={`tag-organizer-tag-list flex-grow`}>
      {
        status === Status.loading &&
        <Spinner />
      }
      { status !== Status.loading &&
        tags.getTags().map((tag, index) => {
          return <Tag
            key={`tag-organizer-tag-${tag.id}`}
            index={index + 1}
            className={`PTListItem`}
            tag={tag}
            save={async (tag: TagDTO) => {
              const r = await tags.addTagLocal(tag)
              setStatus(Status.standard)
              return r
            }}
            error={() => {
              setStatus(Status.loading)
              tags.removeTagLocal(tag.id)
              setStatus(Status.standard)
            }}
            delete={async (id: number) => {
              setStatus(Status.loading)
              await tags.deleteTag(id)
              setStatus(Status.standard)
            }}
            resortnumber={async (newValStr: string) => {
              const newIndex = newValStr ? parseInt(newValStr, 10) : -1
              setStatus(Status.loading)
              await tags.newPosition(tag.id, newIndex)
              setStatus(Status.standard)
            }}
          />
        })
      }
    </div>
  </PlaningToolRoot>
}

function Tag(props: {
  tag: TagDTO,
  save: (tag: TagDTO) => void,
  error?: () => void,
  delete?: (id: number) => void,
  className?: string,
  index?: number,
  resortnumber?: (newIndex: string) => void,
}) {
  const tag = props.tag
  const [index, setIndex] = useState(props.index || -1)
  const [rerender, setRerender] = useState(0)
  useEffect(() => {
    if (props.index) {
      setIndex(props.index)
    }
  }, [props.index])
  return <div
    className={`tag-organizer-tag w3-padding ${props.className || ''}`}
  >
    <div className={`flex`}>
      {props.resortnumber &&
        <InputB
          className={`position-field`}
          value={index + ''}
          returnVal={props.resortnumber}
        />
      }
      <InputB
        className={`flex-grow`}
        value={tag.name}
        returnVal={async (name: string) => {
          const r = await tag.saveName(name)
          // If Name can not be saved - just quit
          if (!r) {
            if (props.error) {
              props.error()
            }
            return
          }
          await props.save(tag)
        }}
      />
      {
        tag.kind === 'contractState' &&
        <button
          title={tag.special}
          className='w3-button'
          onClick={() => {
            if (tag.special.search('leads') > -1) {
              tag.saveSpecial('')
            } else {
              tag.saveSpecial('leads')
            }
            setRerender(rerender + 1)
          }}
        >
          {
            tag.special && tag.special.search('leads') > -1 ?
            <CheckSquare /> :
            <Square />
          }
        </button>
      }
      <ColorPickerDropDown
        currentColor={tag.color}
        changeColor={(c) => {
          tag.saveColor(c)

        }}
      />
      {
        props.delete &&
        <button
          className='w3-button'
          onClick={() => {
            if (props.delete && window.confirm(`Das Tag ${tag.name} wirklich unwiederbringlich löschen?`)) {
              props.delete(tag.id)
            }
          }}
        ><Trash /></button>
      }
    </div>
  </div>
}

export function ColorPickerDropDown(props: {
  currentColor: string,
  changeColor: (c: string) => void,
}) {
  const [currentColor, setCurrentColor] = useState(props.currentColor || '#000')
  function useColor(c: string) {
    setCurrentColor(c)
    props.changeColor(c)
  }
  return  <div className="w3-dropdown-hover">
  <button className="w3-button">
    <div className={`colorBox w3-border`} style={{
      backgroundColor: currentColor,
      height: '24px',
      width: '24px',
      }}></div>
  </button>
  <div className="w3-dropdown-content w3-bar-block w3-border" style={{"right": 0}}>
    <ColorPicker
      currentColor={props.currentColor}
      changeColor={useColor}
    />
    
  </div>
</div> 
}

function ColorPicker(props: {
  currentColor: string,
  changeColor: (c: string) => void,
}) {
  const [currentColor, setCurrentColor] = useState(props.currentColor || '#000')
  function useColor(c: string) {
    setCurrentColor(c)
    props.changeColor(c)
  }
  return <div className='colorPicker'>
    <InputB
      value={currentColor}
      returnVal={useColor}
    />
    <HexColorPicker
      color={currentColor}
      onChange={useColor}
    />
  </div>
}