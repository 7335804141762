import React, { ReactElement } from 'react'
import PageDTO from '../../DTO/PageDTO'
import {Layer} from '../../DTO/BookDTO'
import mainservice, {ElementType} from '../../services/MainService'
import PageElement from '../PageElement/PageElement'
import './Page.scss'


type Props = {
  selectFkt: any,
  page: PageDTO,
  parent: any,
  className: string
}

type State = {
  loading: boolean,
  page: PageDTO,
  isAdmin: number,
  editElementIndex: number,
  sortNumber: number,
  focus: number,
  className: string,
  manualClass: string,
  privateClassName: string,
  giveFocus: number,
  screenHeight: number
}

class Page extends React.Component<Props, State> {
  selectFkt: any
  state: State
  broadCastName: string
  constructor(props: Props) {
    super(props)
    this.selectFkt = props.selectFkt

    this.state = {
      loading: true,
      isAdmin: mainservice.isAdmin(),
      page: props.page,
      editElementIndex: -2,
      sortNumber: props.page.sortNumber,
      focus: 0,
      className: props.className,
      manualClass: '',
      privateClassName: props.page.getCssClass(),
      giveFocus: -1,
      screenHeight: window.innerHeight
    }
    this.broadCastName = `page-${props.page.id}`
  }
  componentDidMount() {
    mainservice.registerToBroadcast(this.broadCastName, (key: string, value: any) => {
      switch(key) {
        case 'window-resize':
          this.setState({screenHeight: value[1]})
          break
        case `rerenderPage-${this.state.page.id}`:
          this.forceUpdate()
          break
      }
    }, this)
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast(this.broadCastName)
  }

  async getContent() {
    await this.state.page.loadFromServer()
    this.forceUpdate()
  }

  trash() {
    if (!window.confirm('Seite wirklich löschen?')) { return }
    this.state.page.remove().then(() => this.props.parent.getContent())
  }

  addPageElement(elementTypeId: number, position: number) {
    if (!this.state.page) { return }
    this.setState({loading: true})
    this.state.giveFocus = position - 1
    // this.setState({giveFocus: position - 1})
    this.state.page.addPageElement(elementTypeId, position).then(() => this.getContent())
  }

  public gainFocus() {
    this.setState({
      focus: 1
    })
  }
  public stopFocus() {
    this.setState({
      focus: 0,
      isAdmin: 1
    })
  }

  public setColorClass(kind: string, value: string) {
    if (value === 'delete') {
      this.state.page.removeProp('colorClass', kind).then(() => this.setState({privateClassName: this.state.page.getCssClass()}))
    }
    this.state.page.addProp({
      id: -1,
      key1: 'colorClass',
      key2: kind,
      value1: value,
      value2: 'NA'
    }).then(() => this.setState({privateClassName: this.state.page.getCssClass()}))
  }

  public async manageMedia(o: {mode: 'use', id: number} | {mode: 'get' | 'getId' | 'remove'}) {
    switch (o.mode) {
      case 'use':
        await this.state.page.setPicture(o.id)
        break
      case 'remove':
        await this.state.page.removePicture()
        break
      case 'getId':
        this.state.page.getPictureId()
        break
      case 'get':
        this.state.page.getPicture()
        break
    }
    this.forceUpdate()
  }

  public instantManageMedia(o: {mode: 'use', id: number} | {mode: 'get' | 'getId' | 'remove'}) {
    switch (o.mode) {
      case 'use':
        this.state.page.setPicture(o.id)
        break
      case 'remove':
        this.state.page.removePicture()
        break
      case 'getId':
        this.state.page.getPictureId()
        break
      case 'get':
        this.state.page.getPicture()
        break
    }
    this.forceUpdate()
  }

  renderAddButton(index: number, key: string) {
    if (this.state.isAdmin > 0) {
      return <div
        key={`add-button-${key}`}
        className='addBlockRoot'
        onMouseUp={() => {
          mainservice.setInteractionLayer(Layer.element)
          this.addPageElement(mainservice.droplet.id, index)
        }}
      >
        <div className="addBlock" >PageElement</div>
      </div>
    }
    return <></>
  }

  renderPageElementsLegacy() {
    const page = this.state.page
    if (!page) { return <></>}
    let index: number = -1
    console.log('RENDER PAGE ELEMENTS', page.pageElements)
    const pageElementsOut = page.pageElements.map((pE, jndex) => {
      // Do not display, if text and empty:
      const layoutClasses = ['kind:standard', 'manualColumns:single', 'absPosition:standard', 'color:standard'].map(o => {
        const t = o.split(':')
        const name = t[0]
        const standard = t[1]
        return `layout-${name}-${pE.getPropVal1('layout', name, standard)}`
      }).join (' ')
      if (this.state.isAdmin === 0 &&
        pE.value1 === '' &&
        pE.elementType.name === 'text'
      ) {
        return null
      }
      index += 1
      return <PageElement
          key={`pageElement-${pE.id}-${jndex}`}
          pageElement={pE}
          selectFkt={(id: number) => console.log('selected id: ', id)}
          parent={this}
          hasFocus={(this.state.giveFocus === index) ? 1 : 0}
          layoutClasses={layoutClasses}
          index={index}
          trailingElement={this.renderAddButton(index + 2, `${pE.id}-${jndex}`)}
        />
    })
    return <>
      {this.renderAddButton(1, `${page.id}`)}
      {pageElementsOut}
    </>
  }

  renderPageElements() {
    let minIndex = -1
    const page = this.state.page
    if (!page) { return <></>}
    let index: number = -1
    console.log('RENDER PAGE ELEMENTS', page.pageElements)
    let pageElementsOut: ReactElement[] = []

    page.pageElements.forEach((pE, jndex) => {
      // TODO Hier sorgen wir dafür, dass Elemente übersprugen werden, wenn sie schon bearbeitet wurden
      if (jndex <= minIndex) { return null }
      // Do not display, if text and empty:
      const layoutClasses = ['kind:standard', 'manualColumns:single', 'absPosition:standard', 'color:standard'].map(o => {
        const t = o.split(':')
        const name = t[0]
        const standard = t[1]
        return `layout-${name}-${pE.getPropVal1('layout', name, standard)}`
      }).join (' ')
      if (this.state.isAdmin === 0 &&
        pE.value1 === '' &&
        pE.elementType.name === 'text'
      ) {
        return null
      }
      index += 1
      const colCount = pE.getPropVal1('cols', 'count')
      const overlayName = pE.getPropVal1('overlay', 'name', '')
      if (!colCount || overlayName !== '' || this.state.isAdmin > 0) {
        pageElementsOut.push(<>
          <PageElement
            key={`pageElement-${pE.id}-${jndex}`}
            pageElement={pE}
            selectFkt={(id: number) => console.log('selected id: ', id)}
            parent={this}
            hasFocus={(this.state.giveFocus === index) ? 1 : 0}
            layoutClasses={layoutClasses}
            index={index}
          />
          {this.renderAddButton(index + 2, `${page.id}-${pE.id}-${jndex}`)}
        </>)
      }
      // Column Erzeuger mal anders:
      
      // if (colCount && this.state.isAdmin === 0) {
      if (colCount && overlayName === '') {
        let colCountInt = parseInt(colCount, 10)
        let className = `_cols _col-count-${colCount}`
        if (colCountInt === 212) {
          colCountInt = 3
        }
        if (colCountInt === 51 || colCountInt === 54 || colCountInt === 511 || colCountInt === 512 || colCountInt === 542) {
          colCountInt = 5
        }
        // TODO - hier sorgen wir dafür, dass die Elemente einfach eingeladen werden
        let extraCols: ReactElement[] = []
        for (let i = 1, m = colCountInt; i <= m; i++) {
          let pEExtra = page.pageElements[i + jndex]
          console.log(pEExtra, i, jndex, i + jndex, page.pageElements.length, page.pageElements)
          if (pEExtra) {
            extraCols.push(<PageElement
              key={`pageElement-${pEExtra.id}-${jndex}`}
              pageElement={pEExtra}
              selectFkt={(id: number) => console.log('selected id: ', id)}
              parent={this}
              hasFocus={(this.state.giveFocus === index + i) ? 1 : 0}
              layoutClasses={layoutClasses}
              index={index + i}
            />)
          }
        }
        let element = <div
          key={`col-el-${pE.id}-${jndex}`}
          className={className}
        >
          {extraCols}
        </div>
        pageElementsOut.push(element)
        minIndex = jndex + colCountInt
      }
    })
    return <>
      {this.renderAddButton(1, `${page.id}-last`)}
      {pageElementsOut}
    </>
  }
  renderToolBar() {
    return <div className="adminToolBar editMode">
    <span className="w3-tag">Page:</span>
    <input className='narrowNumberField' value={this.state.sortNumber} onChange={(event) => this.setState({sortNumber: parseInt(event.target.value, 10) || 0})}/>
    <button onClick={() => this.state.page.setSortNumber(this.state.sortNumber).then(() => this.props.parent.getContent())}>ok</button>
    </div>
  }
  render() {
    // let className = (this.state.isAdmin > 2) ? 'pageAdmin' : 'page'
    const pEs = this.state.page.pageElements
    let className = 'focus-' + this.state.focus
    className += ' ' + this.state.privateClassName
    className += ' column-layout-' + this.state.page.getPropValue1('layout', 'columns', 'inherit')
    // className += ((pEs.length === 0 || pEs.length === 1 && pEs[0].elementType.name === 'text' && pEs[0].value1 === '') ? '' : ' page-padding')
    className += ' ' + this.state.page.getPropValue1('layout', 'picturePosition', 'inherit')
    className += ' page-padding'
    // Mark page if no content and no backGroundImage:
    className += (pEs.length === 0 && !this.state.page.picture) ? ' blank-page' : ''
    const pageElements = this.renderPageElements()
    const backGroundImage = this.state.page.getCssBGPicture()
    // const screenHeight = window.innerHeight
    const style = (backGroundImage) ? {
      backgroundImage: backGroundImage,
      minHeight: this.state.screenHeight + 'px'
    } : {
      minHeight: this.state.screenHeight + 'px'
    }
    return <div
      id={`pageId-${this.state.page.id}`}
      className={`${this.state.className} ${className}`}
      onClick={() => mainservice.thingHasBeenClicked(Layer.page, this)}
      style={style}
    >
      {this.state.isAdmin > 0 && this.renderToolBar()}
      <div
        id={`pageId-${this.state.page.id}-bg-holder`}
        className="background-holder"
        style={style}
      ></div>
      {pageElements}
    </div>
  }
}

export default Page
