import { Getter, Setter } from "../services/ComService";
import WidgetDTO, { IWidget, WidgetStatus, WidgetTypes } from "./WidgetDTO";
import AsyncWaitForAll from '../services/AsyncWaitForAll'
import { Emoji2PlainText } from "../services/EmojiServices";

export default class WidgetAdminDTO extends WidgetDTO {
  /*
  constructor(data: IWidget) {
    super(data)
  }
  */

  async getFromServer() {
    const data = await Getter(`admin/widget/${this.id}`)
    if (!data?.data) { return }
    this.init(data.data)
  }

  setName(name: string) {
    this.name = name
  }
  setDescription(description: string) {
    this.description = description
  }
  setType(t: number) {
    this.widgetType = t
  }
  setStatus(s: number) {
    this.status = s
  }
  getStatusName(): string {
    const s = WidgetStatus.find(ws => ws.id === this.status)
    return s?.name || 'NAN'
  }
  async save(): Promise <string> {
    if (!this.name) {
      return 'Es muss ein Name ausgewählt werden!'
    }
    if (this.widgetType < 1) {
      return 'Es muss ein Typ ausgewählt werden!'
    }
    let method = this.id > -1 ? 'PATCH' : 'PUT'
    Setter('admin/widget', {
      id: this.id,
      name: this.name,
      description: this.description,
      type: this.widgetType,
      status: this.status,
    }, {method: method})
    return ''
  }
  getPreferenceManifest() {
    const t = this.getType()
    return WidgetTypes.find(wt => wt.id === t)?.globPreferences || []
  }
  async setProp(prop: {
    id?: number,
    key1: string,
    key2: string,
    value1: string,
    value2?: string,
  }) {
    prop.value2 = prop.value2 || ''
    if (this.pageElement) {
      // If we are in pageElement context, we really want to save with pageElement:
      await this.pageElement.addProp({...prop, ...{
        id: -1,
        value2: prop.value2 || ''
      }})
      return
    }
    const old = this.getProp(prop, true)
    const server = await Setter('admin/widget/prop',
      {
        ...prop,
        ...{
          widgetId: this.id,
          value1: Emoji2PlainText(prop.value1),
          value2: Emoji2PlainText(prop.value2),
        }
      },
      {method: 'PUT'}
    )
    if (old) {
      old.value1 = prop.value1
      old.value2 = (prop.value2 === undefined) ? '' : prop.value2
    } else {
      this.props.push(server.data)
    }
  }

  getNewPropSortNumber(key1: string) {
    return (this.getProps(key1).length + 1) * 10
  }

  async moveSibling(p: {key1: string, key2: string}, dir: number) {
    const prop = this.getProp(p)
    if (!prop) { return }
    prop.value2 = (parseInt(prop.value2, 10) + (dir * 15)) + ''
    await this.doSortNumbersAndSave(p.key1)
  }

  async doSortNumbersAndSave(key1: string) {
    const props = this.getProps(key1)
    await AsyncWaitForAll(props.map((p, index) => {
      return async () => {
        await this.setProp({...p, ...{value2: (index + 1) * 10 + ''}})
      }
    }))
  }

  async removeProp(p: {key1: string, key2: string}) {
    const prop = this.getProp(p)
    if (this.pageElement) {
      // In pageElement context we only refer to things in pageElement
      const externAttributes = this.pageElement.getProps('childAttribute' + prop?.id)
      await this.pageElement.removeElementProp(p.key1, p.key2)
      await AsyncWaitForAll(externAttributes.map((p) => {
        return async () => {
          await this.pageElement?.removeElementProp(p.key1, p.key2)
        }
      }))
      return
    }
    if (!prop) { return }
    // Find all children and add them to the remove list:
    const id = prop.id
    let deleteList = this.getProps('childAttribute' + id)
    deleteList.push(prop)
    this.props = this.props.filter(p => !deleteList.some(i => i.id === p.id))
    await AsyncWaitForAll(deleteList.map((p) => {
      return async () => {
        await Setter('admin/widget/prop', {
          id: p.id,
          widgetId: this.id,
        }, {method: 'DELETE'})
      }
    }))
  }

  async setPropKey2(p: {key1: string, key2: string}, key2: string) {
    const prop = this.getProp(p)
    if (this.pageElement) {
      const extProp = this.pageElement.getProp(p.key1, p.key2)
      const key1 = p.key1 + ''
      const key2new = key2 + ''
      const value1 = extProp ? extProp.value1 : prop?.value1 || ''
      const value2 = extProp ? extProp.value2 : prop?.value2 || ''
      if (!extProp) { return }
      await this.pageElement.removeElementProp(p.key1, p.key2)
      await this.pageElement.addProp({
        id: -1,
        key1: key1,
        key2: key2new,
        value1: value1,
        value2: value2,
      })
      return
    }
    if (!prop) { return }
    prop.key2 = key2
    await Setter('admin/widget/prop/key2', {
      id: prop.id,
      widgetId: this.id,
      key2: key2,
    }, {method: 'PATCH'})
  }

}
