const failSaveMax = 2000

export function EqualizeItemListAndSortString(sortString: string, items: any[]) {
  const arr = sortString.split(';')
  const arrLength = arr.length
  const itemsLength = items.length
  let out = []
  let newSortArr = []
  let arrIndex = 0
  let usedItemsCount = 0
  let itemIndex = 0
  let failSafeIndex = 0
  while(usedItemsCount < itemsLength && arrIndex < arrLength && failSafeIndex < failSaveMax) {
    let currentId = parseInt(arr[arrIndex], 10)
    let currentItem = items.find(i => i.id === currentId)
    if (currentItem) {
      out.push(currentItem)
      newSortArr.push(currentId)
      usedItemsCount += 1
    }
    arrIndex += 1
    failSafeIndex += 1
  }
  // If there are additional items - add them to the end:
  while(usedItemsCount < itemsLength && itemIndex < itemsLength  && failSafeIndex < failSaveMax) {
    let currentItem = items[itemIndex]
    if (out.findIndex(i => i.id === currentItem.id) === -1) {
      out.push(currentItem)
      newSortArr.push(currentItem.id)
      usedItemsCount += 1
    }
    itemIndex += 1
    failSafeIndex += 1
  }
  return {
    items: out,
    sort: newSortArr.join(';')
  }
}

export function SortItemsBySortString(sortString: string, items: any[]) {

  const result = EqualizeItemListAndSortString(sortString, items)
  return result.items
}

export function UpdateSortString(sortString: string, items: any[]) {
  return EqualizeItemListAndSortString(sortString, items).sort
}

/**
 * Function to add or move an id to a position in a searchstring
 * The function first splits the sortString into an array of item-ids, filters out any occurrences of the provided id, inserts the new id at the specified position, and then joins the array back into a string using semicolons as separators.
 * @param sortString: A string of item-ids separated by semicolons.
 * @param id: The new item-id to be added to the sortString.
 * @param position: The position at which the new item-id should be inserted.
 * @returns new sort String with tehe 
 */
export function PutIdAtPosition(sortString: string, id: number, position: number): string {
  const ids = sortString.split(';')
  // correct new position:
  const oldPosition = ids.indexOf(`${id}`)
  if (oldPosition > 0 && oldPosition < position) {
    position -= 1
  }
  let idsWip = ids.filter(i => i !== `${id}`)
  idsWip.splice(position, 0, `${id}`)
  return idsWip.join(';')
}

export function RemoveId(sortString: string, id: number): string {
  return sortString.split(';').filter(i => i !== `${id}`).join(';')
}

export function ReplaceIdWithId(sortString: string, oldId: number, newId: number): string {
  const oldIdS = `${oldId}`
  const newIdS = `${newId}`
  return sortString.split(';').map(i => (i === oldIdS) ? newIdS : i).join(';')
}
