import config from '../config.json'
import { parseStringToServer, extractFirstHeadline, parseStringFromServer } from '../services/TextConverter'

export default class BookPropDTO {
  public id: number
  public key1: string
  public key2: string
  public value1: string
  public value2: string

  constructor(o: IBookProp) {
    this.id = o.id
    this.key1 = parseStringFromServer(o.key1)
    this.key2 = parseStringFromServer(o.key2)
    this.value1 = parseStringFromServer(o.value1)
    this.value2 = parseStringFromServer(o.value2)
  }

  get() {
    return {
      id: this.id,
      key1: this.key1,
      key2: this.key2,
      value1: this.value1,
      value2: this.value2
    }
  }

  public async remove() {
    await fetch(`${config.apiPrefix}BookProp/${this.id}/remove`)
  }

  public setValue1(value: string) {
    this.value1 = value
    this.updateServer()
  }

  public async updateServer() {
    await fetch(`${config.apiPrefix}BookProp/${this.id}/${parseStringToServer(this.key1)}/${parseStringToServer(this.key2)}/${parseStringToServer(this.value1)}/${parseStringToServer(this.value2)}`)
  }
}

export interface IBookProp {
  id: number
  key1: string
  key2: string
  value1: string
  value2: string
}
