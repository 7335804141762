import React from 'react'
// import { Getter } from '../../services/ComService'
import mainservice from '../../services/MainService'
import CollectibleDTO, { CollectibleType, CollectiblesDTO } from '../../DTO/CollectibleDTO'
import CollectibleItemC from './CollectibleItemC'
// import { X } from 'react-feather'
import Spinner from '../Spinner/Spinner'
import { Remove } from '../Icon/Icon'

type Props = {
  type: CollectibleType
}

type State = {
  collectibles: CollectibleDTO[],
  showSingle: number,
  loading: boolean
}

export default class CollectibleListB extends React.Component<Props, State> {
  collectibles = new CollectiblesDTO({version: 'user'})
  constructor(props: Props) {
    super(props)
    this.state = {
      collectibles: [],
      showSingle: -1,
      loading: true
    }
  }

  componentDidMount() {
    this.getData()
    mainservice.registerToBroadcast('collectibleListB', (key: string, value: any) => {
      this.broadCastListener(key, value)
    }, this)
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('collectibleListB')
  }

  getData() {
    this.collectibles.getFromServer().then(() => {
      this.setState({
        collectibles: this.collectibles.get({type: this.props.type}),
        loading: false
      })
    })
  }

  broadCastListener(key: string, value: any) {
    switch(key) {
      case 'nav':
        const navData = value as {view: string}
        // Only load information if this component will still be used
        if (value.view.search(/collectible/) > -1) {
          this.getData()
        }
        break
    }
  }

  render() {
    if (this.state.showSingle > -1) {
      return <div className='lightbox'>
        {
          this.state.collectibles.filter((c) => c.id === this.state.showSingle).map((c) => <CollectibleItemC
            key={c.id}
            collectible={c}
            className='lightboxItem'
          />)
        }
        <button
          onClick={() => {
            this.setState({showSingle: -1})
          }}
        ><Remove /></button>
      </div>
    }
    return <div className={`CollectibleListB w3-row p-m-1 p-l-1 p-xl-1`}>
      {this.state.loading && <Spinner />}
      {
        this.state.collectibles.length > 0 &&
        this.state.collectibles.map((c, index) =>
          <CollectibleItemC
            key={`${c.id}-${index}`}
            collectible={c}
            onClick={(id) => {
              this.setState({showSingle: id})
            }}
          />
        )
      }
      {
        !this.state.loading && this.state.collectibles.length === 0 &&
        <div className='noData'>Lernkarten kannst du in den Workbooks sammeln. Die eingesammelten Lernkarten werden dir dann an dieser Stelle angezeigt.</div>
      }
    </div>
  }
}
