import { GenericEditField, DisplayScope, EditFieldKind } from "./GenericDTO";

export const CustomerLocationEditFields: GenericEditField[] = [
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Adresse',
    key1: 'data',
    key2: 'address',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
];
