import { MoreHorizontal, RefreshCcw, Save, Trash, X } from "react-feather";
import { EventDTO } from "../../DTO/EventDTO";
import { ObjectKind } from "../../models/objectKinds";
import mainservice from "../../../services/MainService";
import { DisplayDateFromString } from "../../../services/DateTime";
import { TagSelector } from "../tags/TagSelector";
import { TagDTO } from "../../DTO/TagDTO";
import PersonList from "../Person/PersonList";
import { PersonKind } from "../Person/PersonKind";
import { EventTagDisplay } from "../Person/Person";


export function DisplayEvent(props: {
  item: EventDTO,
  className?: string,
  onClick?: (id: number, item: EventDTO) => void,
  onRemove?: () => void,
  onTrash?: () => void,
  onUntrash?: () => void,
  onDublicate?: () => void,
  onDublicateAsDesign?: () => void,
  compact?: boolean | 'name' | 'participated',
  personId?: number,
}) {
  const item = props.item;
  function onClick () {
    if (props.onClick) {
      return props.onClick(item.id, item);
    }
    mainservice.navTo([['view', item.objectKind === ObjectKind.event ? 'spVeranstaltungsDetails' : 'spVeranstaltungsDesigner'], ['id', item.id]]);
  }
  const veranstaltungsnummer = item.getPropV1('data', 'uniqueEventId')
  return <div
    className={`DisplayEvent PTListItem justify-content-space-between`}
  >
    <div
      className={`pointer  ${props.compact ? 'w3-padding' : ''}`}
      onClick={onClick}
    >
      {((props.compact !== 'name' && props.compact !== 'participated') && (item.startdate || item.enddate)) &&
        <div className='w3-margin-bottom'>
          <label className='EditFieldLabel'>Datum</label>
          <div>{DisplayDateFromString(item.startdate )} bis {DisplayDateFromString(item.enddate )}</div>
        </div>
      }
      <div
        className={`EventName ${props.className || ''}`}
      >
        {[item.titlePrefix, item.name, item.titleSuffix].filter(i => i).join(' ')}
      </div>
      {
        item.objectKind === ObjectKind.event && veranstaltungsnummer &&
        <div>
          <label className='EditFieldLabel'>Veranstaltungsnummer</label>
          <div>{veranstaltungsnummer}</div>
        </div>
      }
    </div>
    {
      props.compact === 'participated' &&
      <div className='w3-padding eventDate'>
        {DisplayDateFromString(item.startdate )} bis {DisplayDateFromString(item.enddate )}
      </div>
    }
    {
      props.personId &&
      <EventTagDisplay
        className='w3-padding participation'
        personId={props.personId}
        event={item}
      />
    }
    
    
    {
      !props.compact &&
      <>
          { item.objectKind === ObjectKind.event &&
            <div className='hide-on-gantt'>
              <TagSelector
                kind={"contractState"}
                mode={"single"}
                selectedTags={item.getTagsByKind("contractState", item.appointmentKey)}
                parentId={item.id}
                selectTag={(tag: TagDTO, single: boolean) => {
                  item.setTag(tag, !item.appointmentKey, item.appointmentKey);
                }}
                unsetTag={(tag: TagDTO) => {
                  item.unsetTag(tag);
                }}
                readMode={false}
              />
              
            </div>
          }
          { item.objectKind === ObjectKind.event &&
            <div className='hide-on-gantt'>
              <TagSelector
                kind={"eventKind"}
                mode={"single"}
                selectedTags={item.getTagsByKind("eventKind")}
                parentId={item.id}
                selectTag={(tag: TagDTO, single: boolean) => {
                  item.setTag(tag, single);
                }}
                unsetTag={(tag: TagDTO) => {
                  item.unsetTag(tag);
                }}
                readMode={false}
              />  
            </div>
          }
          { item.objectKind === ObjectKind.event &&
            <div className='hide-on-gantt'>
              <TagSelector
                kind={"billingStatus"}
                mode={"single"}
                selectedTags={item.getTagsByKind("billingStatus")}
                parentId={item.id}
                selectTag={(tag: TagDTO, single: boolean) => {
                  item.setTag(tag, single);
                }}
                unsetTag={(tag: TagDTO) => {
                  item.unsetTag(tag);
                }}
                readMode={false}
              />
              
            </div>
          }
          { item.objectKind === ObjectKind.event &&
            <div className='hide-on-gantt'>
              <TagSelector
                kind={"priorityA"}
                mode={"single"}
                selectedTags={item.getTagsByKind("priorityA")}
                parentId={item.id}
                selectTag={(tag: TagDTO, single: boolean) => {
                  item.setTag(tag, single);
                }}
                unsetTag={(tag: TagDTO) => {
                  item.unsetTag(tag);
                }}
                readMode={false}
              />  
            </div>
          }
          { item.objectKind === ObjectKind.seminar &&
            <div>
              <TagSelector
                kind={"subjectTags"}
                mode={"list"}
                selectedTags={item.getTagsByKind("subjectTags")}
                parentId={item.id}
                selectTag={(tag: TagDTO, single: boolean) => {
                  item.setTag(tag, single);
                }}
                unsetTag={(tag: TagDTO) => {
                  item.unsetTag(tag);
                }}
                readMode={false}
              />  
            </div>
          }
          { item.objectKind === ObjectKind.seminar &&
            <div>
              <TagSelector
                kind={"workStateTag"}
                mode={"single"}
                selectedTags={item.getTagsByKind("workStateTag")}
                parentId={item.id}
                selectTag={(tag: TagDTO, single: boolean) => {
                  item.setTag(tag, single);
                }}
                unsetTag={(tag: TagDTO) => {
                  item.unsetTag(tag);
                }}
                readMode={false}
              />  
            </div>
          }
          {item.objectKind === ObjectKind.event &&
            <div>
              <div>
                {item.customerName}
              </div>
              <PersonList
                eventId={item.id}
                className=' '
                noSearch={true}
                personKind={PersonKind.principal}
                propTrainerTagParent={item}
                compact={true}
                noCount={true}
                prefix={'Auftraggeber*in: '}
                persons={item.principal ? [item.principal] : []}
              />
            </div>
          }
          {item.objectKind === ObjectKind.event &&
            <div>
              <PersonList
                eventId={item.id}
                className=' '
                noSearch={true}
                personKind={PersonKind.trainer}
                propTrainerTagParent={item}
                compact={'bubble'}
                noCount={true}
                prefix={'Trainer: '}
                persons={item.trainers}
              />
            </div>
          }
        <div>
          <div className="w3-dropdown-hover w3-right hide-on-gantt">
            <button className="w3-button"><MoreHorizontal /></button>
            <div className="w3-dropdown-content w3-bar-block w3-card-4" style={{right:0}}>
              { !item.deletedDate && props.onDublicate &&
                <button
                  className={`w3-button w100 textLeft`}
                  onClick={props.onDublicate}
                ><Save /> Kopie Speichern</button>
              }
              { !item.deletedDate && props.onDublicateAsDesign &&
                <button
                  className={`w3-button w100 textLeft`}
                  onClick={props.onDublicateAsDesign}
                ><Save /> Als Design speichern</button>}
              {
                item.deletedDate &&
                <button
                  className={`w3-button w100 textLeft`}
                  onClick={props.onUntrash}
                ><RefreshCcw /> Wieder herstellen</button>
              }
              {props.onRemove &&
                <button
                  className={`w3-button w100 textLeft`}
                  onClick={props.onRemove}
                ><X /> Enfernen</button>
              }
              {props.onTrash &&
                <button
                  className={`w3-button w100 textLeft`}
                  onClick={props.onTrash}
                ><Trash /> Löschen</button>
              }
            </div>
          </div>
        </div>
      </>
    }
    
  </div>;
}
