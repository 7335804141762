import ItemPersonDTO from "../../DTO/ItemPersonDTO";
import mainservice from "../../../services/MainService";



export function PersonBubble(props: {
  item: ItemPersonDTO;
  onClick?: (id: number, item: ItemPersonDTO) => void;
}) {
  const item = props.item;
  return <div
    title={`${item.getDisplayName()}`}
    className={`personBubble`}
    onClick={() => {
      if (props.onClick) {
        props.onClick(item.id, item);
        return;
      }
      mainservice.navTo([['view', 'spPersonEdit'], ['id', item.id]]);
    }}
    style={{ backgroundColor: item.nameToColor() }}
  ><span>{item.getInitials()}</span></div>;
}
