import React from 'react'
// import PageElementDTO from '../../../DTO/PageElementDTO'
// import ElementPropDTO, {IElementProp} from '../../../DTO/ElementPropDTO'
import { Edit, Trash, ArrowRight, Image } from 'react-feather'
import CardDTO from './CardDTO'
import mainservice from '../../../services/MainService'
import './Card.scss'

const hoverWhenDragged: number = 220

enum Mode {
  standard,
  edit,
  selectPicture
}

type XY = [number, number]

type Area = 'left' | 'none' | 'right'

type Props = {
  card: CardDTO,
  game: any,
  editOk?: boolean
}

type State = {
  mode: Mode,
  titleEdit: string,
  zPos: number,
  movementIsActivated: boolean
}

export default class Card extends React.Component<Props, State> {
  // movementIsActivated: boolean = false
  movementStartCoordinates: XY = [0, 0]
  movementBoxStartCoordinates: XY = [0, 0]
  movementArea: 'left' | 'none' | 'right' = 'none'
  movementTriggerWidth: number = 0
  movementRecognizeWidth: number = 20
  movementRecognized: boolean = false
  movementStamp: number = 0
  movementStampPosition: number = 0
  movementLock: boolean = false
  cardRef: any
  id: string
  aboveGround: number = 0
  constructor(props: Props) {
    super(props)
    this.id = 'CardSortGameCard-' + props.card.id
    this.cardRef = React.createRef()
    this.state = {
      mode: props.card.spawn ? Mode.edit : Mode.standard,
      titleEdit: props.card.title,
      zPos: 0,
      movementIsActivated: false
    }
  }

  componentDidMount() {
    mainservice.registerToBroadcast(this.id, (key: string, value: string | {key: string, fileId: number, fileName: string}) => {
      switch(key) {
        case 'CardSortGame-Reset':
          let c = this.cardRef.current
          c.style.top = null
          c.style.left = null
          c.style.transform = null
          return
        case 'CardSortGamePutCard':
          const v = value as unknown as {cardId: string, target: Area}
          if (v.cardId === this.props.card.id && v.target !== 'none') {
            this.setSide(v.target)
          }
          return
        case 'fileListSelect':
          const fileName = (value as {fileName: string}).fileName
          const key = (value as {key: string}).key
          if (key === `cardBgSelect-${this.id}`) {
            this.props.card.save({picture: fileName})
          }
          return
      }
    }, this)
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast(this.id)
  }

  movementActivate(e: any) {
    mainservice.pageFlipInteractive = false
    // Do not allow moving, if the user has decided:
    if (this.props.card.userSide || this.movementLock) { return }
    this.movementStartCoordinates = this.getPositionFromEvent(e)
    /*
    offsetHeight: 276
    offsetLeft: 214
    ​offsetTop: 162
    offsetWidth: 364
    */
    let c = this.cardRef.current
    const top = c.offsetTop
    const left = c.offsetLeft
    this.movementBoxStartCoordinates = [left, top]
    this.movementTriggerWidth = c.offsetWidth * 0.5
    c.style.left = left + 'px'
    c.style.top = top + 'px'
    c.style.transform = `translate3d(0, 0, ${hoverWhenDragged}px)`
    this.setState({
      movementIsActivated: true
    })
  }

  movementMove(e: any) {
    if (!this.state.movementIsActivated || this.movementLock) { return }
    const newMouseCoordinates = this.getPositionFromEvent(e)
    const diff = this.xyDiff(this.movementStartCoordinates, newMouseCoordinates)
    let c = this.cardRef.current
    if (!this.movementRecognized && this.movementRecognizeWidth < Math.abs(diff[0])) {
      this.movementRecognized = true
      this.movementStamp = Date.now()
      this.movementStampPosition = diff[0]
      c.style.zIndex = 200
    } else if (!this.movementRecognized) {
      return
    }
    const newBoxCoordinates = this.xyAdd(this.movementBoxStartCoordinates, diff)
    c.style.left = newBoxCoordinates[0] + 'px'
    c.style.top = newBoxCoordinates[1] + 'px'
    c.style.transform = this.getWindAngle(diff[0])
    this.checkMovementArea(diff[0])
  }

  getWindAngle(x: number) {
    const speed = this.getSpeed(x)
    const d = 0.08
    let angle = 0
    if (speed < -d) {
      angle = 0.3
    } else if (speed > d) {
      angle = -0.3
    } else {
      return `rotate3d(1, 0, 0, 5deg) translate3d(0, 0, ${hoverWhenDragged}px)`
    }
    // return `rotate(${angle}deg)`
    return `rotate3d(1, 0, ${angle}, 5deg) translate3d(0, 0, ${hoverWhenDragged}px)`
  }

  getSpeed(x: number) {
    const lDelta = x - this.movementStampPosition
    const now = Date.now()
    const tDelta = now - this.movementStamp
    if (tDelta === 0) {
      return 0
    }
    return lDelta / tDelta
  }

  checkMovementArea(xDiff: number) {
    const triggerDelta = this.movementTriggerWidth
    const doIt = (area: string) => {
      mainservice.broadcast('cardSortGameDropArea', area)
      this.movementArea = area as Area
    }
    if (this.movementArea !== 'left' && xDiff < -triggerDelta) {
      doIt('left')
    } else if (this.movementArea === 'left' && xDiff > -triggerDelta) {
      doIt('none')
    } else if (this.movementArea !== 'right' && xDiff > triggerDelta) {
      doIt('right')
    } else if (this.movementArea === 'right' && xDiff < triggerDelta) {
      doIt('none')
    }
  }

  setHeightAboveGround(cardNumberAboveGround: number) {
    const limitHeightAtCardCount = 6
    const heightFactor = 3
    const endHeight = Math.min(limitHeightAtCardCount, cardNumberAboveGround) * heightFactor + ((cardNumberAboveGround > limitHeightAtCardCount) ? (cardNumberAboveGround - limitHeightAtCardCount) : 0)
    return endHeight
  }

  movementFinished(e: any, reset: boolean) {
    if (!this.state.movementIsActivated || this.movementLock) { return }
    this.movementLock = true
    let c = this.cardRef.current
    c.style.top = 0
    c.style.left = null
    const endHeight = this.setHeightAboveGround(this.state.zPos)

    this.setState({
      movementIsActivated: false
    })
    if (this.movementArea != 'none' && this.movementRecognized) {
      this.setSide(this.movementArea)
      c.style.transform = `rotate3d(1, 0, 0, 10deg) translate3d(0, 0, ${endHeight}px)`
    } else {
      c.style.transform = null
    }
    this.movementRecognized = false
    mainservice.broadcast('cardSortGameDropArea', 'none')
    this.movementLock = false
  }

  xyDiff(a: XY, b: XY): XY {
    return [b[0] - a[0], b[1] - a[1]]
  }

  xyAdd(a: XY, b: XY): XY {
    return [b[0] + a[0], b[1] + a[1]]
  }

  getPositionFromEvent(e: any): XY {
    // possible values:
    // clientX
    // pageX
    // screenX
    console.log('getPositionFromEvent', e)
    if (e.clientX || e.clientY) {
      return [e.clientX || 0, e.clientY || 0]
    }
    if (e.touches && e.touches[0] && e.touches[0].clientX && e.touches[0].clientY) {
      return [e.touches[0].clientX, e.touches[0].clientY]
    }
    return [0, 0]
  }

  renderShow() {
    const card = this.props.card
    let cardStyle: {transform?: string, top?: string, height?: string, zIndex?: number} = {
      //top: `${this.state.yPos}%`
    }
    const endHeight = this.setHeightAboveGround(this.aboveGround)
    //const height = this.props.game.endCardHeight()
    if (this.props.card.userSide !== '') {
      //cardStyle.height = `${height}px`
      cardStyle.zIndex = this.state.zPos + 11
      cardStyle = {transform: ` rotate3d(1, 0, 0, 10deg) translate3d(0, 0, ${endHeight}px) `}
    }
    return <div
      id={card.id}
      ref={this.cardRef}
      onMouseDown={(e) => {this.movementActivate(e)}}
      onTouchStart={(e) => {
        this.movementActivate(e)
      }}
      onMouseMove={(e) => {this.movementMove(e)}}
      onTouchMove={(e) => {
        this.movementMove(e)
      }}
      onMouseUp={(e) => {this.movementFinished(e, false)}}
      onTouchEnd={(e) => {
        this.movementFinished(e, false)
      }}
      onTouchCancel={(e) => {
        this.movementFinished(e, false)
      }}
      onMouseLeave={(e) => {this.movementFinished(e, false)}}
      className={`cardBody ${this.props.card.getSideClass()} ${this.props.card.isPlacingGood() ? 'card-correct' : 'card-incorrect'} ${(!this.state.movementIsActivated) ? 'animation' : 'dragging'}`}
      style={cardStyle}
    >
      <div className='cardBodyBody' style={{
        backgroundImage: this.props.card.getBgPicture()
      }}>
        <div className='cardContent'>
          {
            (
              this.props.editOk ||
              this.props.card.getBgPicture() == 'none' ||
              this.props.card.alwaysShowTitle
            ) &&
            <div className={`title ${(card.title.length > 124) ? 'title-long' : ''}`}>{card.title}</div>
          }
          {
            this.props.editOk &&
            <>
              <button
              onClick={ () => this.setState({ mode: Mode.edit }) }
              >
              <Edit
              />
              </button>

              <button
                onClick={ () => {
                  mainservice.broadcast('fileListOpen', {
                    broadcastName: `cardBgSelect-${this.id}`
                  })
                }}
              ><Image /></button>

              <button
                onClick={ async () => {
                  await this.props.card.save({picture: '-'})
                }}
              ><Image /><ArrowRight /><Trash /></button>

              <button
                onClick={ () => this.trash() }
              ><Trash /></button>
              <div>
                <input
                  type='checkbox'
                  defaultChecked={this.props.card.alwaysShowTitle}
                  onChange={(e) => {
                    this.props.card.save({alwaysShowTitle: !this.props.card.alwaysShowTitle})
                  }}
                />Titel immer zeigen
              </div>
            </>
          }
        </div>
      </div>
    </div>
  }

  setSide(side: 'left' | 'right') {
    let c = this.cardRef.current
    if (c?.style) {
      c.style.top = null
      c.style.left = null
      c.style.zIndex = null
      c.style.transform = null
    }
    this.movementArea = 'none'
    // const yPos = this.props.game.getNewYPosition(side)
    this.props.card.userSetSide(side)
    // this.setState({yPos: yPos})
    this.props.game.checkForFinish()
    this.aboveGround = this.props.game.getNewZPosition(this.props.card.userSide)
    this.forceUpdate()
  }

  save() {
    this.props.card.save({
      title: this.state.titleEdit,
      picture: '',
    })
    this.props.game.forceUpdate()
  }

  trash() {
    if (window.confirm('Möchtest Du die Karte wirklich löschen?')) {
      this.props.card.trash()
      this.props.game.adminTrashCard(this.props.card.id)
    }
  }

  renderEdit() {
    return <div className='cardEditBody'>
      Titel: <input
        value={this.state.titleEdit}
        onChange={ (event) => {
          this.setState({
            titleEdit: event.target.value
          })
        } }
      />
      <button
        onClick={ () => {
          this.props.card.switchSide()
          this.save()
        } }
      >Seitenwechsel</button>
      <button
        onClick={ () => {
          this.save()
          this.setState({ mode: Mode.standard })
        } }
      >
        Speichern
      </button>
    </div>
  }

  render() {
    if (this.state.mode === Mode.standard) {
      return this.renderShow()
    }
    return this.renderEdit()
  }
}
