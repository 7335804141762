import { DayDTO } from "../../DTO/DayDTO";
import { ObjectKind } from "../../models/objectKinds";
import DayView, { DisplayMode, Status as DayStatus, Status } from "./dayView";
import './daysView.scss';
import { SeminarDayDTO } from "../../DTO/SeminarDayDTO";
import { PublishKind } from "../../models/publishKinds";
import { DropZone } from "../../elements/DropZone";
import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { GenericDTO } from "../../DTO/GenericDTO";
import ModuleDTO from "../../DTO/ModuleDTO";
import DayModuleDTO from "../../DTO/DayModuleDTO";

export default function DaysView (props: {
  items: SeminarDayDTO[]
  identKey: string,
  parentPublishKind: PublishKind,
  parentObjectKind: ObjectKind,
  parent?: GenericDTO,
  rerender?: number,
  getItems?: () => Promise<SeminarDayDTO[]>,
  addItem?: (position: number, item: DayDTO, type: 'position' | 'template2seminar') => void,
  trashItem?: (item: SeminarDayDTO) => void,
  setDayStatus?: (status: DayStatus) => void,
  dayStatus?: DayStatus,
  rerenderRightSide?: () => void,
  setEditItem?: (module: ModuleDTO | DayModuleDTO) => void,
  currentActiveDay?: number,
  setCurrentActiveDay?: (status: number) => void,
}) {
  const [currentActiveDay, setCurrentActiveDay] = useState(props.currentActiveDay !== undefined ? props.currentActiveDay : -Status.dayView)
  const [status, setStatus] = useState((props.getItems) ? DayStatus.loading : DayStatus.dayView)
  const [items, setItems] = useState(props.items)
  useEffect(() => {
    if (props.getItems) {
      setStatus(Status.loading)
      props.getItems().then((items: SeminarDayDTO[]) => {
        setItems(items)
        setStatus(DayStatus.dayView)
        // setStatus(currentActiveDay !== undefined && currentActiveDay < 0 ? -currentActiveDay : (props.dayStatus || DayStatus.agendaPlusView))
      })
    }
  }, [props.rerender])
  if (status === DayStatus.loading) {
    return <Spinner />
  }
  const itemCount = items.length
  return <div className={`DaysView`}>
    <DropZone
      label={``}
      key={`${props.identKey}-dropzone-${0}-${0}`}
      position={0}
      addItem={async (position, item) => {
        if (props.addItem) {
          await props.addItem(0, item, 'position')
        }
      }}
      acceptKind={ObjectKind.day}
      className='ptdropzoneHorizontal ptdropTopGeneric'
    />
    {items.length === 0 ? <div
      className='w3-padding textCenter'
    >
      Noch keine Tage angelegt
    </div> : null}
    {
      items.map((day, index) => {
        return <DayView
            key={`${props.identKey}-day-${day.id}`}
            day={day}
            displayMode={DisplayMode.leftSide}
            trash={props.trashItem}
            status={status}
            parent={props.parent}
            setStatus={(status) => {
              if (status === Status.agendaView || status === Status.agendaPlusView) {
                setCurrentActiveDay(-status)
                if (props.setCurrentActiveDay) {
                  props.setCurrentActiveDay(-status)
                }
              } else {
                setCurrentActiveDay(day.id)
                if (props.setCurrentActiveDay) {
                  props.setCurrentActiveDay(day.id)
                }
              }
              if (props.setDayStatus) {
                props.setDayStatus(status)
              }
            }}
            position={index}
            parentObjectKind={props.parentObjectKind}
            parentPublishKind={props.parentPublishKind}
            addItem={async (position, item, type: 'position' | 'template2seminar') => {
              if (props.addItem) {
                await props.addItem(position + 1, item, type)
              }
            }}
            activeDay={currentActiveDay}
            rerenderRightSide={props.rerenderRightSide}
            setEditItem={props.setEditItem}
            itemCount={itemCount}
          />
      })
    }
  </div>
}
