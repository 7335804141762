import React from 'react'
import mainservice from '../../services/MainService'
import './FloatIcon.scss'

type Props = {
  iconName: string
}

type State = {
  targetX: number,
  targetY: number,
  active: boolean,
  lastChange: number
}

class FloatIcon extends React.Component<Props, State> {
  iconName: string
  testInterval: any
  constructor(props: Props) {
    super(props)
    this.iconName = props.iconName
    mainservice.registerFloatIcon(this.props.iconName, this)
    this.state = {
      targetX: -100,
      targetY: -100,
      active: false,
      lastChange: 0
    }
  }

  componentDidMount() {
    this.testInterval = setInterval(() => this.resetTarget(), 1000)
  }

  comkponentWillUnmount() {
    clearInterval(this.testInterval)
  }

  setNewTarget (self: any, x: number, y: number) {
    self.setState({
      targetX: x,
      targetY: y,
      active: true,
      lastChange: new Date().getTime()
    })
  }

  resetTarget () {
    if (!this.state.active) { return }
    const now = new Date().getTime()
    if (this.state.lastChange + 1000 < now) {
      this.setState({
        targetX: -100,
        targetY: -100,
        active: false
      })
    }
  }
  render () {
    const style = {
      transform: `translate3d(${this.state.targetX}px, ${this.state.targetY}px, 0)`,
      'transitionProperty': 'transform',
      'transitionDuration': '.5s',
      'transitionTimingFunction': 'ease'
    }
    return <div className={`floatIcon icon-${this.props.iconName}`} style={style}>

    </div>
  }
}

export default FloatIcon
