import React from 'react'
import './BarA.scss'

type BarAProps = {
  value: number,
  subtext?: string,
  counter?: number
  animate?: boolean
}

type BarAState = {
  displayValue: number,
  value: number,
  counter: number
}

class BarA extends React.Component<BarAProps, BarAState> {
  state: BarAState
  counter: number = 0
  justEntered: boolean = true
  constructor(props: BarAProps) {
    super(props)
    this.counter = props.counter || 0
    this.state = {
      displayValue: 0,
      counter: this.props.counter || 0,
      value: props.value
    }
  }

  componentWillReceiveProps(props: BarAProps) {
    this.setState({displayValue: 0, value: props.value})
    this.animate()
  }

  componentDidMount() {
    this.animate()
  }

  animate() {
    if (this.props.animate === false) {
      this.setState({displayValue: this.state.value})
      return
    }
    const stepSize = 1
    const timeStep = 20
    const delay = 1000
    setTimeout(() => {
      let interval = setInterval(() => {
        if (this.state.displayValue >= this.state.value) {
          clearInterval(interval)
          return
        }
        this.setState({
          displayValue: this.state.displayValue + stepSize
        })
      }, timeStep)
    }, delay)
  }

  render() {
    const value = Math.max(this.state.displayValue, 0)
    const yourValueStyle = {
      bottom: `${value}%`
    }
    const barStyle = {
      height: `${value}%`
    }
    return <div className={`barHome counter-${this.state.counter}`}>
      <div className='headLine1'>100%</div>
      <div className='barHolder'>
        <div className='barBackground'>
          <div className='bar' style={barStyle}></div>
        </div>
        <div className='yourValue' style={yourValueStyle}>{value} %</div>
      </div>
      <div className='subLine1'>0%</div>
      <div className='subLine2'>{this.props.subtext || ''}</div>
    </div>
  }
}

export default BarA
