import { GenericEditField, DisplayScope, EditFieldKind } from "../DTO/GenericDTO";
import { Materialkinds } from "../DTO/Materialkinds";


export const MaterialEditFields: GenericEditField[] = [
  /*
  {
    title: 'UniqueID',
    key1: 'data',
    key2: 'uniqueId',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.stringDisplay,
    default: 'AUTOFILLUNIQUEID',
  },
  */
  {
    title: 'Name',
    key1: 'tag',
    key2: 'materialNameTag',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Sprache',
    key1: 'tags',
    key2: 'language',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Tags',
    key1: 'tags',
    key2: 'materialTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Themen',
    key1: 'tags',
    key2: 'subjectTags',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Art Tags',
    key1: 'tags',
    key2: 'materialKindTag',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.multitag,
  },
  {
    title: 'Art',
    key1: 'data',
    key2: 'materialKind',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.dropDown,
    options: Materialkinds
  },
  {
    title: 'Mehrfachnutzung im Event möglich',
    key1: 'data',
    key2: 'reusableTimes',
    default: '-1',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.dropDown,
    options: [
      {
        value: '-1',
        title: 'Beliebig oft',
      },
      {
        value: '1',
        title: '1',
      },
      {
        value: '2',
        title: '2',
      },
      {
        value: '3',
        title: '3',
      },
      {
        value: '5',
        title: '5',
      },
      {
        value: '10',
        title: '10',
      },
      {
        value: '20',
        title: '20',
      },
    ]
  },
  {
    title: 'Exemplar pro Teilnehmer',
    key1: 'data',
    key2: 'copiesPerPerson',
    default: '0',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.dropDown,
    options: [
      {
        value: '0',
        title: 'Nein',
      },
      {
        value: '1',
        title: 'Ja',
      },
    ]
  },
  {
    title: 'Beschreibung',
    key1: 'data',
    key2: 'description',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Hinweise ⚠️',
    key1: 'data',
    key2: 'hints',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Produktionshinweise 🔨  ',
    key1: 'data',
    key2: 'productionHints',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Transporthinweise 🚚',
    key1: 'data',
    key2: 'transportHints',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.textArea,
  },
  {
    title: 'Medien',
    key1: 'file',
    key2: 'generic',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.files
  },
  {
    title: 'Länge',
    description: 'Länge in cm',
    key1: 'data',
    key2: 'length',
    unit: 'cm',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Breite',
    description: 'Breite in cm',
    key1: 'data',
    key2: 'width',
    unit: 'cm',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Höhe',
    description: 'Höhe in cm',
    key1: 'data',
    key2: 'height',
    unit: 'cm',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Gewicht',
    description: 'Gewicht in kg',
    key1: 'data',
    key2: 'weight',
    unit: 'kg',
    scope: DisplayScope.templateAndSeminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Lagerort',
    key1: 'tag',
    key2: 'materialStorageLocation',
    scope: DisplayScope.templateAndSeminarRead,
    kind: EditFieldKind.tag,
  },
  {
    title: 'Anzahl (mitnehmen)',
    key1: 'data',
    key2: 'numberNeeded',
    scope: DisplayScope.seminar,
    kind: EditFieldKind.number,
  },
  {
    title: 'Anzahl (Bestand)',
    key1: 'data',
    key2: 'numberStorage',
    scope: DisplayScope.template,
    kind: EditFieldKind.number,
  },
  {
    title: 'Realer einmaliger Gegenstand',
    key1: 'data',
    key2: 'singleton',
    scope: DisplayScope.template,
    kind: EditFieldKind.check,
  },
];
