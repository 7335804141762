import React from 'react'
import DoublePageDTO from '../../DTO/DoublePageDTO'
import DoublePagePropDTO, {IDoublePageProp} from '../../DTO/DoublePagePropDTO'
import mainservice from '../../services/MainService'
// import Spinner from '../Spinner/Spinner'
import Page from '../Page/Page'
import './DoublePage.scss'
import Crumb from './Crumb'
import {Trash, ArrowLeft, ArrowRight, FilePlus} from 'react-feather'

type Props = {
  doublePage: DoublePageDTO,
  parent: any
}

type State = {
  loading: boolean,
  doublePage: DoublePageDTO,
  isAdmin: number,
  sortNumber: number,
  privateClassName: string
}

type ElementBlockNextPage = {id: number, block: boolean}

class DoublePage extends React.Component<Props, State> {
  state: State
  toolIconSize = '16px'
  nextPageEnabled: boolean = true
  elementBlockNextPage: ElementBlockNextPage[] = []
  broadCastName: string
  constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
      isAdmin: mainservice.isAdmin(),
      doublePage: props.doublePage,
      sortNumber: props.doublePage.sortNumber,
      privateClassName: props.doublePage.getCssClass()
    }
    this.state.doublePage.currentDisplayer = this
    this.broadCastName = `doublePage-${props.doublePage.id}`
  }

  componentDidMount() {
    mainservice.registerToBroadcast(this.broadCastName, (key: string, _value: any) => {
      switch(key) {
        case `rerenderDoublePage-${this.state.doublePage.id}`:
          this.forceUpdate()
          break
      }
    }, this)
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast(this.broadCastName)
  }

  async getContent() {
    await this.state.doublePage.loadFromServer()
    this.forceUpdate()
  }

  async setLayout(layout: string) {
    await this.state.doublePage.setLayout(layout)
    await this.props.parent.getContent()
  }
  addPage(position: number) {
    if (!this.state.doublePage) { return }
    this.setState({loading: true})
    this.state.doublePage.addPage(position).then(() => this.getContent())
  }

  public setColorClass(kind: string, value: string) {
    if (value === 'delete') {
      this.state.doublePage.removeProp('colorClass', kind).then(() => this.setState({privateClassName: this.state.doublePage.getCssClass()}))
    }
    this.state.doublePage.addProp({
      id: -1,
      key1: 'colorClass',
      key2: kind,
      value1: value,
      value2: 'NA'
    }).then(() => this.setState({privateClassName: this.state.doublePage.getCssClass()}))
  }

  public addProp(prop: IDoublePageProp) {
    console.log('DoublePage addProp', prop)
    this.state.doublePage.addProp(prop).then(() => {this.getContent()})
  }

  public async manageMedia(o: {mode: 'use', id: number} | {mode: 'get' | 'getId' | 'remove'}) {
    switch (o.mode) {
      case 'use':
        await this.state.doublePage.setPicture(o.id)
        break
      case 'remove':
        await this.state.doublePage.removePicture()
        break
      case 'getId':
        return this.state.doublePage.getPictureId()
      case 'get':
        return this.state.doublePage.getPicture()
    }
    this.forceUpdate()
  }

  public instantManageMedia(o: {mode: 'use', id: number} | {mode: 'get' | 'getId' | 'remove'}) {
    switch (o.mode) {
      case 'use':
        this.state.doublePage.setPicture(o.id)
        break
      case 'remove':
        this.state.doublePage.removePicture()
        break
      case 'getId':
        return this.state.doublePage.getPictureId()
      case 'get':
        return this.state.doublePage.getPicture()
    }
    this.forceUpdate()
  }

  getPropValue1(key1: string, key2: string, defaultValue: string): string {
    if (this.state.doublePage) {
      const result = this.state.doublePage.getProp(key1, key2)
      if (result) {
         return result.value1 || defaultValue
      }
    }
    return defaultValue
  }

  renderPages() {
    const doublePage = this.state.doublePage
    if (!doublePage) { return }
    // case if only one doublepage available:
    let pageClass = 'page'
    pageClass += (doublePage.pages.length === 1) ? ' single-page center' : ''
    const pageCount = doublePage.pages.length
    const pagesOut = doublePage.pages.map((p, index: number) => {
      const page = <Page
        className={`${pageClass} page-local-index-${index} page-local-amount-${pageCount}`}
        key={'page' + p.id}
        page={p}
        selectFkt={(id: number) => console.log('selected id: ', id)}
        parent={this}
      />
      if (this.state.isAdmin > 0) {
        return <div key={'pageAdmin-' + p.id} className='adminPageHolder'>
          {page}
          <div key={'addBlockKey-' + index} className="addBlockRoot"><div className="addBlock" key={'AddPage' + index} onClick={() => this.addPage(index + 2)}>Page</div></div>
        </div>
      }
      return page
    })
    return <>
      {this.state.isAdmin > 0 && <div key={'addBlockKey--1'} className="addBlockRoot"><div className="addBlock" key={'doublePage' + doublePage.id + 'AddPage-1'} onClick={() => this.addPage(1)}>Page</div></div>}
      {pagesOut}
    </>
  }
  renderToolBar() {
    return <div className="adminToolBar editMode">
      <div>
        <span className="w3-tag">DoublePage</span>
        Pos: <input className='narrowNumberField' value={this.state.sortNumber} onChange={(event) => this.setState({sortNumber: parseInt(event.target.value, 10) || 0})}/>
        <button onClick={() => this.state.doublePage.setSortNumber(this.state.sortNumber).then(() => this.props.parent.getContent(true))}>ok</button>
        <button className="tool" onClick={async () => {
          if (!window.confirm('Diese Doppelseite wirklich löschen?')) { return }
          await this.state.doublePage.remove()
          this.props.parent.getContent(true)
        }}>
          <Trash/>
        </button>
      </div>
      <div>
        <span className="w3-padding">Permalink: <code>{`/permalink=doublePage-${this.state.doublePage.id}`}</code></span>
      </div>
    </div>
  }
  render() {
    // const className = (this.state.isAdmin > 2) ? 'doublePageAdmin' : 'doublePage'
    let className = 'doublePage ' + this.state.privateClassName
    className += ' column-layout-' + this.state.doublePage.getPropValue1('layout', 'columns', 'inherit')
    className += ' ' + this.state.doublePage.getPropValue1('layout', 'picturePosition', 'inherit')
    className += this.state.doublePage.getCssBGPicture() ? '' : ' no-background'
    const pages = this.renderPages()
    const backGroundImage = this.state.doublePage.getCssBGPicture()
    const style = (backGroundImage) ? {
      backgroundImage: backGroundImage
    } : {}
    return <div
      className={className}
      id={`doublePageId-${this.state.doublePage.id}`}
    >
      {
        this.state.isAdmin > 0 && this.state.doublePage.anchorId > -1 &&
        <div className='collectibleWarning'>Diese Seite ist eine Verknüpfung!</div>
      }
      <Crumb
        doublePage={this.state.doublePage}
        book={this.props.parent.state.book}
        />
      <div className="DoublePageBackgroundHolder no-pointer-events" style={style}></div>
      <div className="DoublePageContent">
        {this.state.isAdmin > 0 && this.renderToolBar()}
        {pages}
      </div>
    </div>
  }
}

export default DoublePage
