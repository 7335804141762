export default class TimeFormatService {
  constructor() {

  }
  convertTimeToHourMinuteSeconds(date: Date) {

    const days = Math.floor(date.getTime()/1000/60/60/24)
    const hours = Math.floor(date.getTime()/1000/60/60)
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    console.log(date, days, hours, minutes, seconds)
    if (days === 1) {
      return `${days} Tage ${hours} Stunden`
    }
    if (days > 1) {
      return `${days} Tage`
    }
    if (hours === 1) {
      return `${hours} Stunde ${minutes} Minuten`
    }
    if (hours > 1) {
      return `${hours} Stunden ${minutes} Minuten`
    }
    if (minutes > 0) {
      return `${this.TwoDi(minutes)}:${this.TwoDi(seconds)} Minuten`
    }
    return `${this.TwoDi(seconds)} Sekunden`
  }
  TwoDi(n: number): string {
    if (n >= 0 && n < 10) {
      return `0${n}`
    } else if (n >= 10) {
      return `${n}`
    } else if (n <= -10) {
      return `${n}`
    }
    return `${n}`.replace('-', '-0')
  }

}
