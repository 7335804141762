import React from 'react'
import mainservice from '../../../services/MainService'
import PageElementDTO from '../../../DTO/PageElementDTO'
// import {SessionItem} from '../../../DTO/SessionDTO'
import './UserInputScale1.scss'
import {ThumbsUp, ThumbsDown} from 'react-feather'
import InputA from '../../InputA/InputA'

type Props = {
  pageElement: PageElementDTO
}

type State = {
  isAdmin: number,
  evaluationKey: string,
  evaluationItemKey: string,
  evaluationItemCategoryKey: string,
  userDescription: string,
  numberOfScaleElements: number,
  valuesOfScaleElements: string,
  currentVal: number
}

export default class UserInputScale1 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    console.log('#>userinputstate - pageElement', props.pageElement)
    const evaluationKey = props.pageElement.value1 || 'evaluationKey'
    const evaluationItemKey = props.pageElement.getPropVal1('evaluationItemKey', 'none') || props.pageElement.id + ''
    const session = mainservice.getAllSessionData()
    console.log('session', session)
    const currentValItem = session.find((sI) => sI.key1 === evaluationKey && sI.key2 === evaluationItemKey)
    console.log('session item', currentValItem)
    const currentVal = (currentValItem) ? parseInt(currentValItem.value2, 10) : -1
    this.state = {
      isAdmin: mainservice.isAdmin(),
      evaluationKey: evaluationKey,
      evaluationItemKey: evaluationItemKey,
      evaluationItemCategoryKey: props.pageElement.value2 || 'Kategorie1',
      userDescription: props.pageElement.getPropVal1('userDescription', 'none'),
      numberOfScaleElements: parseInt(props.pageElement.getPropVal1('numberOfScaleElements', 'none') || '4', 10),
      valuesOfScaleElements: props.pageElement.getPropVal1('valuesOfScaleElements', 'none') || '',
      currentVal: currentVal
    }
  }

  componentDidMount() {
    this.nextPageBlocking()
  }

  getData() {
    // Data to gather:
    // - Evaluation name (value1) **
    // - Evaluation category (eg color, value2) **
    // - userDescription (prop) +
    // - Number of Scale Elements (prop) +
    // - Evaluation item Number () // who cares?
  }

  picValue(value: number) {
    console.log('pickedValue', value)
    this.setState({currentVal: value})
    mainservice.setSessionData(
      this.state.evaluationKey,
      this.state.evaluationItemKey,
      this.state.evaluationItemCategoryKey,
      `${value}`
    ).then(() => {
      this.props.pageElement.elementBlockNextPage(false)
      /*
      const currentBook = mainservice.getCurrentBookView()
      if (currentBook) {
        currentBook.getContent(true)
      }
      */
    })
  }

  nextPageBlocking() {
    // const seDa = mainservice.getSessionData(this.state.evaluationKey, this.state.evaluationItemKey)

    const blockNextPage = (this.state.currentVal < 0)
    console.log('Blocking', blockNextPage)
    this.props.pageElement.elementBlockNextPage(blockNextPage)
  }

  renderAdmin() {
    return <table>
      <tbody>
        <tr>
          <td>
            Umfrageschlüssel:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({evaluationKey: rv})
                self.props.pageElement.setValue1(rv)
              }}
              value={this.state.evaluationKey}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            ItemSchlüssel (optional):
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({evaluationItemKey: rv})
                self.props.pageElement.addElementProp({
                  key1: 'evaluationItemKey',
                  key2: 'none',
                  value1: rv,
                  value2: ''
                })
              }}
              value={this.state.evaluationItemKey}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            Kategorie:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({evaluationItemCategoryKey: rv})
                self.props.pageElement.setValue2(rv)
              }}
              value={this.state.evaluationItemCategoryKey}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            Anzahl Zwischeneinheiten:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({numberOfScaleElements: parseInt(rv, 10)})
                self.props.pageElement.addElementProp({
                  key1: 'numberOfScaleElements',
                  key2: 'none',
                  value1: rv,
                  value2: ''
                })
              }}
              value={this.state.numberOfScaleElements + ''}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            Werte Zwischeneinheiten:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({valuesOfScaleElements: rv})
                self.props.pageElement.addElementProp({
                  key1: 'valuesOfScaleElements',
                  key2: 'none',
                  value1: rv,
                  value2: ''
                })
              }}
              value={this.state.valuesOfScaleElements + ''}
              equalizeFkt={(v: string): string => {
                return this.equalizeValuesOfScaleElements(v).join('; ')
              }}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            User Text:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({userDescription: rv})
                self.props.pageElement.addElementProp({
                  key1: 'userDescription',
                  key2: 'none',
                  value1: rv,
                  value2: ''
                })
              }}
              value={this.state.userDescription}
              parent={this}
              type='textArea'
            />
          </td>
        </tr>
      </tbody>
    </table>
  }

  equalizeValuesOfScaleElements(v: string) {
    const userInput = v.split(/[;, ]+/)
    let lastValue = -1
    let out = []
    for (let i = 0; i < this.state.numberOfScaleElements; i++) {
      const uI = parseInt(userInput[i], 10) || lastValue + 1
      lastValue = uI
      out.push(uI)
    }
    return out
  }

  renderScaleInput() {

    const vals = this.equalizeValuesOfScaleElements(this.state.valuesOfScaleElements)
    return <div className="userInputScale1">
      <div
        className="scaleDescription"
        dangerouslySetInnerHTML={{__html: this.state.userDescription}}>
      </div>
      <div className="scaleSelectionHolder w3-cell-row">
        <div className="userSays userSaysNo w3-container w3-cell">
          <ThumbsDown />
        </div>
        {
          vals.map((v, index) => <div
            key={index}
            className={`scaleItem w3-container w3-cell ${(this.state.currentVal === v) ? 'active': 'not-active'}`}
            onClick={() => this.picValue(v)}
          >
            <div className="bubble">
              <div className="innerBubble">
              </div>
            </div>
          </div>)
        }
        <div className="userSays userSaysYes w3-container w3-cell">
          <ThumbsUp />
        </div>
      </div>
    </div>
  }

  render() {
    if (this.state.isAdmin) {
      return <>
        <div className="editMode">
          {
            this.renderAdmin()
          }
        </div>
        <div className="displayMode">
          {
            this.renderScaleInput()
          }
        </div>
      </>
    }
    return this.renderScaleInput()
  }
}
