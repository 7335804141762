
export enum PersonKind {
  generic,
  principal,
  trainer
}

export function getPersonKindName(kind: PersonKind) {
  return PersonKind[kind]
  /*
  switch(kind) {
    case PersonKind.principal:
      return 'principal'
    case PersonKind.trainer:
      return 'triner'
  }
  return 'generic'
  */
}
