export type OptionArray = [string, string][] | undefined

export function OptionArrayHas(arr: OptionArray, needle: string) {
  if (arr === undefined) { return false }
  return arr.some(i => i[0] === needle)
}

export function OptionArrayHasValue(arr: OptionArray, needle: string, value: string) {
  if (arr === undefined) { return false }
  return arr.some(i => i[0] === needle && i[1] === value)
}
