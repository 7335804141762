import StaticSiteParagraphDTO from '../../DTO/StaticSiteParagraphDTO'
import RenderImage from '../MediaManager/RenderImage'
import UserRegister from '../User/UserRegister'
import UserLogin from '../User/UserLogin'

export default function Paragraph (props: {
  paragraph: StaticSiteParagraphDTO
}) {
  const p = props.paragraph
  switch(p.getPropVal1('paragraphType', '')) {
    case 'type1':
    case 'main-headline':
    case 'login':
    case 'register':
      return <ParagraphType1 paragraph={p} />
  }
  return <div>

  </div>
}

export function ParagraphType1(props: {
  paragraph: StaticSiteParagraphDTO
}) {
  const p = props.paragraph
  const paragraphType = p.getPropVal1('paragraphType', '')
  const content = p.getPropVal1('content', '-')
  const headline = p.getPropVal1('headline', '-')
  const imageId = parseInt(p.getPropVal1('picture', 'id'), 10)
  const picturePosition = p.getPropVal1('picture', 'position') || 'right'
  const linkTarget = p.getPropVal1('link', 'target')
  const linkContent = p.getPropVal1('link', 'content')
  // Calc things:

  let imageClass = 'text-only'
  let paragraphClass = 'l9 m12'
  if (imageId || paragraphType.search(/register|login/) > -1) {
    if (paragraphType === 'main-headline') {
      if (picturePosition === 'center') {
        imageClass = 's12'
        paragraphClass = 's12'
      } else {
        imageClass = 'l4 m12 text-and-image'
        paragraphClass = 'l8 m12'
      }
    } else {
      if (picturePosition === 'center') {
        imageClass = 's12'
        paragraphClass = 's12'
      } else {
        imageClass = 'l6 m12 text-and-image'
        paragraphClass = 'l6 m12'
      }
    }
  }
  const mediaElement = () => {
    switch (paragraphType) {
      case 'login':
        return <UserLogin
            showAsElement={true}
            className={ `w3-col ${imageClass}` }
          />
      case 'register':
        return <UserRegister
            showAsElement={true}
            className={ `w3-col ${imageClass}` }
          />
    }
    if (imageId > -1) {
      return <RenderImage
        className={`w3-col paragraph-image-content ${imageClass}`}
        fileId={imageId}
      />
    }
    return null

  }
  console.log('Image id', imageId)
  return <div className={`paragraph ${picturePosition} ${imageClass} ${paragraphType}`}>
    <div className={`paragraph-content w3-row`}>
      { mediaElement() }
      <div className={`w3-col paragraph-text-content ${paragraphClass}`}>
        {
          headline &&
          <h2
            className={`paragraph-headline-content`}
            dangerouslySetInnerHTML={{__html: headline}}
          ></h2>
        }
        <div
          className={`paragraph-text-content-content`}
          dangerouslySetInnerHTML={{__html: content}}
        ></div>
        {
          linkTarget &&
          <a href={linkTarget}>
            <button
              className='paragraphLinkButton primary-button'
              dangerouslySetInnerHTML={{__html: linkContent}}
            ></button>
          </a>
        }
      </div>
    </div>
  </div>
}
