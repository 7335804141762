import { useState } from 'react'
import './UserAvatarManager.scss'
import UserDTO from '../../DTO/UserDTO'
import userEvent from '@testing-library/user-event'

enum Status {
  showAvatar,
  selectNewAvatar
}
const UserAvatarManager = (props: {
  user: UserDTO
}) => {
  const [status, setStatus] = useState(Status.showAvatar)
  const [renderStatus, rerender] = useState(0)
  switch (status) {
    case Status.showAvatar:
      return <div>
          <RenderAvatar user={props.user} />
          <div>
            <button
              className="secondary-button"
              onClick={() => setStatus(Status.selectNewAvatar)}
            >
              Neues Bild hinzufügen
            </button>
          </div>
          {
            props.user.getAvatarUrl() &&
            <div>
              <button
                className="secondary-button"
                onClick={() => {
                  if (window.confirm('Wirklich das Bild löschen?')) {
                    props.user.removeAvatar()
                    setStatus(Status.selectNewAvatar)
                  }
                }}
              >Bild löschen</button>
            </div>
          }
          {
            props.user.avatarCount() > 1 &&
            <div>
              <button
                className="secondary-button"
                onClick={() => {
                  if (window.confirm('Wirklich das letzte Bild löschen und zum vorigen zurückkehren?')) {
                    props.user.removeLastAvatar()
                    rerender(renderStatus + 1)
                  }
                }}
              >Zu letztem Bild zurückkehren</button>
            </div>
          }
        </div>
    case Status.selectNewAvatar:
      return <div>
          <RenderSelectAvatar
            done={() => {
              setStatus(Status.showAvatar)
            }}
            user={props.user}
          />
          <div>
            <button
              className="secondary-button"
              onClick={() => setStatus(Status.showAvatar)}
            >
              Abbrechen
            </button>
          </div>
        </div>
  }
}

const RenderAvatar = (props: {
  user: UserDTO
}) => {
  const imgUrl = props.user.getAvatarUrl()
  if (imgUrl) {
    return <div className='user-avatar-img'>
      <img src={imgUrl} alt="Noch kein Bild"></img>
    </div>
  }
  return <div className='user-avatar-img'>Noch kein Bild hinterlegt</div>
  
}


enum UploadStatus {
  init,
  uploading,
  uploaded,
  failed,
  failedBadExtension
}

const RenderSelectAvatar = (props: {
  done: () => void,
  user: UserDTO
}) => {
  // const [filename, setFilename] = useState('')
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.init)
  const handleFileUpload = async (file: File) => {
    setUploadStatus(UploadStatus.uploading)
    console.log('FILE STATUS', file)
    await props.user.addAvatarPicture(file).then(() => {
      props.done()
    }).catch(err => {
      if (err.reason === 'bad extension') {
        setUploadStatus(UploadStatus.failedBadExtension)
      }
      setUploadStatus(UploadStatus.failed)
    })
    
  }
  switch(uploadStatus) {
    case UploadStatus.init:
      return <div>
        <input className="w3-margin" type="file" name="file" onChange={(event) => {
          if (event.target?.files && event.target?.files.length > 0) {
            handleFileUpload(event.target.files[0])
          }
        }} />

      </div>
    case UploadStatus.uploading:
      return <div>Lade hoch</div>
    case UploadStatus.failed:
      return <div className="warning-box">Fehler</div>
    case UploadStatus.failedBadExtension:
      return <div className="warning-box">Die Dateiendung wird leieder nicht unterstützt!</div>
  }
  return null
}

export default UserAvatarManager
