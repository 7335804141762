import React from 'react'
import './Tooltip.scss'

type Props = {
  content: any,
  className?: string
}

type State = {

}

export default class Tooltip extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

  }

  render() {
    return <div className={`tooltip ${this.props.className || ''}`}>
      <div className="tooltipBody">
        <div className="tttriangle"></div>
        <div
          className="tooltipContent"
          dangerouslySetInnerHTML={{__html: this.props.content}}
        ></div>
      </div>
    </div>
  }
}
