import React from 'react'
import BookDTO, {Section} from '../../DTO/BookDTO'
import Book from './Book'
// import { Trash } from 'react-feather'
// import config from '../config.json'
import { BurgerMenu, Remove } from '../Icon/Icon'
import './TOC.scss'
import { Dribbble, Zap } from 'react-feather'
import {parseStringFromServer} from '../../services/TextConverter'
import LockNavigationService from '../../services/LockNavigationService'

type Props = {
  book: BookDTO
  bookView: Book
}

type State = {
  opened: boolean
}

export default class TOC extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      opened: false
    }
  }

  open() {
    this.setState({opened: true})
  }

  close() {
    this.setState({opened: false})
  }

  toggle() {
    this.setState({opened: !this.state.opened})
  }

  renderHamburger(active: string) {
    return <div
      className={`toc-hamburger ${active}`}
      onClick={() => {this.toggle()}}
      >
      <BurgerMenu className='toc-icon burger'/>
      <Remove
        className='toc-icon close'
        type='black'
      />
    </div>
  }

  renderContentHider(active: string) {
    return <div className={`toc-content-hider ${active}`}
      onClick={() => {this.close()}}
    >
    </div>
  }

  renderTocContent(active: string) {
    const sections = this.props.book.getSections()
    const nameStyle = this.props.book.getPropVal1('tableOfContens', 'chapter-style', 'chapter-style-clean')
    // const currentPage = (this.props.bookView) ? this.props.bookView.state.currentDoublePage : 1
    return <div className={`toc-content ${active} ${nameStyle}`}>
      <div className='toc-content-content'>
        <div
          className='toc-section'
          onClick={() => {
            if (LockNavigationService.isLocked()) { return false }
            this.props.bookView.changeCurrentPage(1)
            this.close()
          }}
        >
          Anfang
        </div>
      {
         sections.map((s, index) => this.renderSection({
          section: s,
          index: index
        }))
      }
      </div>
      <div className="legalInfo">
        <a href="https://lubbers.de/impressum" target='_blank' rel='noreferrer'>Impressum</a>
        <a className="hideOnMobile" href="https://lubbers.de/datenschutz" target='_blank' rel='noreferrer'>Datenschutz</a>
        <a className="hideOnMobile" href="https://lubbers.de/ueber-uns/kontakt" target='_blank' rel='noreferrer'>Kontakt</a>
      </div>
    </div>
  }

  renderSection(data: {
    section: Section,
    index: number,
    subs?: string
  }) {
    const s = data.section
    const i = data.index
    const subs = data.subs || ''
    const subsub = subs + 'sub'
    const displayName = s.name
    if (displayName === '') {
      console.warn('SubSection without name:', s)
      return null
    }
    const displayObject = {__html: parseStringFromServer(displayName)}
    return <div key={i}>
      <div className={`toc-${subs}section`}
        onClick={() => {
          if (LockNavigationService.isLocked()) { return false }
          if (s.page) {
            this.props.bookView.changeCurrentPage(s.page)
          } else if (s.doublePageId) {
            this.props.bookView.changeCurrentPageByDoublePageId(s.doublePageId)
          }
          this.close()
        }}
      >
        <span className='chapternumber'>{s.sectionNumberString}</span>
        {
          s.interactive &&
          <span className='interactive-icon w3-circle' title='interactive'>&nbsp;</span>
        }
        <span
          dangerouslySetInnerHTML={displayObject}
        ></span>
      </div>
      {
        s.child.length > 0 &&
        <div className={`toc-${subsub}sections`}>
          {
            s.child.map((section, index) => this.renderSection({
              section: section,
              index: index,
              subs: subsub
            }))
          }
        </div>
      }
    </div>
  }

  render() {
    const active = this.state.opened ? 'active' : ''
    return <>
      { this.renderHamburger(active) }
      { this.renderContentHider(active) }
      { this.renderTocContent(active) }
    </>
  }
}
