import React from 'react'
import './CollectibleListA.scss'
import CollectibleDTO, { CollectiblesDTO, CollectibleType } from '../../DTO/CollectibleDTO'
import CollectibleItemA from './CollectibleItemA'
import InputA from '../InputA/InputA'
import FileList, { FileListMode } from '../MediaManager/FileList'
import mainservice from '../../services/MainService'

type Props = {
  onSelect?: (id: number | null) => void
}

type State = {
  collectibles: CollectiblesDTO,
  type: CollectibleType,
  newState: newState,
  newCollectible: undefined | CollectibleDTO,
  itemId: number,
  pictureId: number,
  selected: number[],
  needle: string[]
}

enum newState {
  neutral,
  new,
  selectPicture,
  selectCollectible
}


export default class CollectibleListA extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    console.log('-->CollectibleListA')
    this.state = {
      collectibles: new CollectiblesDTO({version: 'admin'}),
      type: mainservice.nav.getVal('type') as CollectibleType || CollectibleType.flashcard,
      newState: (this.props.onSelect) ? newState.selectCollectible : newState.neutral,
      newCollectible: undefined,
      itemId: -1,
      pictureId: -1,
      selected: [],
      needle: []
    }
    /*
    if (props.onSelect) {
      mainservice.broadcast('enablescrollbook', false)
    }
    */
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    console.log('getData')
    await this.state.collectibles.getFromServer(undefined, 'admin')
    if (this.state.newState === newState.neutral) {
      console.log('o1')
      this.forceUpdate()
    } else {
      console.log('o2')
      this.setState({
        newState: newState.neutral
      })
      //this.forceUpdate()
    }
  }

  isSelected(id: number) {
    return this.state.selected.indexOf(id) > -1
  }

  addSelected(id: number) {
    if (this.isSelected(id)) { return }
    let selected = this.state.selected
    selected.push(id)
    this.setState({selected: selected})
  }

  removeSelected(id: number) {
    this.setState({selected: this.state.selected.filter((s) => s != id)})
  }

  clearSelected() {
    this.setState({selected: []})
  }

  selectAll() {
    this.setState({
      selected: this.state.collectibles.
        get({type: this.state.type}).
        map((c) => c.id)
    })
  }

  closeFileList(order?: string) {
    if (order === 'reload') {
      this.state.collectibles.getFromServer(undefined, 'admin').then(() => {
        this.setState({
          newState: newState.new
        })
      })
    } else {
      this.setState({
        newState: newState.new
      })
    }

  }

  renderTypeTabs () {
    return  <div className="w3-bar w3-black collectible-list-a-nav-bar">
      <button
        className='w3-bar-item w3-button'
        onClick={() => {
          if (this.props.onSelect) {
            /*mainservice.broadcast('enablescrollbook', true)*/
            this.props.onSelect(null)
            return
          }
          mainservice.navTo([['view', 'adminlogin']])
        }}
      >
          &larr;
      </button>
      {
        [
          {
            name: 'Lernkarten',
            type: CollectibleType.flashcard
          },
          {
            name: 'Plakate',
            type: CollectibleType.poster
          },
          {
            name: 'Sinnspruch',
            type: CollectibleType.motto
          }
        ].map((i, index) => <button
          key={index}
          className={`w3-bar-item w3-button ${(this.state.type === i.type) ? 'w3-blue' : ''}`}
          onClick={() => {
            if (!this.props.onSelect) {
              mainservice.navTo([['view', 'CollectibleListA'], ['type', i.type]])
            }
            this.setState({
              type: i.type,
              newState: newState.neutral
            })
          }}
        >
          {i.name}
        </button>)
      }
    </div>
  }


  renderNewCollectible() {
    return <div className='newCollectible'>
      <button
        className='w3-btn w3-ripple w3-green'
        onClick={() => mainservice.navTo([['view', 'CollectibleEditor'], ['id', '-1'], ['type', this.state.type]])}
      >
          +
      </button>
    </div>
  }

  renderTagManager() {
    if (this.state.selected.length === 0) { return }
    return <div className='tagManager'>
      <label className='block'>Tag eingabe</label>
      <InputA
        returnVal={(rv: string) => {
          const tags = rv.split(/[ ,;-]+/g)
          this.state.collectibles.addTags({
            ids: this.state.selected,
            tags: tags
          }).then(() => {
            this.getData()
          })
        }}
      />
    </div>
  }

  renderSearchBar() {
    return <div className='searchBar'>
      <label className='block'>Suche</label>
      <InputA
        returnVal={(rv: string) => {
          this.setState({
            needle: rv.split(/[ ,;-]+/g)
          })
        }}
      />
    </div>
  }

  render () {
    if (this.state.newState === newState.selectPicture && this.state.itemId > -1) {
      return <FileList
        parent={this}
        mode={FileListMode.selectPicture}
        usePicture={(id: number) => {
          this.state.collectibles.setPicture({
            pictureId: id,
            collectibleId: this.state.itemId
          }).then(() => {
            this.state.collectibles.getFromServer(undefined, 'admin').then(() => {
              this.setState({
                newState: newState.new
              })
            })
          })
        }}
      />
    }
    return <div className={`collectibleListA ${(this.props.onSelect ? ' asOverlay' : '')}`}>
      {
        this.renderTypeTabs()
      }
      {
        this.state.newState != newState.selectCollectible &&
        this.renderTagManager()
      }
      {
        this.renderSearchBar()
      }
      {
        this.state.newState != newState.selectCollectible &&
        this.renderNewCollectible()
      }
      <div className='w3-row-padding'>
        {
          this.state.collectibles.
            get({type: this.state.type}).
            filter((c) => {
              if (this.state.needle.length === 0) { return true }
              if (
                this.state.needle.every(
                  (n) => {
                    const scope = new RegExp(n, 'i')
                    return c.tags.some((t) => t.search(scope) > -1) ||
                    c.title.search(scope) > -1 ||
                    c.content.search(scope) > -1
                  }

                )
              ) {
                return true
              }
            }).
            map((c, index) =>
            <CollectibleItemA
              key={`${index}-${c.id}`}
              className='w3-col s12 m6 l4'
              collectible={c}
              fresh={() => {
                this.getData()
              }}
              selectFile={(o: {
                id: number,
                pictureId: number
              }) => {
                this.setState({
                  newState: newState.selectPicture,
                  itemId: o.id,
                  pictureId: o.pictureId
                })
              }}
              selected={this.isSelected(c.id)}
              selectMe={(o: {id: number, select: boolean}) => {
                if (this.props.onSelect) {
                  this.props.onSelect(o.id)
                } else if (o.select) {
                  this.addSelected(o.id)
                } else {
                  this.removeSelected(o.id)
                }
              }}
              onlySelect={this.props.onSelect ? true : false}
            />
        )
        }
      </div>
    </div>
  }
}
