import config from '../../config.json'
import { useState } from 'react'
import { Getter } from '../../services/ComService'
import './RenderImage.scss'

const RenderImage = (props: {
  fileId: number,
  className?: string
}) => {
  const [src, setSrc] = useState('')
  const [active, setActive] = useState('image-loading')
  const className = props.className || ''
  if (!props.fileId || props.fileId < 0) {
    return null
  }
  Getter(`File/${props.fileId}`, true).then(r => {
    setSrc(r.fileName)
    setActive('image-ready')
  })
  if (!src) {
    return null
  }
  return <img
      src={config.mediaDir + src}
      className={`imagerenderer ${className} ${active}`}
    />
}
export default RenderImage
