import React from 'react'
import mainservice from '../../services/MainService'
import JoditReact from "jodit-react";
// import 'jodit/build/jodit.min.css';
import './InputA.scss'

type ReturnValType = (rv: string, parent?: any) => void

type Props = {
  broadCastParent?: any
  returnVal: ReturnValType
  value?: string
  parent?: any
  type?: string
  equalizeFkt?: (v: string) => string
  cancel?: any
  resetOnSend?: boolean
  autofocus?: boolean
  className?: string
  contentType?: 'html' | 'svg' | 'number' | 'text' | 'time' | 'date'
  newValBroadCastKey?: string
  placeholder?: string,
  sendOnBlur?: boolean,
  disabled?: boolean,
}

type State = {
  editValue1Status: string,
  type: string,
  editInProgress: boolean
}

class InputA extends React.Component<Props, State> {
  returnVal: ReturnValType
  state: State
  constructor(props: Props) {
    super(props)
    this.returnVal = props.returnVal
    console.log('InputA contentType', props.contentType)
    const value = (props.type === 'wysiwyg') ? (props.value || '') : this.transformFromHtml(props.value || '')

    this.state = {
      editValue1Status: value,
      type: props.type || 'input',
      editInProgress: false
    }
  }

  componentDidMount() {
    if (this.props.newValBroadCastKey && this.props.broadCastParent) {
      this.props.broadCastParent.registerToBroadcast(this.props.newValBroadCastKey + '-InputA',
      (key: string, string: any) => {
        if (key === this.props.newValBroadCastKey) {
          this.setState({
            editValue1Status: this.transformFromHtml(string || '')
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState({editValue1Status: this.props.value || ''})
    }
  }

  componentWillUnmount() {
    if (this.props.newValBroadCastKey && this.props.broadCastParent) {
      this.props.broadCastParent.unregisterBroadcast(this.props.newValBroadCastKey + '-InputA')
    }
  }

  newExternalValue(v: string) {
    const value = this.transformFromHtml(v || '')
    this.setState({
      editValue1Status: value
    })
  }
  transformFromHtml(value: string) {
    if (this.props.contentType === 'svg') {
      return value//.replace(/<flowPara[^>]+>(.*)<\/flowPara>/g, '$1\n')
    }
    return value.replace(/\<br \/\>/g, '\n')
  }
  transformToHtml(value: string) {
    if (this.props.contentType === 'svg') {
      return value// .replace(/(.*)\n/g, '<flowPara>$1</flowPara>')
    }
    return value.replace(/\n/g, '<br />')
  }
  sendResult() {
    let sendVal = this.state.editValue1Status
    if (this.props.equalizeFkt) {
      sendVal = this.props.equalizeFkt(sendVal)
    }
    this.returnVal(this.transformToHtml(sendVal), this.props.parent)
    const editValue1Status = (this.props.resetOnSend) ? '' : this.state.editValue1Status
    this.setState({
      editInProgress: false,
      editValue1Status: editValue1Status
    })
  }
  render() {
    return <div
        className={`InputA ${this.props.className || ''}`}
      >
      {
        this.state.type === 'input' &&
        <input
          value={this.state.editValue1Status}
          onChange={(event) => {
            this.setState({
              editValue1Status: event.target.value,
              editInProgress: true
            })
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              this.sendResult()
            }
          }}
          autoFocus={!!this.props.autofocus}
          placeholder={this.props.placeholder || ''}
          onFocus={() => {
            mainservice.broadcast('textEditFocus', true)
          }}
          onBlur={() => {
            if (this.props.sendOnBlur) {
              this.sendResult()
            }
            mainservice.broadcast('textEditBlur', true)
          }}
          type={this.props.contentType || 'text'}
          disabled={!!this.props.disabled}
        />
      }
      {
        this.state.type === 'textArea' &&
        <textarea
        style={{resize: 'none'}}
        value={this.state.editValue1Status}
        onChange={(event) => {
          this.setState({
            editValue1Status: event.target.value,
            editInProgress: true
          })
        }}
        onBlur={() => {
          if (this.props.sendOnBlur) {
              this.sendResult()
            }
        }}
        />
      }
      {
        this.state.type === 'wysiwyg' &&
        <JoditReact
          onChange={(c: string) => {
            this.setState({
              editValue1Status: c,
              editInProgress: true
            })
          }}
          value={this.state.editValue1Status || ''}
        />
      }
      {
        !this.props.sendOnBlur &&
        <button
          className='primary-button'
          style={{
            opacity: (this.state.editInProgress) ? 1 : 0
          }}
          onClick={() => {
            this.sendResult()
          }}
        >ok</button>
      }
      { this.props.cancel &&
        <button
        onClick={() => {
          this.props.cancel(this.props.parent)
        }}
        >Abbrechen</button>
      }
    </div>
  }
}

export default InputA
