const shortMonthName = [
  'Jan',
  'Feb',
  'Mär',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez',
]

export const DisplayDateTimeNumeric = (d: Date) => {
  return `${DisplayDate(d)} ${TwoDi(d.getHours())}:${TwoDi(d.getMinutes())} Uhr`
}

export const DisplayDate = (d: Date) => {
  return `${TwoDi(d.getDate())}.${TwoDi(d.getMonth() + 1)}.${d.getFullYear()}`
}

export const DisplayTime = (d: Date) => {
  // console.log('DisplayTime', d)
  return `${TwoDi(d.getHours())}:${TwoDi(d.getMinutes() + 1)} Uhr`
}

export const DisplayDateFromString = (d?: string) => {
  if (!d) { return '-'}
  return DisplayDate(new Date(d))
}

export const DisplayDateTimeFromString = (d?: string) => {
  if (!d) { return '-'}
  // console.log('DisplayDateTimeFromString', d)
  return `${DisplayDate(new Date(d))} ${DisplayTime(new Date(d))}`
}

export const DisplayMonthYear = (d: Date) => {
  return `${shortMonthName[d.getMonth()]} '${(d.getFullYear() + '').replace(/^[0-9]{2}/, '')}`
  // return `${TwoDi(d.getMonth() + 1)}.${d.getFullYear()}`
}

export const DisplayYear = (d: Date) => {
  return `${d.getFullYear()}`
}

export const DisplayPHPDate = (d: Date | null) => {
  if (d) {
    return `${d.getFullYear()}-${TwoDi(d.getMonth() + 1)}-${TwoDi(d.getDate())}`
  }
  return ''
}

export const CreateTimeStamp = () => {
  const d = new Date()
  return `${d.getFullYear()}-${TwoDi(d.getMonth() + 1)}-${TwoDi(d.getDate())}:${TwoDi(d.getHours())}-${TwoDi(d.getMinutes())}-${TwoDi(d.getSeconds())}`
}

export const TwoDi = (n: number): string => {
  if (n >= 0 && n < 10) {
    return `0${n}`
  } else if (n >= 10) {
    return `${n}`
  } else if (n <= -10) {
    return `${n}`
  }
  return `${n}`.replace('-', '-0')
}

export const SanitizeDate = (d: string): string => {
  const scope = /([1-3][1-9]|0?[1-9])[^0-9]+(1[0-2]|0?[0-9])[^0-9]+([1-9]?[0-9]?[0-9][0-9])/
  const m = d.match(scope)
  if (!m) {
    console.log('sanitize error')
    return 'error'
  }
  let rawYear = parseInt(m[3])
  const now = new Date()
  const nowYear = now.getFullYear()
  if (rawYear < 100) {
    rawYear += 2000
    if (rawYear > nowYear - 18) {
      rawYear -= 100
    }
  }
  const result = `${TwoDi(parseInt(m[1]))}.${TwoDi(parseInt(m[2]))}.${rawYear}`
  return result
}

export function TimeString2Minutes (ts: string) {
  let raw: string[] = []
  if (ts.search(':') === -1) {
    raw = [ts.substring(0, 2), ts.substring(0, 4)]
  } else {
    raw = ts.split(':')
  }
  let hours: number = parseInt(raw[0], 10) || 8
  hours = Math.min(24, hours)
  hours = Math.max(6, hours)
  const minutes = parseInt(raw[1] || '0', 10) || 0
  return minutes + hours * 60
}

export function Minutes2TimeString(minutes: number, suffix?: string) {
  let hours = Math.floor(minutes / 60)
  let rest = minutes - hours * 60
  return `${String(hours).padStart(2, '0')}:${String(rest).padStart(2, '0')}${suffix === undefined ? ' Uhr' : suffix}`
}

export function DateStringToDateString(s: string): string {
  const scope = /([0-9]{4}-[0-9]{2}-[0-9]{2})/
  const r = s.match(scope)
  if (r) {
    return r[1]
  }
  return ''
}

export function DayDiff(min: Date, max: Date) {
  const diffTime = Math.abs(max.getTime() - min.getTime())
  return Math.min(1024, Math.abs(Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + 1)
}

export function DateAddDays(d: Date, days: number) {
  let n = new Date(d)
  n.setDate(n.getDate() + days)
  return n
}

export function minutes2Input(inMinutes: number) {
  const hours = Math.floor(inMinutes / 60)
  const minutes = inMinutes - hours * 60
  return `${TwoDi(hours)}:${TwoDi(minutes)}`
}

export function timeInput2minutes(inTimeString: string) {
  const parts = inTimeString.split(':')
  const hours = parts[0]
  const minutes = parts[1]
  if (hours && minutes) {
    return (parseInt(hours, 10) * 60) + parseInt(minutes, 10)
  }
  return 0
}

export function minutes2HumanFormat(minutes: number) {
  if (minutes < 60) {
    return `${minutes} min`
  }
  if (minutes < 120) {
    let hours = Math.floor(minutes / 60)
    let rest = Math.floor(minutes - hours * 60)
    return `${hours}:${rest} h`
  }
  return `${minutes / 60} h`
}
