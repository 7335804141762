import { useState, useEffect } from 'react'
import { ShoppingCart } from 'react-feather'
import mainservice from '../../services/MainService'
import { cart } from '../../DTO/ShopDTO'

import './cartMenuIcon.scss'

const CartMenuIcon = () => {
  const [bookCount, setBookCount] = useState(cart.count())
  const [disabled, setDisabled] = useState(true)
  const setCartDetails = () => {
    if (!cart.disabled) {
      setDisabled(false)
      setBookCount(cart.count())
    }
  }
  useEffect(() => {
    mainservice.registerToBroadcast('shoppingCartIcon', (key: string, value: any) => {
      switch (key) {
        case 'cartLoaded':
        case 'bookAddedToCart':
        case 'bookRemovedFromCart':
        case 'loginsuccess':
        case 'nav':
          setCartDetails()
          return
      }
    })
    setCartDetails()
  }, [])
  if (disabled) {
    return null
  }
  return <div
      className={`cartMenuIcon linkButton inlineBlock w3-right w3-button`}
      onClick={() => {
        mainservice.navTo([['view', 'shoppingCart']])
      }}
    >
      <ShoppingCart />
      <span className={`cart-item-counter`}>
        {bookCount}
      </span>
    </div>
}

export default CartMenuIcon