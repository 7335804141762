import React from 'react'
import mainservice from '../../../services/MainService'
import PageElementDTO from '../../../DTO/PageElementDTO'
import {SessionItem} from '../../../DTO/SessionDTO'
import InputA from '../../InputA/InputA'
import './RadarChartA.scss'
import config from './RadarConfigA.json'

type Config = {
  config: {
    viewMaxX: number,
    viewMaxY: number,
    radius: number,
    centerX: number,
    centerY: number,
    labelRadius: number,
    ticLength: number,
    markerRadius: number,
    markerFactor: number
  },
  labels: {
    id: number,
    x: number,
    y: number,
    anchor: string,
    rotate?: number
  }[]
}

type SizeVersion = 'desktop' | 'mobile'

type Props = {
  pageElement: PageElementDTO
}

type State = {
  isAdmin: number,
  evaluationKey: string,
  categoryKeys: string,
  maxValue: number,
  polygonBG: string,
  polygonStroke: string,
  focalPointBG: string,
  c: Config
}


const categorySettings = [
  {
    name: 'Name',
    key: 'name'
  },
  {
    name: 'Farbe',
    key: 'color'
  }
]

export default class RadarChartA extends React.Component<Props, State> {
  amounts = new Map()
  standardPoligonBG = 'rgba(100, 100, 100, 0.5)'
  standardPoligonStroke = 'green'
  standardFocalPointBG = 'yellow'
  broadcastKey: string = ''
  currentSizeVersion: SizeVersion = 'desktop'

  constructor(props: Props) {
    super(props)
    this.broadcastKey = 'RadarChart-' + props.pageElement.id
    this.state = {
      isAdmin: mainservice.isAdmin(),
      evaluationKey: props.pageElement.value1,
      categoryKeys: props.pageElement.value2,
      maxValue: parseInt(this.props.pageElement.getPropVal1('categoryKeySetup', 'maxValue'), 10) || 10,
      polygonBG: this.props.pageElement.getPropVal1('categoryKeySetup', 'polygonBG') || this.standardPoligonBG,
      polygonStroke: this.props.pageElement.getPropVal1('categoryKeySetup', 'polygonStroke') || this.standardPoligonStroke,
      focalPointBG: this.props.pageElement.getPropVal1('categoryKeySetup', 'focalPointBG') || this.standardFocalPointBG,
      c: config[this.chooseSizeVersion() as SizeVersion]
    }
    this.gatherData()
    mainservice.registerToBroadcast(
      this.broadcastKey,
      this.broadcastReceiver,
      this
    )
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast(this.broadcastKey)
  }

  broadcastReceiver(key: string, value: any) {
    if (key === 'window-resize') {
      const newVersion = this.chooseSizeVersion(value)
      if (this.currentSizeVersion === newVersion) {
        return
      }
      this.currentSizeVersion = newVersion
      this.setState({c: config[newVersion as SizeVersion]})
    }
  }

  chooseSizeVersion(sizes?: [number, number]): SizeVersion {
    let width = 0
    if (!sizes || sizes.length != 2) {
      width = document.body.clientWidth
    } else {
      width = sizes[0]
    }
    if (width > 1200) {
      return 'desktop'
    }
    return 'mobile'
  }

  keySplitter(ks: string): string[] {
    return ks.split(/[ ,;]+/)
  }

  gatherData() {
    // TODO: If maxValue -1, then calc maxValue by comparing to maxdata in amounts data:
    let sessionData = mainservice.getAllSessionData()
    let ourSessionData = sessionData.filter((sD) => sD.key1 === this.state.evaluationKey)
    ourSessionData.forEach((oSD) => {
      const k = oSD.value1
      if (this.amounts.has(k)) {
        this.amounts.set(k, parseInt(this.amounts.get(k), 10) + parseInt(oSD.value2, 10))
      } else {
        this.amounts.set(k, parseInt(oSD.value2, 10))
      }
    })
  }

  getFocalPoint(coordinates: string[]): {x: number, y: number, xValue: number, yValue: number} {
    let x = 0
    let y = 0
    const s = this.state.c.config
    if (!coordinates || coordinates.length === 0) { return {x: 0, y: 0, xValue: 0, yValue: 0} }
    coordinates.forEach((c) => {
      const co = c.split(',')
      x += parseFloat(co[0])
      y += parseFloat(co[1])
    })
    x = x / coordinates.length
    y = y / coordinates.length
    let relX = x - s.centerX
    let relY = y - s.centerY

    // Do creazy stuff:
    relX = relX * s.markerFactor
    relY = relY * s.markerFactor

    relX = Math.min(s.radius, relX)
    relX = Math.max(-s.radius, relX)
    relY = Math.min(s.radius, relY)
    relY = Math.max(-s.radius, relY)
    x = relX + s.centerX
    y = relY + s.centerY

    return {x: x, y: y, xValue: 0, yValue: 0}
  }


  getFocalPoint4Style(keys: string[]): {x: number, y: number, xValue: number, yValue: number} {
    let amounts: number[] = keys.map((key) => this.amounts.get(key))
    console.log('amounts', amounts)
    type Tuple = [number, number]
    const halfSmallerValueAndCalc = (t: Tuple): number => {
      if (t[0] < t[1]) {
        return t[0] * 0.5 - t[1]
      } if (t[0] > t[1]) {
        return t[0] - t[1] * 0.5
      }
      return t[0] - t[1]
    }
    const yTuple = [amounts[0], amounts[2]] as Tuple
    const xTuple = [amounts[1], amounts[3]] as Tuple
    const yAmount = halfSmallerValueAndCalc(yTuple)
    const xAmount = halfSmallerValueAndCalc(xTuple)
    const xc = this.convertCoordinatesByName(keys[1], xAmount)
    const yc = this.convertCoordinatesByName(keys[0], yAmount)
    return {x: xc.x, y: yc.y, xValue: Math.abs(xAmount), yValue: Math.abs(yAmount)}
  }

  renderFront() {
    const keys = this.keySplitter(this.state.categoryKeys)
    //const minRelevantValue = this.getMinRelevantValue()
    // let relevantCoordinates: string[] = []

    // GET RELEVANT POINTS
    // get array of amounts:
    // let amounts: number[] = keys.map((key) => this.amounts.get(key))
    // console.log('amounts', amounts)
    /*
    // get cruical limit:
    let importantLimit = amounts[2] || 0
    if (amounts[2] === amounts[3]) {
      importantLimit = amounts[1] || 0
    }
    */


    const diagramCoordinates = keys.map((key) => {
      const amount = this.amounts.get(key)
      const c = this.convertCoordinatesByName(key, amount)
      const coordinates = `${c.x},${c.y}`
      /*
      if (amount >= importantLimit) {
        relevantCoordinates.push(coordinates)
      } else {
        const c = this.convertCoordinates(key, amount * 0.5)
        const halfCoordinates = `${c.x},${c.y}`
        relevantCoordinates.push(halfCoordinates)
      }
      */
      return coordinates
    })
    const focalPoint = (keys.length === 4) ?
      this.getFocalPoint4Style(keys) :
      this.getFocalPoint(diagramCoordinates)
    const s = this.state.c.config
    return <div key={`rc-renderFront`} className='rc-canvas'>
      <svg
        viewBox={`0 0 ${s.viewMaxX} ${s.viewMaxY}`}
      >
        {
          keys.map((key, index) => this.renderAxis(key, index))
        }
        <polygon
          className="rc-area"
          points={diagramCoordinates.join(' ')}
          style={{
            fill: this.state.polygonBG,
            stroke: this.state.polygonStroke
          }}
        />
        {
          diagramCoordinates.map((k: string, index) => {
            const xy = k.split(',')
            return <circle
              key={index}
              cx={xy[0]}
              cy={xy[1]}
              r={s.markerRadius}
              fill={'#000'}
            />
          })
        }
        <circle
          cx={focalPoint.x + ''}
          cy={focalPoint.y + ''}
          r={s.markerRadius}
          fill={this.state.focalPointBG}
        />
        { focalPoint.xValue && focalPoint.yValue &&
          <text
            className='focal-label'
            x={focalPoint.x + 2}
            y={focalPoint.y + 0.5}
            textAnchor='start'
          >
            {`(${focalPoint.xValue}|${focalPoint.yValue})`}
          </text>
        }
      </svg>
    </div>
  }

  add2DVectors(v1: {x: number, y: number}, v2: {x: number, y: number}): {x: number, y: number} {
    return {x: v1.x + v2.x, y: v1.y + v2.y}
  }

  sub2DVectors(v1: {x: number, y: number}, v2: {x: number, y: number}): {x: number, y: number} {
    return {x: v1.x - v2.x, y: v1.y - v2.y}
  }

  convertCoordinatesByName(name: string, value: number): {x: number, y: number} {
    const index = this.keySplitter(this.state.categoryKeys).indexOf(name)
    return this.convertCoordinatesByIndex(index, value)
  }

  convertCoordinatesByIndex(index: number, value: number): {x: number, y: number} {
    const s = this.state.c.config
    const maxR = s.radius
    const countKeys = this.keySplitter(this.state.categoryKeys).length
    const pieceSize = 2 * Math.PI / countKeys
    const direction = pieceSize * index
    const maxValue = this.state.maxValue
    const ourLength = maxR / maxValue * value
    return this.coordinatesByDirAndLength(ourLength, direction)
  }

  coordinatesByDirAndLength(ourLength: number, direction: number) {
    const s = this.state.c.config
    const xy = this.relativeCoordinatesByDirAndLength(ourLength, direction)
    return this.add2DVectors(xy, {x: s.centerX, y: s.centerY})
  }

  relativeCoordinatesByDirAndLength(ourLength: number, direction: number) {
    const x2: number = ourLength * Math.sin(direction) || 0
    const y2: number = - ourLength * Math.cos(direction) || 0
    return {x: x2, y: y2}
  }

  renderTic(name: string, color: string, value: number, index: number) {
    const s = this.state.c.config
    const center = this.convertCoordinatesByName(name, value)
    const countKeys = this.keySplitter(this.state.categoryKeys).length
    const pieceSize = 2 * Math.PI / countKeys
    const direction = pieceSize * index + 0.5 * Math.PI
    const ticDir = this.relativeCoordinatesByDirAndLength(s.ticLength, direction)
    // const ticDirNegative = this.relativeCoordinatesByDirAndLength(1, -direction)
    const start = this.sub2DVectors(center, ticDir)
    const end = this.add2DVectors(center, ticDir)
    return <line
      key={`tic-${index}-${value}`}
      className='tic'
      x1={start.x}
      y1={start.y}
      x2={end.x}
      y2={end.y}
      style={{stroke: color}}
    />
  }

  renderLabel(name: string, color: string, index: number) {
    const s = this.state.c.config
    const labels = this.state.c.labels
    const displayName = this.props.pageElement.getPropVal1('categoryKeySetup', `${name}-name`)
    const countKeys = this.keySplitter(this.state.categoryKeys).length
    const pieceSize = 2 * Math.PI / countKeys
    const direction = pieceSize * index
    let xy = this.relativeCoordinatesByDirAndLength(s.labelRadius, direction)
    xy = this.add2DVectors(xy, {x: s.centerX, y: s.centerY})
    const pCL = labels.length
    const labelConfig = labels.find((_pC, index) => {
      const alpha = index * 2 * Math.PI / pCL
      return direction < alpha
    }) || {
      id: 13,
      x: 0,
      y: 0,
      anchor: 'middle'
    }
    const rotate = labelConfig.rotate || 0
    xy = this.add2DVectors(xy, labelConfig)
    console.log('L: ', displayName, ' - ', labelConfig.id)
    return <text
      className='axis-label'
      x={xy.x}
      y={xy.y}
      style={{fill: color}}
      textAnchor={labelConfig.anchor}
      transform={`rotate(${rotate} ${xy.x} ${xy.y})`}
    >
      {displayName}
    </text>
  }

  renderValue(name: string, color: string, index: number) {
    const s = this.state.c.config
    const content = this.amounts.get(name)
    const center = this.convertCoordinatesByName(name, parseFloat(content))
    const countKeys = this.keySplitter(this.state.categoryKeys).length
    const pieceSize = 2 * Math.PI / countKeys
    const direction = pieceSize * index + 0.5 * Math.PI
    const ticDir = this.relativeCoordinatesByDirAndLength(s.ticLength * 4, direction)
    // const ticDirNegative = this.relativeCoordinatesByDirAndLength(s.ticLength * 2, -direction)
    const start = this.sub2DVectors(center, ticDir)
    const end = this.add2DVectors(center, ticDir)
    let xy = start
    if (0 < index && index <= countKeys * 0.5) {
      xy = end
    }
    return <text
      key={`value-tic-${index}`}
      className='axis-label'
      x={xy.x}
      y={xy.y + 1}
      style={{fill: color}}
      textAnchor={'middle'}
    >
      {content}
    </text>
  }

  renderAxis(name: string, index: number) {
    const maxValue = this.state.maxValue
    const center = this.convertCoordinatesByName(name, 0)
    const outer = this.convertCoordinatesByName(name, maxValue)
    const color = this.props.pageElement.getPropVal1('categoryKeySetup', `${name}-color`)
    const label = this.renderLabel(name, color, index)
    const x1: number = center.x
    const y1: number = center.y
    const x2: number = outer.x
    const y2: number = outer.y
    const outerQuadrantNamePosition = this.convertCoordinatesByIndex(index + 0.5, maxValue)
    let tics = []
    for (let i = 1; i < maxValue + 1; i++) {
      tics.push(this.renderTic(name, color, i, index))
    }
    // figure out direction for the axis
    return <g key={`axis-${name}-${index}`}>
      <line
        className='axis'
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        style={{stroke: color}}
      />
      {this.renderValue(name, color, index)}
      {tics}
      {label}
      <text
        className='quadrant-label'
        x={outerQuadrantNamePosition.x}
        y={outerQuadrantNamePosition.y}
        textAnchor='middle'
        style={{fill: color}}
      >
        {`Q${index + 1}`}
      </text>
    </g>
  }

  renderAdmin() {
    return <table className='radarChartAdmin'>
      <tbody>
        <tr>
          <td>
            Umfrageschlüssel:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({evaluationKey: rv})
                self.props.pageElement.setValue1(rv)
              }}
              value={this.state.evaluationKey}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            Achsenmaximum:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({maxValue: parseInt(rv, 10)})
                self.props.pageElement.addElementProp({
                  key1: 'categoryKeySetup',
                  key2: 'maxValue',
                  value1: rv,
                  value2: ''
                })
              }}
              value={this.state.maxValue + ''}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            Flächenfarbe:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({polygonBG: rv || this.standardPoligonBG})
                self.props.pageElement.addElementProp({
                  key1: 'categoryKeySetup',
                  key2: 'polygonBG',
                  value1: rv,
                  value2: ''
                })
              }}
              value={this.state.polygonBG}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            FlächenRandFarbe:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({polygonStroke: rv || this.standardPoligonStroke})
                self.props.pageElement.addElementProp({
                  key1: 'categoryKeySetup',
                  key2: 'polygonStroke',
                  value1: rv,
                  value2: ''
                })
              }}
              value={this.state.polygonStroke}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            SchwerpunktMarkerFarbe:
          </td>
          <td>
            <InputA
              returnVal={(rv: string, self: any) => {
                // Wert speichern
                self.setState({focalPointBG: rv || this.standardFocalPointBG})
                self.props.pageElement.addElementProp({
                  key1: 'categoryKeySetup',
                  key2: 'focalPointBG',
                  value1: rv,
                  value2: ''
                })
              }}
              value={this.state.focalPointBG}
              parent={this}
            />
          </td>
        </tr>
        <tr>
          <td>
            Kategorieschlüssel (Kommasepariert):
          </td>
          <td>
            <InputA
              returnVal={(rv: string) => {
                // Wert speichern
                const newKeys = this.keySplitter(rv).join(', ')
                this.setState({categoryKeys: newKeys})
                this.props.pageElement.setValue2(newKeys)
              }}
              value={this.state.categoryKeys}
              parent={this}
            />
          </td>
        </tr>
        {
          this.keySplitter(this.state.categoryKeys).map((cK: string, catIndex) => {
            if (!cK) { return <></> }
            return categorySettings.map((cS, setIndex) => {
              const keyName = `${cK}-${cS.key}`
              return <tr key={`${catIndex}-${setIndex}`}>
                <td>Kategorie <b>{cK}</b> {cS.name}</td>
                <td>
                  <InputA
                    returnVal={(rv: string, self: any) => {
                      // Wert speichern
                      self.props.pageElement.addElementProp({
                        key1: 'categoryKeySetup',
                        key2: keyName,
                        value1: rv,
                        value2: ''
                      })
                    }}
                    value={this.props.pageElement.getPropVal1('categoryKeySetup', keyName)}
                    parent={this}
                  />
                </td>
              </tr>
            })
          })
        }
      </tbody>
    </table>
  }

  render() {
    if (this.state.isAdmin) {
      return <>
        <div className="editMode">
          {
            this.renderAdmin()
          }
        </div>
        <div className="displayMode">
          {
            this.renderFront()
          }
        </div>
      </>
    }
    return this.renderFront()
  }
}
