import { useState } from "react";
import './EventEdit.scss'
import PlaningToolRoot, { State } from "../PlaningToolRoot";
import { ObjectKind } from "../../models/objectKinds";
import { GenericDTO } from "../../DTO/GenericDTO";
import { MItem } from "../Menu";
import { BuildingBlocks } from "./BuildingBlocks";
import { EditItem } from "./EditItem";

export default function BuildingBlockEdit(props: {
  isTemplate?: boolean,
}) {
  const [editItem, setEditItem] = useState(new GenericDTO({id: -1}))
  const [editObjectKind, setEditObjectKind] = useState(-1)
  const [showLeftDrawer, setShowLeftDrawer] = useState(false)
  const [reloadRightSide, setReloadRightSide] = useState(1)

  return <PlaningToolRoot
    className='BuildingBlockEdit'
    standalone={true}
    menuItem={MItem.buildingBlocks}
    mainContentClass={`flex flex-column mh100noScroll`}
    rightDrawer={<BuildingBlocks
      editItem={(item: GenericDTO, objectKind: ObjectKind) => {
        setEditObjectKind(objectKind)
        setEditItem(item)
        setShowLeftDrawer(true)
      }}
      reloadTrigger={reloadRightSide}
      showEmptyElement={false}
    />}
    startStateRightDrawer={State.open}
    showLeftDrawer={showLeftDrawer}
    leftDrawer={
      (editItem && showLeftDrawer) ?
        <EditItem
          close={() => {
            setReloadRightSide(reloadRightSide + 1)
            setShowLeftDrawer(false)
          }}
          item={editItem}
          objectKind={editObjectKind}
          rerenderRightSide={() => {
            setReloadRightSide(reloadRightSide + 1)
          }}
          setEditItem={(item) => {
            setEditItem(item)
            setEditObjectKind(item.objectKind)
            setShowLeftDrawer(true)
          }}
        /> : undefined
    }
  >
    <div className='flex-grow'></div>
    <h2 className='textCenter'>Bausteineditor</h2>
    <div className='flex-grow'></div>
  </PlaningToolRoot>
}
