import { Setter, Getter } from '../services/ComService'
import { CurrencyFormatter } from '../services/Currency'
import mainservice from '../services/MainService'
import {DisplayDateTimeNumeric} from '../services/DateTime'

export type Book = {
  id: number,
  name: string,
}

type Prop = {
  key1: string,
  key2: string,
  value1: string,
  value2: string,
}

export enum CartStatus {
  unknown = 0,
  FULLFILLED = 1,
  ISPAID = 2,
  MARKEDASPAYED = 4,
  CHECKOUT = 8,
  CANCELED = 16,
}

const CartStatus2StringTable: [number, string][] = [
  [16, 'CANCELED'], // Has to be first, because everything else will be skipped if true
  [1, 'FULLFILLED'],
  [2, 'ISPAID'],
  [4, 'MARKEDASPAYED'],
  [8, 'CHECKOUT'],
]

export type ICart = {
  hash: string,
  kind: string,
  email?: string,
  props?: Prop[],
  books?: Book[],
  total?: number,
  additional?: [string, string][],
  status?: CartStatus,
  modified?: {
    date: string
  }
}

export function TranslateCartStatus(i: CartStatus) {
  switch(i) {
    case CartStatus.FULLFILLED:
      return 'Beendet'
    case CartStatus.ISPAID:
      return 'Bezahlt - warte auf Ausführung'
    case CartStatus.MARKEDASPAYED:
      return 'Als bezahlt markiert - warte auf Überprüfung'
    case CartStatus.CHECKOUT:
      return 'Warte auf Bezahlung'
    case CartStatus.CANCELED:
      return 'Storniert'
    default:
      return 'unbekannt'
  }
}

export default class CartDTO {
  hash: string = ''
  kind: string = ''
  props: Prop[] = []
  books: Book[] = []
  total: number = 0
  tax: number = 19
  initDone: boolean = false
  renderCounter: number = 1
  modified: string = ''
  email: string = ''
  private additional: [string, string][] = []
  public disabled: boolean = true
  status: CartStatus = CartStatus.unknown

  constructor(options: ICart, noServer?: boolean) {
    this.hash = options.hash || ''
    this.kind = options.kind || 'default'
    if (!noServer) {
      this.getFromServer()
    } else {
      this.receiveValues(options)
    }
  }

  count(): number {
    return this.books.length
  }

  increaseRenderCounter(): number {
    return this.renderCounter += 1
  }

  receiveValues(options: ICart) {
    if (!options) { return }
    this.books = options.books || []
    this.props = options.props || []
    this.total = options.total || 0
    this.additional = options.additional || []
    this.status = options.status || CartStatus.unknown
    this.email = options.email || ''
    if (options.modified?.date) {
      // this.modified = options.modified// new Date(options.modified.date.replace(' ', 'T').replace(/:[0-9]{2}\..*/, ''))
      // this.modified = new Date(options.modified.date)
      this.modified = options.modified.date
    }
  }

  HasStatus(v: CartStatus) {
    return (this.status & v) === v
  }

  HasSomeStatus(vs: CartStatus[]) {
    return vs.some(v => this.HasStatus(v))
  }

  HasEveryStatus(vs: CartStatus[]) {
    return vs.every(v => this.HasStatus(v))
  }
  
  GetCartStatus() {
    let outStrings: string[] = []
    const i = this.status
    CartStatus2StringTable.some(c => {
      if (this.HasStatus(c[0])) {
        outStrings.push(TranslateCartStatus(c[0]))
        return true
      }
    })
    return outStrings.join(', ')
  }

  async getFromServer() {
    // if (this.initDone) { return }
    this.initDone = true
    return new Promise((resolve, reject) => {
      /*
      Setter('shop/getCart', {
          kind: this.kind,
          hash: this.hash,
        }
        */
      Getter('shop/getCart').then((r) => {
        if (r && r.status === 'disabled') {
          this.disabled = true
        } else {
          this.hash = r.hash
          this.disabled = false
          this.receiveValues(r)
        }
        mainservice.broadcast('cartLoaded', this.count())
        resolve(true)
      }).catch((err) => {
        console.log('hm, did not work the first time', err)
      })
    })
  }

  async checkout(kind: 'manualTransfer') {
    const data: any = await Setter('shop/checkoutManualTransfer', {
      kind: this.kind,
      hash: this.hash,
    })
    this.receiveValues(data)
  }

  async cancel() {
    const data: any = await Setter('shop/cancelCart', {
      kind: this.kind,
      hash: this.hash,
    })
    this.receiveValues(data)
  }

  async markPaid() {
    const data: any = await Setter('shop/markPaid', {
      kind: this.kind,
      hash: this.hash,
    })
    this.receiveValues(data)
  }

  async sendPaymentInfo() {
    const data: any = await Setter('shop/sendPayInfo', {
      kind: this.kind,
      hash: this.hash,
    })
    this.receiveValues(data)
  }

  getModified(): string {
    if (this.modified === '') {
      return '?'
    }
    return DisplayDateTimeNumeric(new Date(this.modified))
    // return SanitizeDate(this.modified)
  }

  getAdditional(key: string): string {
    const res = this.additional.find(a => a[0] === key)
    // set destroy timer:
    setTimeout(async () => {
      // await this.trashCart()
      this.hash = ''
      await this.getFromServer()
      mainservice.broadcast('cartLoaded', this.count())
    }, 2000)
    if (res && res.length > 1) {
      return res[1]
    }
    return ''
  }

  async removeBooks(bookIds: number[]) {
    const nc = await Setter('shop/removeBooks', {
      bookIds: bookIds,
      kind: this.kind,
      hash: this.hash,
    })
    if (!nc) { return }
    this.receiveValues(nc)
  }

  private getProp(key1: string, key2: string): Prop | undefined {
    return this.props.find(p => p.key1 === key1 && p.key2 === key2)
  }

  private getPropV1(key1: string, key2: string, fallbackVal: string) {
    const r = this.getProp(key1, key2)
    if (r) {
      return r.value1
    }
    return fallbackVal
  }

  public listCart(): Book[] {
    return this.books
  }

  public totalUser(): string {
    return CurrencyFormatter((this.total * 100 / (100 + this.tax)) + '')
  }

  public taxToPayAmount(): string {
    return CurrencyFormatter((this.total * this.tax / (100 + this.tax)) + '')
  }

  public totalWithTax(): string {
    return CurrencyFormatter(this.total + '')
  }

  getBookPrice(bookId: number): number {
    const price = this.props.find(p => p.key1 === 'bookPrice' && p.key2 === '' + bookId)
    if (!price) { return 0 }
    return parseFloat(price.value1)
  }

  renderBookPrice(bookId: number): string {
    const price = this.getBookPrice(bookId) + ''
    return CurrencyFormatter(price)
  }

  async trashCart(): Promise<void> {
    const nc = await Setter('shop/trashCart', {
      kind: this.kind,
      hash: this.hash,
    })
    this.receiveValues(nc)
  }

  async addBooks(bookIds: number[], options?: {hash?: string, kind?: string}) {
    const nc = await Setter('shop/addBooks', {
      bookIds: bookIds,
      hash: options?.hash || '',
      kind: options?.kind || 'default'
    })
    this.receiveValues(nc)
  }

  public isBookInCart(bookId: number): boolean {
    return this.books.some(b => b.id === bookId)
  }

  public async markPaidAndFullfill() {
    Setter('shop/markPaidAndFullfill', {
      kind: this.kind,
      hash: this.hash,
    })
  }
}

export let cart: CartDTO = new CartDTO({hash: '', kind: 'default'})
