import { useState } from "react"
import './TimeperiodSelector.scss'
import InputB from "../../components/InputB/InputB"
import { minutes2HumanFormat, minutes2Input, timeInput2minutes } from "../../services/DateTime"

const AvailableTimes = [
  [
    10, 15, 20, 25, 30, 45
  ],
  [
    60, 75, 90, 105
  ],
  [
    120, 150, 180, 240
  ],
  [
    300, 360, 420, 480
  ],
]
enum Status {
  display,
  select,
}

export default function TimeperiodSelector (props: {
  parentId: string,
  value: number,
  setValue: (value: number) => void,
}) {
  const [status, setStatus] = useState(Status.display)
  const rowClass = 'tsrow'
  const colClass = 'w3-border w3-padding timecol'
  return <div className="TimeperiodSelector w3-dropdown-hover">
    <button
      className="w3-button w3-border"
      onClick={() => {
        if (status === Status.display) {
          setStatus(Status.select)
        } else {
          setStatus(Status.display)
        }
      }}
    >
      Dauer:&nbsp;
      {
        minutes2Input(props.value)
      }&nbsp;h
    </button>
    {
      status === Status.select &&
      <div
        className={`TimeperiodSelectorDrawer w3-dropdown-content`}
      >
        <InputB
          className={`without_ampm`}
          value={minutes2Input(props.value)}
          returnVal={(v) => {
            props.setValue(timeInput2minutes(v))
          }}
          type={'time'}
        />
        {
          AvailableTimes.map((row, rowIndex) => {
            return <div
              key={`TimeSelect-row-${props.parentId}-${rowIndex}`}
              className={rowClass}
            >
              {
                row.map((col, colIndex) => {
                  return <div
                    key={`TimeSelect-row-${props.parentId}-${rowIndex}-${colIndex}`}
                    className={`${colClass} ${col === props.value ? 'w3-blue' : ''}`}
                    onClick={() => {
                      props.setValue(col)
                      setStatus(Status.display)
                    }}
                  >{minutes2HumanFormat(col)}</div>
                })
              }
            </div>
          })
        }
      </div>
    }
  </div>
}

