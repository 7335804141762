import React from 'react'
import mainservice from '../../services/MainService'
// import config from '../../config.json'
// import {Getter, Setter} from '../../services/ComService'
import BookDTO, {IBook, GetBooks} from '../../DTO/BookDTO'
import { Book, OpenBookMode } from '../Library/Library'
import CollectibleDTO, { CollectibleType, CollectiblesDTO } from '../../DTO/CollectibleDTO'
import CollectibleItemC from '../Collectible/CollectibleItemC'
import layoutConfig from '../../static/styles/layout-config.json'
import Spinner from '../Spinner/Spinner'
import { Remove } from '../Icon/Icon'

type Props = {
}

type State = {
  openBookId: number,
  things: Thing[],
  showSingle: number,
  loading: boolean
}

type Thing = {
  date: Date,
  content: CollectibleDTO | BookDTO,
  type: 'collectible' | 'book'
}

export default class UserLastElements extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    // console.log('+++ UserLastElements starting')
    this.state = {
      openBookId: -1,
      things: [],
      showSingle: -1,
      loading: true
    }
    // Broadcast receiver - if we login - fetch data
    mainservice.registerToBroadcast(
      'userlastelements',
      (key: string, value: any) => {
        switch(key) {
          case 'loginsuccess':
            this.getData()
        }
      },
      this
    )
  }

  componentDidMount() {
    this.getData()
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('userlastelements')
  }

  async getData() {
    const books = await GetBooks()
    const c = new CollectiblesDTO({version: 'user'})
    await c.getFromServer()
    const collectibles = c.get()
    const bookThings: Thing[] = books.filter(b => !b.teaser).map((b) => {
      return {
        date: (b.lastPosition.date) ? b.lastPosition.date : new Date(0),
        content: b,
        type: 'book'
      }
    })
    const collectibleThings: Thing[] = collectibles.map((c) => {
      return {
        date: c.date,
        content: c,
        type: 'collectible'
      }
    })
    let things: Thing[] = bookThings.concat(collectibleThings)
    this.setState({things: things, loading: false})
  }

  openBook(book: BookDTO, mode: OpenBookMode) {
    const id = book.id
    mainservice.broadcast('libraryOpensBook', {bookId: id})
    this.setState({
      openBookId: id
    })
    setTimeout(() => {
      if (book.teaser) { return }
      if ((mode === OpenBookMode.standard || mode === OpenBookMode.lastPosition) && book.lastPosition.doublePageId && book.lastPosition.doublePageId > 0) {
        // mainservice.navToDoublePage(book.lastPosition.doublePageId, book.id)
        mainservice.navTo([
          ['view', 'book'],
          ['book', book.id],
          ['doublePageId', book.lastPosition.doublePageId],
          ['percentage', book.lastPosition.percentage]
          // ['page', 40]
        ])
      } else if (mode === OpenBookMode.furthestPosition && book.furthestPosition.doublePageId) {
        mainservice.navTo([
          ['view', 'book'],
          ['book', book.id],
          ['doublePageId', book.furthestPosition.doublePageId],
          ['percentage', book.furthestPosition.percentage]
        ])
      } else {
        mainservice.navTo([
          ['view', 'book'],
          ['book', book.id],
          ['page', 1]
        ])
      }
    }, 900)
  }

  render() {
    if (this.state.showSingle > -1) {
      return <div className='lightbox'>
        {
          this.state.things.filter((c) => c.type === 'collectible' && c.content.id === this.state.showSingle).map((c) => <CollectibleItemC
            key={c.content.id}
            collectible={c.content as CollectibleDTO}
            className='lightboxItem'
          />)
        }
        <button
          onClick={() => {
            this.setState({showSingle: -1})
          }}
        ><Remove /></button>
      </div>
    }
    return <div
      className={`Library w3-row ${layoutConfig.tileParentClass} ${(this.state.openBookId > -1) ? 'openBook' : ''}`}
    >
    {!this.state.loading && !this.state.things && <div className='noData'>
      <p>Keine Inhalte vorhanden</p>
    </div>}
    {this.state.loading && <Spinner />}
    {
      this.state.things.sort((ta: Thing, tb: Thing) => tb.date.getTime() - ta.date.getTime()).map((t, index) => {
        if (t.type === 'book') {
          // console.log('TDate', t.date, t)
          return <Book
            key={`${t.content.id}-${index}`}
            book={t.content as BookDTO}
            index={index}
            active={false}
            openBook={(book: BookDTO, mode: OpenBookMode) => {
              this.openBook(book, mode)
            }}
          />
        }
        if (t.type === 'collectible') {
          return <CollectibleItemC
            key={`${t.content.id}-${index}`}
            collectible={t.content as CollectibleDTO}
            onClick={(id) => {
              this.setState({showSingle: t.content.id})
            }}
            className={(index === 0) ? layoutConfig.tileClassFirst : layoutConfig.tileClass}
          />
        }
      })
    }
    </div>
  }

}
