import React from 'react'
import BookDTO, {Layer} from '../../DTO/BookDTO'
import DoublePageDTO from '../../DTO/DoublePageDTO'
import BookSelector from '../Booklist/BookSelector'
import InputA from '../InputA/InputA'
import { DragAndDropTypes } from '../../Models/dnd/dragAndDropTypes'
import { useDrop } from 'react-dnd'
import './DoublePageOverview.scss'

type Props = {
  book: BookDTO,
  changeCurrentPage: (index: number) => void,
  refresh: () => void
}

type State = {
  // refCollectionEarmark: number,
  markedDoublePages: number[]
}

function DropZone(props: {
  sortNumber: number,
  addCollection: (sortNumber: number, item: any) => void
}) {
  let currentItem: any = ''
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: DragAndDropTypes.Book,
    canDrop: (props, monitor) => {
      currentItem = monitor.getItem()
      return true
    },
    drop: () => {
      return props.addCollection(props.sortNumber, currentItem)
    },
    collect: monitor => ({
      // item: monitor.getItem(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  }), [props.sortNumber])
  return <div
    ref={drop}
    className={`dropzone ${(isOver) ? 'hover' : ''} ${(canDrop) ? 'canDrop' : ''}`}
  >
  </div>
}

export default class DoublePageOverview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      // refCollectionEarmark: -1,
      markedDoublePages: []
    }
  }

  addCollection(sortNumber: number, item: {bookId: number}) {
    console.log('realDPPosition SORT Number', sortNumber)
    this.props.book.addCollection({
      bookId: this.props.book.id,
      collectionId: item.bookId,
      sortNumber: sortNumber * 10 + 5
    }).then(() => {
      this.props.refresh()
    })
  }

  renderDoublePageOverviewThumb(dP: DoublePageDTO, index: number, jndex: number) {
      const content = dP.getAllPageElements().find((e) => e.elementType.name === 'text')?.getValue1() || ''
      console.log('content:', content)
      const marked = this.state.markedDoublePages.some((mDP) => mDP === dP.id)
      const backGroundImage = dP.pages[0]?.getCssBGPicture()
      console.log('BG', backGroundImage)
      const style = (backGroundImage) ? {
        backgroundImage: backGroundImage
      } : {}
      return <div
        className={`${(dP.sortNumber) + 1} ${dP.id} doublePageThumb ${dP.getCssClass()}`}
        key={`${dP.id}-${(dP.sortNumber)}-${jndex}`}
        style={style}
      >
        #{(dP.sortNumber)}
        <div className={`doublePageContent ${dP.pages[0]?.getCssClass()}`}>
          <div className='peek' dangerouslySetInnerHTML={{__html: content}}></div>
          <div className='info'>
            {`Seite ${dP.sortNumber + 1} Id ${dP.id} `}
          </div>

          <div className='interaction'>
            <button onClick={() => {
              // this.props.changeCurrentPage(index + 1)
              window.open(`/view=book/book=${this.props.book.id}/doublePageId=${dP.id}/`, '')
            }}>zeigen</button>
            <button onClick={() => {
              window.open(`/view=book/book=${this.props.book.id}/doublePageId=${dP.id}/`, '_blank')
            }}>In Tab zeigen</button>
            { dP.sourceBookId === -1 &&
              <>
                <button
                  onClick={() => {
                    if (marked) {
                      this.setState({markedDoublePages: this.state.markedDoublePages.filter((mdp) => mdp !== dP.id)})
                    } else {
                      this.setState({markedDoublePages: this.state.markedDoublePages.concat([dP.id])})
                    }
                  }}
                  className={`${(marked) ? 'active' : '' }`}
                >
                  {`${marked ? 'markiert' : 'markieren'}`}
                </button>
                <button onClick={() => {
                  this.props.book?.removeDoublePage(dP.id)
                }}>
                  löschen
                </button>
              </>
            }
          </div>
        </div>
      </div>

  }

  renderDoublePageOverview() {
    if (!this.props.book) { return }

    let glued: {
      doublePages: DoublePageDTO[],
      sourceBook: number,
      anchorId: number,
      sourceBookName: string
    }[] = []
    let currentId = -1
    this.props.book?.doublePages.forEach((dP) => {
      let push = true
      if (dP.sourceBookId > -1 && dP.sourceBookId !== this.props.book?.id) {
        if (currentId === dP.sourceBookId) {
          glued[glued.length - 1].doublePages.push(dP)
          push = false
        } else {
          currentId = dP.sourceBookId
        }
      } else {
        currentId = -1
      }
      if (push) {
        glued.push({
          doublePages: [dP],
          sourceBook: currentId,
          sourceBookName: dP.sourceBookName,
          anchorId: dP.anchorId
        })
      }
    })
    return <div className='pageOverviewRoot'>
      <h2>{this.props.book.name}</h2>
      <div className='pageOverview'>
        {
          this.state.markedDoublePages.length > 0 &&
          <div>
            <span>Markierte Seiten als Referenzbuch exportieren unter folgendem Namen:</span>
            <InputA
              returnVal={(rv) => {
                if (!rv) { return }
                this.props.book?.exportDoublePagesAsCollection(rv, this.state.markedDoublePages).then(() => {
                  this.setState({markedDoublePages: []})
                  this.props.refresh()
                })
              }}
            />
            <button
              onClick={() => {
                this.setState({markedDoublePages: []})
              }}
            >Auswahl aufheben</button>
          </div>
        }
        <DropZone
          sortNumber={0}
          addCollection={(sortNumber, item) => this.addCollection(sortNumber, item)}
        />
        {
          glued.map((g, index) => {
            return <div
              key={index}
            >
              <div className={`${g.sourceBook > -1 ? 'externalBook' : ''}`}>
                {
                  g.anchorId > -1 &&
                  <div className='collectionInfo'>
                    <div className='collectionTitle'>
                      <a href={`/view=book/book=${g.sourceBook}/`}>
                        {g.sourceBookName}
                      </a>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          this.props.book?.removeDoublePage(g.anchorId).then(() => {
                            this.props.refresh()
                          })
                        }}
                      >
                        Entfernen
                      </button>
                      <button
                        onClick={() => {
                          this.props.book?.integrateCollection(g.anchorId).then(() => {
                            this.props.refresh()
                          })
                        }}
                      >
                        Integrieren
                      </button>

                    </div>
                  </div>

                }
                { g.doublePages.map((dP, jndex) => this.renderDoublePageOverviewThumb(dP, index, jndex)) }
              </div>
              <DropZone
                sortNumber={this.props.book.realDPPosition(g.doublePages[0].id)}
                addCollection={(sortNumber, item) => this.addCollection(sortNumber, item)}
              />
            </div>
          })
        }
      </div>
      <BookSelector
        bookBlackList={[this.props.book.id]}
      />
    </div>
  }

  render() {
    return this.renderDoublePageOverview()
  }
}
