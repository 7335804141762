// import config from '../config.json'

export default class RightDTO {
  id: number
  name: string
  description: string
  value: number

  constructor(data: IRight) {
    this.id = data.id
    this.name = data.name
    this.description = data.description
    this.value = data.value
  }

  public get() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      value: this.value
    }
  }

}

export interface IRight {
  id: number,
  name: string,
  description: string,
  value: number
}
