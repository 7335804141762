import React from 'react'
import mainservice from '../../../services/MainService'
import PageElementDTO from '../../../DTO/PageElementDTO'
import './IconMagnet.scss'

type Props = {
  pageElement: PageElementDTO
}

type State = {
  isAdmin: number,
  adminEdit: boolean,
  pageElement: PageElementDTO,
  editValue1Status: string,
  editValue2Status: string,
}

class IconMagnet extends React.Component<Props, State> {
  state: State
  possiblePositions = ['left', 'center', 'right']
  possibleIcons = ['A', 'B', 'C', 'D', 'ABCD']
  doublePageId: number
  ref1 = React.createRef()
  ref2 = React.createRef()
  ref3 = React.createRef()
  ref4 = React.createRef()
  timerID: any
  constructor(props: Props) {
    super(props)
    this.state = {
      isAdmin: mainservice.isAdmin(),
      adminEdit: false,
      pageElement: props.pageElement,
      editValue1Status: props.pageElement.value1,
      editValue2Status: props.pageElement.value1,
    }
    this.doublePageId = this.state.pageElement.getDoublePageID()
  }

  componentDidUpdate() {

  }
  componentDidMount() {
    this.timerID = setInterval(() => this.suck(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  suck() {
    if (!this.ref1 || !this.ref1.current) { return }
    const q = 20
    const x = (this.ref1.current as {offsetLeft: number}).offsetLeft
    const y = (this.ref1.current as {offsetTop: number}).offsetTop
    if (this.state.pageElement.value1 === 'ABCD') {
      mainservice.setFloatIconCoordinates('A', this.doublePageId, x - q, y + q)
      mainservice.setFloatIconCoordinates('B', this.doublePageId, x + q, y + q)
      mainservice.setFloatIconCoordinates('C', this.doublePageId, x - q, y + 3*q)
      mainservice.setFloatIconCoordinates('D', this.doublePageId, x + q, y + 3*q)

    } else if (this.ref1.current) {
      mainservice.setFloatIconCoordinates(this.state.pageElement.value1, this.doublePageId, x, y)
    }
  }

  renderMagnet(ref: any) {
    return <div ref={ref}></div>
  }

  render() {
    const active = 'w3-ripple w3-blue'
    const passive = 'w3-white w3-border'
    let content = <></>
    let alignClass = 'doCenter'
    if (this.state.pageElement.value2 === 'left') {
      alignClass = 'floatLeft'
    } else if (this.state.pageElement.value2 === 'right') {
      alignClass = 'floatRight'
    }
    if (this.state.pageElement.value1 === 'ABCD') {
      content = <div className={`magnet ${alignClass} magnet-ABCD`}>
        <div className='magnet magnet-A'></div>
        <div className='magnet magnet-B'></div>
        <div className='magnet magnet-C'></div>
        <div className='magnet magnet-D'></div>
      </div>
    } else {
      content = <div className={`magnet ${alignClass} magnet-${this.state.pageElement.value1}`} key={`magnet-${this.state.pageElement.value1}`}>
      {
        this.renderMagnet(this.ref1)
      }
      </div>
    }
    if (this.state.isAdmin > 0) {
      return <>
        <div className="editMode">
          <div>Position:</div>
          <div className="w3-row">
             {
               this.possiblePositions.map((pP, index) => <button key={index} className={'w3-col s4 ' + (this.state.pageElement.value2 === pP ? active : passive)} onClick={() => {
                 this.state.pageElement.setValue2(pP)
               }}>
                 {pP}
               </button>)
             }
          </div>
          <div>Icon:</div>
          <div className="w3-row">
            {
              this.possibleIcons.map((pI, index) => <button key={index} className={'w3-col s3 ' + (this.state.pageElement.value1 === pI ? active : passive)} onClick={() => {
                this.state.pageElement.setValue1(pI)
              }}>
                {pI}
              </button>)
            }
          </div>
        </div>
        {content}
      </>
    }
    return content
  }
}

export default IconMagnet
