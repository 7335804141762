import { useState } from "react"

export default function Select(props: {
  options: {value: string, title: string}[],
  value: string,
  returnVal: (rv: string) => void,
  className?: string,
}) {
  const [value, setValue] = useState(props.value)
  return <select
    className={props.className || 'w3-input'}
    value={value}
    onChange={(event) => {
      setValue(event.target.value)
      props.returnVal(event.target.value)
    }}
  >
    {
      props.options.map((o, i) => {
        return <option
            key={`ddo-${o.value}-${i}`}
            value={o.value}
          >{o.title}</option>
      })
    }
  </select>
}
