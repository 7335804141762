import config from '../config.json'
import FileDTO, {IFile} from './FileDTO'
import TagDTO, {ITag} from './TagDTO'

type GetOptions = {
  fresh?: boolean
  id?: number
}
export default class FilesDTO {
  private files: FileDTO[] = []

  constructor() {

  }

  init(o: {files: IFile[], tagList: ITag[]}): FileDTO[] {
    const tags = o.tagList.map((t) => new TagDTO(t))
    this.files = o.files.map((f: IFile) => new FileDTO(f, {tags: tags}))
    return this.files
  }

  async get(o?: GetOptions): Promise <FileDTO[]> {
    if (this.files.length === 0 || o && o.fresh) {
      const response = await fetch(`${config.apiPrefix}Files/getAll`)
      let responseJson = await response.json()
      this.init(responseJson)
    }
    return this.files
  }

  getSingle(id: number): FileDTO {
    const result = this.files.find((f) => f.id === id)
    if (!result) {
      return new FileDTO()
    }
    return result
  }
}
