import mainservice from "../../services/MainService";
import { Setter } from "../../services/ComService";
import { ObjectKind } from "../models/objectKinds";
import { IMaterialItem, MaterialItemDTO } from "./MaterialItemDTO";
import { GenericDTO, GenericEditField, IGeneric } from "./GenericDTO";
import PropDTO from "./PropDTO";
import CONFIG from '../../config.json'
import { TrainingItemDTO } from "./TrainingItemDTO";
import { IModuleTrainingItem, ModuleTrainingItemDTO } from "./ModuleTrainingItemDTO";
import { EqualizeItemListAndSortString, PutIdAtPosition } from "../../services/SortString";
import { GenericMaterialItemDTO } from "./GenericMaterialItemDTO";
import { PublishKind } from "../models/publishKinds";
import { LightOrDarkColor, LightenColor } from "../../services/LightOrDarkColor";
import { minutes2Input } from "../../services/DateTime";
import { ModuleEditFields } from "../editFields/ModuleEditFields";

export default class ModuleDTO extends GenericDTO {
  duration: number = 60
  start?: number
  pin?: boolean
  plannedStart?: number
  trainingItems: ModuleTrainingItemDTO[] = []
  trainingItemSorting: string = ''

  constructor(o: IModule) {
    super(o)
    this.objectKind = ObjectKind.module
    this.trainingItemSorting = this.getPropV1('trainingItem', 'sorting') || ''
    const propDuration = parseInt(this.getPropV1('set', 'duration'), 10)
    if (propDuration) {
      this.duration = propDuration
    } else if (o.duration) {
      this.duration = o.duration
      this.props.push(new PropDTO({
        id: -1,
        key1: 'set',
        key2: 'duration',
        value1: `${o.duration}`,
        value2: '',
      }))
    }
  }

  public getName(): string {
    // return this.up[0]?.name || this.name
    return this.name || this.getUpName() || 'leere Sequenz'
  }

  getDuration() {
    return this.duration
  }

  public getDurationForHumanEyes() {
    return `${minutes2Input(this.duration)}`
  }

  async updateGlobal(body: {duration: number}) {
    mainservice.broadcast(`updateId-${this.id}`, body)
  }

  async setDuration(duration: number) {
    this.duration = duration
    await this.addProp('set', 'duration', `${duration}`)
    this.updateGlobal({duration: duration})
    
  }

  public getHeight() {
    return this.duration * CONFIG.planningHeightFactor
  }

  public isTiny() {
    return this.duration * CONFIG.planningHeightFactor < 48
  }

  public async getChildrenFromServer() {
    // const url = 'spt/getChildren'
    const url = 'spt/getAllMaterial'
    if (this.id === -1) { return }
    const data = await Setter(url, {id: this.id})
    this.materialItems = data.items.map((item: IModuleTrainingItem) => new ModuleTrainingItemDTO(item))
    // TrainingItems:
    // this.trainingItems = this.mixOwnUpChildren(data, ObjectKind.moduletrainingItem).map((item: IModuleTrainingItem) => new ModuleTrainingItemDTO(item))
    // Materials:
    // this.materialItems = this.mixOwnUpChildren(data, ObjectKind.genericMaterialItem).map((item: IGenericMaterialItem) => new GenericMaterialItemDTO(item))
  }

  public getTrainingItems() {
    // Sort the output
    // return this.trainingItems
    const sorted = EqualizeItemListAndSortString(this.trainingItemSorting, this.trainingItems)
    this.trainingItemSorting = sorted.sort
    return sorted.items as TrainingItemDTO[]
    // return SortItemsBySortString(this.trainingItemSorting, this.trainingItems) as TrainingItemDTO[]
  }

  public async addTrainingItem(position: number, item: TrainingItemDTO, type?: 'position' | 'template2seminar') {
    // Do sorting - if item already there:
    // if we have a up Child:
    if (type === 'template2seminar' && item.up[0]) {
      // Neues Element erstellen
      const cloneItem = new ModuleTrainingItemDTO({
        ...item,
        ...{
          id: -1,
          publishKind: PublishKind.isSeminar,
        }
      })
      await cloneItem.saveToServer()
      await cloneItem.addParent(this)
      await cloneItem.addUp(item.up[0] as ModuleTrainingItemDTO)
      // Information speichern, dass dieses Element nun das andere ersetzt:
      await cloneItem.addProp('link', 'replace', `${item.id}`)
      // Neues Element an alte Position setzen
      this.trainingItems = this.trainingItems.filter(m => m.id !== item.id)
      this.trainingItems.push(cloneItem)
      // Sortierung korrigieren:
      this.trainingItemReplace(item.id, cloneItem.id)
      // An anderer Stelle sorgen wir dafür, dass nur das neue Element "überlebt"
      return
    }
    this.trainingItemReplace(item.id, position)
    this.addProp('trainingItem', 'sorting', this.trainingItemSorting)
    if (this.trainingItems.find(ti => ti.id === item.id)) {
      return
    }
    // Add item
    const respons = await Setter('spt/linkTargetToParent', {
      parentId: this.id,
      targetId: item.id,
      objectKind: ObjectKind.genericMaterialItem,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar,
    })
    const newItem = new ModuleTrainingItemDTO(respons.item)
    /*
    const newItem = new ModuleTrainingItemDTO({
      id: -1,
      publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar
    })
    await newItem.saveToServer()
    await newItem.addParent(this)
    await newItem.addUp(item)
    */
    this.trainingItems.push(newItem)
  }

  public async trashTrainingItem(item: TrainingItemDTO) {
    // Remove from local list
    this.trainingItems = this.trainingItems.filter(ti => ti.id !== item.id)
    if (this.publishKind === PublishKind.isSeminar && item.publishKind === PublishKind.isTemplate) {
      this.addToBlacklist(item.id)
    } else {
      const replaceIdRaw = item.getPropV1('link', 'replace')
      if (replaceIdRaw !== '') {
        const replaceId = parseInt(replaceIdRaw, 10)
        this.trainingItemReplace(item.id, replaceId)
        // Load the original Data and append it to the modules:
        const url = 'spt/getInfo'
        const data = await Setter(url, {id: replaceId})
        const oldModule = new ModuleTrainingItemDTO(data.item as unknown as IModuleTrainingItem)
        this.trainingItems.push(oldModule)
      }
    }
    // Remove from Server:
    await item.seminarTrash()
  }

  public async trainingItemReplace(oldId: number, newId: number) {
    const trainingItemSorting = PutIdAtPosition(this.trainingItemSorting, oldId, newId)
    this.trainingItemSorting = trainingItemSorting
    await this.addProp('trainingItem', 'sorting', trainingItemSorting)
  }

  public async materialItemReplace(oldId: number, newId: number) {
    const materialItemSorting = PutIdAtPosition(this.materialItemSorting, oldId, newId)
    this.materialItemSorting = materialItemSorting
    await this.addProp('materialItem', 'sorting', materialItemSorting)
  }

  public getMaterialItems(): GenericMaterialItemDTO[] {
    // Sort the output
    const sorted = EqualizeItemListAndSortString(this.materialItemSorting || this.getPropV1('materialItem', 'sorting') || '', this.materialItems)
    this.materialItemSorting = sorted.sort
    return sorted.items as GenericMaterialItemDTO[] || []
    // return SortItemsBySortString(this.trainingItemSorting, this.trainingItems) as TrainingItemDTO[]
  }

  public async addMaterialItem(position: number, item: MaterialItemDTO) {
    let id = item.id
    if (!this.materialItems.find(ti => ti.id === item.id)) {
      const respons = await Setter('spt/linkTargetToParent', {
        parentId: this.id,
        targetId: item.id,
        objectKind: ObjectKind.genericMaterialItem,
        publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar,
      })
      const newItem = new GenericMaterialItemDTO(respons.item)
      /*
      const newItem = new GenericMaterialItemDTO({
        id: -1,
        publishKind: (this.publishKind === PublishKind.isTemplate && item.publishKind === PublishKind.isTemplate) ? PublishKind.isTemplate : PublishKind.isSeminar
      })
      await newItem.saveToServer()
      await newItem.addParentId(this.id)
      await newItem.addUp(item)
      */
      this.materialItems.push(newItem)
      id = newItem.id
    }
    this.materialItemSorting = PutIdAtPosition(this.materialItemSorting, id, position)
    this.addProp('materialItem', 'sorting', this.materialItemSorting)
  }

  public async trashMaterialItem(item: GenericMaterialItemDTO) {
    // Remove from local list
    this.materialItems = this.materialItems.filter(ti => ti.id !== item.id)
    if (this.publishKind === PublishKind.isSeminar && item.publishKind === PublishKind.isTemplate) {
      this.addToBlacklist(item.id)
    } else {
      const replaceIdRaw = item.getPropV1('link', 'replace')
      if (replaceIdRaw !== '') {
        const replaceId = parseInt(replaceIdRaw, 10)
        this.materialItemReplace(item.id, replaceId)
        // Load the original Data and append it to the modules:
        const url = 'spt/getInfo'
        const data = await Setter(url, {id: replaceId})
        const oldModule = new MaterialItemDTO(data.item as unknown as IMaterialItem)
        this.materialItems.push(oldModule)
      }
    }
    // Remove from Server:
    await item.seminarTrash()
  }

  public getEditFields(): GenericEditField[] {
    return ModuleEditFields.map(mef => {
      return {...mef, value: this.getPropV1(mef.key1, mef.key2)}
    })
  }

  public generateColorStyles() {
    const colorTags = this.getTagsByKind('moduleKind')
    if (colorTags && colorTags.length > 0) {
      const colorTag = colorTags[0]
      const groundColor = colorTag.color
      const contrastColor = LightOrDarkColor(groundColor)
      let lightColor = LightenColor(groundColor)
      return {
        color: groundColor,
        lightColor: lightColor,
        contrastColor: contrastColor,
        lightContrastColor: LightOrDarkColor(lightColor)
      }
    }
    return {
      color: undefined,
      lightColor: undefined,
      contrastColor: undefined,
    }
  }

}

export interface IModule extends IGeneric {
  duration?: number
}

