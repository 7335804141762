import { useEffect, useState } from 'react'
import './PrintPdf.scss'
import CollectibleDTO, { CollectibleType, CollectiblesDTO } from '../../DTO/CollectibleDTO'
import Spinner from '../Spinner/Spinner'
import CollectibleItemA from '../Collectible/CollectibleItemA'
import { DragAndDropTypes } from '../../Models/dnd/dragAndDropTypes'
import { useDrag, useDrop } from 'react-dnd'
import { Printer, X } from 'react-feather'
import mainservice from '../../services/MainService'

enum Status {
  loading,
  standard,
}

const NavRight = [
  {
    name: 'Lernkarten',
    type: CollectibleType.flashcard
  },
  {
    name: 'Plakate',
    type: CollectibleType.poster
  },
  {
    name: 'Sinnspruch',
    type: CollectibleType.motto
  }
]

type LSList = {
  el: CollectibleDTO
}

class LeftSideData {
  list: LSList[] = []
  add(position: number, el: CollectibleDTO | undefined) {
    if (el === undefined) { return }
    this.list.splice(position, 0, {el: el})
  }
  rem(position: number) {
    this.list.splice(position, 1)
  }
  get(rerender: number) {
    console.log('get LeftSideData ' + rerender, this.list)
    return this.list
  }

}

export function PrintPdf(props: {

}) {
  const [status, setStatus] = useState(Status.loading)
  const [collectibles] = useState(new CollectiblesDTO({version: 'admin'}))
  const [navItemRight, setNavItemRight] = useState(NavRight[0])
  const [leftSideData] = useState(new LeftSideData())
  const [rerender, setRerender] = useState(0)
  useEffect(() => {
    collectibles.getFromServer(undefined, 'admin').then(() => {
      setStatus(Status.standard)
    })
  }, [])
  function generatePrintQueryLink() {
    const prefix = 'http://suha.duia.eu/lubberspdf/index.php?option=pdf&items='
    // const prefix = 'http://localhost:8003/index.php?option=pdf&items='
    const suffix = leftSideData.get(rerender).map(d => 'c_' + d.el.id).join(';')
    return prefix + suffix
  }
  if (status === Status.loading) {
    return <div className='PrintPdf'>
      <div className='ppLeftPanel'><Spinner /></div>
      <div className='ppRightPanel'><Spinner /></div>
    </div>
  }
  const elementsRight = collectibles.get({type: navItemRight.type})
  console.log('I am rendering')
  return <div className={`PrintPdf id-${rerender}`} >
    <div className='ppLeftPanel'>
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <a href={generatePrintQueryLink()} target='_blank' rel='noreferrer'>
        <button
          className='ppPrint w3-button w3-btn w3-blue'
        >
          <Printer />
        </button>
      </a>
      <DropZone
        sortNumber={0}
        addCollection={(sortNumber, item) => {
          console.log('DROPPING', sortNumber, item)
          leftSideData.add(sortNumber, collectibles.getById(item.collectibleId))
          setRerender(new Date().getTime())
        }}
      />
      {
        leftSideData.get(rerender).map((c, index) => {
          const key = `c-${(c as unknown as {id: number}).id}-index-${index}`
          return <div key={key}>
            <X onClick={() => {
              leftSideData.rem(index)
              setRerender(new Date().getTime())
            }} />
            <Item
              c={c.el}
              index={index}
              key={`ls-${c.el.id}-${index}`}
              leftSide={true}
            />
            <DropZone
              sortNumber={index + 1}
              addCollection={(sortNumber, item) => {
                console.log('DROPPING', sortNumber, item)
                leftSideData.add(sortNumber, collectibles.getById(item.collectibleId))
                setRerender(new Date().getTime())
              }}
            />
          </div>
        })
      }
    </div>
    <div className='ppRightPanel'>
      <div className='tab-menu'>
        {
          NavRight.map(n => {
            return <button
              key={`navrightbutton-${n.name}`}
              className={`w3-button w3-btn ${n.type === navItemRight.type ? 'w3-yellow' : ''}`}
              onClick={() => {
                setNavItemRight(n)
              }}
            >{n.name}</button>
          })
        }
      </div>
      
      {
        elementsRight.map((c, index) => {
          return <Item
            c={c}
            index={index}
            key={`rs-${c.id}-${index}`}
          />
        })
      }
    </div>
  </div>
}

function DropZone(props: {
  sortNumber: number,
  addCollection: (sortNumber: number, item: any) => void
}) {
  let currentItem: any = ''
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: DragAndDropTypes.Book,
    canDrop: (props, monitor) => {
      currentItem = monitor.getItem()
      return true
    },
    drop: () => {
      return props.addCollection(props.sortNumber, currentItem)
    },
    collect: monitor => ({
      // item: monitor.getItem(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  }), [props.sortNumber])
  return <div
    ref={drop}
    className={`dropzone ${(isOver) ? 'hover' : ''} ${(canDrop) ? 'canDrop' : ''}`}
  >
  </div>
}

function Item(props: {
  c: CollectibleDTO,
  index: number,
  leftSide?: boolean
}) {
  const c = props.c
  const index = props.index
  const [{isDragging}, drag] = useDrag(() => ({
    type: DragAndDropTypes.Book,
    item: {collectibleId: c.id},
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [42])
  const sideName = props.leftSide ? 'left' : 'right'
  const className = props.leftSide ? '' : 'w3-col s6 m3 l1'
  return <div
    key={`${index}-${c.id}-${sideName}`}
    ref={drag}
    className={`${className} ${(isDragging) ? 'dragging' : ''}`}
  >
    <CollectibleItemA
      collectible={c}
      fresh={() => {}}
      showOnly={true}
    />
  </div>
}
