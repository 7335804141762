import { useEffect, useState } from "react"
import GroupsDTO from "../../DTO/GroupsDTO"
import Spinner from "../Spinner/Spinner"
import GroupDTO from "../../DTO/GroupDTO"
import { Edit, Plus, Send, Trash, Users, X } from "react-feather"
import InputA from "../InputA/InputA"
import UserDTO from "../../DTO/UserDTO"
import mainservice from "../../services/MainService"

enum Status {
  standard,
  loading,
  rename,
  admins,
}
export default function ClientsList(props: {

}) {
  const [Items] = useState(new GroupsDTO())
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    Items.getFromServer().then(() => {
      setStatus(Status.standard)
    })
  })
  return <div
    className={`flex flex-column h100`}
  >
    <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
    <h1>Mandanten</h1>
    <button
      className='w3-button w3-green'
      onClick={async () => {
        const name = window.prompt('Bitte neuen Namen eingeben', 'Neuer Kunde');
        if (!name) { return }
        setStatus(Status.loading);
        await Items.addItem(name);
        setStatus(Status.standard)
      }}
    ><Plus /></button>
    {status === Status.loading &&
      <Spinner />
    }
    <div
      className={`flex-grow`}
    >
      {
        Items.getItems().map(item => {
          return <Client
            key={`client-${item.id}`}
            item={item}
            reload={async () => {
              setStatus(Status.loading)
              Items.getFromServer().then(() => {
                setStatus(Status.standard)
              })
            }}
          />
        })
      }
    </div>
  </div>
}

function Client(props: {
  item: GroupDTO,
  reload: () => void,
}) {
  const [status, setStatus] = useState(Status.loading)
  const item = props.item
  return <div className={`client w3-card w3-margin w3-padding client-id-${item.id}`}>
    <div className='client-name'>
      {
        status !== Status.rename &&
        item.name
      }
      {
        status === Status.rename &&
        <InputA
          value={item.name}
          returnVal={async (rv: string) => {
            await item.rename(rv)
            setStatus(Status.standard)
            props.reload()
          } }
        />
      }
    </div>
    <button
      onClick={() => {
        setStatus(Status.rename)
      }}
    >
      <Edit />
    </button>
    <button
      title={`Verwalte Admins`}
      onClick={() => {
        setStatus(status === Status.admins ? Status.standard : Status.admins)
      }}
    ><Users />
    </button>
    {
      item.id !== 1 &&
      <button
        onClick={async () => {
          if (!window.confirm(`Wirklich diesen Kunden löschen?`)) { return }
          item.delete()
          props.reload()
        }}
      >
        <Trash />
      </button>
    }
    {
      status === Status.admins &&
      <Admins
        client={item}
        close={() => {
          setStatus(Status.standard)
        }}
      />
    }
  </div>
}

function Admins(props: {
  client: GroupDTO,
  close: () => void,
}) {
  const [status, setStatus] = useState(Status.loading)
  const client = props.client
  useEffect(() => {
    client.getAdminsFromServer().then(() => {
      setStatus(Status.standard)
    })
  })
  async function reload() {
    setStatus(Status.loading)
    client.getAdminsFromServer().then(() => {
      setStatus(Status.standard)
    })
  }
  return <div
    className='Admins'
  >
    <button
      className={`w3-button w3-green`}
      onClick={async () => {
        const email = window.prompt(`Gruppenadmin durch E-Mail hinzufügen`)
        if (!email) { return }
        setStatus(Status.loading)
        await client.addAdminByEMail(email)
        
        reload()
      }}
    ><Plus /></button>
    {
      status === Status.standard &&
      client.getAdmins().map((a, index) => {
        return <Admin
          key={`admin-${index}`}
          item={a}
          client={client}
          reload={() => {
            reload()
          }}
        />
      })
    }
  </div>  
}

function Admin (props: {
  item: UserDTO,
  client: GroupDTO,
  reload: () => void,
}) {
  return <div className={`w3-card w3-padding flex`}>
    <div className={`userName flex-grow`}>{[props.item.email].join(' - ')}</div>
    <button
        title='Passwort Mail schicken'
        className='w3-button'
        onClick={async () => {
          if (window.confirm(`User ${props.item.email} den Einladungslink schicken?`)) {
            await props.item.sendPasswordMail()
          }
        }}
      ><Send /></button>
    <button
      className='w3-button'
      title='Gruppenadmin Recht entziehen'
      onClick={async () => {
        if (!window.confirm(`Gruppenadmin Rechte von ${props.item.email} entfernen?`)) { return }
        await props.client.removeAdmin(props.item.id)
        props.reload()
      }}
    ><X /></button>
    
  </div>
}
