import UserDTO, { IUser, UsersDTO } from './UserDTO'
import { PropsDTO, IProp } from './PropDTO'
import { Getter, Setter } from '../services/ComService'

export default class UnitDTO {
  id: number = -1
  name: string = ''
  description: string = ''
  users: UsersDTO = new UsersDTO([])
  bookIds: number[] = []
  props: PropsDTO = new PropsDTO([])
  type: UnitType = 'event'
  startDate: Date | null = null
  endDate: Date | null = null
  constructor(unit: IUnit) {
    this.init(unit)
  }
  init(unit: IUnit) {
    this.id = unit.id
    this.name = unit.name
    this.description = unit.description
    this.users = new UsersDTO(unit.users)
    this.bookIds = unit.bookIds
    this.props = new PropsDTO(unit.props)
    this.type = unit.type
    this.startDate = unit.startDate ? new Date(unit.startDate) : null
    this.endDate = unit.endDate ? new Date(unit.endDate) : null
    // this.startDate = new Date()
    // this.endDate = new Date()
  }
  public filterUserIds(exIds: number[]): number[] {
    return this.users.filterUserIds(exIds)
  }
  private async update()  {
    const r = await Setter('unit/update', this.get(), {retryName: `${this.id}`})
    if (r.status === 'okay') {
      this.init(r.result)
    }
  }
  public async setName(name: string) {
    if (!name) { return }
    this.name = name
    await this.update()
  }
  public async setStartDate(d: Date | null) {
    this.startDate = d
    await this.update()
  }
  public async setEndDate(d: Date | null) {
    this.endDate = d
    await this.update()
  }
  public async setDescription(name: string) {
    if (!name) { return }
    this.description = name
    await this.update()
  }
  public async addUsers(ids: number[]) {
    await Setter('unit/addUsers', {
      id: this.id,
      userIds: ids,
    }, {retryName: `${this.id},${ids.join(',')}`})
    ids.forEach(i => this.users.addUser(new UserDTO({id: i})))
  }
  public async removeUsers(ids: number[]) {
    await Setter('unit/removeUsers', {
      id: this.id,
      userIds: ids,
    }, {retryName: `${this.id},${ids.join(',')}`})
    this.users.removeByIds(ids)
  }
  public async addBooks(ids: number[]) {
    await Setter('unit/addBooks', {
      id: this.id,
      bookIds: ids,
    }, {retryName: `${this.id},${ids.join(',')}`})
    this.bookIds = this.bookIds.concat(ids)
  }
  public async removeBooks(ids: number[]) {
    await Setter('unit/removeBooks', {
      id: this.id,
      bookIds: ids,
    }, {retryName: `${this.id},${ids.join(',')}`})
    this.bookIds = this.bookIds.filter(id => !ids.some(i => i === id))
  }
  get() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      startDate: this.startDate, // ? this.startDate.toJSON() : null,
      endDate: this.endDate, // ? this.endDate.toJSON() : null,
    }
  }
}

export interface IUnit {
  id: number
  name: string
  description: string
  users: IUser[]
  userIds: number[]
  bookIds: number[]
  props: IProp[]
  type: 'company' | 'event'
  startDate: string
  endDate: string
}

export type UnitType = 'company' | 'event'
export const UnitTypes: {name: string, key: UnitType}[] = [
  {
    name: 'Firmen',
    key: 'company'
  },
  {
    name: 'Veranstaltungen',
    key: 'event'
  }
]

export class UnitsDTO {
  units: UnitDTO[] = []
  constructor(units?: IUnit[]) {
    this.units = this.mapUnits(units || [])
  }
  private mapUnits(units: IUnit[]) {
    return units.map(u => new UnitDTO(u))
  }
  public async getFromServer() {
    const result = await Getter('unit/list')
    this.units = this.mapUnits(result.result)
  }
  public list(unitType?: UnitType, needle?: string): UnitDTO[] {
    if (unitType || needle) {
      const nps = needle ? needle.split(' ') : []
      return this.units.filter(u => {
        const filterOkay = (!unitType || u.type === unitType) && (
          nps.length === 0 || nps.every(n => u.name.search(n) > -1 || u.users.list(n).length > 0))
        return filterOkay
      })
    }
    return this.units
  }
  public get(id: number): UnitDTO | undefined {
    return this.units.find(u => u.id === id)
  }
  public isEmpty(): boolean {
    return (this.units.length === 0)
  }
  public async remove(id: number) {
    const result = await Setter('unit/delete', {
      id: id
    }, {retryName: `${id}`})
    if (result.result === 'done') {
      this.units = this.units.filter(u => u.id !== id)
      return true
    }
    // alert('Gruppe konnte nicht gelöscht werden')
    throw('Gruppe konnte nicht gelöscht werden')
  }
  public async add(unit: IUnit) {
    const userIds = unit.userIds || []
    const result = await Setter('unit/new', {
      name: unit.name,
      userList: userIds,
      type: unit.type
    }, {retryName: `${unit.name},${userIds.join(',')},${unit.type}`})
    if (result.status === 'okay') {
      this.units.push(new UnitDTO(result.result))
      return true
    }
    throw('Gruppe konnte nicht erstellt werden')
  }
  public async addNew(name: string, typeName: UnitType, userIds: number[]) {
    const r = await Setter('unit/new', {
      name: name,
      type: typeName,
      userList: userIds,
    }, {retryName: `${name},${typeName},${userIds.join(',')}`})
    if (r.status === 'okay') {
      this.units.push(new UnitDTO(r.result))
    }
  }
}
