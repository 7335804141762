import './PlaningToolRoot.scss'
import Menu, { MItem } from './Menu'
import { Columns, Menu as MenuIcon, X } from 'react-feather'
import { useEffect, useState } from 'react'
import lubberslogo from '../../static/gfx/lubbers-logo.svg'
import { RightDrawer } from './RightDrawer'
import mainservice from '../../services/MainService'
import Modal from './Modal'

export enum State {
  closed,
  open,
  standard,
  loading,
}

export default function PlaningToolRoot(props: {
  children?: React.ReactElement | (React.ReactElement | null | false)[],
  standalone?: boolean,
  menuItem: MItem,
  topMenu?: React.ReactElement | (React.ReactElement | null | false)[],
  rightDrawer?: React.ReactElement | (React.ReactElement | null | false)[],
  leftDrawer?: React.ReactElement | (React.ReactElement | null | false)[],
  showLeftDrawer?: boolean,
  startStateRightDrawer?: State,
  mainContentClass?: string,
  className?: string,
}) {
  const [leftMenuState, setLeftMenuState] = useState(State.closed)
  const [rightDrawerState, setRightDrawerState] = useState(props.startStateRightDrawer === undefined ? State.closed : props.startStateRightDrawer)
  // const [rightDrawerStateInit, setRightDrawerStateInit] = useState(props.startStateRightDrawer === undefined ? State.closed : props.startStateRightDrawer)
  useEffect(() => {
    setRightDrawerState(props.startStateRightDrawer === undefined ? State.closed : props.startStateRightDrawer)
  }, [props.startStateRightDrawer])
  function MenuItemClick() {
    setLeftMenuState(leftMenuState === State.open ? State.closed : State.open)
    // setRightDrawerStateInit(State.open)
  }
  function RightDrawerToggleClick() {
    setRightDrawerState(rightDrawerState === State.open ? State.closed : State.open)
    // setRightDrawerStateInit(State.open)
  }
  function getRootClass() {
    return [
      leftMenuState === State.open ? 'PlaningToolRootLeftBarOpen' : 'PlaningToolRootLeftBarClosed',
    ].join(' ')
  }
  if (props.standalone) {
    return <div className={`PlaningToolRoot ${getRootClass()} ${props.className || ''}`}>
      <div className={`PlaningToolRootHeader`}>
        <div className={`headerLeft`}>
          <button className={`w3-button`} onClick={MenuItemClick}><MenuIcon /></button>
          <button className={`w3-button lubbers-logo-holder`}>
            <img src={lubberslogo} alt='lubberslogo' className='lubbers-logo' />
          </button>
          { (props.topMenu && !props.showLeftDrawer) ? props.topMenu : null }
        </div>
        <div className={`headerRight`}>
          {(props.startStateRightDrawer === State.open) ?
            <button className={`w3-button`} onClick={RightDrawerToggleClick}><Columns /></button> : null
          }
        </div>
      </div>
      <div className={`PlaningToolRootAntiheader`}>
        <div className={`PlaningToolRootLeftBar`}>
          <Menu MItem={props.menuItem} teaser={`Komponenten`} />
        </div>
        
        <div className={`PlaningToolRootMainContentAndLeftDrawer`}>
          <div className={`PlaningToolRootMainContent`}>
            <div className={`PlaningToolRootMainContentHolder current-page-${mainservice.nav.getVal('view')} ${props.mainContentClass || ''}`}>
              {props.children}
            </div>
          </div>
          <div className={`PlaningToolRootLeftDrawer ${props.showLeftDrawer ? 'showDrawer' : ''}`}>{props.leftDrawer}</div>
        </div>

        <div className={`PlaningToolRootRightDrawer ${(rightDrawerState === State.open) ? 'showDrawer' : ''}`}>
            <div className={`PlaningToolRootRightDrawerContent`}>
              <RightDrawer
                changeOpenState={(newState) => {
                  // Drawer can open and close itselve!
                  if (rightDrawerState !== newState) {
                    setRightDrawerState(newState)
                  }
                }}
              >
                <>{props.rightDrawer || null}</>
              </RightDrawer>
            </div>
        </div>
      </div>
      <Modal />
    </div>
  }
  return <>{props.children}</>
}


