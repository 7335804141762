import {useState} from 'react'
import Spinner from '../Spinner/Spinner'
// import { Getter, Setter } from '../../services/ComService'
import mainservice from '../../services/MainService'

enum Status {
  start,
  loading,
  credentialsWrong,
  forceToWait,
  done
}

let failures: Date[] = [new Date(0), new Date(0), new Date(0)]
let reloadTimer: any = 0

const login = async (
  email: string,
  pw: string,
  setStatus: (s: Status) => void,
  setLoggedIn?: () => void
) => {
  const minute = 60 * 1000
  setStatus(Status.loading)
  const result = await mainservice.loginService.login(email, pw)
  console.log('login result', result)
  if (result === false) {
    failures.shift()
    failures.push(new Date())
    if (failures[0].getTime() + minute > new Date().getTime()) {
      reloadTimer = setTimeout(() => {
        clearTimeout(reloadTimer)
        setStatus(Status.credentialsWrong)
      }, minute)
      setStatus(Status.forceToWait)
      return false
    }
    setStatus(Status.credentialsWrong)
    return false
  }
  console.log('DONE?', setLoggedIn)
  if (setLoggedIn) {
    setLoggedIn()
  } else {
    mainservice.navTo([['view', 'dashboard']])
  }
    // setStatus(Status.done)
}

function ShowLoginFormular (props: {
  setStatus: (s: Status) => void,
  status: Status,
  setLoggedIn?: () => void
}) {
  const setStatus = props.setStatus
  const [email, setEmail] = useState('')
  const [pw, setPw] = useState('')
  return <>
    <div className="w3-container lu-headline">
      Login
    </div>

    <div className="w3-container">

      <label>E-Mail Adresse</label>
      <input
        className="w3-input"
        type="text"
        autoFocus
        value={ email || '' }
        onChange={ (event) =>setEmail(event.target.value) }
      />

      <label>Passwort</label>
      <input
        className="w3-input"
        type="password"
        value={ pw || '' }
        onChange={(event) => setPw(event.target.value) }
        onKeyUp={(event) => {
          const code = (event as unknown as {code: string}).code
          if (code && code.search('Enter') > -1) {
            login(email, pw, setStatus, props.setLoggedIn)
          }
        }}
      />

      {
        props.status === Status.credentialsWrong &&
         <div className='w3-panel w3-pale-red w3-padding w3-border w3-border-red'>
          <p>Die Logindaten sind falsch!</p>
          <p>Überprüfen Sie E-Mail Adresse und Passwort.</p>
          <ul>
            <li>Das Passwort haben Sie selbst bei der Registrierung gewählt</li>
          </ul>
         </div>
      }

      <button
        className='w3-btn w3-ripple login-button'
        onClick={() => login(email, pw, setStatus, props.setLoggedIn)}
      >Einloggen</button>
    </div>
  </>
}

function ShowContent (props: {
  setStatus: (s: Status) => void,
  status: Status,
  setLoggedIn?: () => void
}) {
  switch(props.status) {
    case Status.credentialsWrong:
    case Status.start:
      return <ShowLoginFormular
          setStatus={ (s: Status) => props.setStatus(s) }
          status={ props.status }
          setLoggedIn={ props.setLoggedIn }
        />
    case Status.loading:
      return <div className='w3-container'>
          <div className="w3-container lu-headline">
            Du wirst eingeloggt
          </div>
          <p className='w3-center'><Spinner
          mini={true}
          sizeClass='normalSize'
          /></p>
        </div>
    case Status.forceToWait:
      return <>
        <div className="w3-container lu-headline">
          Login - zu viele Versuche
        </div>
        <div className="w3-container">
          <p>Das waren zu viele Login-Versuche. Du bist für eine Minute gesperrt!</p>
          <p>Überprüfe Deine E-Mail Adresse und Passwort.</p>
          <ul>
            <li>Das Passwort hast Du selbst bei der Registrierung gewählt</li>
          </ul>
          <p>Die Seite wird in einer Minute weitere Login-Versuche erlauben!</p>
        </div>
      </>
    case Status.done:
      return <>
          <div className="w3-container lu-headline">
            Login - Erfolg
          </div>
          <div className="w3-container">
            <p>Du bist eingeloggt</p>
          </div>
        </>
  }

  return null
}

function UserLogin (props: {
  showAsElement?: boolean,
  className?: string,
  setLoggedIn?: () => void
}) {
  const [status, setStatus] = useState(Status.start)
  if (props.showAsElement) {
    return <div className={`w3-card-4 lu-dialog ${props.className || ''}`}>
        <ShowContent
          setStatus={(s: Status) => { setStatus(s) }}
          status={ status }
          setLoggedIn={ props.setLoggedIn }
          />
      </div>
  }
  return <div className='w3-row'>
    <div className='w3-col l4 m3 s0'>&nbsp;</div>
    <div className="w3-card-4 lu-dialog w3-col l4 m6 s12">
      <ShowContent
        setStatus={(s: Status) => { setStatus(s) }}
        status={ status }
        setLoggedIn={ props.setLoggedIn }
        />
    </div>
  </div>
}

export default UserLogin
