import { ObjectKind } from "../models/objectKinds";
import { DisplayMode, Status } from "./day/dayView";
import './modulesView.scss';
import ModuleDTO from "../DTO/ModuleDTO";
import ModuleView from "./moduleView";
import DayModuleDTO from "../DTO/DayModuleDTO";
import { DayDTO } from "../DTO/DayDTO";
import { useEffect, useState } from "react";
import CONFIG from '../../config.json'
// import { DropZone } from "../elements/DropZone";
import { Minutes2TimeString, TimeString2Minutes } from "../../services/DateTime";
import { TimeGridHours } from "./TimeGridHours";
import { PublishKind } from "../models/publishKinds";
import Spinner from "../../components/Spinner/Spinner";

export default function ModulesView (props: {
  identKey: string,
  modules: DayModuleDTO[]
  parentPublishKind: PublishKind,
  parentObjectKind: ObjectKind,
  triggerReload?: number,
  day?: DayDTO,
  displayKind: Status,
  broadcastContext?: string,
  addItem?: (position: number, item: ModuleDTO, type: 'time' | 'position' | 'template2seminar' | 'duration') => void,
  trashItem?: (item: DayModuleDTO) => void,
  setEditItem?: (module: ModuleDTO | DayModuleDTO) => void,
  
}) {
  const [rerender, setRerender] = useState(0)
  const [modules, setModules] = useState(props.day?.getModules() || props.modules)
  const [status, setStatus] = useState(Status.standard)
  const [moduleDisplayMode, setModuleDisplayMode] = useState(props.displayKind === Status.agendaView ? DisplayMode.agenda : (props.displayKind === Status.agendaPlusView ? DisplayMode.agendaPlus : DisplayMode.leftSide))
  useEffect(() => {
    setStatus(Status.loading)
    setModuleDisplayMode(props.displayKind === Status.agendaView ? DisplayMode.agenda : (props.displayKind === Status.agendaPlusView ? DisplayMode.agendaPlus : DisplayMode.leftSide))
    setModules(props.day?.getModules() || props.modules)
    setStatus(Status.standard)
  }, [props.triggerReload, props.displayKind])
  function addItem(position: number , item: ModuleDTO, type?: 'time' | 'position' | 'template2seminar' | 'duration') {
    if (props.addItem) {
      console.log('modulesView: addItem', position, item, type || 'position')
      props.addItem(position, item, type || 'position')
    }
  }
  
  if (status === Status.loading) {
    return <Spinner />
  }
  return <div
    className={`ModulesView`}
    style={{
      minHeight: props.displayKind === Status.dayView ? `${((props.day?.endTime || TimeString2Minutes('24:00')) - (props.day?.startTime || TimeString2Minutes('06:00'))) * CONFIG.planningHeightFactor}px` : undefined
    }}
  >
    {
      modules.length === 0 &&
      <div className='w3-center'>leer</div>
    }
    {
      modules.map((module, index) => {
        return <ModuleView
          key={`${props.identKey}-module-${module.id}`}
          broadcastContext={`${props.broadcastContext || ''}modules`}
          index={index}
          module={module}
          displayMode={moduleDisplayMode}
          trash={props.trashItem}
          dayStart={props.day?.startTime || 0}
          addItem={addItem}
          rerender={() => {
            setRerender(new Date().getTime())
          }}
          parentPublishKind={props.parentPublishKind}
          parentObjectKind={props.parentObjectKind}
          pin={(module: ModuleDTO, unpin: boolean) => {
            if (props.day) {
              props.day.pinItem(module.id, unpin, module.start)
              setRerender(new Date().getTime())
            }
          }}
          fullScreenEdit={() => {
            if (props.setEditItem) {
              props.setEditItem(module)
            }
          }}
          triggerReload={rerender}
        />
      })
    }
    {
      props.displayKind === Status.dayView && props.day &&
      <TimeGridHours
        identKey={props.identKey}
        grid={props.day.getHourGrid()}
        addItem={addItem}
      />
    }
  </div>
}
