import config from '../config.json'
import {parseStringToServer, parseStringFromServer} from '../services/TextConverter'

export default class ElementPropDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public key1: string
  public key2: string
  public value1: string
  public value2: string

  constructor(o: IElementProp) {
    this.id = o.id
    this.key1 = o.key1
    this.key2 = o.key2
    this.value1 = parseStringFromServer(o.value1)
    this.value2 = parseStringFromServer(o.value2)
  }

  get() {
    return {
      id: this.id,
      key1: this.key1,
      key2: this.key2,
      value1: this.value1,
      value2: this.value2
    }
  }

  public async remove() {
    await fetch(`${this.apiPrefix}PageElementProp/${this.id}/remove`)
  }

  public async update(data: {value1: string, value2: string}) {
    this.value1 = data.value1
    this.value2 = data.value2
    await this.updateServer()
  }

  public async updateServer() {
    await fetch(`${this.apiPrefix}PageElementProp/${this.id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        key1: this.key1,
        key2: this.key2,
        value1: parseStringToServer(this.value1),
        value2: parseStringToServer(this.value2),
      })
    })
  }
}

export interface IElementProp {
  id: number
  key1: string
  key2: string
  value1: string
  value2: string
}
