import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import './ProgressBar.scss'

type Props = {
  progress: number,
  absolut: number
}

type State = {

}

export default class ProgressBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }
  pepTalk(progress: number): string {
    if (progress < 5) { return 'Los gehts!' }
    if (progress < 20) { return 'Weiter gehts!' }
    if (progress < 30) { return 'Guter Anfang!' }
    if (progress < 50) { return 'Ein drittel ist bereits geschafft!' }
    if (progress < 66) { return 'Die Hälfte ist schon geschafft!' }
    if (progress < 90) { return 'Wir nähern uns dem Ende!' }
    if (progress < 100) { return 'Gleich geschafft!' }
    return 'Glückwunsch!'
  }
  render () {
    const progress = 100 * this.props.progress / this.props.absolut
    return <div className={`ProgressBar`}>
        <Tooltip content={this.pepTalk(progress)} />
        <div className={`BarHolder`}>
          <div className={`Bar`}
            style={{
              width: `${progress}%`
            }}
          >

          </div>
        </div>
        <div className={`ProgressBarLabel`}>
          {this.props.progress} / {this.props.absolut}
        </div>
      </div>
  }
}
