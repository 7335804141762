import React from 'react'
import PageElementDTO from '../../../DTO/PageElementDTO'
import InputA from '../../InputA/InputA'
import './Embed.scss'

type Props = {
  pageElement: PageElementDTO
  parent: any
}

type State = {
  adminEdit: boolean,
  editValue1Status: string,
  specialClass: string
}

const DisplayStates: [string, string][] = [
  ['standard', 'Embed'],
  // ['youtube', 'Youtube Embed'],
  ['youtubelink', 'Youtube Link'],
]

export default class Embed extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      adminEdit: false,
      editValue1Status: props.pageElement.value1,
      specialClass: props.pageElement.value2
    }
  }

  renderContent() {
    if (this.state.specialClass === 'youtubelink') {
      const youtubeVLScope = /(v=|embed\/)([^&#"/]+)/
      const yVLSR = this.state.editValue1Status.match(youtubeVLScope)
      if (yVLSR) {
        const yVL = yVLSR[2]
        return <p><p
          className={`embed embed-${this.state.specialClass}`}
        >
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${yVL}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </p></p>
      }
      return <></>
    }
    return <p><p
      className={`embed embed-${this.state.specialClass}`}
      dangerouslySetInnerHTML={{__html: this.state.editValue1Status}}
    >
    </p></p>
  }

  renderAdmin() {
    return <div>
      <div className='editMode'>
        <div>
          Art:
          <select
            value={this.state.specialClass || 'standard'}
            onChange={(event) => {
              const newVal = event.target.value
              this.setState({specialClass: newVal})
              this.props.pageElement.setValue2(newVal)
            }}
          >
            {DisplayStates.map((o: [string, string], index: number) => <option key={index} value={o[0]}>{o[1]}</option>)}
          </select>
        </div>
        <InputA
          returnVal={(rv: string, self: any) => {
            self.setState({editValue1Status: rv})
            self.props.pageElement.setValue1(rv)
          }}
          value={this.state.editValue1Status}
          parent={this}
          type='textArea'
        />
      </div>
      <div className='displayMode'>
        { this.renderContent() }
      </div>
    </div>
  }

  render() {
    if (this.props.parent.isAdmin() > 0) {
      return this.renderAdmin()
    }
    return this.renderContent()
  }
}
