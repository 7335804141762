const DELAY = 500

let Save: {
  key: string,
  ref: number,
}[] = []

// This Function solves multiple fireing of a named event:
// This is how to use:
// Debounce('UNIQUEIDENTIFIER', ASYNCFUNCTION).cat(() => {})
// ASYNCFUNCTION can look like this: ASYNCFUNCTION = (blubb) => {await DOSOMETHING()}
export default async function Debounce(key: string, fkt: () => Promise<any>) {
  return new Promise((success, fail) => {
    const save = Save.find(s => s.key === key)
    if (save) {
      window.clearTimeout(save.ref)
    }
    Save = Save.filter(s => s.key !== key)
    const ref = window.setTimeout(async () => {
      const result = await fkt()
      success(result)
    }, DELAY)
    Save.push({key: key, ref: ref})
    window.setTimeout(() => {
      fail('timeout reached')
    }, DELAY * 2)
  })
}
