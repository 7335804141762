import { MutableRefObject } from "react"

enum Status {
  free,
  locked,
  quit,
}

interface IInfinitScroll {
  ref: MutableRefObject<null>,
  cb: () => void,
  locked?: boolean,
}

/**
 * Has to start like this:
 * const [infinitScroll] = useState(new InfinitScroll({
 *   ref: useRef(null),
 * }))
 * 
 * Then we need to use it in the scroll container like this
 * <div ref={infinitScroll.getRef()}>
 */
export class InfinitScroll {
  ref: MutableRefObject<null>
  status: Status = Status.free
  cb: () => void
  currentPage: number = 0

  constructor(data: IInfinitScroll) {
    this.ref = data.ref
    this.cb = data.cb
    this.status = !!data.locked ? Status.locked : Status.free
    this.activate()
  }

  private activate() {
    if (this.ref.current && this.ref.current !== null) {
      const scrollableElement = this.ref.current as unknown as HTMLDivElement
      scrollableElement.addEventListener('scroll', (ev: Event) => {
        this.handleScroll(ev, scrollableElement)
      })
    }
  }

  private handleScroll(ev: Event, el: HTMLDivElement): void {
    if (this.status !== Status.free) { return }
    const position = el.scrollTop
    const innerHeight = el.clientHeight
    const outerHeight = el.scrollHeight
    const THRESHOLD = 20
    if (position + innerHeight + THRESHOLD >= outerHeight) {
      this.cb()
      
    }
  }

  public getRef(): MutableRefObject<null> {
    return this.ref
  }

  public lock(): void {
    this.status = Status.locked
  }

  public unlock(): void {
    this.status = Status.free
  }

  /**
   * 
   * @returns current page to load or -1 if locked
   */
  public incrementAndLock(): number {
    if (this.status === Status.locked) { return -1 }
    this.status = Status.locked
    this.currentPage += 1
    return this.currentPage
  }

  public setPage(i: number) {
    this.currentPage = i
  }

  public getPage(): number {
    return this.currentPage
  }
}
