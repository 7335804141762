import React from 'react'
import mainservice from '../../../services/MainService'
import PageElementDTO from '../../../DTO/PageElementDTO'
// import ElementPropDTO from '../../../DTO/ElementPropDTO'
import {Setter} from '../../../services/ComService'
import {DisplayPHPDate} from '../../../services/DateTime'
import Card from './Card'
import CardDTO, {ICardAnalysis, Side} from './CardDTO'
import InputA from '../../InputA/InputA'
import { Plus, Repeat, BarChart2 } from 'react-feather'
import BookAdminPropSwitcher from '../../Book/BookAdminPropSwitcher'
import './CardSortGameA.scss'

enum Mode {
  standard,
  edit,
  result,
  analysis
}

type SessionCard = {
  id: string,
  timesRight: number,
  timesWrong: number
}

type Props = {
  pageElement: PageElementDTO
}

type State = {
  mode: Mode,
  cards: CardDTO[],
  dropArea: string,
  groupSessionKey: string,
  groupSessionCards: SessionCard[]
}

export default class CardSortGameA extends React.Component<Props, State> {
  totalHeight: number = 600
  newCardCounter: number = 0
  id: string
  positionCounter = {
    left: 0,
    right: 0
  }
  keyGrabber: any
  groupDataInterval: any
  usedGroupSessionKeys = new Map()
  constructor(props: Props) {
    super(props)
    this.id = 'CardSortGame-' + props.pageElement.id
    this.state = {
      mode: Mode.standard,
      cards: [],
      dropArea: 'none',
      groupSessionKey: (this.props.pageElement.getPropVal1('multiplayer', 'mode', 'no') === 'constant') ? 'multi-card-session' : '',
      groupSessionCards: []
    }
    console.log('group session key', this.state.groupSessionKey, this.props.pageElement.getPropVal1('multiplayer', 'mode', 'no'))
  }

  componentDidMount() {
    this.initData()
    mainservice.registerToBroadcast(this.id, (key: string, value: string) => {
      switch(key) {
        case 'cardSortGameDropArea':
          this.setState({dropArea: value})
      }
    }, this)
    document.addEventListener('keyup', (event) => this.onKeyDown(event))
    this.groupDataInterval = setInterval(() => {
      if (!this.state.groupSessionKey) { return }
      this.getAnalysisTeamData()
    }, 2500)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', (event) => this.onKeyDown(event))
    mainservice.unregisterBroadcast(this.id)
    clearInterval(this.groupDataInterval)
  }


  onKeyDown(event: any) {
    console.log('event.keyCode', event.keyCode)
    let dir: Side = ''
    if (event.keyCode === 65) {
      dir = 'left'
    } else if (event.keyCode === 68) {
      dir = 'right'
    }
    this.sendCardToSide(dir)
  }

  sendCardToSide(dir: Side) {
    if (!dir) { return }
    for (let i = this.state.cards.length - 1; i >= 0; i--) {
      if (this.state.cards[i].userSide === '') {
        mainservice.broadcast('CardSortGamePutCard', {
          cardId: this.state.cards[i].id,
          target: dir
        })
        return
      }
    }
  }


  initData() {
    const props = this.props.pageElement.elementProps
    const cardKeys = props.filter((eP) => eP.key1 === 'card')
    const cards = cardKeys.map((cK) => new CardDTO({
      id: cK.key2,
      pageElement: this.props.pageElement
    }))
    this.setState({cards: this.resortCards(cards)})
  }

  saveData() {

  }

  randomNumber(max: number) {
    return Math.round(Math.random() * max)
  }

  generateMapping(leng: number) {
    let arr: number[] = []
    let out: number[] = []
    let i: number = 0
    for(i = 0; i < leng; i++) {
      arr.push(i)
    }
    while(arr.length > 0) {
      i = this.randomNumber(arr.length - 1)
      out.push(arr.splice(i, 1)[0])
    }
    return out
  }

  resortCards(cardsIn: CardDTO[]): CardDTO[] {
    const mapping = this.generateMapping(cardsIn.length)
    return mapping.map((i: number) => cardsIn[i])
  }

  public getNewZPosition(side: Side) {
    if (!side) { return 0 }
    const positionCount = this.positionCounter[side]
    this.positionCounter[side] = positionCount + 1
    return positionCount
  }

  /*
  public endCardHeight() {
    return this.totalHeight / this.state.cards.length
  }
  */

  checkForFinish() {
    if (!this.state.cards.some((c) => c.userSide === '')) {
      console.log('game finished!!!')
      // finish game and show result if all are distributed:
      this.setState({
        mode: Mode.result
      })
    }
  }

  renderGame() {
    return <div className={`game`} onKeyUp={(event) => this.onKeyDown(event)}>
      <div className={`board ${(this.state.mode === Mode.result) ? 'show-result' : ''}`}>
        <div className={`cardBody underbuttons`}>
          <div className='cardBodyBody'>
            <div className='cardContent'>
              { this.repeatButton() }
              <button
              className='goToResultPage'
              onClick={() => {
                mainservice.pageFlipInteractive = true
                if (this.state.groupSessionKey) {
                  this.sendAnalysisTeamData(this.state.groupSessionKey)
                }
                this.setState({mode: Mode.analysis})
              }}
              >
              <BarChart2 /> Auswertung
              </button>
            </div>
          </div>
        </div>
        <div className={`stack leftStack ${(this.state.dropArea === 'left') ? 'activeDrop' : ''}`}
        ></div>
        <div className="stack middleStack"></div>
        <div className={`stack rightStack ${(this.state.dropArea === 'right') ? 'activeDrop' : ''}`}
        ></div>
        {
          this.state.cards.map((c) => {
            return <Card
              key={c.id}
              card={c}
              game={this}
            />
          })
        }
      </div>

      <div className='header'>
        <h3 className='stackLabel leftLabel' onClick={()=> this.sendCardToSide('left')}>
          {this.props.pageElement.getPropVal1('label', 'left', '')}
        </h3>
        <h3 className='stackLabel rightLabel' onClick={()=> this.sendCardToSide('right')}>
          {this.props.pageElement.getPropVal1('label', 'right', '')}
        </h3>
      </div>
    </div>

  }

  renderAnalysisCardStack(cards: ICardAnalysis[]) {
    const header = <tr>
        <th>Karte</th>
        <th>{this.props.pageElement.getPropVal1('label', 'left', '')}</th>
        <th>{this.props.pageElement.getPropVal1('label', 'right', '')}</th>
      </tr>
    const printLabelAndCount = (card: ICardAnalysis, side: Side) => {
      const printSymbol = (card.userSide === card.target && card.target === side) ? <span className='cardResultSymbol rightSymbol'>✅</span> : <span className='cardResultSymbol wrongSymbol'>❌</span>
      return <td>
          { side === card.userSide && printSymbol }
          { side != card.userSide && <span className='cardResultSymbol emptySymbol'></span> }
          { side === card.target && card.timesRight > 0 &&
            <span className='cardResultCount rightCount'>{card.timesRight}</span>
          }
          { side != card.target && card.timesWrong > 0 &&
            <span className='cardResultCount wrongCount'>{card.timesWrong}</span>
          }
        </td>
    }
    return <table>
        { header }
        {
          cards.sort(
            (a, b) => {
              // sort by: side, correct, wrong, timesright, timeswrong
              const aRight: boolean = (a.target === a.userSide)
              const bRight: boolean = (b.target === b.userSide)
              if (a.target !== b.target) {
                return (a.target === 'left') ? -1 : 1
              }
              if (aRight !== bRight) {
                return (aRight) ? -1 : 1
              }
              return b.timesWrong - a.timesWrong
            }
          ).map(
            (c) => <tr key={'card-result-' + c.id}>
                <td>{c.title}</td>
                  { printLabelAndCount(c, 'left')}
                  { printLabelAndCount(c, 'right')}
              </tr>
          )
        }
      </table>
  }
  /*
  renderAnalysisCardStackOld(cards: ICardAnalysis[]) {
    const header = <div key='header' className='resultCard header w3-row'>
      <div className='w3-col m6 card-title'>Karte</div>
      <div className='w3-col m2'>Kategorie</div>
      <div className='w3-col m2'>
        Anzahl Richtig
      </div>
      <div className='w3-col m2'>
        Anzahl Falsch
      </div>
    </div>
    return <>
      {header}
      {
      cards.sort(
        (a, b) => b.timesWrong - a.timesWrong
      ).map((c) => <div key={c.id} className='resultCard w3-row'>
        <div className='w3-col m6 card-title'>
          {c.title}
        </div>
        <div className='w3-col m2'>
          {this.props.pageElement.getPropVal1('label', c.target, '')}
        </div>
        <div className='w3-col m2 w3-text-green'>
          {c.timesRight}
        </div>
        <div className='w3-col m2 w3-text-red'>
          {c.timesWrong}
        </div>
      </div>)
      }
    </>
  }
  */

  repeatButton() {
    if (this.props.pageElement.getPropVal1('multiplayer', 'mode', 'no') != 'no') {
      return null
    }
    return <button
    className='resetButton'
    onClick={() => {
      this.setState({mode: Mode.standard})
      this.reset()
    }}
    ><Repeat /> Karten mischen</button>
  }

  renderAnalysis() {
    console.log('card status', this.state.cards)
    let data: ICardAnalysis[]
    if (this.state.groupSessionCards.length) {
      data = this.state.cards.map((c) => {
        const gc = this.state.groupSessionCards.find((gsc: SessionCard) => gsc.id === c.id)
        let out = c.getAnalysisResult()
        if (gc) {
          out.timesRight = gc.timesRight
          out.timesWrong = gc.timesWrong
        }
        return out
      })
    } else {
      data = this.state.cards
    }
    return <div className='game row'>
      <div className={`result w3-col w3-margin-bottom`}>
        <h2>Ergebnis</h2>
        {
          this.renderAnalysisCardStack(data)
        }
      </div>
      {
        this.props.pageElement.getPropVal1('multiplayer', 'mode', 'no') === 'legacy' &&
        <div className='shareWithGroupSection w3-padding'>
        Ergebnis mit Gruppe Teilen: <InputA
        value={this.state.groupSessionKey}
        returnVal={(v: string) => {
          this.setState({groupSessionKey: v})
          this.sendAnalysisTeamData(v)
        }}
        />
        </div>
      }
      { this.repeatButton() }
    </div>
  }

  generateTeamHash(groupSessionKey: string) {
    return `${this.id}-${groupSessionKey}`
  }

  sendAnalysisTeamData(hash: string) {
    if (this.usedGroupSessionKeys.has(hash)) {
      console.log('dont send twice! Just listening')
      return
    }
    this.usedGroupSessionKeys.set(hash, true)
    const out = {
      kind: 'team',
      hash: this.generateTeamHash(hash),
      start: DisplayPHPDate(new Date()),
      action: 'addition',
      tokenProps: this.state.cards.map((c) => {
        return {
          key1: 'card',
          key2: c.id,
          value1: c.timesRight,
          value2: c.timesWrong
        }
      })
    }
    Setter('token/setByHash', out)
  }

  async getAnalysisTeamData() {
    // TODO - get data
    const params = {
      hash: this.generateTeamHash(this.state.groupSessionKey),
      start: DisplayPHPDate(new Date())
    }
    
    const raw = await Setter('token/getByHash', params)
    if (!raw) {
      return
    }
    const result = raw as {
      tokenProps: {key1: string, key2: string, value1: string, value2: string}[]
    }
    const sessionCards = result.tokenProps.filter(
      (entry) => entry.key1 === 'card'
    ).map((c) => {
      return {
        id: c.key2,
        timesRight: parseInt(c.value1, 10),
        timesWrong: parseInt(c.value2, 10)
      }
    })
    this.setState({groupSessionCards: sessionCards})
  }

  reset() {
    this.positionCounter = {left: 0, right: 0}
    this.state.cards.forEach((c) => {
      c.reset()
    })
    mainservice.broadcast('CardSortGame-Reset', '')
    this.setState({
      cards: this.resortCards(this.state.cards)
    })
  }

  renderEdit() {
    return <div className='admin'>
      <h2>CardSortGame Admin</h2>
      <div className='adminBoard'>
        {
          [
            {
              side: 'left'
            },
            {
              side: 'right'
            }
          ].map((s, index) => <div
            key={index}
            className={`stack ${s.side}Stack`}
          >
            <InputA
              returnVal={(rv: string, _self: any) => {
                this.props.pageElement.setPropVal('label', s.side, rv)
                this.forceUpdate()
              }}
              value={this.props.pageElement.getPropVal1('label', s.side, '')}
              parent={this}
            />
            {
              this.state.cards.filter((c) => c.target === s.side).map((c) => {
                return <Card
                  key={c.id}
                  card={c}
                  game={this}
                  editOk={true}
                />
              })
            }
            <button
              onClick={() => {
                this.setState({
                  cards: this.state.cards.concat([
                    new CardDTO({
                      id: new Date().getTime()+'' + (this.newCardCounter++),
                      pageElement: this.props.pageElement,
                      target: s.side as Side
                    })
                  ])
                })
              }}
            >
              <Plus />
            </button>
          </div>)
        }
        <div className='w3-container'>
          <BookAdminPropSwitcher
            parent={this.props.pageElement}
            name='Multiplayer'
            key1='multiplayer'
            key2='mode'
            value1Arr={[['no', 'nein'], ['legacy', 'ja'], ['constant', 'immer an (ohne SessionCode Eingabe)']]}
            value1Current={this.props.pageElement.getPropVal1('multiplayer', 'mode', 'no')}
            cb={() => {this.forceUpdate()}}
          />
        </div>
      </div>
    </div>
  }

  renderAdminToggleButton() {
    if (this.state.mode === Mode.standard) {
      return <button onClick={() => {this.setState({ mode: Mode.edit })}}>
        Editieren
      </button>
    }
    return <button onClick={() => {this.setState({ mode: Mode.standard })}}>
      Useransicht
    </button>
  }

  adminTrashCard(id: string) {
    this.setState({
      cards: this.state.cards.filter((c) => c.id != id)
    })
    this.forceUpdate()
  }

  render() {
    return <div className='cardSortGame'>
      {
        this.state.mode === Mode.edit &&
        this.renderEdit()
      }
      {
        this.state.mode === Mode.analysis &&
        this.renderAnalysis()
      }
      {
        (this.state.mode === Mode.standard  ||
        this.state.mode === Mode.result)  &&
        this.renderGame()
      }
      {
        mainservice.isAdmin() > 0 &&
        this.renderAdminToggleButton()
      }
    </div>
  }
}
