import EvaluationTexts from './ResultTexts.json'


export type EvaluationTextType = {
  // orList: OrList
  logic: string
  text: string[],
}

type ValuesType = [number, number, number, number]

class Evaluation1TextGenerator {
  s1: number
  s2: number
  s3: number
  s4: number
  values: ValuesType
  texts: EvaluationTextType[] = []

  constructor(values: ValuesType) {
    this.s1 = values[0]
    this.s2 = values[1]
    this.s3 = values[2]
    this.s4 = values[3]
    this.values = values
    EvaluationTexts.forEach((et) => this.texts.unshift(et))
  }

  getAllTextAndValidIndex() {
    return {
      index: this.getTextId(),
      texts: this.getAllText()
    }
  }

  getAllText(): EvaluationTextType[] {
    return this.texts
  }

  getTextId(): number {
    return this.texts.findIndex((eT) => this.evalText(eT, this.values))
  }

  evalText(eT: EvaluationTextType, values: [number, number, number, number]): boolean {
    console.log('evalText', eT, values)
    const logic = eT.logic.split('')
    console.log('## logic', logic)
    // find negative result (return true so it will be false ...)
    return logic.every((l: string, index: number) => {
      const currentValue = values[index]
      if (currentValue < 4 && (l === 'A' || l === 'B')) { return false }
      const twoThirdCurrent =  2 * currentValue / 3
      console.log('###', index, l, values)
      switch(l) {
        case 'A':
          // const SUMA = values[0] + values[1] + values[2] + values[3]
          // return !(values[index] > SUMA * 0.5)
          // Value has to be at least 2/3rd of every other value:
          return values.every((v: number, i: number) => {
            if (logic[i] === 'A' || v <= twoThirdCurrent) { return true }
            return false
          })
        case 'B':
          // Value has to be at least 2/3rd of every unspecified value:
          return values.every((v: number, i: number) => {
            if (logic[i] != '_' || v <= twoThirdCurrent) { return true }
            return false
          })
          /*
          let SUM_ = values[index]
          logic.forEach((subL: string, subIndex: number) => {
            if (subL === '_') {
              SUM_ += values[subIndex]
            }
          })
          return !(values[index] > SUM_ * 0.5)
          */
        case '_':
        default:
          return true
      }
    })
  }

  getItem(values: ValuesType) {
    return this.texts.find((eT) => this.evalText(eT, values))
  }
  getText() {
    const rightChoice = this.getItem(this.values)
    if (!rightChoice) { return []}
    return rightChoice.text
  }
}

export default Evaluation1TextGenerator
