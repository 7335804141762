import { useEffect, useState } from "react";
import { Eye } from "react-feather";
import PageElementDTO from "../../../DTO/PageElementDTO";
import WidgetAdminDTO from "../../../DTO/WidgetAdminDTO";
import { IWidget } from "../../../DTO/WidgetDTO";
import WidgetUserDTO from "../../../DTO/WidgetUserDTO";
import { Getter } from "../../../services/ComService";
import mainservice from "../../../services/MainService";
import { WidgetPropsAdmin } from "../../widget/WidgetAdmin";
import WidgetUser from "../../widget/WidgetUser";

enum Status {
  standard,
  adminEdit,

}

export default function WidgetElement(props: {
  pageElement: PageElementDTO
}) {
  // const [rerender, setRerender] = useState(0)
  const [status, setStatus] = useState(Status.standard)
  return <>
    {
      mainservice.isAdmin() > 0 &&
      <RenderAdmin rerender={(mode: Status) => {
          // setRerender(rerender + 1)
          setStatus(mode)
        }}
        pageElement={props.pageElement}
      />
    }
    {
      status !== Status.adminEdit && props.pageElement.widget &&
      <WidgetUser
        id={props.pageElement.widget.id}
        pageElement={props.pageElement}
      />
    }
    {
      status === Status.adminEdit &&
      <button
        onClick={() => {
          setStatus(Status.standard)
        }}
      ><Eye /></button>
    }
  </>
}

let widgetList: WidgetUserDTO[] = []

function RenderAdmin(props: {
  rerender: (mode: Status) => void,
  pageElement: PageElementDTO,
}) {
  const [w] = useState(new WidgetAdminDTO({
    id: props.pageElement.widget?.id !== undefined ? props.pageElement.widget?.id : -1,
    pageElement: props.pageElement,
  }))
  const [status, setStatus] = useState(0)
  useEffect(() => {
    Getter('admin/widgets').then(r => {
      if (!r?.data) { return }
      const list: WidgetUserDTO[] = r.data.map((w: IWidget) => new WidgetUserDTO(w))
      widgetList = list
      // props.rerender()
      setStatus(status + 1)
    })
    w.getFromServer().then(() => {
      // props.rerender()
      setStatus(status + 1)
    })
  }, [])
  return <div className='adminToolBar w3-margin-top'>
    {
      widgetList.map(w => <button
        key={`widget-id-${w.id}`}
        className={`w3-card w3-padding ${props.pageElement.widget?.id === w.id ? 'w3-green' : ''}`}
        onClick={async () => {
          await props.pageElement.setWidget(w.id)
          window.location.reload()
        }}
      >
        {w.getName()}
      </button>)
    }
    {
      status !== Status.adminEdit &&
      <button
        onClick={() => {
          setStatus(Status.adminEdit)
        }}
      >Widget Einstellungen für diese Seite</button>
    }
    {
      status === Status.adminEdit &&
      <WidgetPropsAdmin
        widget={w}
        success={() => {
          props.rerender(Status.standard)
          setStatus(Status.standard)
        }}
      />
    }
  </div>
}
