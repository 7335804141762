import { Getter, Setter } from "../services/ComService"
import GroupDTO, { IGroup } from "./GroupDTO"

export default class GroupsDTO {
  private items: GroupDTO[] = []

  public async getFromServer() {
    const data = await Getter('groups')
    console.log(data)
    this.items = data.map((d: IGroup) => new GroupDTO(d))
  }

  public getItems() {
    return this.items
  }

  public async addItem(name: string) {
    await Getter(`group/add/${name}`)
    await this.getFromServer()
  }

  public deleteItem() {

  }

  public renameItem() {

  }
}
