import { Setter } from "../../services/ComService";
import ItemDTO, { IItem } from "./ItemDTO";

export default class ItemsDTO {
  getUrl: string = ''
  trashUrl: string = ''
  needle: string = ''
  items: any[] = []
  personId: number = -1
  eventId: number = -1
  customerId: number = -1
  customerLocationId: number = -1
  additionalBody = {}
  constructor(o: IItems, additionalBody?: any) {
    if (o.getUrl) {
      this.getUrl = o.getUrl
    }
    if (o.trashUrl) {
      this.trashUrl = o.trashUrl
    }
    if (o.personId) {
      this.personId = o.personId
    }
    if (o.eventId) {
      this.eventId = o.eventId
    }
    if (o.customerId) {
      this.customerId = o.customerId
    }
    if (o.customerLocationId) {
      this.customerLocationId = o.customerLocationId
    }
    if (additionalBody) {
      this.additionalBody = additionalBody
    }
    if (o.items) {
      this.items = o.items
    }
  }
  public async setNeedle(needle: string) {
    this.needle = needle
    await this.getFromServer()
  }
  public async getFromServer(page?: number) {
    const body = {
      needle: this.needle,
      personId: this.personId,
      eventId: this.eventId,
      customerId: this.customerId,
      customerLocationId: this.customerLocationId,
      page: page || 0
    }
    const data = await Setter(this.getUrl, {...body, ...this.additionalBody})
    const newItems = this.mapItems( data.items)
    if ((page || 0) > 0) {
      this.items = [...this.items, ...newItems]
    } else {
      this.items = newItems
    }
    // Return with true, if items has been found:
    return newItems.length > 0
  }

  public count() {
    if (!this.items) { return 0 }
    return this.items.length
  }

  public mapItems(items: IItem[]) {
    return items.map((i: IItem) => new ItemDTO(i))
  }

  public getItems() {
    return this.items
  }

  public async trashItem(id: number) {
    this.items = this.items.filter(i => i.id !== id)
    await Setter(this.trashUrl, {
      id: id
    })
  }
}

interface IItems {
  trashUrl?: string
  getUrl?: string
  personId?: number
  eventId?: number
  customerId?: number
  customerLocationId?: number
  items?: any[]
}
