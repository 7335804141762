import { useState } from "react"
import InputB from "../../../components/InputB/InputB"
import { EventDTO } from "../../DTO/EventDTO"
import PropDTO from "../../DTO/PropDTO"
import { Trash } from "react-feather"

/**
 * Zeigt den ausgewählten Datumszeitraum an.
 * Wenn man das Datum auswählt kriegt man eine Kalenderansicht, mit allen anderen Veranstaltungen zur Orientierung.
 * Das ganze sollte man der Einfachheit halber als Zeitstrahl darstellen
 * @param props 
 * @returns 
 */
export default function DateSelect(props: {
  event: EventDTO
}) {
  const [Rerender, setRerender] = useState(0)
  let appointments = props.event.getAppointments()
  console.log('DateSelect appointments', appointments)
  if (appointments.length === 0) {
    appointments.push(new PropDTO({id: -1, key1: 'appointment', key2: makeHash(), value1: '', value2: ''}))
  }
  return <div>
    {
      appointments.map((a, index) => {
        return <Appointment
          key={`appointment-${a.key2}`}
          event={props.event}
          key2={a.key2}
          title={`Zeitraum ${index + 1}`}
          rerenderParent={() => {
            setRerender(Rerender + 1)
          }}
        />
      })
    }
    <button
      className='positiveButton'
      onClick={async () => {
        await props.event.addAppointment(makeHash())
        setRerender(Rerender + 1)
      }}
    >Weiteren Zeitraum hinzufügen</button>
  </div>
}

function Appointment(props: {
  event: EventDTO,
  key2: string,
  title: string,
  rerenderParent: () => void,
}) {
  const infoMsg = props.event.dateWarnMsg()
  const [Rerender, setRerender] = useState(0)
  const key2 = props.key2
  return <div
    className={`DateSelect`}
  >
    <h3>{props.title} <Trash
      onClick={async () => {
        if (window.confirm('Zeitraum wirklich löschen?')) {
          await props.event.removeAppointment(key2)
          props.rerenderParent()
        }
      }}
    /></h3>
    <label className={'EditFieldLabel'}>
      Von:
    </label>
    <InputB
      value={props.event.getAppointmentStart(key2)}
      type={'date'}
      returnVal={(v) => {
        props.event.setAppointmentStart(key2, v)
        setRerender(Rerender + 1)
      }}
    />

    <label className={'EditFieldLabel'}>
      Bis:
    </label>
    <InputB
      value={props.event.getAppointmentEnd(key2)}
      type={'date'}
      returnVal={(v) => {
        props.event.setAppointmentEnd(key2, v)
        setRerender(Rerender + 1)
      }}
    />
    <label className={'EditFieldLabel'}>
      Startzeit:
    </label>
    <InputB
      value={props.event.getPropV1(key2, 'startTime') || '09:00'}
      type={'time'}
      returnVal={(v) => {
        props.event.addProp(key2, 'startTime', v)
        setRerender(Rerender + 1)
      }}
    />
    <label className={'EditFieldLabel'}>
      Endzeit:
    </label>
    <InputB
      value={props.event.getPropV1(key2, 'endTime') || '17:00'}
      type={'time'}
      returnVal={(v) => {
        props.event.addProp(key2, 'endTime', v)
        setRerender(Rerender + 1)
      }}
    />
    {
      infoMsg && infoMsg.kind !== 'okay' &&
      <div
        className={`w3-margin-top w3-padding ${infoMsg.className}`}
      >{infoMsg.msg}</div>
    }
  </div>
}

function makeHash() {
  return `${new Date().getTime()}`
}
