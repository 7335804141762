import React from 'react'
import './CollectibleItemA.scss'
import CollectibleDTO, {CollectiblesDTO} from '../../DTO/CollectibleDTO'
import { Edit, Trash, Image, Check, X, Save } from 'react-feather'
// import JoditReact from "jodit-react-ts";
// import 'jodit/build/jodit.min.css';
import SvgEditor from '../svg/SvgEditor'
import mainservice from '../../services/MainService'

type Props = {
  collectible: CollectibleDTO,
  //key: string,
  fresh: () => void,
  selectFile?: (o: {
    id: number,
    pictureId: number
  }) => void
  selectMe?: (o: {
    id: number,
    select: boolean
  }) => void
  selected?: boolean
  onlySelect?: boolean
  className?: string
  editMode?: boolean
  broadCastListenerKey?: string
  showOnly?: boolean
}

type State = {
  collectible: CollectibleDTO,
  status: Status,
  title: string,
  content: string,
  pictureStyle: string,
  pictureBackground: string,
  onlySelect?: boolean,
  unsaved: boolean
}

enum Status {
  standard,
  edit,
  b4upload,
  show,
}


export default class CollectibleItemA extends React.Component<Props, State> {
  saveTimer: number = 0
  constructor(props: Props) {
    super(props)
    this.state = {
      collectible: props.collectible,
      title: props.collectible.title,
      status: (props.collectible.id === -1 || props.editMode) ? Status.edit : (props.showOnly ? Status.show : Status.standard),
      content: props.collectible.content,
      pictureStyle: props.collectible.pictureStyle || '',
      pictureBackground: props.collectible.getCssPicture(),
      onlySelect: props.onlySelect || false,
      unsaved: false
    }
  }

  componentDidMount() {
    if (this.props.broadCastListenerKey) {
      mainservice.registerToBroadcast(
        this.props.broadCastListenerKey || '',
        (key: string, value: any) => {
          if (key === `${this.props.broadCastListenerKey}-newBG`) {
            this.setState({
              pictureBackground: value as string
            })
          }
        },
        this
      )
    }
  }

  componentWillUnmount() {
    if (this.props.broadCastListenerKey) {
      mainservice.unregisterBroadcast(this.props.broadCastListenerKey)
    }
  }

  saveTitleEventually() {
    window.clearTimeout(this.saveTimer)
    this.saveTimer= window.setTimeout(() => {
      this.state.collectible.save({
        title: this.state.title,
        content: this.state.content,
        pictureStyle: this.state.pictureStyle
      })
      this.setState({unsaved: false})
    }, 2000)
  }

  renderTitleEditor() {
    return <p
      style={{textAlign: 'center'}}
    >
      <label className='w3-text-grey block'>Titel</label>
      <input
        autoFocus
        value={this.state.title}
        onChange={(event) => {
          this.setState({
            title: event.target.value,
            unsaved: true
          })
          console.log('title editor change!')
          this.saveTitleEventually()
        }}
      />
      <Save
        style={{opacity: this.state.unsaved ? 1 : 0}}
      />
    </p>
  }
  renderEdit() {
    return <div className='w3-card w3-margin'>

      <div
        className={`collectible-content collectible-type-${this.state.collectible.type}`}
      >
        <SvgEditor
          onChange={(c) => {
            this.setState({content: c})
          }}
          value={this.state.content}
          backgroundImage={this.state.pictureBackground}
          backgroundImageStyle={this.state.pictureStyle}
          type={this.state.collectible.type}

        />
      </div>
      {
        this.props.selectFile &&
        <p>
          <label className='block'>Hintergrund</label>
          <button
          className='w3-button w3-ripple w3-blue'
          onClick={() => {
            this.state.collectible.save({
              title: this.state.title,
              content: this.state.content,
              pictureStyle: this.state.pictureStyle
            }).then(() => {
              if (!this.props.selectFile) { return }
              this.props.selectFile({
                id: this.state.collectible.id,
                pictureId: this.state.collectible.picture?.id || -1
              })
            })
          }}
          >
            <Image />
          </button>
          {
            [
              {
                name: 'Füllend',
                value: 'collectible-bg-cover'
              },
              {
                name: 'Passend',
                value: 'collectible-bg-contain'
              },
              {
                name: 'Breite',
                value: 'collectible-bg-w100p'
              },
              {
                name: 'Höhe',
                value: 'collectible-bg-h100p'
              }
            ].map((b) => <span
              key={b.value}
              className='horizontal-spacer'
            >
              <input
                type='radio'
                checked={this.state.pictureStyle === b.value}
                onChange={() => {
                  this.setState({
                    pictureStyle: b.value
                  })
                  this.forceUpdate()
                }}
              />
              <label>
                {b.name}
              </label>
            </span>)
          }
        </p>
      }
      <p>
        <button
          className='w3-btn w3-ripple w3-green'
          onClick={() => {
            this.state.collectible.save({
              title: this.state.title,
              content: this.state.content,
              pictureStyle: this.state.pictureStyle
            }).then(() => {
              mainservice.navTo([['view', 'CollectibleListA']])
            })
          }}
        >
          Speichern
        </button>


      </p>
    </div>
  }
  /*
  <button
    className='w3-btn w3-ripple w3-grey'
    onClick={() => {
      mainservice.navTo([['view', 'CollectibleListA']])
    }}
  >
    Abbrechen
  </button>
  <button
    className='w3-btn w3-ripple w3-blue'
    onClick={() => {

    }}
  >
    Download
  </button>
  <button
    className='w3-btn w3-ripple w3-yellow'
    onClick={() => {
      this.setState({status: Status.b4upload})
    }}
  >
    Upload
  </button>
  */

  renderShow() {
    return <div className='w3-card w3-margin show'>
      {this.renderTitleEditor()}
      <p>
        <label className='w3-text-grey block'>Tags</label>
        {
          this.state.collectible.tags.map((t, index) => <span
            key={index}
            className='w3-border'
          >
            {t}
            <X
              className='pointer'
              height={14}
              onClick={() => {
                this.state.collectible.removeTag(t).then(() => {
                  this.props.fresh()
                })
              }}
            />
          </span>
        )
        }
      </p>
      <p
        className={`collectible-content collectible-type-${this.state.collectible.type}`}
        dangerouslySetInnerHTML={{__html: this.state.collectible.getContent()}}
      ></p>
      {
        this.props.selectMe &&
        <button
          className={`w3-btn w3-${(this.props.selected === true) ? 'blue' : 'grey'}`}
          onClick={() => {
            if (!this.props.selectMe) { return }
            this.props.selectMe({
              id: this.state.collectible.id,
              select: !this.props.selected
            })
          }}
          >
          <Check />
        </button>
      }
      {
        !this.state.onlySelect &&
        <button
          className='w3-btn w3-yellow'
          onClick={() => mainservice.navTo([
            ['view', 'CollectibleEditor'],
            ['id', this.state.collectible.id],
            ['type', this.state.collectible.type]
          ])}
        >
          <Edit />
        </button>
      }
      {
        !this.state.onlySelect &&

        <button
        className='w3-btn w3-ripple w3-red'
        onClick={() => {
          if (!window.confirm('Element wirklich löschen?')) { return }
          this.state.collectible.delete().then(() => {
            this.props.fresh()
          })
        }}
        >
        <Trash />
        </button>
      }
    </div>
  }

  renderShowOnly() {
    return <p
      className={`collectible-content collectible-type-${this.state.collectible.type}`}
      dangerouslySetInnerHTML={{__html: this.state.collectible.getContent()}}
    ></p>
  }

  fileUploadHandler(event: any) {
    const fileName = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {

    }

  }
  renderFileSelect() {
    return <input type="file" name="file" onChange={this.fileUploadHandler} />
  }

  render () {
    return <div
      // key={this.props.key}
      className={`collectibleItemA ${this.props.className || ''}`}
    >
      {
        this.state.status === Status.b4upload &&
        this.renderFileSelect()
      }
      {
        this.state.status === Status.edit &&
        this.renderEdit()
      }
      {
        this.state.status === Status.standard &&
        this.renderShow()
      }
      {
        this.state.status === Status.show &&
        this.renderShowOnly()
      }
      
    </div>
  }
}
