import config from '../config.json'


export default class DoublePagePropDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public key1: string
  public key2: string
  public value1: string
  public value2: string

  constructor(o: IDoublePageProp) {
    this.id = o.id || -1
    this.key1 = o.key1
    this.key2 = o.key2
    this.value1 = o.value1
    this.value2 = o.value2
  }

  get() {
    return {
      id: this.id,
      key1: this.key1,
      key2: this.key2,
      value1: this.value1,
      value2: this.value2
    }
  }

  public async remove() {
    await fetch(`${this.apiPrefix}DoublePageProp/${this.id}/remove`)
  }

  public setValue1(value: string) {
    this.value1 = value
    this.updateServer()
  }

  public async updateServer() {
    await fetch(`${this.apiPrefix}DoublePageProp/${this.id}/${this.key1}/${this.key2}/${this.value1}/${this.value2}`)
  }
}

export interface IDoublePageProp {
  id?: number
  key1: string
  key2: string
  value1: string
  value2: string
}
