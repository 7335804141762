import UserDTO from '../DTO/UserDTO'

type CB = (key: string, value: any) => void
// TODO
// - user
// - loggedIn
//
// Things to accomplish:
// - Pages shall have login status
//   - user can login and can go back to page
//   - if he is allowed and can still not see it: message
export default class LoginService {
  lastLoginCheck: Date | null = null
  loggedIn: boolean = false
  user: UserDTO
  useCache: boolean = false
  // For comunicating with Main Service:
  cb: CB = (_key, _value) => {}

  constructor(o: {cb: CB}) {
    this.user = new UserDTO(undefined, {cb: () => { this.userChanged() }})
    this.user.getFromServer()
    this.cb = o.cb
  }

  public async login(name: string, password: string) {
    try {
      const user = await this.user.login(name, password)
      this.useCache = false
      this.userChanged()
      return !user.error // Returns false, if login did not succeed!
    } catch(err) {
      return false
    }
  }

  public async logout() {
    await this.user.logout()
    this.useCache = false
    this.userChanged()
  }

  public async isLoggedIn() {
    if (this.useCache && this.lastLoginCheck && ((new Date().getTime() - this.lastLoginCheck.getTime())/1000 > 10)) {
      return this.loggedIn
    }
    const r = await this.user.myInfo()
    this.userChanged()
    this.lastLoginCheck = new Date()
    this.useCache = true
    return (r && r.id && r.id > -1) ? true : false
  }

  public instantIsLoggedIn() {
    return this.loggedIn
  }

  private userChanged() {
    if (this.user.id > -1) {
      if (!this.loggedIn) {
        this.loggedIn = true
        this.cb('loginsuccess', this.user)
      }
    } else {
      if (this.loggedIn) {
        this.loggedIn = false
        this.cb('logoutsuccess', true)
      }
    }
    console.log('new login status: ', this.loggedIn)
  }
}
