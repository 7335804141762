import React, {useState} from 'react'
import mainservice from '../../services/MainService'
import UserDTO, { PrivacyRuleList, PrivacyRule, PrivacyRuleType } from '../../DTO/UserDTO'
import UserAvatarManager from './UserAvatarManager'
import Spinner from '../Spinner/Spinner'
import { Info } from 'react-feather'
// import Tooltip from '../Tooltip/Tooltip'
import './UserPreferences.scss'
enum Status {
  normal,
  loading
}

type Props = {

}

type State = {
  status: Status
}

export default class UserPreferences extends React.Component<Props, State> {
  user: UserDTO
  constructor(props: Props) {
    super(props)
    this.user = mainservice.loginService.user
    this.state = {
      status: Status.loading
    }
  }

  render () {
    return <div className='w3-container w3-row'>
      <div className='w3-col l6 w3-padding'>
        <h2>Bild</h2>
        <UserAvatarManager user={this.user} />
        <h2>Kontaktdaten</h2>
        <RenderUserDataList user={this.user} />
      </div>
      <div className='w3-col l6 w3-padding'>
        <h2>Einstellungen</h2>
        <ContactRules />
      </div>
      <div className='w3-col l6 w3-padding'>
        <h2>Passwort Ändern</h2>
        <ResendPasswordMail />
      </div>
      <div className='w3-col l6 w3-padding'>
      <h2>Account löschen</h2>
        <DeleteAccount />
      </div>
    </div>
  }
}
const timer = new Map()
function RenderUserDataItem(props: {
  user: UserDTO,
  item: {name: string, key: string, value: string, options?: {value: string, name: string}[], sanitize?: (val: string) => string}
}) {
  const [value, setValue] = useState(props.item.value)
  const [saved, setSaved] = useState(false)
  const [status, setStatus] = useState('normal')
  const set = (v: string) => {
    const t: number = timer.get(props.item.key)
    if (t) { clearTimeout(t) }
    setValue(v)
    setSaved(false)
    setStatus('normal')
    const newTimer = setTimeout(async () => {
      if (props.item.sanitize) {
        const s = props.item.sanitize(v)
        if (s === 'error') {
          setStatus('error')
          return
        }
        if (s !== v) {
          setValue(s)
          v = s
        }
      }
      await props.user.setUserDataPersonalDataItem(props.item.key, v)
      setSaved(true)
    }, 2000)
    timer.set(props.item.key, newTimer)
  }
  if (props.item.options) {
    return <div>
        <label>{props.item.name}</label>
        <select
          className='w3-select w3-border'
          value={value}
          onChange={(event) => set(event.target.value)}
        >
          {props.item.options.map((o, index: number) => <option key={'address-option-' + o.value} value={o.value}>{o.name}</option>)}
        </select>
        { saved &&
          <span className='w3-green'>gespeichert!</span>
        }
      </div>
  }
  return <div>
      <label>{props.item.name}</label>
      <input
        className='w3-input'
        onChange={(event) => {
          set(event.target.value)
        }}
        value={value}
      ></input>
      { saved &&
        <span className='w3-green'>gespeichert!</span>
      }
      {
        status === 'error' &&
        <span className='w3-red'>Eingabe Prüfen!</span>
      }
    </div>
}

function RenderUserDataList(props: {
  user: UserDTO
}) {
  const data = props.user.getUserPersonalDataList()
  return <div>
      {
        data.map((item, index) => <div key={`personal-data-category-${index}`}>
          <h2>{item.name}</h2>
          {
            item.data.map((i, j) => <RenderUserDataItem
              key={`personal-data-item-${i.key}`}
              user={props.user}
              item={i}
            />)
          }
        </div>)
      }
    </div>
}

export function ContactRules(props: {
  user?: UserDTO
}) {
  return <>
    { PrivacyRuleList.map((p, i) => <p key={`contact-rule-${p.value}`}><ContactRule p={p} user={props.user} /></p>) }
  </>
}

function ContactRule(props: {
  p: PrivacyRuleType,
  user?: UserDTO
}) {
  const p = props.p
  const user = props.user || mainservice.loginService.user
  const [loading, setLoading] = useState(false)
  const [ok, setOk] = useState(user.contactOkBitwise(p.value))
  const [showInfo, setShowInfo] = useState(false)
  const setContactRule = async (kind: PrivacyRule, value: boolean) => {
    setLoading(true)
    await user.setContactOk(kind, value)
    const newValue = user.contactOkBitwise(kind)
    setOk(newValue)
    setLoading(false)
  }
  return <span className='contactRules'>
    {
      loading && <Spinner mini={true} />
    }
    {
      !loading &&
      <input
      type='checkbox'
      checked={ok}
      onChange={(e) => {
        e.preventDefault()
        setContactRule(p.value, !ok)
      }}
      />
    }
    {
      p.info &&
      <span
        className='infoToolTip w3-hover-opacity'
        onClick={() => setShowInfo(!showInfo)}
      >
        <Info />
      </span>
    }
    {
      p.description
    }
    {
      p.info &&
      <span className={`vhide ${(showInfo && p.info) ? 'show' : ''}`}>
        <span className='notice-box wide'>
          <span className='pointer w3-right w3-xlarge w3-hover-opacity' onClick={() => setShowInfo(false)}>&times;</span>
          <span dangerouslySetInnerHTML={{__html: p.info}}></span>
        </span>
      </span>
    }
    </span>
}

function ResendPasswordMail() {
  const user = mainservice.loginService.user
  enum Status {
    normal,
    success
  }
  const [status, setStatus] = useState(Status.normal)
  return <div className='newPassword'>

    {
      status === Status.success &&
      <div>
        Schaue nun in Dein E-Mail Postfach. Du solltest nun eine Mail mit einem Link zum Passwort ändern erhalten haben!
      </div>
    }
    {
      status === Status.normal &&
      <div>
      <p>Um ein neues Passwort zu erhalten, drücke bitte diesen Button:</p>
      <button
        className='secondary-button'
        onClick={() => {
          user.sendPasswordMail()
          setStatus(Status.success)
        }}
      >Passwort Mail erneut senden</button>
      <p>Du erhälst im Anschluß eine neue Passwort Mail, mit der Du ein neues Passwort setzen kannst.</p>
      </div>
    }
  </div>
}

function DeleteAccount() {
  const user = mainservice.loginService.user
  enum Status {
    normal,
    userWantsToDelete,
    waitingForDelete
  }
  const [status, setStatus] = useState(Status.normal)
  switch(status) {
    case Status.userWantsToDelete:
      return <div className='deleteAccount warning-box'>
          <p>Bist Du Dir sicher? Alle Daten gehen beim Löschen verloren!</p>
          <div>
            <button
              className='danger-button'
              onClick={async () => {
                setStatus(Status.waitingForDelete)
                await user.selfdelete()
                window.location.href = '/view=accountdeleted'
              }}
            >Ja, Account wirklich löschen!</button>
            <button
              className='good-button'
              onClick={() => setStatus(Status.normal)}
            >Nein, Account behalten</button>
          </div>
        </div>
    case Status.waitingForDelete:
      return <div className='deleteAccount notice-box w3-center'>
        <p><b>Dein Account wird gelöscht</b></p>
        <p><Spinner mini={true} /></p>
        <p>bitte warten</p>
      </div>
    default:
      return <div className='deleteAccount'>
          <p>Zum Löschen Deines Accounts folgenden Knopf drücken:</p>
          <div>
            <button
              className='secondary-button'
              onClick={() => setStatus(Status.userWantsToDelete)}
            >Account löschen</button>
          </div>
        </div>
  }
}
