import ObjGeneric, {DObj, OGProps, OGState} from './ObjGeneric'

export default class ObjCirc extends ObjGeneric {
  constructor(props: OGProps) {
    super(props)
  }
  public render() {
    const obj = this.props.data
    let x, y, w, h
    if (obj.xStart < obj.xEnd) {
      x = obj.xStart
      w = obj.xEnd - obj.xStart
    } else {
      x = obj.xEnd
      w = obj.xStart - obj.xEnd
    }

    if (obj.yStart < obj.yEnd) {
      y = obj.yStart
      h = obj.yEnd - obj.yStart
    } else {
      y = obj.yEnd
      h = obj.yStart - obj.yEnd
    }

    const r = Math.min(h / 2, w / 2)
    const rx = obj.locked ? r : w / 2
    const ry = obj.locked ? r : h / 2

    return (
      <ellipse
        key={obj.id}
        cx={x + rx}
        cy={y + ry}
        rx={rx}
        ry={ry}
        fill={obj.bg}
        // onMouseDown={(event) => {this.handleMouseDownObj(obj, event)}}
        // onMouseUp={this.handleMouseUpObj.bind(this, obj)}
        onMouseDown={(event) => {
          if (!this.props.onMouseDown) { return }
          this.props.onMouseDown(obj, event)
        }}
        onMouseUp={(event) => {
          if (!this.props.onMouseUp) { return }
          this.props.onMouseUp(obj, event)
        }}
      />
    )
  }
}
