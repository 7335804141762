import { Edit, Trash } from "react-feather"
import ItemLocationDTO from "../../DTO/ItemLocationDTO"
import mainservice from "../../../services/MainService"

export default function Location(props: {
  item: ItemLocationDTO,
  onClick?: (id: number, item: ItemLocationDTO) => void,
  className?: string,
  onTrash?: () => void,
  noEdit?: boolean
}) {
  const item = props.item
  return <div className={`STLocation PTListItem flex justify-content-space-between ${props.className || ''}`}>
    <button
      className={`w3-button flex-grow textLeft`}
      onClick={() => {
        if (props.onClick) {
          props.onClick(item.id, item)
          return
        }
        mainservice.navTo([['view', 'spLocationEdit'], ['id', item.id]])
      }}
    >{item.name}</button>
    { !props.noEdit && 
      <button
        className={`w3-button`}
        onClick={() => {
          mainservice.navTo([['view', 'spLocationEdit'], ['id', item.id]])
        }}
      ><Edit /></button>
    }
    {
      props.onTrash &&
      <button
        className={`w3-button`}
        onClick={props.onTrash}
      ><Trash /></button>
    }
  </div>
}
