import ItemPersonDTO, { IItemPerson } from "./ItemPersonDTO";
import ItemsDTO from "./ItemsDTO";

export default class ItemPersonsDTO extends ItemsDTO {

  public mapItems(items: IItemPerson[]) {
    return items.map((i: IItemPerson) => new ItemPersonDTO(i))
  }

  public async filterByTrainer(trainer: number) {
    this.additionalBody = {trainer: trainer}
    await this.getFromServer()
  }

}
