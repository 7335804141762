import { parseStringToServer } from '../services/TextConverter'
import PropDTO from './PropDTO'

export default class PagePropDTO extends PropDTO {

  public async remove() {
    await fetch(`${this.apiPrefix}PageProp/${this.id}/remove`)
  }

  public async updateServer() {
    await fetch(`${this.apiPrefix}PageProp/${this.id}/${this.key1}/${this.key2}/${parseStringToServer(this.value1)}/${parseStringToServer(this.value2)}`)
  }
}
