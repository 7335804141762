export function stringToFloat(t: string): number {
  t = t.replace(',', '.')
  return parseFloat(t)
}

export function floatToValue(n: number): string {
  return n.toFixed().replace('.', ',')
} 

export function CurrencyFormatter(s: string): string {
  let f = parseFloat(s) || 0
  const str = (f).toFixed(2).replace('.', ',')
  return `${str} €`
}
