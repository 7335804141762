import React from 'react'
import './Branding.scss'
import mainservice from './../../services/MainService'

type Props = {

}

type State = {
  scheme: string,
  hide: boolean
}

export default class Branding extends React.Component<Props, State> {
  // doublePageComponent: any
  // bookComponent: any
  hideTimer: any

  constructor(props: Props) {
    super(props)
    this.state = {
      scheme: 'black',
      hide: false
    }
    // this.doublePageComponent = props.parent
    // this.bookComponent = props.parent.parent
  }

  componentDidMount() {
    mainservice.registerToBroadcast('Branding', (key, _value) => {
      switch(key) {
        case 'currentDoublePageSet':
          this.updateScheme()
          break
        case 'setBranding':
          this.setScheme(_value)
          break
      }
    })
    this.updateScheme()
  }

  updateScheme() {
    const scheme = mainservice.currentDoublePage.getPropValue1('brand', 'scheme', 'black')
    if (scheme !== this.state.scheme) {
      this.setState({hide: true})
      if (this.hideTimer) { clearTimeout(this.hideTimer) }
      this.hideTimer = setTimeout(() => {
        this.setState({scheme: scheme, hide: false})
      }, 1000)
    }
  }

  public setScheme(key: string) {
    this.setState({scheme: key})
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('Branding')
    if (this.hideTimer) { clearTimeout(this.hideTimer) }
  }

  render() {
    // const scheme = this.doublePageComponent.getPropValue1('brand', 'scheme', 'black')
    return <div className={`branding no-pointer-events ${this.state.hide ? 'hide' : ''}`}>
      <div className="goldenAspect">
        <div className={`brandImg ${this.state.scheme}Img`}></div>
      </div>
    </div>
  }
}
