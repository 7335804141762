import PageElementDTO from "../DTO/PageElementDTO"
import WidgetUserDTO from "../DTO/WidgetUserDTO"

type WidgetItem = {
  widget: WidgetUserDTO,
  widgetId: number,
  elementId: number,
}

class WidgetService {
  widgets: WidgetItem[] = []
  get(o: {
    widgetId?: number,
    pageElementId?: number,
    pageElement?: PageElementDTO,
  }) {
    const pageElementId = o.pageElement?.id || o.pageElementId || -1
    let id = o.pageElement?.widget?.id
    if (id === undefined || id < 0) {
      if (o.widgetId !== undefined && o.widgetId > -1) {
        id = o.widgetId
      } else {
        id = -1
      }
    }
    const storedItem = this.widgets.find(w => w.widgetId === id && w.elementId === pageElementId)
    if (storedItem) {
      return storedItem.widget
    }
    const newItem = new WidgetUserDTO({
      id: id,
      pageElement: o.pageElement,
    })
    this.widgets.push({
      widget: newItem,
      widgetId: id,
      elementId: pageElementId,
    })
    return newItem
  }
}

const widgetService = new WidgetService()
export default widgetService
