import { Edit, Trash, X } from "react-feather"
import ItemCustomerDTO from "../../DTO/ItemCustomerDTO"
import mainservice from "../../../services/MainService"
import { OptionArray, OptionArrayHasValue } from "../../../services/OptionArrayParser"

export default function Customer(props: {
  item: ItemCustomerDTO,
  onClick?: (id: number, item: ItemCustomerDTO) => void,
  className?: string,
  onTrash?: (id: number) => void,
  onRemove?: () => void,
  options?: OptionArray,
}) {
  const item: ItemCustomerDTO = props.item
  return <div className={`STCustomer PTListItem flex justify-content-space-between ${props.className || ''}`}>
    <button
      className={`w3-button flex-grow textLeft`}
      onClick={() => {
        if (props.onClick) {
          props.onClick(item.id, item)
          return
        }
        mainservice.navTo([['view', 'spCustomerEdit'], ['id', item.id]])
      }}
    >
      <DisplayCustomerName
        item={item}
        options={props.options}
      />
    </button>
    <button
      title={`Standort von ...`}
      className={`w3-button textLeft width300`}
      onClick={() => {
        if (props.onClick && item.parentId > -1) {
          props.onClick(item.parentId, item)
          return
        }
        mainservice.navTo([['view', 'spCustomerEdit'], ['id', item.parentId]])
      }}
    >
      {item.parentName}
    </button>

    <button
      className={`w3-button`}
      onClick={() => {
        mainservice.navTo([['view', 'spCustomerEdit'], ['id', item.id]])
      }}
    ><Edit /></button>
    {
      props.onTrash &&
      <button
        className={`w3-button`}
        onClick={() => {
          if (props.onTrash) {
            props.onTrash(item.id)
          }
        }}
      ><Trash /></button>
    }
    {
      props.onRemove &&
      <button
        className={`w3-button`}
        onClick={props.onRemove}
      ><X /></button>
    }
  </div>
}

function DisplayCustomerName(props: {
  item: ItemCustomerDTO, options?: [string, string][]
}) {
  const item = props.item
  const options = props.options
  if (OptionArrayHasValue(options, 'customerShowAddress', 'yes')) {
    return <>
      <div>{item.name}</div>
      <div className='lightFont'>
        {item.getPropV1('data', 'street')} {item.getPropV1('data', 'streetNr')}<br />
        {item.getPropV1('data', 'postalcode')} {item.getPropV1('data', 'town')}<br />
        {item.getPropV1('data', 'country')}
      </div>
    </>
  }
  return <>{item.name}</>
}
