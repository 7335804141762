import React, {useState} from 'react'
import Spinner from '../Spinner/Spinner'
import { Getter, Setter } from '../../services/ComService'
import StaticSiteDTO, {IStaticSite} from '../../DTO/StaticSiteDTO'
import Paragraph from './Paragraph'
import './StaticPage.scss'

enum Status {
  loading,
  ready,
  unknown
}

type Props = {
  url: string
}

type State = {
  status: Status
}

export default class StaticPage extends React.Component<Props, State> {
  page: StaticSiteDTO | undefined = undefined
  constructor(props: Props) {
    super(props)
    this.state = {
      status: Status.loading
    }
  }

  componentDidMount() {
    this.getContent()
  }

  private async getContent() {
    const raw = await Setter('staticsite/get', {url: this.props.url})
    if (!raw) { return }
    const data = raw as {status: string, result: IStaticSite}
    if (data.status === 'error') {
      this.setState({status: Status.unknown})
      return
    }
    this.page = new StaticSiteDTO(data.result)
    this.setState({status: Status.ready})
  }

  render() {
    const result = () => {
      switch(this.state.status) {
        case Status.loading:
          return <Spinner />
        case Status.unknown:
          return <div className='notice-box'>Diese Seite konnte nicht gefunden werden</div>
        case Status.ready:
          return <div className='pageContent w3-border'>
            {
              this.page?.getParagraphs().map((p) => <Paragraph key={`static-paragraph-${p.id}`} paragraph={p} />)
            }
          </div>
      }
    }
    return <div className='StaticPage'>
      {result()}
    </div>
  }
}
