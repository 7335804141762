import React from 'react'
import mainservice from '../../services/MainService'
// import config from '../../config.json'
// import {Getter, Setter} from '../../services/ComService'
// import UserWidget from './UserWidget'
import Library, { LibraryMode } from '../Library/Library'
import UserLastElements from './UserLastElements'
import './UserDashboard.scss'
import UserDashMainMenu from './UserDashMainMenu'
import CollectibleDTO, { CollectibleType, CollectiblesDTO } from '../../DTO/CollectibleDTO'
import CollectibleListB from '../Collectible/CollectibleListB'
import UserPreferences from '../User/UserPreferences'
import StaticPage from '../staticPages/StaticPage'

type Props = {

}

type State = {
  user: {id: number},
  stateClass: string
}

export default class UserDashboard extends React.Component<Props, State> {
  listeners = [
    {
      name: 'popstate',
      cb: (e: any) => {
        mainservice.nav.getRoute()
        mainservice.broadcast('reloadMain', true)
      }
    }
  ]

  constructor(props: Props) {
    super(props)
    this.state = {
      user: {id: -1},
      stateClass: ''
    }
    // Broadcast receiver - if we login - fetch data
    mainservice.registerToBroadcast(
      'userdashboard',
      (key: string, value: any) => {
        switch(key) {
          case 'loginsuccess':
          case 'logoutsuccess':
            this.getData()
            break
          case 'libraryOpensBook':
            this.setState({
              stateClass: 'hideLeftBar'
            })
            break
        }
      },
      this
    )
  }

  componentDidMount() {
    this.listeners.forEach((l) => window.addEventListener(l.name, l.cb))
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('userdashboard')
    this.listeners.forEach((l) => window.removeEventListener(l.name, l.cb))
  }

  getData() {

  }

  renderMainContent() {
    switch(mainservice.nav.getVal('view')) {
      case 'static':
        return <StaticPage url={mainservice.nav.getVal('permaname') as string}/>
      case 'collectibleMotto':
        return <CollectibleListB
            type={CollectibleType.motto}
          />
      case 'collectiblePoster':
        return <CollectibleListB
            type={CollectibleType.poster}
          />
      case 'collectibleFlashcard':
        return <CollectibleListB
            type={CollectibleType.flashcard}
          />
      case 'dashboard':
        return <UserLastElements
          />
      case 'library':
        return <Library
          mode={LibraryMode.user}
        />
      case 'shop':
        return <Library
          mode={LibraryMode.shop}
        />
      case 'UserPreferences':
        return <UserPreferences />
      default:
        return <Library />
    }
  }

  render() {
    return <div
      className={`UserDashboard ${this.state.stateClass}`}
    >
      <div className="dashMainBar">
        <UserDashMainMenu />
      </div>
      <div className='dashMainContent'>
        <div className='dashMainContentContent'>
          { this.renderMainContent() }
        </div>
      </div>

    </div>
  }

}
