import { useDrop } from "react-dnd";
import { ObjectKind } from "../models/objectKinds";
import CONFIG from '../../config.json';
import { TimeCell } from "../DTO/DayDTO";

export function TimeGridHours(props: {
  identKey: string;
  grid: TimeCell[];
  addItem: (minutes: number, item: any, type: 'time') => void;
}) {
  return <>
    {props.grid.map(t => {
      return <TimeGridHour
        identKey={props.identKey}
        key={`${props.identKey}-timeGridHour-${t.timeProg}`}
        t={t}
        addItem={props.addItem} />;
    })}
  </>;
}

function TimeGridHour(props: {
  identKey: string;
  t: TimeCell;
  addItem: (minutes: number, item: any, type: 'time') => void;
}) {
  const className = 'TimeGridHour';
  const t = props.t;
  let currentItem: any = '';
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: ObjectKind.module + '',
    canDrop: (props, monitor) => {
      currentItem = monitor.getItem();
      if (currentItem.pin) {
        return false
      }
      return true;
    },
    drop: () => {
      if (props.addItem) {
        return props.addItem(t.timeProg, currentItem, 'time');
      }
    },
    collect: monitor => ({
      // item: monitor.getItem(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  }), [t.startTime]);
  return <div
    ref={drop}
    className={`ptdropzone ${(isOver) ? 'ptdzhover' : ''} ${(canDrop) ? 'ptdzcanDrop' : ''} ${className}`}
    style={{
      height: `${t.duration * CONFIG.planningHeightFactor}px`,
      top: `${(t.timeProg - t.startTime) * CONFIG.planningHeightFactor}px`,
    }}
  >
    <div className={`timeName ${t.displayTime ? '' : 'showWhenHovering'}`}>{t.timeName}</div>
  </div>;
}
