import React from 'react'
import PageElementDTO from '../../../DTO/PageElementDTO'
import './Text.scss'
import JoditReact from "jodit-react";
// import 'jodit/build/jodit.min.css';
import { Emoji2Unicode } from '../../../services/EmojiServices';

type Props = {
  pageElement: PageElementDTO
  parent: any
  index?: number
}

type State = {
  adminEdit: boolean,
  pageElement: PageElementDTO,
  editValue1Status: string,
}

/*
const editorConfig = {
  buttons: [
    // 'source',
    'paragraph',
    'align',
    'bold',
    'italic',
    'fontsize',
    '|',
    'ul',
    'ol',
    // 'eraser',
    '|',
    // 'font',
    'brush',
    '|',
    // 'image',
    'table',
    'link',
    '|',
    'undo',
    'redo',
    '|',
    'hr',
    'copyformat',
    'fullsize',
    'dots'
  ]
}
*/

class Text extends React.Component<Props, State> {
  state: State
  editorText: string
  parent: any
  constructor(props: Props) {
    super(props)
    this.editorText = props.pageElement.value1
    this.parent = props.parent
    this.state = {
      adminEdit: false,
      pageElement: props.pageElement,
      editValue1Status: props.pageElement.value1,
    }
  }

  renderAdmin(content: any) {
    const wysiwygEditor = this.state.pageElement.value2 != 'h1' && this.state.pageElement.value2 != 'h2'
    const editor = (wysiwygEditor) ? <JoditReact
      onChange={(c) => {this.editorText = c}}
      value={this.state.editValue1Status || ''}
    /> : <textarea
      autoFocus
      value={this.state.editValue1Status || ''}
      onChange={(event) => {
        this.editorText = event.target.value
        this.setState({editValue1Status: event.target.value})
      }}
    />
    return <div key={`editor-element-id-holder-${this.state.pageElement.id}`}>
      <div className='OurJoditEditor editMode' key={`editor-element-id${this.state.pageElement.id}`}>
        { editor }
        <button className="hideButton w3-btn w3-green"
          onClick={
            () => this.state.pageElement.setValue1(this.editorText).then(() => {
              this.props.parent.stopFocus()
            })
          }
        >ok</button>
      </div>
      <div className="displayMode">
        { content }
      </div>
    </div>
  }

  /*
  renderCrumb(c: any, hasHeadline: string, headlinePosition: number) {
    // Do not render crumb, if this is a section
    console.log('render crumb pos', headlinePosition)

    if (
      headlinePosition === -1 ||
      headlinePosition > 50 ||
      this.state.pageElement.getPropVal1('section', 'type', 'standard') === 'section' ||
      !hasHeadline ||
      !this.props.parent || // page Element
      !this.props.parent.props.parent || // page
      !this.props.parent.props.parent.props.parent || // doublepage
      !this.props.parent.props.parent.props.parent.props.parent || // book
      this.props.parent.props.parent.props.parent.props.parent.state.book.getPropVal1('tableOfContens', 'enable') != 'yes'
    ) {
      return <></>
    }
    const bookView = this.props.parent.props.parent.props.parent.props.parent
    const bookDTO = bookView.state.book
    const pageView = this.props.parent.props.parent
    const pageDTO = pageView.state.page
    const currentSection = bookDTO.getSectionByPage(pageDTO.id)
    console.log('render crumb - DO', currentSection)
    if (currentSection.name === '') { return <></> }
    const nameStyle = bookDTO.getPropVal1('tableOfContens', 'chapter-style', 'chapter-style-clean')
    // Get the correct current Chapter name:
    return <div className={`toc-crumb toc-crumb-${hasHeadline} ${nameStyle}`}>
      <div className={`toc-crumb-content`}>
      <div className='toc-book-name'>{bookDTO.name}</div>
        <div className='toc-chapter-name'>
          <span className='chapternumber'>{currentSection.sectionNumberString}</span>
          {currentSection.name}
        </div>
      </div>
    </div>


  }
  */
  extractH1 (displayContent: string) {
    const h1Scope = /^[^a-zA-Z0-9]*<h1[^>]*>(.*)<\/h1>/
    const h2Scope = /^[^a-zA-Z0-9]*<h2[^>]*>(.*)<\/h2>/
    const res1 = displayContent.match(h1Scope)
    const res2 = displayContent.match(h2Scope)
    const contentWithourH1 = displayContent.replace(h1Scope, '')
    const contentWithourH2 = displayContent.replace(h2Scope, '')
    if (res1) {
      return <>
        <h1
          className='extractedHeadline'
          dangerouslySetInnerHTML={{__html: res1[1]}}
        ></h1>
        { !!contentWithourH1 &&
          <p
            className='textholder'
            dangerouslySetInnerHTML={{__html: contentWithourH1}} >
          </p>
        }
      </>
    }

    if (res2) {
      return <>
        <h2
          className='extractedHeadline'
          dangerouslySetInnerHTML={{__html: res2[1]}}
        ></h2>
        { !!contentWithourH2 &&
          <p
            className='textholder'
            dangerouslySetInnerHTML={{__html: contentWithourH2}} >
          </p>
        }
      </>
    }

    // If first element - simulate Headline
    if (this.props.index === 0) {
      return <>
        <h1
          className='extractedHeadline hide'
        >→</h1>
        <p
          className='textholder'
          dangerouslySetInnerHTML={{__html: displayContent}} >
        </p>
      </>
    }

    return <p
      className='textholder'
      dangerouslySetInnerHTML={{__html: displayContent}} >
    </p>
  }

  render() {
    const displayContent = Emoji2Unicode(this.state.pageElement.value1 || '')
    // const scope = /<(h[0-9])/
    // const getHeadline = (displayContent.match(scope))
    // const headlinePosition = displayContent.search(scope)
    // let hasHeadline = (getHeadline) ? getHeadline[1] : ''
    const text = () => {
      switch (this.state.pageElement.value2) {
        case 'h1':
          // hasHeadline = 'h1'
          return <h1 dangerouslySetInnerHTML={{__html: displayContent}} />
        case 'h2':
          // hasHeadline = 'h2'
          return <h2 dangerouslySetInnerHTML={{__html: displayContent}} />
        case 'poster':
          return <div className="poster"  dangerouslySetInnerHTML={{__html: displayContent}} />
        default:
          return this.extractH1(displayContent)
      }
    }
    const c = text()
    // const content = <>{c}{this.renderCrumb(c, hasHeadline, headlinePosition)}</>
    const content = c
    if (this.parent.isAdmin() > 0) {
      return this.renderAdmin(content)
    }
    return content
  }
}

export default Text
