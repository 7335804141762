import config from '../config.json'
import { Catcher, SetterParams } from './Catcher'
const GetterCache = new Map()

var catcher = new Catcher()

export async function Getter(command: string, cache?: boolean, retryName?: string) {
  // console.log('catcher okay?', catcher)
  // catcher.RetryFilter(retryName || '', 'GET')
  if (cache && GetterCache.has(command)) {
    return GetterCache.get(command)
  }
  // console.log('fetch', command)
  try {
    const response = await fetch(`${config.apiPrefix}${command}`)
    console.log('Getter response', response.status)
    if (response.status === 401) {
      window.location.href = '/login'
      return
    }
    const r = await response.json()
    if (cache) {
      GetterCache.set(command, r)
    }
    catcher.RetryWalk(retryName || '', 'GET')
    return r
  } catch(err) {
    console.log('Getter did not succeed while fetching ' + command, err)
    if (retryName) {
      catcher.RetryAdd(command, {}, {kind: 'GET', retryName: retryName})
    }
  }
}

export async function Setter(command: string, body: object, params?: SetterParams)
: Promise<any>
{
  // console.log('Setter', command)
  return new Promise((success, fail) => {
    let method = params?.method || 'POST' 
    // let status = 'normal'
    fetch(`${config.apiPrefix}${command}`, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(async (response) => {
      if (response.status === 401) {
        window.location.href = '/login'
        return
      }
      catcher.RetryWalk(params?.retryName || '', 'POST', command) // do not wait for this
      const result = await response.json()
      if (result.status === 'error') {
        // status = 'regular-error'
        throw(result.reason)
      }
      success(result)
    }).catch((error) => {
      console.error('LOGIN?', error, command, body)
      catcher.RetryAdd(command, body, params)
      fail({
        desciption: 'fail',
        status: 'fail',
        error: error
      })
    })
  })

}
