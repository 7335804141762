import { Setter } from "../../services/ComService";
import { ObjectKind } from "../models/objectKinds";
import { PublishKind } from "../models/publishKinds";
import { DayDTO } from "./DayDTO";
import ModuleDTO, { IModule } from "./ModuleDTO";

export default class DayModuleDTO extends ModuleDTO {
  
  constructor(o: IDayModule) {
    super(o)
    this.objectKind = ObjectKind.daymodule
  }

  public async addParent(item: DayDTO) {
    const url = 'spt/setChild'
    const parentId = item.id
    this.parentId = parentId
    const data = await Setter(url, {
      id: parentId,
      childId: this.id
    })
    this.parentId = data.item.id
  }

  public async addUps(modules: IModule[]) {
    const id = this.id
    const url = 'spt/setUps'
    let upIds: number[] = []
    console.log('addUps Modules', modules)
    for (let i = 0, m = modules.length; i < m; i++) {
      let module = modules[i]
      let moduleId = module.id
      if (moduleId === -1) {
        const newModule = new ModuleDTO({...module, ...{
          publishKind: PublishKind.isSeminar
        }})
        await newModule.saveToServer()
        moduleId = newModule.id
      }
      let upId = parseInt(module.id + '', 10)
      this.upId = upId
      upIds.push(upId)
      this.up.push({
        id: upId,
        name: module.name
      })
      this.duration = module.duration || 60
    }
    await Setter(url, {
      id: id,
      jds: upIds.join(';')
    })
  }

}

export interface IDayModule extends IModule {

}