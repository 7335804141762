import { Setter } from "../../services/ComService";
import { ObjectKind } from "../models/objectKinds";
import { PublishKind } from "../models/publishKinds";
import { DayDTO, IDay } from "./DayDTO";
import { SeminarDTO } from "./SeminarDTO";

export class SeminarDayDTO extends DayDTO {
  
  constructor(o: ISeminarDay) {
    super(o)
    this.objectKind = ObjectKind.seminarday
  }

  public async addParent(seminar: SeminarDTO) {
    const url = 'spt/setChild'
    const parentId = seminar.id
    this.parentId = parentId
    const data = await Setter(url, {
      id: parentId,
      childId: this.id
    })
    this.parentId = data.item.id
  }

  public async addUp(day: DayDTO) {
    const url = 'spt/setUp'
    let dayId = day.id
    if (dayId === -1) {
      const newDay = new DayDTO({...day, ...{
        publishKind: PublishKind.isSeminar
      }})
      await newDay.saveToServer()
      dayId = newDay.id
    }
    this.upId = parseInt(day.id + '', 10)
    const id = this.id
    const data = await Setter(url, {
      id: id,
      jd: dayId
    })
    this.up.push({
      id: this.upId,
      name: day.name,
      props: day.props,
    })
  }

}

export interface ISeminarDay extends IDay {

}