import React from 'react'
import mainservice from '../../../services/MainService'
import BookDTO from '../../../DTO/BookDTO'
import PageElementDTO from '../../../DTO/PageElementDTO'
import Evaluation1Flexible from '../../../Models/Evaluation1/Flexible'
import Evaluation1Effective from '../../../Models/Evaluation1/Effective'
import BarA from '../../BarA/BarA'
import CakeA from '../../CakeA/CakeA'
import Text1Generator from '../../../Models/Evaluation1/Text1Generator'
import './Evaluation1.scss'

type Props = {
  pageElement: PageElementDTO
}

type State = {
  isAdmin: number,
  sessionData: any[],
  book: BookDTO | undefined,
  pageElement: PageElementDTO,
  // pageElements: PageElementDTO[],
  // result: any,
  flexPoints: number,
  effectivePoints: number,
  styles: {name: string, key: string, amount: number}[],
  text: string[],
  gatherCounter: number
}

class Evaluation1 extends React.Component<Props, State> {
  getDataInterval: any
  doublePageId: number
  justEntered: boolean = true
  constructor(props: Props) {
    super(props)
    console.log('EVAL constructor')
    this.state = {
      isAdmin: mainservice.isAdmin(),
      sessionData: [],
      book: mainservice.readBook(),
      flexPoints: 0,
      effectivePoints: 0,
      pageElement: props.pageElement,
      styles: [
        {name: 'Anweisend', key: 'A', amount: 0},
        {name: 'Trainierend', key: 'B', amount: 0},
        {name: 'Coachend', key: 'C', amount: 0},
        {name: 'Delegierend', key: 'D', amount: 0},
      ],
      text: [],
      gatherCounter: 0
    }
    this.doublePageId = this.state.pageElement.getDoublePageID()
  }

  componentDidMount() {
    console.log('EVAL did mount')
    this.getDataInterval = setInterval(() => {
      if (mainservice.isCurrentDoublePage(this.doublePageId)) {
        if (this.justEntered) {
          this.justEntered = false
          this.gatherData()
        }
      } else {
        this.justEntered = true
      }
    }, 2000)
  }

  componentWillUnmount() {
    clearInterval(this.getDataInterval)
    this.justEntered = true
  }

  gatherData() {
    console.log('gather eva data')
    let flexible = new Evaluation1Flexible(
      mainservice.getAllSessionData()
    )
    let effective = new Evaluation1Effective(
      mainservice.getAllSessionData()
    )
    let firstText = new Text1Generator(
      effective.effective,
      flexible.flexiblePoints
    )
    this.setState({
      sessionData: mainservice.getAllSessionData(),
      flexPoints: flexible.flexiblePoints,
      effectivePoints: effective.effective,
      styles: [
        {name: 'Anweisend', key: 'A', amount: flexible.pointsByCategory[0]},
        {name: 'Trainierend', key: 'B', amount: flexible.pointsByCategory[1]},
        {name: 'Coachend', key: 'C', amount: flexible.pointsByCategory[2]},
        {name: 'Delegierend', key: 'D', amount: flexible.pointsByCategory[3]},
      ],
      text: firstText.text.concat(flexible.text),
      gatherCounter: this.state.gatherCounter + 1
    })
  }

  renderBarBox(headline: string, value: number, counter: number) {
    return <div className='w3-col s12 m6 l6'>
      <h2>{headline}</h2>
      <BarA value={value} counter={counter}  animate={this.justEntered}/>
    </div>
  }

  render() {
    return <div className='evaluation1-holder'>
      <div className={`counter-${this.state.gatherCounter}`}>
        <h2>Anteilsverteilung der von dir bevorzugten Führungsstile</h2>
        <CakeA data={this.state.styles} animate={this.justEntered}/>
        {
          this.renderBarBox(
            'Deine Stil-Flexibilität',
            this.state.flexPoints,
            this.state.gatherCounter
          )
        }
        {
          this.renderBarBox(
            'Deine Stil-Effektivität',
            this.state.effectivePoints,
            this.state.gatherCounter
          )
        }
      </div>
      <h2>Kurzanalyse</h2>
      {
        this.state.text.map((p, index) => { return <p key={index}>{p}</p> })
      }
      <div className="lubbersLogoHolder"></div>
    </div>
  }
}

export default Evaluation1
