import config from '../config.json'
import { Getter, Setter } from '../services/ComService'
import {DisplayDateTimeNumeric} from '../services/DateTime'
import TagDTO, {ITag} from './TagDTO'

export default class FileDTO {
  public id: number
  public fileName: string
  public displayName: string
  public uploadDate: Date
  public tags: TagDTO[] = []
  public books: {id: number, name: string}[]
  public collectibles: {id: number, name: string}[]
  public staticSites: {id: number, name: string, url: string}[]
  public size: number = 0
  public thumb: string
  public extension: string
  constructor(f?: IFile, o?: {tags: TagDTO[]}) {
    if (!f) {
      this.id = -1
      this.fileName = ''
      this.displayName = ''
      this.uploadDate = new Date()
      this.books = []
      this.collectibles = []
      this.size = 0
      this.staticSites = []
      this.thumb = ''
      this.extension = ''
      return
    }
    if (o && o.tags && f.tagIds) {

      f.tagIds.forEach((ti: number) => {
        const t = o.tags.find((ts) => ts.id === ti)
        if (t) { this.tags.push(t) }
      })
    }
    this.id = f.id || -1
    this.fileName = f.fileName || ''
    this.displayName = f.displayName || ''
    this.size = f.size || 0
    if (f.uploadDate && f.uploadDate.date) {
      this.uploadDate = new Date(f.uploadDate.date)
    } else {
      this.uploadDate = new Date()
    }
    // console.log('UPLOADDATE', f.uploadDate, this.uploadDate)
    this.books = f.books || []
    this.collectibles = f.collectables || []
    this.staticSites = f.staticSites || []
    this.thumb = f.thumb || ''
    this.extension = f.extension || ''
  }

  /*
  init(f: IFile) {
    this.id = f.id
    this.fileName = f.fileName
    this.displayName = f.displayName
    this.uploadDate = new Date(f.uploadDate.date)
  }
  */

  get() {
    return {
      id: this.id,
      fileName: this.fileName,
      displayName: this.displayName,
      uploadDate: this.uploadDate,
      tags: this.tags,
      books: this.books,
      collectibles: this.collectibles,
      size: this.size,
      thumb: this.thumb,
      extension: this.extension,
    }
  }

  printSize() {
    const s = this.size
    if (s === 0) { return '' }
    if (s < 100) { return `${s} B`}
    if (s < 1000 * 100) { return `${Math.round(s / (100)) / 10} KB`}
    return `${Math.round(s / (1000 * 100)) / 10} MB`
  }

  toBig() {
    if (this.size > 1000000) {
      return true
    }
    return false
  }

  getURL() {
    return (this.fileName !== '') ? config.mediaDir + this.fileName : ''
  }

  getCSSBackground() {
    return `url('${this.getURL()}')`
  }

  getThumbURL() {
    return (this.thumb !== '') ? config.mediaDir + this.thumb : ''
  }

  getThumbCSSBackground() {
    return `url('${this.getThumbURL()}')`
  }

  getInfo(): {key: string, value: string}[] {
    console.log(this.uploadDate)
    return [
      {key: 'Anzeige Name', value: this.displayName},
      {key: 'ID', value: `${this.id}`},
      {key: 'Dateiname', value: this.fileName},
      {key: 'Pfad', value: this.getURL()},
      {key: 'Datum', value: DisplayDateTimeNumeric(this.uploadDate)},

    ]
  }

  getFromServer() {
    // TODO
  }

  writeToServer() {
    // TODO
  }

  async setDisplayName(displayName: string) {
    this.displayName = displayName
    return await Setter(`File/${this.id}/setDisplayName`, {
      displayName: displayName
    }, {retryName: `${this.id}`})
  }

  async setTag(name: string) {
    this.tags.push(new TagDTO({
      id: -1,
      name: name
    }))
    return await Setter(`File/${this.id}/addTags`, {
      tags: [name]
    }, {retryName: `${this.id}-${name}`})
  }

  async removeTag(name: string) {
    this.tags = this.tags.filter((t) => t.name != name)
    return await Setter(`File/${this.id}/removeTags`, {
      tags: [name]
    }, {retryName: `${this.id}${name}`})
  }

  async trash() {
    return await Getter(`File/${this.id}/trash`)
  }

  public async regenerateThumb() {
    this.thumb = await regenerateThumb(this.id)
  }
}

export async function regenerateThumb(id: number): Promise <string> {
  return new Promise(async (success, reject) => {
    try {
      const raw = await Getter(`File/generateThumb/${id}`)
      // this.thumb = raw.item.thumb
      if (raw.status === 'okay') {
        console.log('regenerate thumb okay! done with own resouces')
        success(raw.thumb as string)
      }
      console.log('regenerate thumb needs to be done externaly', raw)
      const fileName = raw.filename
      // const localCollectibleFileUrl = `${config.apiPrefix}pub/collectable/getById/${this.id}/secretAccessCode1`
      const localFileUrl = `${config.mediaDir}${fileName}`
      const convertToThumbUrl = `${config.externalImageConverter}/index.php?option=thumb`
      console.log('file mover', localFileUrl, convertToThumbUrl)
      const convertResult = await fileFromServer2Server(localFileUrl, convertToThumbUrl, 'file') as {thumbLocation: string}
      const thumbSource = `${config.externalImageConverter}${convertResult.thumbLocation}`
      const thumbUploadUrl = `${config.apiPrefix}File/uploadThumbnail/${id}`;
      console.log('file mover 2', thumbSource, thumbUploadUrl)
      const thumbUploadResult = await fileFromServer2Server(thumbSource, thumbUploadUrl, 'FileName') as {thumb: string}
      console.log('thumbUploadResult', thumbUploadResult)
      success(thumbUploadResult.thumb as string)
      // setTimeout(async () => {
      // },1000);
      // await this.rerouteThumb(raw.item.thumb)
    } catch (err) {
      console.error('this did not work', err)
      reject(err)
    }
  })
}

export async function fileFromServer2Server(fromUrl: string, toUrl: string, fileField?: string): Promise <object> {
  return new Promise((success, reject) => {
    fetch(fromUrl)
      .then(response => response.blob())
      .then(file => {
        // Create a FormData object
        let formData = new FormData()
        // Append the file to the FormData object
        formData.append(fileField || "file", file)
        formData.append('type', `pdf`)
        // Upload the file to the second server
        return fetch(toUrl, {
          method: "POST",
          body: formData
        })
      })
      .then(async response => {
        const result = await response.json()
        success(result)
      })
      .catch(error => {
        console.log("Error:", error)
        reject(error)
      });
  })
}

export interface IFile {
  id: number
  fileName: string
  displayName: string
  uploadDate: {date: string, timezone: string, timezone_type: number}
  tagIds?: number[]
  books?: {id: number, name: string}[]
  collectables?: {id: number, name: string}[]
  staticSites?: {id: number, name: string, url: string}[]
  size?: number
  thumb?: string
  extension?: string
}
