import { useEffect, useState } from "react"
import BookDTO from "../../../DTO/BookDTO"
import PageElementDTO from "../../../DTO/PageElementDTO"
import { Getter } from "../../../services/ComService"
import mainservice from "../../../services/MainService"
import BookSelector from "../../Admin/BookSelector"
import InputA from "../../InputA/InputA"
import { Book } from "../../Library/Library"
import './BookLink.scss'

type Prop  = {
  key1: string,
  key2: string,
  description: string,
  kind: 'text' | 'multiButton',
  options?: {
    displayText: string,
    value: string,
  }[],
}

const PropList: Prop[] = [
  {
    key1: 'text',
    key2: 'ifOwned',
    description: 'Text, wenn Buch User gehört',
    kind: 'text',
  },
  {
    key1: 'text',
    key2: 'ifNotOwned',
    description: 'Text, wenn Buch user NICHT gehört',
    kind: 'text',
  },
  {
    key1: 'book',
    key2: 'displaySize',
    description: 'Wie groß soll das Buch dargestellt werden',
    kind: 'multiButton',
    options: [
      {
        displayText: 'Standard',
        value: '8',
      },
      {
        displayText: 'kleiner',
        value: '6',
      },
      /*
      {
        displayText: 'noch kleiner',
        value: '4',
      },
      {
        displayText: 'winzig',
        value: '2',
      },
      */
    ]
  },
  {
    key1: 'book',
    key2: 'goToCartAfterCartClick',
    description: 'Einkaufswagen automatisch aufsuchen',
    kind: 'multiButton',
    options: [
      {
        displayText: 'Ja',
        value: 'yes',
      },
      {
        displayText: 'nein',
        value: 'no',
      },
    ]
  }
]

function PropEditor(props: {
  prop: Prop,
  pageElement: PageElementDTO,
  cb: () => void,
}) {
  const p = props.prop
  const pageElement = props.pageElement
  const value = pageElement.getPropVal1(p.key1, p.key2, '')
  return <div>
    <div>{p.description}</div>
    {
      p.kind === 'text' &&
      <InputA
        value={value}
        returnVal={async (value) => {
          await props.pageElement.addElementProp({
            id: -1,
            key1: p.key1,
            key2: p.key2,
            value1: value,
            value2: '',
          })
          props.cb()
        }}
      />
    }
    {
      p.kind === 'multiButton' && p.options &&
      <div>
        {
          p.options.map(o => <button
            key={`${p.key1}-${p.key2}-${o.value}`}
            className={`w3-button ${(value === o.value || (value === '' && o.value === '8')) ? 'w3-green' : 'w3-grey'}`}
            onClick={async () => {
              await props.pageElement.addElementProp({
                id: -1,
                key1: p.key1,
                key2: p.key2,
                value1: o.value,
                value2: '',
              })
              props.cb()
            }}
          >{o.displayText}</button>)
        }
      </div>
    }
  </div>
}

function BookClassGenerator(size: string) {
  switch (size) {
    case '2':
      return {
        side: 'w3-col s4 m5 l5',
        book: 'w3-col s4 m2 l2',
      }
    case '4':
      return {
        side: 'w3-col s3 m4 l4',
        book: 'w3-col s6 m4 l4',
      }
    case '6':
      return {
        side: 'w3-col s2 m3 l3',
        book: 'w3-col s8 m6 l6',
      }
    default:
      return {
        side: 'w3-col s1 m2 l2',
        book: 'w3-col s10 m8 l8',
      }
  }
}

function RenderBookLinkAdmin(props: {
  pageElement: PageElementDTO,
  parent: any,
  cb: () => void,
}) {
  const currentBookId = parseInt(props.pageElement.getPropVal1('book', 'id', '-1'), 10)
  return <div className='bookLinkAdmin'>
    Book Link:
    <BookSelector
      currentBookId={currentBookId}
      selectBook={async (newBookId, name) => {
        await props.pageElement.addElementProp({
          id: -1,
          key1: 'book',
          key2: 'id',
          value1: newBookId + '',
          value2: '',
        })
        await props.pageElement.addElementProp({
          id: -1,
          key1: 'book',
          key2: 'name',
          value1: name + '',
          value2: '',
        })
        props.cb()
      }}
    />
    {
      PropList.map(p => <PropEditor
        key={`BookLinPropEditor-${props.pageElement.id}-${p.key1}-${p.key2}`}
        prop={p}
        pageElement={props.pageElement}
        cb={() => {
          props.cb()
        }}
      />)
    }
  </div>
}

export default function BookLink (props: {
  pageElement: PageElementDTO,
  parent: any,
}) {
  const [rerender, setRerender] = useState(0)
  const [bookRight, setBookRight] = useState(1)
  // const [bookId, setBookId] = useState(parseInt(props.pageElement.getPropVal1('book', 'id', '-'), 10))
  // let bookId = parseInt(props.pageElement.getPropVal1('book', 'id', '-'), 10)
  const [book, setBook] = useState(new BookDTO({id: -1, name: ''}))
  // const [book, setBook] = useState(null)
  async function LoadContent () {
    let bookId = parseInt(props.pageElement.getPropVal1('book', 'id', '-'), 10) || -1
    console.log('load book content', bookId)
    const bookright = await Getter(`bookright/${bookId}`, true)
    const teaser = bookright.userRight !== 1
    const bookData = await Getter(`book/basic/${bookId}`, false)
    setBook(new BookDTO({...bookData, ...{teaser: teaser}}))
    setBookRight(bookright.userRight)
  }
  useEffect(() => {
    LoadContent()
  }, [])
  function getTeaserText() {
    if (book.teaser === true) {
      return props.pageElement.getPropVal1('text', 'ifNotOwned', '')
    }
    if (book.teaser === false) {
      return props.pageElement.getPropVal1('text', 'ifOwned', '')
    }
    return ''
  }
  const teaser = getTeaserText()
  const classes = BookClassGenerator(props.pageElement.getPropVal1('book', 'displaySize', ''))
  // side div: <div className={classes.side}>&nbsp;</div>
  return <>
    {
      props.parent.isAdmin() > 0 &&
      <RenderBookLinkAdmin
        pageElement={props.pageElement}
        parent={props.parent}
        cb={async () => {
          await LoadContent()
          setRerender(rerender + 1)
        }}
      />
    }
    {
      book &&
      <div className={`Library libraryLinkButton w3-row libraryLinkButton-${book.id}`}>
        { teaser &&
          <div className='w3-col s12 m12 l12' dangerouslySetInnerHTML={{__html: teaser}}></div>
        }
        
        <Book
          className={classes.book}
          book={book}
          index={424242}
          openBook={() => {
            mainservice.navTo([
              ['view', 'book'],
              ['book', book.id],
              ['page', 1]
            ])
          }}
          putIntoCartCb={() => {
            if (props.pageElement.getPropVal1('book', 'goToCartAfterCartClick', '') === 'yes') {
              mainservice.navTo([
                ['view', 'shoppingCart'],
              ])
            }
          }}
          active={false}
        />
        <div className={classes.side}>&nbsp;</div>
      </div>
    }
    {
      bookRight !== 1 &&
      <div className='w3-row w3-margin-bottom'>
        <button
          className='primary-button'
          onClick={() => {
            mainservice.navTo([
              ['view', 'shoppingCart'],
            ])
          }}
        >
          Zum Einkaufswagen
        </button>
      </div>
    }
  </>
}

