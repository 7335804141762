import config from '../config.json'
import TagDTO, { ITag } from './TagDTO'
import UnitDTO, { IUnit, UnitTypes } from './UnitDTO'
import FileDTO, { IFile } from './FileDTO'
import { Getter, Setter } from '../services/ComService'
import { SanitizeDate } from '../services/DateTime'
import UserRightsDTO, { IUserRightsDTO } from './UserRightsDTO'

type UserProp = {
  id: number,
  key1: string,
  key2: string,
  value1: string,
  value2: string
}

export type AvailableUser = {
  id: number,
  unitIds: number[],
  marked: boolean
}

export enum PrivacyRule {
  newsletter = 1,
  livePicVideoOk = 8,
  livePicVideoOkPurposeLearning = 16,
  livePicVideoSharingToCourseOk = 32,
  livePicVideoDontDistribute = 64,
  livePartipantVoluntary = 128,
}

export type PrivacyRuleType = {
  name: string,
  value: PrivacyRule,
  description: string,
  info?: string
}

const PictureVideoInfo = `<h3>Nutzung von Bild- und Videomaterial</h3>
<p>Im Verlauf einiger unserer Veranstaltungen zeichnen wir Videos auf. Oft sind das Videos von dir oder der Gruppe. Diese Videos setzen wir zum Videofeedback ein. Sie decken blinde Flecken auf und helfen sehr bei der Weiterentwicklung. So, wie im Profisport auch.</p>
<h3>Verwendungszweck</h3>
<p>Veranstaltungsinterne Verwendung wie z. B. Erinnerungsfotos oder Videoaufnahmen, die zu Lernzwecken (Videofeedback) in der Veranstaltung verwendet werden.</p>
<p>Bilder und Videos aus unseren Veranstaltungen verwenden wir ausschließlich für den Zweck der Veranstaltung. Wir werden sie niemals - ohne ausdrückliche Erlaubnis - veröffentlichen oder eigene Zwecke nutzen. Die meisten Daten werden direkt nach der Veranstaltung gelöscht. Auf Wunsch geben wir erstelltes Material über einen passwortgeschützten Link zum Download an.</p>
<h3>Erklärung</h3>
<p>Ich erkläre mein Einverständnis mit der (unentgeltlichen) veranstaltungsinternen Verwendung der Bild- sowie Videoaufnahmen meiner Person für die oben beschriebenen Zwecke. Eine Verwendung des Bildmaterials für andere als die beschriebenen Zwecke oder ein Inverkehrbringen durch Überlassung der Aufnahmen an Dritte ist unzulässig.</p>`

export const PrivacyRuleList: PrivacyRuleType[] = [
  {
    name: 'Newsletter',
    value: PrivacyRule.newsletter,
    description: 'Hiermit erlaubst Du uns, Dich mit interessanten Neuigkeiten zu kontaktieren',
  },
  {
    name: 'Live Bild/Video Ok',
    value: PrivacyRule.livePicVideoOk,
    description: 'Ich willige ein, dass während lubbers-Veranstaltungen Videoaufnahmen von mir gemacht werden dürfen. Mir ist bewusst, dass ich auch auf den Aufnahmen anderer Teilnehmerinnen und Teilnehmer zu sehen sein kann.',
    info: PictureVideoInfo,
  },
  {
    name: 'Live Bild/Video Verwendung Ok',
    value: PrivacyRule.livePicVideoOkPurposeLearning,
    description: 'Ich bin damit einverstanden, dass das aufgezeichnete  Bild- und Videomaterial in der Veranstaltung zu Lernzwecken verwendet wird. ',
    info: PictureVideoInfo,
  },
  {
    name: 'Live Bild/Video Share an Kurs Ok',
    value: PrivacyRule.livePicVideoSharingToCourseOk,
    description: 'Ich bin damit einverstanden, dass das aufgezeichnete Bild- und Videomaterial mir selbst und Teilnehmerinnen und Teilnehmern der entsprechenden Veranstaltung (via Download-Link) zur Verfügung gestellt werden kann.',
    info: PictureVideoInfo,
  },
  {
    name: 'Live Bild/Video Nicht nach außen',
    value: PrivacyRule.livePicVideoDontDistribute,
    description: 'Ich sichere zu, dass ich Bild- und Videomaterial, welches mir bereitgestellt worden ist vertraulich verwende, nur zu privaten Lernzwecken verwende und nicht an Dritte weitergebe.',
    info: PictureVideoInfo,
  },
  {
    name: 'Kurs Freiwillig',
    value: PrivacyRule.livePartipantVoluntary,
    description: 'Ich nehme freiwillig an lubbers-Veranstaltungen teil und unterliege keinerlei äußeren Zwängen.',
    info: `<h2>Freiwilligkeit</h2>
<p>
Wir wollen mit unseren Veranstaltungen Wirkung erzeugen. Wir wollen die Möglichkeit bieten, sich als Persönlichkeit weiterzuentwickeln. Aus diesem Grund sind die meisten unserer Veranstaltungen nach dem Motto „Lernen durch Erfahren“ aufgebaut. In Einzel- und Gruppencoachings gehen wir in die Tiefe. Das bedeutet, dass es für dich und die Gruppe auf verschiedenen Ebenen herausfordernd werden kann. Wir bieten dir einen wertschätzenden, vertrauensvollen und geschützten Lernraum. Du hast zu jedem Zeitpunkt die Gelegenheit, diesen Lernraum zu verlassen.</p>`
  },
]
type UserDataCategoryType = {
  name: string,
  data: {
    name: string,
    key: string,
    options?: {value: string, name: string}[],
    sanitize?: (val: string) => string
  }[]
}
export const UserDataList: UserDataCategoryType[] = [
  {
    name: 'Basisdaten',
    data: [
      {name: 'Anrede', key: 'formOfAddress', options: [
        {value: '', name: ''},
        {value: 'Herr', name: 'Herr'},
        {value: 'Frau', name: 'Frau'},
        {value: 'Divers', name: 'Divers'},
      ]},
      {name: 'Vorname', key: 'firstname'},
      {name: 'Name', key: 'surname'},
      {name: 'Titel', key: 'title'},
      {name: 'Geburtstag', key: 'dateofbirth', sanitize: (val: string) => {
        return SanitizeDate(val)
      }},
    ]
  },
  {
    name: 'Geschäftliche Kontaktdaten',
    data: [
      {name: 'Firmenname', key: 'company'},
      {name: 'Position', key: 'business_position'},
      {name: 'Abteilung', key: 'business_department'},
      {name: 'Straße', key: 'business_street'},
      {name: 'PLZ', key: 'business_postcode'},
      {name: 'Ort', key: 'business_city'},
      {name: 'Land', key: 'business_country'},
      {name: 'Telefon', key: 'business_telefon'},
      {name: 'Mobil', key: 'business_mobile'},
      {name: 'E-Mail', key: 'business_email'},
    ]
  },
  {
    name: 'Private Kontaktdaten',
    data: [
      {name: 'Straße', key: 'private_street'},
      {name: 'PLZ', key: 'private_postcode'},
      {name: 'Ort', key: 'private_city'},
      {name: 'Land', key: 'private_country'},
      {name: 'Telefon', key: 'private_telefon'},
      {name: 'Mobil', key: 'private_mobile'},
      {name: 'E-Mail', key: 'private_email'},
    ]
  },
]

export default class UserDTO {
  private apiPrefix = config.apiPrefix
  public id: number = -1
  public name: string = ''
  public email: string = ''
  public tags: TagDTO[] = []
  public tagIds: number[] = []
  public bookIds: number[] = []
  private aData: IAData = {} as IAData
  public roles: string[] = []
  private changeCb: () => void = () => {}
  private cartBooks: number[] = []

  private loadingMyInfo: boolean = false
  private loadingMyInfoCBL: ((i: IUser) => void)[] = []
  private contactRules: number = 0
  private props: UserProp[] = []
  private sessionHash: string = ''
  public headOfUnit?: number
  public unitIds: number[] = []
  public units: UnitDTO[] = []
  public files: FileDTO[] = []
  public rights = new UserRightsDTO()

  constructor(user?: IUser, aData?: IAData) {
    if (user) {
      this.useData(user)
      if (aData) {
        this.aData = aData
        if (aData.units && user.unitIds) {
          this.units = user.unitIds.map(i => new UnitDTO((aData.units || []).find(u => u.id === i) as IUnit))
        }
        if (aData.cb) {
          this.changeCb = aData.cb
        }
      }
    }

  }

  public bookInCart(bookId: number): boolean {
    return this.cartBooks.indexOf(bookId) > -1
  }

  useData(user?: IUser) {
    if (user) {
      this.id = user.id || -1
      this.name = user.name || ''
      this.headOfUnit = user.headOfUnit
      this.email = user.email || ''
      this.roles = user.roles || []
      this.bookIds = user.bookIds || []
      this.unitIds = user.unitIds || []
      this.cartBooks = user.cartBooks || []
      if (user.sessionHash) {
        this.sessionHash = user.sessionHash
      }
      if (user.tags) {
        this.tags = user.tags.map((tag: ITag) => new TagDTO(tag))
      }
      this.tagIds = user.tagIds || []
      this.contactRules = user.contactRules || 0
      this.props = user.props || []
      if (user.files && user.files.length > 0) {
        this.files = user.files.map(f => new FileDTO(f))
      }
      this.rights = new UserRightsDTO(user.rights)
    }
    this.changeCb()
  }

  public setCartBooks (bookIds: number[]) {
    this.cartBooks = bookIds
  }

  public async myInfo() {
    // This could be used directly in Getter - if globally needed:
    return new Promise <IUser> ((resolve, _reject) => {
      if (this.loadingMyInfo) {
        this.loadingMyInfoCBL.push((r: IUser) => {
          resolve(r)
        })
      } else {
        this.loadingMyInfo = true
        Getter('user/info').then(r => {
          this.loadingMyInfoCBL.forEach(l => l(r))
          this.loadingMyInfoCBL = []
          this.loadingMyInfo = false
          resolve(r)
        })
      }
    })
    /*
    const r = await Getter('user/0')
    this.id = r.id
    this.name = r.name
    this.email = r.email
    return r
    */
  }

  /*
  public contactOk(kind: PrivacyRule): boolean {
    if (this.contactRules > 0) {
      return true
    }
    return false
  }
  */

  // True, if every rule v is matches
  // False, if one or more v rules are missmatched
  public contactOkBitwise(v: number): boolean {
    const c = this.contactRules
    const result = (v & c) === v
    return result
  }

  public async setContactOk(kind: PrivacyRule, val: boolean) {
    // const newVal = (val) ? 7 : 0
    let newValue = 0
    if (val) {
      newValue = this.contactRules | kind
    } else {
      newValue = this.contactRules ^ kind
    }
    let r
    if (this.id >= 0) {
      r = await Getter(`user/${this.id}/setContactOk/${newValue}`)
    } else if (this.id < 0 && this.sessionHash) {
      r = await Setter('user/setContactOkByToken', {
        token: this.sessionHash,
        contactOk: newValue
      }, {retryName: `${this.id},${this.sessionHash}`})
    } else {
      console.log('setContactOk ERROR', this)
      return
    }
    this.contactRules = r.contactRules || 0
  }

  public async info() {
    const r = await Getter(`user/${this.id}`)
    this.id = r.id
    this.name = r.name
    this.email = r.email
    this.contactRules = r.contactRules || 0
    return r
  }

  public async getFromServer() {
    const data = await this.myInfo()
    this.useData(data as IUser)
  }

  public async login(name: string, password: string) {
    // This code should work with new and old login method:
    const encodedLogin = window.btoa(`${name}:${password}`)
    const credentials = `Basic ${encodedLogin}`
    let headers = new Headers()
    headers.append('Authorization', credentials)
    headers.append('crossDomain', 'true')
    headers.append('withCredentials', 'true')
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        username: name,
        password: password
      })
    }
    let result = await new Promise <IUser> ((resolve, reject) => {
      fetch(`${this.apiPrefix}user/login`, requestOptions).then((response: any) => {
        const data = response.json()
        resolve(data)
      }).catch((err: any) => reject(err))
    })
    this.id = result.id || -1
    this.name = result.name || ''
    this.email = result.email || ''
    this.roles = result.roles || []
    this.contactRules = result.contactRules || 0
    console.log('LoginResult', result, this.id, !!result.error)
    return result
  }

  public async logout() {
    await Getter('user/logout')
    this.id = -1
    this.name = ''
    this.email = ''
    this.tags = []
    this.tagIds = []
    this.bookIds = []
    this.aData = {}
    this.roles = []
    this.contactRules = 0
  }

  public getTags(): TagDTO[] {
    if (this.tags.length > 0) {
      return this.tags
    }
    this.tags = this.tagIds.map((tagId: number) => {
      if (this.aData && this.aData.tags && this.aData.tags != undefined) {
        const iTag = this.aData.tags.find((uT) => uT.id === tagId)
        if (iTag) {
          return new TagDTO(iTag)
        }
      }
      return new TagDTO({id: tagId, name: 'NA'})
    })
    return this.tags
  }

  public addTags(tagsString: string) {
    const tags = tagsString.split(/[,; ]+/)
    const newTags: string[] = []
    tags.forEach((tag: string) => {
      const tagIsThere = this.tags.find((t) => t.name === tag)
      if (tagIsThere) { return }
      const newTag = new TagDTO({
        id: -1,
        name: tag
      })
      this.tags.push(newTag)
      newTags.push(tag)
    })
    Setter(`user/${this.id}/addTags`, {
      tags: newTags
    }, {retryName: `${this.id},${newTags.join(',')}`})
  }

  public deleteTag(id: number) {
    this.tagIds = this.tagIds.filter((ti) => ti != id)
    this.tags = this.tags.filter((t) => t.id != id)
    Getter(`user/${this.id}/deleteTag/${id}`)
  }

  public addBook(bookId: number) {
    const bookIsThere = this.bookIds.some((bid) => bid === bookId)
    if (!bookIsThere) {
      this.bookIds.push(bookId)
    }
    Getter(`user/${this.id}/addBook/${bookId}`)
  }

  public async removeBook(bookId: number) {
    this.bookIds = this.bookIds.filter((bid) => bid != bookId)
    await Getter(`user/${this.id}/removeBook/${bookId}`)
  }

  public async sendPasswordMail() {
    this.roles = this.roles.filter(r => r.search('UNREGISTERED') === -1)
    this.roles.push('PASSWORDRESET')
    await Getter(`user/${this.id}/sendPasswordMail`)
  }

  public async makeAdmin() {
    this.roles.push('ROLE_ADMIN')
    await Getter(`user/${this.id}/addRole/ADMIN`)
  }

  public async revokeAdmin() {
    this.roles = this.roles.filter(r => r !== 'ROLE_ADMIN')
    await Getter(`user/${this.id}/addRole/USER`)
  }

  public async makeRole(roleName: string) {
    this.roles = this.roles = ['ROLE_' + roleName]
    await Getter(`user/${this.id}/addRole/${roleName}`)
  }

  public async delete() {
    await Getter(`user/${this.id}/delete`)
  }

  public async selfdelete() {
    await Getter(`user/selfdelete`)
  }

  public hasProp(key1: string, key2: string): boolean {
    return this.props.some(p => p.key1 === key1 && p.key2 === key2)
  }

  public getPropVal1(key1: string, key2: string, fallback?: string): string {
    const p = this.props.find(p => p.key1 === key1 && p.key2 === key2)
    if (p) {
      return p.value1
    }
    return fallback || ''
  }

  public getUserPersonalDataList() {
    return UserDataList.map(c => {
      return {
        name: c.name,
        data: c.data.map(d => {
          const prop = this.props.find(p => p.key1 === 'personalData' && p.key2 === d.key)
          const value = (prop) ? prop.value1 : ''
          return {...d, ...{value: value}}
          /*
          return {
            name: d.name,
            key: d.key,
            value: value
          }
          */
        })
      }
    })
  }

  public async setUserDataPersonalDataItem(key: string, value: string) {
    return await Setter('user/addProp', {
      userId: this.id,
      key1: 'personalData',
      key2: key,
      value1: value,
      value2: ''
    }, {retryName: `${this.id},${'personalData'},${key}`})
  }

  public getPrivacySettings() {
    return PrivacyRuleList.map(i => {
      return {...i, ...{
        isSet: this.contactOkBitwise(i.value)
      }}
    })
  }

  public listUnits() {
    return UnitTypes.map(t => {
      return {
        key: t.key,
        name: t.name,
        list: this.units.filter(u => u.type === t.key)
      }
    })
  }

  public async addAvatarPicture(file: File) {
    let formData = new FormData()
    formData.append('FileName', file)
    const target = `${config.apiPrefix}user/file/upload`
    const rawResponse = await fetch(target, {
        method: 'POST',
        body: formData,
      }
    )
    const result = await rawResponse.json()
    if (result.status === 'okay') {
      this.files.push(new FileDTO(result.info))
      return
    }
    // Error:
    throw(result)
  }

  public getName(): string {
    return this.name || this.email
  }

  public getAvatar(): FileDTO | null {
    if (this.files.length > 0) {
      return this.files[this.files.length - 1]
    }
    return null
  }

  public avatarCount(): number {
    return this.files.length
  }

  public getAvatarUrl(): string {
    const avatar = this.getAvatar()
    if (avatar) {
      return avatar.getURL() || ''
    }
    return ''
  }

  public getAvatarCSS(): string {
    const avatar = this.getAvatar()
    if (avatar) {
      return avatar.getCSSBackground()
    }
    return ''
  }

  public removeAvatar(): void {
    this.files = []
    Setter('user/removeAvatar', {})
  }

  public removeLastAvatar(): void {
    this.files.pop()
    Setter('user/removeLastAvatar', {})
  }
}

export interface IUser {
  id?: number
  name?: string
  email?: string
  tags?: ITag[]
  tagIds?: number[]
  roles?: string[]
  bookIds?: number[]
  contactRules?: number
  headOfUnit?: number | undefined
  props?: UserProp[]
  sessionHash?: string
  unitIds?: number[]
  files?: IFile[]
  cartBooks?: number[]
  rights?: IUserRightsDTO
  error?: string
}

export interface IAData {
  tags?: ITag[],
  units?: IUnit[],
  cb?: () => void,
}

export class UsersDTO {
  users: UserDTO[] = []
  userIds: number[] = []
  constructor(users?: IUser[]) {
    if (users) {
      this.init(users)
    }

  }
  public init(users: IUser[], aData?: IAData) {
    this.users = users.filter(u => !(u.email && u.email.search(/dummyuser.*lubbers.de/) > -1)).reverse().map(u => new UserDTO(u, aData))
    this.userIds = this.users.map(u => u.id)
  }
  public async getFromServer() {
    const result = await Getter('users')
    this.init(result.users, {
      tags: result.tagList,
      units: result.units,
    })
  }
  public getById(id: number): UserDTO | undefined {
    return this.users.find(u => u.id === id)
  }
  public getByIds(ids: number[]): UserDTO[] {
    return this.users.filter(u => ids.indexOf(u.id) > -1)
  }
  public has(id: number): boolean {
    return this.userIds.some(u => u === id)
  }
  public isEmpty(): boolean {
    return this.users.length === 0
  }
  public list(needle?: string): UserDTO[] {
    const na = needle ? needle.split(' ') : []
    return this.users.filter(u => (!u.headOfUnit || u.headOfUnit === -1) && (!na || na.every((n: string) => u.email.search(n) > -1)))
  }
  public length(): number {
    return this.userIds.length
  }
  public remove(id: number) {
    this.users = this.users.filter(u => u.id !== id)
  }
  public removeByIds(ids: number[]) {
    ids.forEach(i => this.remove(i))
  }
  public filterUserIds(exIds: number[]): number[] {
    return exIds.filter(e => this.userIds.indexOf(e) > -1)
  }
  public addUser(u: UserDTO) {
    if (this.has(u.id)) { return }
    this.users.push(u)
    this.userIds.push(u.id)
  }
  public getSelectInfo(o: {
      users?: UserDTO[],
      marked?: number[],
    }): AvailableUser[] {
    const users = o.users || this.users
    const marked = o.marked || []
    return users.map(u => {
      return {
        id: u.id,
        unitIds: u.unitIds,
        marked: marked.indexOf(u.id) > -1
      }
    })
  }
}
