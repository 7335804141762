// type Scale = 'high' | 'middle' | 'low'
enum Scale {
  none,
  high,
  middle,
  low
}
const texts: {flex: Scale, effective: Scale, text: string[] }[] = [
  {
    flex: Scale.middle,
    effective: Scale.low,
    text: [
      "Du verfügst über einen großen Handlungsspielraum und kannst scheinbar dein Führungsverhalten stark variieren. Es kann allerdings sein, dass du entweder die Situation (dringlich und wichtig) und/oder die Person (kann sie das/will sie das) zuweilen anders einschätzen als es für ein erfolgreiches Handeln erforderlich wäre.",
      "Konkret scheinst du in Situationen, in denen Anweisungen, Kontrolle und konsequentes Handeln erforderlich wäre, eher darauf setzt, dass Menschen in Eigenverantwortung Lösungen finden."
    ]
  },
  {
    flex: Scale.middle,
    effective: Scale.middle,
    text: [
      'Kompliment: Du scheinst „Führung“ als eine Aufgabe verstanden zu haben, die sowohl Klarheit und Konfliktbereitschaft aber auch Partnerschaft und Freiheit zugleich beinhaltet.',
      'Im Wesentlichen schätzt du die in den von dir bearbeiteten Führungssituationen sowohl in der Betrachtung von "dringlich/wichtig" wie auch in der Einschätzung der jeweiligen Personen nach Kompetenz und emotionaler Reife (können/wollen) situationsgerecht ein.',
      'Um ein klareres Bild über dein Führungsverhalten im Bezug zu den in der von dir bearbeiteten Unterlage zu erhalten, empfehlen wir dir die Detailanalyse.'
    ]
  },
  {
    flex: Scale.high,
    effective: Scale.high,
    text: [
      "Kompliment: Du scheinst „Führung“ als eine Aufgabe verstanden zu haben, die sowohl Klarheit und Konfliktbereitschaft aber auch Partnerschaft und Freiheit zugleich beinhaltet.",
      "Im Wesentlichen schätzt du die in den von dir bearbeiteten Führungssituationen sowohl in der Betrachtung von „dringlich/wichtig“ wie auch in der Einschätzung der jeweiligen Personen nach Kompetenz und emotionaler Reife (können/wollen) situationsgerecht ein."
    ]
  },
  {
    flex: Scale.high,
    effective: Scale.low,
    text: [
      "Du verfügst über einen großen Handlungsspielraum. Es fällt dir aber scheinbar schwer entweder Situation (dringlich und wichtig) und/oder die Person (kann sie das/will sie das) so einschätzen als es für ein erfolgreiches Handeln erforderlich wäre."
    ]
  },
  {
    flex: Scale.high,
    effective: Scale.middle,
    text: [
      "Du verfügst über einen Handlungsspielraum, der dir helfen kann Führungsituationen angemessen einzuschätzen. Allerdings scheinst du aber dazu zu neigen Situation oder Person anders einzuschätzen als es für ein erfolgreiches Handeln erforderlich sein müsste. "
    ]
  }
]
export default class Text1Generator {
  text: string[]
  constructor(effectiveValue: number, flexibleValue: number) {
    const eff = this.calcEffScale(effectiveValue)
    const flex = this.calcFlexScale(flexibleValue)
    const result = texts.find((t) => t.flex === flex && t.effective === eff)
    if (result) {
      this.text = result.text
    } else {
      this.text = []
    }
  }

  calcEffScale(effectiveValue: number) {
    if (effectiveValue < 0) {
      return Scale.none
    }
    if (effectiveValue >= 70) {
      return Scale.high
    }
    if (effectiveValue <= 40) {
      return Scale.low
    }
    return Scale.middle
  }

  calcFlexScale(flexValue: number) {
    if (flexValue >= 70) {
      return Scale.high
    }
    if (flexValue <= 40) {
      return Scale.low
    }
    return Scale.middle
  }
}
