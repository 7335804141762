import { useEffect, useState } from "react"
import PageElementDTO from "../../../DTO/PageElementDTO"
import InputA from "../../InputA/InputA"
import './Tabs.scss'

enum Status {
  standard,
  loading,
}

export default function Tabs (props: {
  pageElement: PageElementDTO,
  parent: any,
  setClass: (c: string) => void,
}) {
  // const [rerender, setRerender] = useState(0)
  const [status, setStatus] = useState(Status.standard)
  const [activeTab, setActiveTab] = useState(0)
  const tabsRaw = props.pageElement.getPropVal1('tabs', 'names', 'Erster Tab')
  let tabs = tabsRaw.split(';')
  function makeTabCountClass() {
    let c = ''
    const count = tabs.length
    for (let i = 0; i < count; i++) {
      c += ' tab-count-' + i
    }
    return c
  }
  function setClass(activeTab: number) {
    const newClass = `tabs ${makeTabCountClass()} active-tab-${activeTab}`
    console.log('new Class', newClass)
    props.setClass(newClass)
  }
  function setTab(index: number) {
    setActiveTab(index)
    setClass(index)
  }
  useEffect(() => {
    setClass(activeTab)
  }, [])
  if (status === Status.loading) {
    return <div>Lade ...</div>
  }
  return <>
    { props.parent.isAdmin() > 0 &&
      <div className='adminToolBar w3-margin-top'>
        <label>Tab-Namen - durch ";" getrennt</label>
        <InputA
          value={tabsRaw}
          returnVal={async (v) => {
            setStatus(Status.loading)
            await props.pageElement.addProp({
              id: -1,
              key1: 'tabs',
              key2: 'names',
              value1: v,
              value2: '-',
            })
            setStatus(Status.standard)
          }}
        />
      </div>
    }
    <div className='tab-holder'>
      {
        tabs.map((t, index) => <div
          key={`${props.pageElement.id}-${index}-${t}`}
          onClick={() => {
            setTab(index)
          }}
          className={`single-tab ${index === activeTab ? 'single-tab-active' : ''}`}
        >
          {t}
        </div>)
      }
    </div>
  </>
}