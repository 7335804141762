import React from 'react'
import './Spinner.scss'
type Props = {
  hide?: boolean,
  mini?: boolean,
  sizeClass?: string,
  className?: string,
}
type State = {show: boolean}

class Spinner extends React.Component<Props, State> {
  showTimeout: any
  constructor(props: Props) {
    super(props)
    this.state = {
      show: false
    }
  }
  componentDidMount() {
    this.showTimeout = setTimeout(() => {
      this.setState({show: true})
    }, 100)
  }
  componentWillUnmount() {
    this.setState({show: false})
    clearTimeout(this.showTimeout)
  }
  render() {
    if (this.props.mini) {
      return <span
        className={`spinner-base ${this.props.className || ''} ${this.state.show ? 'show-spinner-base': ''}`}
      >
        <span
          className={`spinner-socket ${this.props.sizeClass || ''}`}
        ></span>
      </span>
    }
    return <>
      <div
        className={`spinner-base ${this.props.className || ''} ${this.state.show ? 'show-spinner-base': ''} ${this.props.sizeClass || ''}`}
      >
        <div
          className='spinner-socket'
          style={{top: (window.innerHeight / 2 - 27) + 'px'}}
        ></div>
      </div>
    </>
  }
}

export default Spinner
