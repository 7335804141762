import { useEffect, useState } from "react"
import { IBook } from "../../DTO/BookDTO"
import { Getter } from "../../services/ComService"
import mainservice from "../../services/MainService"
import './BookSelector.scss'

let BookSelectorBooks: IBook[] = []

export default function BookSelector(props: {
  currentBookId: number,
  selectBook: (bookId: number, name?: string) => void,
}) {
  const [rerender, setRerender] = useState(0)
  const [filter, setFilter] = useState('')
  const currentBook = BookSelectorBooks.find(b => b.id === props.currentBookId)
  const currentName = currentBook ? `${currentBook.id} - ${currentBook.name}` : 'Kein Buch ausgewählt'
  useEffect(() => {
    Getter('books', true).then(data => {
      BookSelectorBooks = data.books
      setRerender(rerender + 1)
    })
  }, [])
  function filterClass(b: IBook) {
    if (filter === '') { return '' }
    const parts = filter.split(' ')
    const found = parts.every(p => {
      if (b.name.search(new RegExp(p, 'gi')) > -1 || (b.id + '').search(p) > -1) { return true }
      return false
    })
    return found ? '' : 'hide'
  }
  return <div className='admin-bookselector w3-dropdown-hover'>
    <button
      className='w3-button w3-white'
    >
      🔻{ currentName }
    </button>
    <div className='w3-dropdown-content w3-bar-block w3-border'>
      
      <div className='w3-bar-item w3-row'>
        <input
          className='w3-col s10'
          onChange={(e) => {
            const newValue = e.target.value
            setFilter(newValue)
          }}
        ></input>
        <button
          className='w3-col s2'
          onClick={() => {
            setFilter('')
          }}
        >
          &times;
        </button>
      </div>

      {
        BookSelectorBooks.map(b => <div
          key={`book-selector-bid-${b.id}`}
          onClick={() => {
            props.selectBook(b.id, b.name)
          }}
          className={`book-ddi w3-border-bottom w3-bar-item w3-button pointer ${filterClass(b)}`}
        >
          {b.id} - {b.name}
        </div>)
      }
    </div>
  </div>
}