import { UserData } from "../DTO/WidgetDTO"

type Value = {
  key1: string,
  key2: string,
  value1: string,
  value2: string,
}
export default class ValueHolder {
  values: Value[] = []


  async addValue(value: Value): Promise<UserData> {
    // console.log('valueholder add value', value)
    this.values = this.values.filter(v => v.key1 !== value.key1 && v.key2 !== value.key2)
    this.values.push(value)
    return new Promise((resolve) => {
      resolve({
        id: -1,
        key1: value.key1,
        key2: value.key2,
        value1: value.value1,
        value2: value.value2
      })
    })
  }

  getValue1(key1: string, key2: string) {
    const value = this.values.find(v => v.key1 === key1 && v.key2 === key2)?.value1 || ''
    // console.log('valueHolder give value', value, this.values)
    return value
  }

  reset() {
    this.values = []
  }
}
