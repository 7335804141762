import EmojiConvertor from 'emoji-js'

const C2P = new EmojiConvertor()
C2P.colons_mode = true

const C2U = new EmojiConvertor()
C2U.replace_mode = 'unified'

export function Emoji2PlainText(raw: string): string {
  if (!raw) { return '' }
  return C2P.replace_unified(raw)
}

export function Emoji2Unicode(raw: string): string {
  if (!raw) { return '' }
  return C2U.replace_colons(raw)
}
