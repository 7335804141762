import config from '../config.json'
import { Getter, Setter } from '../services/ComService'
import StaticSiteParagraphPropDTO, { IStaticSiteParagraphProp } from './StaticSiteParagraphPropDTO'

export default class StaticSiteParagraphDTO {
  id: number
  sortNumber: number
  props: StaticSiteParagraphPropDTO[]

  constructor(data: IStaticSiteParagraph) {
    this.id = data.id
    this.sortNumber = data.sortNumber
    this.props = data.props.map(p => new StaticSiteParagraphPropDTO(p))
  }

  public getProp(key1: string, key2: string) {
    return this.props.find(p => p.key1 === key1 && p.key2 === key2)
  }

  public getPropVal1(key1: string, key2: string): string {
    const prop = this.getProp(key1, key2)
    if (prop) {
      return prop.value1
    }
    return ''
  }
  public async setPropVal1(key1: string, key2: string, value: string) {
    await this.setProp(key1, key2, value, '-')
  }

  newToServer() {}

  async remove() {
    await Setter('staticsite/paragraph/remove', {
      id: this.id
    }, {retryName: `${this.id}`})
  }

  async setPosition(newPosition: number) {
    await Setter('staticsite/paragraph/position', {
      id: this.id,
      newPosition: newPosition
    }, {retryName: `${this.id}`})
  }

  async setProp(key1: string, key2: string, value1: string, value2: string) {
    await Setter('staticsite/paragraph/prop/set', {
      id: this.id,
      key1: key1,
      key2: key2,
      value1: value1,
      value2: value2,
    }, {retryName: `${this.id},${key1},${key2}`})
  }

  async setPicture(key1: string, key2: string, id: number) {
    console.log('setPicture', id)
    await Setter('staticsite/paragraph/file/set', {
      id: this.id,
      key1: key1,
      key2: key2,
      fileId: id
    }, {retryName: `${this.id},${key1},${key2}`})
  }

}

export type IStaticSiteParagraph = {
  id: number
  sortNumber: number
  props: IStaticSiteParagraphProp[]
}
