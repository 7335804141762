import React from 'react'
import FileDTO from '../../DTO/FileDTO'
import TagDTO, {ITag} from '../../DTO/TagDTO'
import InputA from '../InputA/InputA'
import { Trash, Edit, Tag, X, Repeat } from 'react-feather'

enum Mode {
  normal,
  edit,
  addTag,
  deleted,
  loading,
}

export enum BroadCastKey {
  scroll,
  activateItem
}

export type ReceiveBroadcastFkt = (key: BroadCastKey, value: any) => void

type Props = {
  file: FileDTO,
  active: boolean,
  onClick: () => void,
  registerToBroadcast: (key: string, cb: ReceiveBroadcastFkt) => void,
  unregisterFromBroadcast: (key: string) => void
}

type State = {
  additionalClass: string,
  mode: Mode,
}

export default class FileListItem extends React.Component<Props, State> {
  divElement: any
  constructor(props: Props) {
    super(props)
    this.state = {
      additionalClass: (props.active) ? 'active' : '',
      mode: Mode.normal
    }
  }

  componentDidMount() {
    const props = this.props
    props.registerToBroadcast(`file-${props.file.id}`, (key: BroadCastKey, value: any) => {
      switch(key) {
        case BroadCastKey.activateItem:
          const isActive = this.state.additionalClass.search('active') > -1
          if (value === this.props.file.id && !isActive) {
            this.setState({additionalClass: 'active'})
          } else if(value != this.props.file.id && isActive) {
            this.setState({additionalClass: ''})
          }
          break
      }
    })
  }

  componentWillUnmount() {
    const props = this.props
    props.unregisterFromBroadcast(`file-${props.file.id}`)
  }

  render() {
    const f = this.props.file
    if (this.state.mode === Mode.deleted) {
      return null
    }
    return <div
      className={`file-item w3-border w3-row ${this.state.additionalClass}`}
      onClick={() => {this.props.onClick()}}
      ref={ (divElement) => { this.divElement = divElement }}
    >
      <div
        className='file-preview w3-col s2'
      >
        <img
          src={f.getThumbURL()}
          loading={`lazy`}
          alt={'loading'}
        />
        <Repeat
          className={`regenerateThumb`}
          onClick={async () => {
            // const oldMode = this.state.mode
            // this.setState({mode: Mode.loading})
            await f.regenerateThumb()
            // this.setState({mode: oldMode})
            this.forceUpdate()
          }}
        />
      </div>
      <div className='rightPart w3-col s10'>

        <div className='w3-container'>
          <div className='displayName w3-bar-item w3-button'>
            {
              this.state.mode != Mode.edit &&
              f.displayName
            }
            {
              this.state.mode === Mode.edit &&
              <InputA
                autofocus={true}
                returnVal={(rv: string) => {
                  f.setDisplayName(rv).then(() => {
                    this.setState({
                      mode: Mode.normal
                    })
                  })
                }}
                value={f.displayName}
              />
            }
          </div>
          <div className='w3-bar-item w3-right'>
            {
              this.props.file.books.length === 0 &&
              this.props.file.collectibles.length === 0 &&
              <Trash
              onClick={(e) => {
                e.stopPropagation()
                if (!window.confirm('Bild wirklich löschen?')) { return }
                this.setState({mode: Mode.deleted})
                this.props.file.trash()
              }}
              />
            }
            <Edit
              onClick={
                () => {
                  this.setState({
                    mode: Mode.edit
                  })
                }
              }
            />
            <Tag
              onClick={
                () => {
                  this.setState({
                    mode: Mode.addTag
                  })
                }
              }
            />
          </div>
          {
            this.props.file.printSize() &&
            <div className='fileSize'>
              {this.props.file.toBig() && <span className="w3-badge w3-red">&bull;</span>} {this.props.file.printSize()}
            </div>
          }
          <div className='includedInBooks'>
            {
              this.props.file.books.length > 0 &&
              <span>Books</span>
            }
            {
              this.props.file.books.map((b, _i) =>
                <div
                  key={b.id}
                  className='includedInBook'
                  title={`Book ID ${b.id}`}
                >
                  @ {b.name || 'no name'}
                </div>
              )
            }
            {
              this.props.file.collectibles.length > 0 &&
              <span>Sammelstücke</span>
            }
            {
              this.props.file.collectibles.map((b, _i) =>
                <div
                  key={b.id}
                  className='includedInBook'
                  title={`Sammelstück ID ${b.id}`}
                >
                  @ {b.name || 'no name'}
                </div>
              )
            }
            {
              this.props.file.staticSites.length > 0 &&
              <span>Statische Seiten</span>
            }
            {
              this.props.file.staticSites.map((b, _i) =>
                <div
                  key={'staticSite-' + b.id}
                  className='includedInStaticSite'
                  title={`StaticSite ID ${b.id}`}
                >
                  @ {b.url || 'no name'}
                </div>
              )
            }
          </div>
        </div>

        <div className={`fileTags w3-container`}>
        {
          this.state.mode === Mode.addTag &&
          <InputA
          autofocus={true}
          returnVal={(rv: string) => {
            f.setTag(rv).then(() => {
              this.setState({
                mode: Mode.normal
              })
            })
          }}
          value=''
          />
        }
        {
          this.state.mode != Mode.addTag &&
          this.props.file.tags.map((t: TagDTO) => <span
            key={t.id}
            className='fileTag'
          >
          {t.name}
          <X
            className='fileTagDel'
            onClick={() => {
              this.props.file.removeTag(t.name).then(() => {
                this.forceUpdate()
              })
            }}
          />
          </span>)
        }
        </div>

      </div>
    </div>
  }
}
