import { useEffect, useState } from 'react'
import './Modal.scss'
import { X } from 'react-feather'
import mainservice from '../../services/MainService'
export default function Modal() {
  const [modalContent, setModalContent] = useState(null)
  useEffect(() => {
    mainservice.registerToBroadcast('globalModal', (key, value) => {
      if (key === 'openmodal') {
        setModalContent(value.content)
      }
    })
  }, [])
  if (!modalContent) {
    return null
  }
  return <div className='Modal'>
    <X
      className='closeModal'
      onClick={() => {
        setModalContent(null)
      }}
    ></X>
    <div>
      {modalContent}
    </div>
  </div>
}
