export function LightOrDarkColor(inColor: string): string {
  // Variables for red, green, blue values
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (inColor.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    let color = inColor.match(
      /^rgba? ((\d+),\s* (\d+),\s* (\d+) (?:,\s* (\d+ (?:.\d+)?))?)$/
    );
    if (color) {
      r = color[1];
      g = color[2];
      b = color[3];
    }
  } else {
    // If hex --> Convert it to RGB: [^1^][7]
    let color = +("0x" + inColor.slice(1).replace(inColor.length < 5 ? /./g : "", "$&$&"));
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  if (r && g && b) {
    // HSP (Highly Sensitive Poo) equation from [^2^][8]
    hsp = Math.sqrt(0.299 * (Number(r) * Number(r)) + 0.587 * (Number(g) * Number(g)) + 0.114 * (Number(b) * Number(b)));
  
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return "light";
    } else {
      return "dark";
    }
  }
  return 'dark'
}

function lc(c: any) {
  const k = c as number
  const diff = 255 - k
  return Math.floor(Math.min(k + (diff * 0.5), 255))
}

export function LightenColor(inColor: string): string {
  // Variables for red, green, blue values
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (inColor.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    let color = inColor.match(
      /^rgba? ((\d+),\s* (\d+),\s* (\d+) (?:,\s* (\d+ (?:.\d+)?))?)$/
    );
    if (color) {
      r = color[1];
      g = color[2];
      b = color[3];
    }
  } else {
    // If hex --> Convert it to RGB: [^1^][7]
    let color = +("0x" + inColor.slice(1).replace(inColor.length < 5 ? /./g : "", "$&$&"));
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  return `rgb(${lc(r)}, ${lc(g)}, ${lc(b)})`
}
