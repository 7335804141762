import React from 'react'
import mainservice from '../../services/MainService'
import './FileList.scss'
import FilesDTO from '../../DTO/FilesDTO'
import FileDTO from '../../DTO/FileDTO'
import FileUploader from './FileUploader'
import InputA from '../InputA/InputA'
import FileListItem, { ReceiveBroadcastFkt, BroadCastKey } from './FileListItem'
import { X } from 'react-feather'

enum DisplayState {
  list,
  filepreview,
  upload,
  replace
}

export enum FileListMode {
  manage,
  selectPicture
}

type Props = {
  parent?: any,
  mode?: FileListMode,
  usePicture?: (id: number) => void
  broadcastName?: string
}

type State = {
  files: FileDTO[],
  displayState: DisplayState,
  previewFileId: number,
  mode: FileListMode,
  filterNeedle: string
}

const sortById = (a: {id: number}, b: {id: number}): number => {
  return b.id - a.id
}

export default class FileList extends React.Component<Props, State> {
  files: FilesDTO
  broadcastList = new Map()
  constructor(props: Props) {
    super(props)
    const previewState = this.initPreviewFileId()
    this.state = {
      files: [],
      displayState: previewState.displayState,
      previewFileId: previewState.id,
      mode: props.mode || FileListMode.manage,
      filterNeedle: ''
    }
    this.files = new FilesDTO()

  }

  componentDidMount() {
    this.files.get().then((files: FileDTO[]) => this.setState({
      files: files.reverse()
    }))
  }

  initPreviewFileId(): {displayState: DisplayState, id: number} {
    if (this.props.mode === FileListMode.selectPicture &&
      mainservice.interactionThingRel &&
      mainservice.interactionThingRel.instantManageMedia
    ) {
      const id: number = mainservice.interactionThingRel.instantManageMedia({
        mode: 'getId'
      })
      return {id: id, displayState: DisplayState.filepreview}
    }
    return {id: -1, displayState: DisplayState.list}
  }

  preview(fId: number) {
    this.setState({
      previewFileId: fId,
      displayState: DisplayState.filepreview
    })
    this.broadcast(BroadCastKey.activateItem, fId)
  }

  showUpload() {
    this.setState({
      displayState: DisplayState.upload
    })
  }

  usePicture() {
    const fileId = this.state.previewFileId
    if (this.props.usePicture) {
      this.props.usePicture(fileId)
      return
    }
    if (mainservice.interactionThingRel &&
      mainservice.interactionThingRel.manageMedia
    ) {
      mainservice.interactionThingRel.manageMedia({
        mode: 'use',
        id: fileId
      }).then(() => {
        if (this.props.parent && this.props.parent.closeFileList) {
          this.props.parent.closeFileList('reload')
        }
      })
    }
    if (this.props.broadcastName) {
      mainservice.broadcast('fileListSelect', {
        key: this.props.broadcastName,
        fileId: fileId,
        fileName: this.files.getSingle(fileId).fileName
      })
    }
  }

  unsetPicture() {
    if (this.props.usePicture) {
      this.props.usePicture(this.state.previewFileId)
      return
    }
    if (mainservice.interactionThingRel &&
      mainservice.interactionThingRel.manageMedia
    ) {
      mainservice.interactionThingRel.manageMedia({
        mode: 'remove'}).then(() => {
        if (this.props.parent && this.props.parent.closeFileList) {
          this.props.parent.closeFileList('reload')
        }
      })
    }
  }

  close() {
    if (this.props.parent && this.props.parent.closeFileList) {
      this.props.parent.closeFileList()
    }
  }

  refresh(o: {id: number, status?: string}) {
    console.log('refresh o', o)
    if (o.status === 'failed') {
      alert('Fehler beim hochladen - Datei zu groß?')
    }
    this.files.get({fresh: true}).then((files: FileDTO[]) => this.setState({
      files: files,
      previewFileId: o.id || this.fileFilter()[0].id,
      displayState: DisplayState.filepreview
    }))
    this.broadcast(BroadCastKey.activateItem, o.id)
  }

  fileFilter() {
    const needles = this.state.filterNeedle
    console.log('allfiles', this.state.files)
    if (needles) {
      return this.state.files.filter((f) => {
        return needles.split(' ').some((n) => {
          const needle = new RegExp(n, 'i')
          if (
            f.fileName.search(needle) > -1 ||
            f.displayName.search(needle) > -1 ||
            f.tags.some((t) => t.name.search(needle) > -1) ||
            f.books.some((t) => t.name.search(needle) > -1) ||
            f.collectibles.some((t) => t.name.search(needle) > -1)
          ) {
            return true
          }
        })
      }).sort(sortById)
    }
    return this.state.files.sort(sortById)
  }

  broadcast(key: BroadCastKey, value: any) {
    this.broadcastList.forEach((cb: ReceiveBroadcastFkt) => {
      cb(key, value)
    })
  }

  render() {
    return <div className={`FileList FileListState-${this.state.displayState}`}>
      {
        this.state.mode != FileListMode.selectPicture &&
        <button
          className='secondary-button'
          onClick={() => {
            mainservice.navTo([['view', 'adminlogin']])
          }}
        >&larr; Dashboard</button>
      }
      <button className="addButton w3-blue w3-border-0 w3-margin w3-round" onClick={() => this.showUpload()}>+</button>
      {
        this.state.mode === FileListMode.selectPicture &&
        <>
          <button className="addButton w3-green w3-border-0 w3-margin w3-round" onClick={() => this.usePicture()}>Bild nutzen</button>
          <button className="closeButton w3-yellow w3-border-0 w3-margin w3-round" onClick={() => this.close()}>Abbrechen</button>
          <button className="removeButton w3-orange w3-border-0 w3-margin w3-round" onClick={() => this.unsetPicture()}>Entkoppeln</button>
        </>
      }

      <div className='fileFilter'>
        Filter Dateien:
        <InputA
          value={this.state.filterNeedle}
          returnVal={(newValue: string) => {
            this.setState({filterNeedle: newValue})
          }}
        />
        {
          this.state.filterNeedle &&
          <button onClick={() => {
            this.setState({filterNeedle: ''})
          }}><X /></button>
        }
      </div>

      <div
        className="listContainer"
        onScroll={(e: any) => {
          this.broadcast(BroadCastKey.scroll, [e.target.scrollTop || 0, e.target.offsetHeight])
        }}
      >
        {
          this.fileFilter().map((f) => {
            return <FileListItem
              key={f.id}
              onClick={() => {this.preview(f.id)}}
              file={f}
              active={(f.id === this.state.previewFileId)}
              registerToBroadcast={(key: string, cb: ReceiveBroadcastFkt) => {
                this.broadcastList.set(key, cb)
                cb(BroadCastKey.scroll, [0, window.outerHeight])
              }}
              unregisterFromBroadcast={(key: string) => {
                this.broadcastList.delete(key)
              }}
            />
          })
        }
      </div>
      {
        this.state.displayState === DisplayState.upload &&
        <div className="previewContainer w3-padding">
          Bild Hochladen:
          <FileUploader uploadCallBack={(o: {id: number, status: string}) => this.refresh(o)} />
        </div>
      }
      {
        this.state.displayState === DisplayState.replace &&
        <div className="previewContainer w3-padding">
          Bild ersetzen:
          <FileUploader
            replaceFileId={this.state.previewFileId}
            uploadCallBack={(o: {id: number, status: string}) => this.refresh(o)}
            />
        </div>
      }
      {
        this.state.displayState === DisplayState.filepreview &&
        <div className="previewContainer" style={{backgroundImage: this.files.getSingle(this.state.previewFileId).getCSSBackground()}}>
          <div className="description">
            {
              this.files.getSingle(this.state.previewFileId).getInfo().map((i: {key: string, value: string}, index: number) => {
                return <div key={index}>
                  {i.key}: {i.value}
                </div>
              })
            }
            <div className='includedInBooks'>
            {
              this.files.getSingle(this.state.previewFileId).books.length > 0 &&
              <span>Books</span>
            }
            {
              this.files.getSingle(this.state.previewFileId).books.map((b, _i) =>
                <div
                  key={b.id}
                  className='includedInBook'
                  title={`Book ID ${b.id}`}
                >
                  @ {b.name || 'no name'}
                </div>
              )
            }
            {
              this.files.getSingle(this.state.previewFileId).collectibles.length > 0 &&
              <span>Sammelstücke</span>
            }
            {
              this.files.getSingle(this.state.previewFileId).collectibles.map((b, _i) =>
                <div
                  key={b.id}
                  className='includedInBook'
                  title={`Sammelstück ID ${b.id}`}
                >
                  @ {b.name || 'no name'}
                </div>
              )
            }
            {
              this.files.getSingle(this.state.previewFileId).printSize() &&
              <div className='fileSize'>
                {this.files.getSingle(this.state.previewFileId).toBig() && <span className="w3-badge w3-red">&bull;</span>} {this.files.getSingle(this.state.previewFileId).printSize()}
              </div>
            }
            <button
              onClick={() => {
                this.setState({displayState: DisplayState.replace})
              }}
            >Bild ersetzen</button>
            <a
              href={`${this.files.getSingle(this.state.previewFileId).getURL()}`}
              target='_blank'
            >
              <button>Bild-direkt-Link</button>
            </a>
            </div>
          </div>
        </div>
      }
    </div>
  }
}
