import { Briefcase, Clipboard, Calendar, Users, Map, Tag, Edit2, Zap, LogOut, Trash } from "react-feather"
import mainservice from "../../services/MainService"
import './Menu.scss'
export enum MItem {
  generic,
  planer,
  designer,
  tags,
  customers,
  persons,
  locations,
  buildingBlocks,
  designDetails,
  planingDetails,
  leads,
  trash,
}

export type TItem = {
  name: string,
  navTo: [string, string | number][],
  icon?: React.ReactElement,
  key?: MItem,
  className?: string,
  onClick?: () => void,
}

const Items: TItem[] = [
  /*
  {
    name: '&larr; Dashboard',
    navTo: [['view', 'login']],
    key: MItem.generic,
  },
  */
  {
    name: 'Leads',
    navTo: [['view', 'spVeranstaltungsLeads']],
    icon: <Zap />,
    key: MItem.leads,
    className: 'mi-event',
  },
  {
    name: 'Veranstaltungen',
    navTo: [['view', 'spVeranstaltungsPlaner']],
    icon: <Calendar />,
    key: MItem.planer,
    className: 'mi-event',
  },
  {
    name: 'Unternehmen',
    navTo: [['view', 'spCustomers']],
    icon: <Briefcase />,
    key: MItem.customers,
  },
  {
    name: 'Veranstaltungsorte',
    navTo: [['view', 'spLocations']],
    icon: <Map />,
    key: MItem.locations,
  },
  {
    name: 'Personen',
    navTo: [['view', 'spPersons']],
    icon: <Users />,
    key: MItem.persons,
  },
  {
    name: 'Veranstaltungsdesigns',
    navTo: [['view', 'spVeranstaltungsDesigns']],
    icon: <Edit2 />,
    key: MItem.designer,
    className: 'mi-design',
  },
  {
    name: 'Bausteine',
    navTo: [['view', 'spBausteine']],
    icon: <Clipboard />,
    key: MItem.buildingBlocks,
    className: 'mi-blocks',
  },
  {
    name: 'SPACER',
    navTo: [],
  },
  {
    name: 'Tagverwaltung',
    navTo: [['view', 'spTagverwaltung']],
    icon: <Tag />,
    key: MItem.tags,
  },
  {
    name: 'SPACER',
    navTo: [],
  },
  {
    name: 'Papierkorb',
    navTo: [['view', 'spPapierkorb']],
    icon: <Trash />,
    key: MItem.trash,
  },
  {
    name: 'Logout',
    navTo: [],
    onClick: async () => {
      mainservice.quitAdmin()
      await mainservice.loginService.logout()
      mainservice.navTo([['view', 'login']])
    },
    icon: <LogOut />,
  },
]

export default function Menu(props: {
  MItem: MItem,
  menuItems?: TItem[],
  navParams?: [string, string],
  teaser?: string,
}) {
  const items = props.menuItems || Items
  return <>
    {props.teaser && <div className='left-menu-header'>{props.teaser}</div>}
    {
      items.filter(i => {
        // Tag Manager für unberechtigte Filtern
        if (i.key === MItem.tags) {
          return mainservice.loginService.user.rights.canSeeTagManager()
        }
        return true
      }).map((i, index) => {
        if (i.name === 'SPACER') {
          return <div
            key={`SPMenu-${index}`}
            className='menu-blank'
          ></div>
        }
        return <button
          key={`SPMenu-${index}`}
          className={`PlaningMenuItem ${i.key === props.MItem ? 'PlaningMenuItem-Active' : ''} ${i.className || ''}`}
          onClick={() => {
            if (i.onClick) {
              i.onClick()
              return
            }
            let navTo = i.navTo
            if (props.navParams) {
              navTo.push(props.navParams)
            }
            mainservice.navTo(navTo)
          }}
        >
          <span className='icon' title={i.name}>{i.icon ? i.icon : null}</span>
          <span className='name' dangerouslySetInnerHTML={{__html: i.name}}></span>
        </button>
      })
    }
  </>
}
