import { Setter } from "../../services/ComService";
import { ObjectKind } from "../models/objectKinds";
import { PublishKind } from "../models/publishKinds";
import ModuleDTO from "./ModuleDTO";
import { ITrainingItem, TrainingItemDTO } from "./TrainingItemDTO";

export class ModuleTrainingItemDTO extends TrainingItemDTO {
  constructor(o: IModuleTrainingItem) {
    super(o)
    this.objectKind = ObjectKind.moduletrainingItem
  }

  public async addParent(item: ModuleDTO) {
    const url = 'spt/setChild'
    const parentId = item.id
    this.parentId = parentId
    const data = await Setter(url, {
      id: parentId,
      childId: this.id
    })
    this.parentId = data.item.id
  }

  public async addUp(up: TrainingItemDTO) {
    const url = 'spt/setUp'
    let upId = up.id
    if (upId === -1) {
      const newUp = new TrainingItemDTO({...up, ...{
        publishKind: PublishKind.isSeminar
      }})
      await newUp.saveToServer()
      upId = newUp.id
    }
    this.upId = parseInt(up.id + '', 10)
    const id = this.id
    const data = await Setter(url, {
      id: id,
      jd: upId
    })
    this.up.push({
      id: this.upId,
      name: up.name
    })
  }
}

export interface IModuleTrainingItem extends ITrainingItem {

}