import { useEffect, useState } from "react"
import { Status } from "../day/dayView"
import { GenericDTO, TextArraySummaryItem } from "../../DTO/GenericDTO"
import '../TextAreaArray.scss'
import { Printer } from "react-feather"
import mainservice from "../../../services/MainService"
import { TextAreaArray } from "./TextAreaArray"
import { CountHolder } from "../CountHolder"

export function TextAreaArraySummary(props: {
  item?: GenericDTO,
  standalone?: boolean,
  appointment: string,
}) {
  const [status, setStatus] = useState(Status.loading)
  const [show, setShow] = useState(-1)
  const [item, setItem] = useState(props.item || new GenericDTO({id: mainservice.nav.getVal('id')}))
  const [appointment, setAppointment] = useState(props.appointment || '')
  const [printCountHolder] = useState(new CountHolder({
    item: item,
    key1: 'textCount',
    key2: 'print'
  }))
  const [packCountHolder] = useState(new CountHolder({
    item: item,
    key1: 'textCount',
    key2: 'pack'
  }))
  const copies = mainservice.nav.getVal('copies') as number
  useEffect(() => {
    // Init
    if (status === Status.loading) {
      item.getTextAreaArraySummaryFromServer().then(() => {
        setStatus(Status.standard)
      })
    }
    if (appointment !== props.appointment) {
      setAppointment(props.appointment || '')
    }
  }, [props.appointment])
  if (
    status === Status.loading ||
    item.textAreaArraySummary.length === 0
  ) { return null}
  if (props.standalone) {
    const key2s = (mainservice.nav.getVal('uid') as string || '').split(';')
    const items: TextArraySummaryItem[] = item.getTextAreaArraySaummariesByKey2(key2s) as TextArraySummaryItem[]
    const paperFormat = (items[0]?.paperFormat || 'A5').toLowerCase()
    const paperOrientation = (items[0]?.orientation || 'landscape')
    const deckPrintName = (items[0]?.deckName || 'Ausdruck') + '-' + paperFormat + '-' + paperOrientation + '.pdf'
    let windowWidth = 826
    let windowHeight = 588
    if (paperOrientation === 'portrait') {
      windowHeight = 826
      windowWidth = 588
    }
    // const paperFormat = 'a5'
    return <div id="printthis" className='taasPrintPageHolder'>
        <h2
          className={`dont-print`}
        >Manuskripte</h2>
        <button
          className={`dont-print`}
          onClick={() => {
            mainservice.navTo(mainservice.nav.historyBack())
          }}
        >Zurück</button>
        <button
          onClick={() => {
            var opt = {
              margin:       0,
              filename:     deckPrintName,
              image:        { type: 'jpeg', quality: 0.98 },
              html2canvas:  { scale: 4, windowWidth: windowWidth, windowHeight: windowHeight },
              jsPDF:        { unit: 'mm', format: paperFormat, orientation: paperOrientation },
            };
            var element = document.getElementsByClassName('printthis')[0];
            // @ts-ignore
            var worker = html2pdf().from(element).set(opt).save();
            console.log('worker', worker)
          }}
        >
          Drucken
        </button>
        <div className='printthis'>
          {
            multiplyPrintItems(items, copies).map((taa, index) => {
              if (!taa) { return null }
              return <TextAreaArray
                key={taa.key2}
                AreaArray={taa}
                index={index}
                setShow={setShow}
                show={show}
                standalone={props.standalone}
                item={item}
              />
            })
          }
        </div>
    </div>
  }
  
  return <div className={`TextAreaArrayItems contentPart`}>
    <div className='contentHeader'>
      <h2
        className={`dont-print`}
      >Manuskripte {appointment || ''}</h2>
    </div>
    {
      item.getTextAreaArraySummaryByPaperFormat({appointment: appointment || ''}).map((F, indexA) => {
        return <div
          key={`print-arreas-format-${F.orientation.key}-${F.paperFormat.key}`}
          className=''
        >
          <div className='contentHeader'>
            <h3>
              <Printer
                className={`dont-print`}
                onClick={() => {
                  mainservice.navTo([['view', 'textAreaArray'], ['id', item.id], ['uid', F.items.map(i => i.key2).join(';')]])
                }}
              /> {F.paperFormat.name} - {F.orientation.name}
            </h3>
          </div>
          {
            F.items.map((AreaArray, indexB) => {
              let indexG = indexA * 1000 + indexB
              return <TextAreaArray
                key={`textAreaArraySummary-${indexG}`}
                AreaArray={AreaArray}
                index={indexG}
                setShow={setShow}
                show={show}
                standalone={props.standalone}
                item={item}
                printCountHolder={printCountHolder}
                packCountHolder={packCountHolder}
              />
            })
          }
        </div>
      })
    }
  </div>
}

function multiplyPrintItems(items: TextArraySummaryItem[], copies: number) {
  let out: TextArraySummaryItem[] = []
  for(let i = 0; i < copies; i++) {
    out = [...out, ...items]
  }
  console.log('copies',multiplyPrintItems)
  return out
}