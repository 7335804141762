import { useEffect, useState } from "react";
import { GenericDTO } from "../../DTO/GenericDTO";
import PropDTO from "../../DTO/PropDTO";
import { Activity } from "react-feather";
import Spinner from "../../../components/Spinner/Spinner";
import AsyncArray from "../../../services/AsyncArray";
import UserDTO from "../../../DTO/UserDTO";
import { Getter } from "../../../services/ComService";
import { DisplayDateTimeFromString } from "../../../services/DateTime";

enum ValueType {
  none,
  Date,
  string,
  UserId,
  itemId,
  old,
  new,
}

enum Status {
  init,
  loading,
  done,
}

type PrintRow = {
  name: string,
  value: string,
}

type CredentialItem = {
  name: string,
  key1: string,
  key2: string,
  value1Type: ValueType,
  value2Type: ValueType,
}

const CredentialList: CredentialItem[] = [
  {
    name: 'Erstellt von',
    key1: 'log',
    key2: 'createUserId',
    value1Type: ValueType.UserId,
    value2Type: ValueType.Date,
  },
  {
    name: 'Veranstaltungsdesign gewählt von',
    key1: 'log',
    key2: 'setUp',
    value1Type: ValueType.UserId,
    value2Type: ValueType.Date,
  },
  {
    name: 'Design Name',
    key1: 'log',
    key2: 'setUpName',
    value1Type: ValueType.string,
    value2Type: ValueType.none,
  },
  {
    name: 'Gebacken von',
    key1: 'log',
    key2: 'bakedByUserId',
    value1Type: ValueType.UserId,
    value2Type: ValueType.Date,
  },
  {
    name: 'Kunde hinzugefügt von',
    key1: 'log',
    key2: 'addedCustomerUserId',
    value1Type: ValueType.UserId,
    value2Type: ValueType.Date,
  },
  {
    name: 'Kunde entfernt von',
    key1: 'log',
    key2: 'removedCustomerUserId',
    value1Type: ValueType.UserId,
    value2Type: ValueType.Date,
  },
  {
    name: 'Umbenannt',
    key1: 'log',
    key2: 'rename',
    value1Type: ValueType.old,
    value2Type: ValueType.new,
  },
  {
    name: 'Umbenannt von',
    key1: 'log',
    key2: 'renameUserId',
    value1Type: ValueType.UserId,
    value2Type: ValueType.Date,
  },
  {
    name: 'Start geändert',
    key1: 'log',
    key2: 'setStart',
    value1Type: ValueType.old,
    value2Type: ValueType.new,
  },
  {
    name: 'Start geändert von',
    key1: 'log',
    key2: 'setStartUserId',
    value1Type: ValueType.UserId,
    value2Type: ValueType.Date,
  },
  {
    name: 'Ende geändert',
    key1: 'log',
    key2: 'setEnd',
    value1Type: ValueType.old,
    value2Type: ValueType.new,
  },
  {
    name: 'Ende geändert von',
    key1: 'log',
    key2: 'setEndUserId',
    value1Type: ValueType.UserId,
    value2Type: ValueType.Date,
  },
  {
    name: 'Original/Quelle',
    key1: 'orig',
    key2: 'itemId',
    value1Type: ValueType.itemId,
    value2Type: ValueType.none,
  },
  {
    name: 'Geklont am',
    key1: 'orig',
    key2: 'cloneDate',
    value1Type: ValueType.Date,
    value2Type: ValueType.none,
  },
  {
    name: 'Geklont durch',
    key1: 'orig',
    key2: 'cloneDate',
    value1Type: ValueType.UserId,
    value2Type: ValueType.none,
  },
]

class CredentialManager {
  // private status: Status = Status.init
  private item: GenericDTO
  private setStatus: (status: Status) => void 
  private rows: PrintRow[] = []
  private userStore: UserDTO[] = []

  constructor(options: {item: GenericDTO, setStatus: (status: Status) => void}) {
    this.item = options.item
    this.setStatus = options.setStatus
  }

  async generateData(): Promise<void> {
    let rows: PrintRow[] = []
    for (let i = 0, m = CredentialList.length; i < m; i++) {
      let cli = CredentialList[i]
      const row = await this.generateRow(cli)
      if (row !== null) {
        rows.push(row)
      }
    }
    this.rows = rows
    this.setStatus(Status.done)
  }

  async generateDataByKeys(key1: string, key2: string) {
    let rows: PrintRow[] = []
    const cli = CredentialList.find(c => c.key1 === key1 && c.key2 === key2)
    if (cli) {
      const row = await this.generateRow(cli)
      if (row !== null) {
        rows.push(row)
        this.rows = rows
        this.setStatus(Status.done)
      }
    }
  }
  
  async generateRow(cli: CredentialItem): Promise<PrintRow | null> {
    const k1 = cli.key1
    const k2 = cli.key2
    const t1 = cli.value1Type
    const t2 = cli.value2Type
    const prop = this.item.getProp(k1, k2)
    if (!prop) { return null}
    let value = ''
    let user
    let date
    if (t1 === ValueType.UserId && t2 === ValueType.Date) {
      user = await this.getUser(prop.value1)
      date = DisplayDateTimeFromString(prop.value2)
      value = `${user.getName()} (${date})`
    } else if (t1 === ValueType.string) {
      value = prop.value1
    } else if (t1 === ValueType.old && t2 === ValueType.new) {
      value = `${prop.value1} → ${prop.value1}`
    } else if (t1 === ValueType.itemId) {
      value = `${prop.value1}`
    } else if (t1 === ValueType.Date) {
      value = DisplayDateTimeFromString(prop.value1)
    }
    return {
      name: cli.name,
      value: value,
    } as PrintRow
  }

  public getRows() {
    return this.rows
  }

  public async getUser(id: string) {
    const i = parseInt(id, 10)
    const userLocal = this.userStore.find(u => u.id === i)
    if (userLocal) { return userLocal}
    // User not local:
    const userServerRaw = await Getter(`user/${i}`, true)
    const userServer = new UserDTO(userServerRaw)
    // Test if it is there now - else - save:
    if (!this.userStore.some(u => u.id === i)) {
      this.userStore.push(userServer)
    }
    return userServer
  }

}

export default function Credentials(props: {
  item: GenericDTO,
}) {
  const [status, setStatus] = useState(Status.init)
  const [item] = useState(props.item)
  const [credentials] = useState(new CredentialManager({item: props.item, setStatus: setStatus}))
  const className = 'w3-margin-top'
  if (status === Status.init) {
    return <button
      onClick={() => {
        setStatus(Status.loading)
        credentials.generateData()
      }}
    ><Activity /></button>
  }

  if (status === Status.loading) {
    return <Spinner />
  }
  const rows = credentials.getRows()
  if (rows.length === 0) {
    return <div className={className}>
      <Activity /> Keine Informationen vorhanden
    </div>
  }
  return <div className={className}>
    <table>
      <tbody>
        {
          rows.map((r, index) => {
            return <PrintItem key={`credentialKey-${item.id}-${index}`} item={r} />
          })
        }
      </tbody>
    </table>
  </div>
}

export function Credential(props: {
  item: GenericDTO,
  key1: string,
  key2: string,
  prefix?: string,
}) {
  const [status, setStatus] = useState(Status.init)
  const [item] = useState(props.item)
  const [credentials] = useState(new CredentialManager({item: props.item, setStatus: setStatus}))
  useEffect(() => {
    setStatus(Status.loading)
    credentials.generateDataByKeys(props.key1, props.key2).then(() => {
      setStatus(Status.done)
    })
  }, [])
  if (status === Status.init || credentials.getRows().length === 0) {
    return null
  }
  return <span>{props.prefix || ''}{credentials.getRows()[0].value}</span>
}

function PrintItem(props: {
  item: PrintRow,
}) {
  const item = props.item
  return <tr>
    <td>{item.name}</td>
    <td>{item.value}</td>
  </tr>
}
