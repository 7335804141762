import React from 'react'
import DoublePageDTO from '../../DTO/DoublePageDTO'
import BookDTO, {Section } from '../../DTO/BookDTO'
import './Crumb.scss'

type Props = {
  doublePage: DoublePageDTO,
  book: BookDTO
}

type State = {
  sections: Section[]
}

export default class Crumb extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      sections: this.props.book.sectionMap.get(props.doublePage.id) || []
    }
  }

  renderCrumb(section: Section, index: number, l: number) {
    let positionClass = ''
    if (index === 0) {
      positionClass = 'first'
    }
    if (index + 1 === l) {
      positionClass += ' last'
    }
    return <span key={section.doublePageId} className={`crumb ${positionClass}`}>

      <span className='chapternumber'>{section.sectionNumberString}</span>
      <span className='chaptername'>{section.name}</span>
    </span>
  }

  render() {
    const show = this.props.doublePage.getPropValue1('breadcrumb', 'show', 'yes')
    const enabled = this.props.book.getPropVal1('tableOfContens', 'enable')
    if (enabled != 'yes' || show != 'yes') {
      return <></>
    }
    const nameStyle = this.props.book.getPropVal1(
      'tableOfContens',
      'chapter-style',
      'chapter-style-clean'
    )
    return <div className={`crumbs ${nameStyle}`}>
      <div className='bookname' dangerouslySetInnerHTML={{__html: this.props.book.name}}></div>
      {
        this.state.sections.map((s, i) => this.renderCrumb(s, i, this.state.sections.length))
      }
    </div>
  }
}
