import NavService from './NavService'
import {Getter} from './ComService'
import TimeFormatService from './TimeFormatService'
import BookDTO, { Layer } from '../DTO/BookDTO'
// import UserDTO from '../DTO/UserDTO'
import DoublePageDTO from '../DTO/DoublePageDTO'
import LoginService from './LoginService'
// import { SessionItem } from '../DTO/SessionDTO'
import config from '../config.json'

export type SessionData = {
  key1: string,
  key2: string,
  value1: string,
  value2: string
}

export type ElementType = {
  id: number,
  name: string,
  propKeyList: string[]
}

export class MainService {
  apiPrefix = config.apiPrefix
  session: {admin: number, init: false} = {
    init: false,
    admin: 0
  }
  elementTypes: ElementType[] = []
  books: BookDTO[] = []
  reloadFunction: any
  floatIconData = new Map()
  currentDoublePageId: number = -1
  currentDoublePage: DoublePageDTO = new DoublePageDTO({id: -1, sortNumber: -1, pages: [], picture: {id: -1}})
  currentDoublePageRef: any
  navToFunction: any
  interactionThingRel: any
  interactionLayer: Layer = Layer.element
  public nav = new NavService()
  // public nav: NavService
  public time = new TimeFormatService()
  public currentBookView: any
  public BookAdminBar: any
  public droplet: any
  public pageFlipInteractive: boolean = true
  public broadcastReceiver = new Map()
  public loginService: LoginService

  constructor() {
    this.loginService = new LoginService({
      cb: (key: string, value: any) => {
        switch(key) {
          case 'loginsuccess':
            this.broadcast(key, value)
        }
      }
    })

    // Get elementTypes:
    Getter('elementType/getAll').then((elementTypes: ElementType[]) => this.elementTypes = elementTypes)
    this.sizeWatcher()
  }

  public breakpointPrinter() {
    const w = window.innerWidth
    /*
    $breakpointM: 601px;
    $breakpointL: 993px;
    $breakpointXl: 1500px;
    */
    if (w > 1500) { return 'Xl' }
    if (w > 993) { return 'L' }
    if (w > 601) { return 'M'}
    return 'S'
  }

  private sizeWatcher() {
    const sizeTimeout = 500
    let timer: number = -1
    window.addEventListener('resize', () => {
      window.clearTimeout(timer)
      timer = window.setTimeout(() => {
        console.info('BREAKPOINT: ', this.breakpointPrinter())
        this.broadcast(
          'window-resize',
          //[document.body.clientWidth, document.body.clientHeight]
          [window.innerWidth, window.innerHeight]
        )
      }, sizeTimeout)
    })
  }

  // Send Broadcast to all interested parties:
  public broadcast(key: string, value: any) {
    console.log('#broadcasted:', key, value)
    this.broadcastReceiver.forEach((bR, bRKey: string) => {
      if (bR && bR.fkt) {
        bR.fkt(key, value)
      } else {
        this.unregisterBroadcast(bRKey)
      }
    })
  }

  public registerToBroadcast(
    key: string,
    fkt: (key: string, value: any) => void,
    _self?: any
  ) {
    this.broadcastReceiver.set(key, {
      fkt: fkt,
      // self: self
    })
  }

  public unregisterBroadcast(key: string) {
    this.broadcastReceiver.delete(key)
  }

  public setNavToFunction(navToFunction: any) {
    this.navToFunction = navToFunction
  }

  // Preferred Nav Function:
  public navTo(n: [string, string|number][]) {
    this.nav.setRoute(n)
    this.broadcast('nav', {view: this.nav.getVal('view')})
    if (this.navToFunction) {
      this.navToFunction()
    }
  }

  // Go Back in History:
  public historyBack() {
    this.navTo(this.nav.historyBack())
  }

  // Show what has been last Element
  public historyBackName() {
    return this.nav.historyBackName()
  }

  // This is obsolete - a doublePage can be in multiple books! So we should not use this!
  /*
  public async navToDoublePage(doublePageId: number, _bookId?: number) {
    const data: {
      doublePageId: number,
      bookId: number
    } = await Getter(`DoublePage/${doublePageId}/getRefs`)
    if (bookId && data.bookId !== bookId && data.bookId > 0) {
      this.navTo([
        ['view', 'book'],
        ['book', data.bookId]
      ])
      return
    }
    this.navTo([
      ['view', 'book'],
      ['book', data.bookId],
      ['doublePageId', data.doublePageId],
    ])
  }
  */

  public setCurrentBookView(bookView: any) {
    this.currentBookView = bookView
  }

  public getCurrentBookView() {
    return this.currentBookView
  }

  public getElementTypes(): ElementType[]{
    return this.elementTypes
  }

  public setCurrentDoublePage(dP: DoublePageDTO) {
    this.currentDoublePage = dP
    this.currentDoublePageId = dP.id || -1
    this.broadcast('currentDoublePageSet', dP.id)
  }

  async getBook(id?: number, fresh?: boolean) {
    if (!id) {
      id = this.nav.getVal('book') as number
    }
    const book = this.books.find((b) => b.id === id)
    if (book) {
      if (fresh) {
        // remove book and proceed to re-catch it:
        this.books = this.books.filter((b: BookDTO) => b.id != id)
      } else {
        return book
      }
    }
    const responseJson = await Getter('book/' + id)
    if (responseJson && responseJson.status === 'error') {
      return responseJson
    }
    const newBook = new BookDTO(responseJson)
    this.books.push(newBook)
    return newBook
  }

  public readBook(id?: number) {
    if (!id) {
      id = this.getCurrentBookId()
    }
    return this.books.find((b) => b.id === id)
  }

  public getCurrentBookId(): number {
    return this.nav.getVal('book') as number || -1
  }

  public reload() {
    if (this.reloadFunction) {
      this.books = []
      this.reloadFunction()
    }
  }
  public setReloadFunction(rlf: any) {
    this.reloadFunction = rlf
  }

  public reload2() {
    if (this.navToFunction) {
      this.navToFunction()
    }
  }

  public getSessionData(key1: string, key2: string): SessionData | undefined {
    const bookId = this.nav.getVal('book') as number
    const book = this.books.find((b) => b.id === bookId)
    if (book) {
      return book.sessionData.find((sD) => sD.key1 === key1 && sD.key2 === key2)
    }
    return undefined
  }

  public getAllSessionData(): SessionData[] {
    const bookId = this.nav.getVal('book') as number
    const book = this.books.find((b) => b.id === bookId)
    if (book) {
      return book.sessionData
    }
    return []
  }

  public async setSessionData(key1: string, key2: string, value1: string, value2: string) {
    const bookId = this.nav.getVal('book')
    const book = this.books.find((b) => {
      return b.id === parseInt(`${bookId}`, 10)
    })
    if (book) {
      await book.setSessionData(key1, key2, value1, value2)
    }
  }

  public isAdmin(): number {
    if (!this.session.init) {
      if (document.cookie.search('isAdmin=true') > -1) {
        this.session.admin = 2
      }
    }
    return this.session.admin
  }

  public becomeAdmin(): void {
    let d = new Date()
    d.setTime(d.getTime() + (12*60*60*1000)) // 1 h
    const expires = "expires="+ d.toUTCString()
    document.cookie = 'isAdmin=true;SameSite=Strict;secure;' + expires + ';path=/'
    this.session.admin = 2
  }

  public removeAllCookies(): void {
    const res = document.cookie
    const multiple = res.split(';')
    multiple.forEach((cs: string) => {
      const splittedCookie = cs.split('=')
      document.cookie = splittedCookie[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
    })
  }

  public quitAdmin(): void {
    if (document.cookie.search('isAdmin=true') > -1) {
      document.cookie = 'isAdmin=false'
    }
    this.session.admin = 0
    // killer method:
    this.removeAllCookies()
  }

  public adminReadMode(): number {
    this.session.admin = 1
    return this.session.admin
  }

  public adminEditMode(): number {
    this.session.admin = 2
    return this.session.admin
  }

  public registerFloatIcon(name: string, floatIcon: any) {
    this.floatIconData.set(name, floatIcon)
  }

  public isCurrentDoublePage(doublePageId: number) {
    return doublePageId === this.currentDoublePageId
  }

  public setFloatIconCoordinates(name: string, doublePage: number, x: number, y: number) {
    // quit if request is not for the current page:
    if (!this.isCurrentDoublePage(doublePage)) { return }
    const fI = this.floatIconData.get(name)
    // quit if floatIcon not available:
    if (!fI) { return }
    // Get Scroll:
    if (this.currentDoublePageRef) {
      y -= this.currentDoublePageRef.current.scrollTop
    }
    // comand to new target:
    fI.setNewTarget(fI, x, y)
  }

  public setInteractionLayer(type: Layer) {
    this.interactionLayer = type
    if (type === Layer.doublePage &&
      this.currentDoublePage &&
      this.currentDoublePage.currentDisplayer
    ) {
      this.thingHasBeenClicked(type, this.currentDoublePage.currentDisplayer)
    } else if (type === Layer.book) {
      this.thingHasBeenClicked(type, this.currentBookView)
    } else {
      this.thingHasBeenClicked(type, undefined)
    }
    if (this.currentBookView) {
      this.currentBookView.setInterationLayer(type)
    }
    if (this.BookAdminBar && this.BookAdminBar.setInteractionLayer) {
      this.BookAdminBar.setInteractionLayer(type)
    }
  }

  public thingHasBeenClicked(type: Layer, thing: any) {
    if (type != this.interactionLayer) { return }
    if (this.interactionThingRel && this.interactionThingRel.stopFocus) {
      this.interactionThingRel.stopFocus()
    }
    this.interactionThingRel = thing
    if (!thing) {
      return
    }
    if (this.interactionThingRel.gainFocus) {
      this.broadcast('thingHasBeenClicked', type) // .broadCastName
      this.interactionThingRel.gainFocus()
    }
    if (this.BookAdminBar && this.BookAdminBar.forceUpdate) {
      this.BookAdminBar.forceUpdate()
    }
  }
}
const mainservice = new MainService()
export default mainservice
