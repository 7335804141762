import config from '../config.json'
import mainservice, {SessionData} from '../services/MainService'
import {Getter, Setter} from '../services/ComService'
import {stringToFloat, floatToValue} from '../services/Currency'
import ArrayAddUnique from '../services/ArrayAddUnique'
import DoublePageDTO, { IDoublePage } from './DoublePageDTO'
import PageElementDTO from './PageElementDTO'
import RightDTO, {IRight} from './RightDTO'
import BookPropDTO, { IBookProp } from './BookPropDTO'
import FileDTO, { IFile } from './FileDTO'
import { parseStringToServer, extractFirstHeadline, parseStringFromServer } from '../services/TextConverter'
import { CurrencyFormatter } from '../services/Currency'

export type V1 = {
  value1: string,
  value2: string,
  amount: number,
  userText: string
}

export type Key2 = {
  key2: string,
  userText: string,
  v1s: V1[]
}

export type Key1 = {
  key1: string,
  key2s: Key2[]
}

export enum Layer {
  none,
  doublePage,
  page,
  element,
  dropElement,
  dropPage,
  book,
  doublePageOverview
}

export type Section = {
  name: string
  doublePageId?: number,
  page?: number,
  child: any[],
  sectionNumberString: string,
  interactive?: boolean,
}



export type LastPosition = {doublePageId: number, percentage: number, modifyDate?: {date: string}, date?: Date}

export default class BookDTO {
  public id: number
  public name: string
  public hyphenName: string
  public description: string
  public doublePages: DoublePageDTO[] = []
  public groupId: number = 1
  public sessionData: SessionData[] = []
  public sessionHash: string = ''
  public props: BookPropDTO[] = []
  public picture: FileDTO | undefined
  public sections: Section[] = []
  public sectionMap = new Map()
  public teaser: boolean = false
  public lastPosition: LastPosition = {doublePageId: 0, percentage: 0}
  public furthestPosition: LastPosition = {doublePageId: 0, percentage: 0}
  public right: RightDTO = new RightDTO({
    id: 1,
    name: 'Public Book',
    description: '',
    value: 6667
  })
  public doublePageCount: number = 0
  private prices: Price[] = []

  constructor(o: IBook) {
    this.id = o.id
    this.name = o.name
    this.hyphenName = o.hyphenName || o.name
    this.initData(o)
    this.teaser = o.teaser || false
    this.description = o.description || ''
    this.prices = o.prices || []
  }

  initData(o: IBook) {
    this.id = o.id
    this.name = o.name
    if (o.doublePages) {
      this.doublePages = o.doublePages.sort((a, b) => {
        return a.sortNumber < b.sortNumber ? -1 : 1
      }).map((dP: IDoublePage) => new DoublePageDTO(dP, this))
    }
    if (o.right) {
      this.right = new RightDTO(o.right)
    }
    this.props = (o.props || []).map((p) => new BookPropDTO(p))
    if (o.picture  && o.picture.id > -1) {
      this.picture = new FileDTO(o.picture as IFile)
    }
    if (o.lastPosition) {
      this.lastPosition = o.lastPosition
      if (o.lastPosition.modifyDate?.date) {
        this.lastPosition.date = new Date(o.lastPosition.modifyDate.date)
      }
    }
    if (o.furthestPosition) {
      this.furthestPosition = o.furthestPosition
      if (o.furthestPosition.modifyDate?.date) {
        this.furthestPosition.date = new Date(o.furthestPosition.modifyDate.date)
      }
    }
    this.doublePageCount = o.doublePageCount || 0
  }

  public getUrl(): string {
    return `/view=book/book=${this.id}/`
  }

  public async getFromServer() {
    let response = await fetch(config.apiPrefix + 'book/' + this.id)
    let responseJson = await response.json()
    this.initData(responseJson)
  }

  public async setName(name: string) {
    if (!name) { return }
    this.name = name
    if (this.id > -1) {
      // await fetch(config.apiPrefix + 'book/' + this.id + '/rename/' + name)
      await Setter(`book/${this.id}/update`, {name: name}, {retryName: `rename`})
    } else {
      await fetch(config.apiPrefix + 'book/add/' + name)
    }
  }

  public async setHyphenName(name: string) {
    if (!name) { return }
    this.hyphenName = name
    if (this.id > -1) {
      // await fetch(config.apiPrefix + 'book/' + this.id + '/rename/' + name)
      await Setter(`book/${this.id}/update`, {hyphenName: name}, {retryName: `setHyphen`})
    }
  }

  public async addDoublePage(position: number) {
      await fetch(`${config.apiPrefix}book/${this.id}/addDoublePage/${position * 10 - 1}`)
  }

  public async remove() {
    await fetch(`${config.apiPrefix}book/${this.id}/remove`)
  }

  public async removeDoublePage(id: number) {
    await fetch(`${config.apiPrefix}DoublePage/${id}/remove`)
  }

  public async getSession(sessionHash: string) {
    // Do we have session?
    for (let i = 0; i < 2; i++) {
      const response = await Getter(`session/addverify/book/${this.id}/group/${this.groupId}/${sessionHash || 'none'}`)
      sessionHash = response.hash
      const sResponseJson = await Getter(`session/${sessionHash}`)
      if (!sResponseJson.error) {
        this.sessionData = sResponseJson.data.map((s: SessionData) => {
          return {
            key1: parseStringFromServer(s.key1),
            key2: parseStringFromServer(s.key2),
            value1: parseStringFromServer(s.value1),
            value2: parseStringFromServer(s.value2)
          }
        })
        this.sessionHash = sessionHash
        return sessionHash
      }
      sessionHash = ''
    }
    return ''
  }

  public async setSessionData(key1: string, key2: string, value1: string, value2: string) {
    const d = this.sessionData.find((sD) => sD.key1 === key1 && sD.key2 === key2)
    if (d) {
      d.value1 = value1
      d.value2 = value2
    } else {
      this.sessionData.push({
        key1: key1,
        key2: key2,
        value1: value1,
        value2: value2
      })
    }
    if (key2 === 'furthestPosition') {
      this.furthestPosition.percentage = parseFloat(value2)
    }
    // await fetch(`${config.apiPrefix}session/${this.sessionHash}/add/${key1}/${key2}/${value1}/${value2}`)
    const command = `session/${this.sessionHash}/add/${key1}/${key2}/${value1}/${value2}`
    await Getter(command, false, `${this.id},${key1},${key2}`)
  }

  public getAllPageElements(): PageElementDTO[] {
    let out: PageElementDTO[] = []
    this.doublePages.forEach((dP) => {
      out = out.concat(dP.getAllPageElements())
    })
    return out
  }

  public async getAgregatedSessionData() {

    const rawJson = await fetch(`${config.apiPrefix}book/${this.id}/getAgregatedSessionData`)
    const raw = await rawJson.json()
    const scope = /;k1:(.*);k2:(.*);v1:(.*);v2:(.*);/
    let k1s = new Map()

    raw.rawKeys.forEach((r: string) => {
      const rr = r || ''
      if (rr.search(scope) === -1) { return }
      const key1 = (rr.match(scope) || ['','','','',''])[1]
      const key2 = (rr.match(scope) || ['','','','',''])[2]
      const value1 = (rr.match(scope) || ['','','','',''])[3]
      const value2 = (rr.match(scope) || ['','','','',''])[4]
      // target: value1: survey1, value2: surveypart9, key1: multiplechoice, key2: 3
      // here: value1: 3, value2: C, key1: survey1, key2: surverypart9




      if (!k1s.has(key1)) {
        k1s.set(key1, new Map())
      }
      const k1 = k1s.get(key1)
      if (!k1.has(key2)) {
        k1.set(key2, new Map())
      }
      const k2 = k1.get(key2)
      if (!k2.has(value1)) {

        const pageElement = this.getElementByV1V2(key1, key2)
        let userText = ''
        if (pageElement !== false) {
          const pageProp = pageElement.elementProps.find((eP) => eP.key2 === value1)
          if (pageProp) {
            userText = pageProp.value1
          }
        }

        k2.set(value1, {
          key1: key1,
          key2: key2,
          value1: value1,
          value2: value2,
          amount: raw.rawObj[rr],
          userText: userText
        })
      }

    })
    let out: Key1[] = []
    k1s.forEach((b1, key1: string) => {
      let key1Out: Key1 = {key1: key1, key2s: []}
      b1.forEach((b2: any, key2: string) => {
        let key2Out: Key2 = {key2: key2, v1s: [], userText: this.getDoublePageContentByElementByV1V2(key1, key2)}
        b2.forEach((bv1: any) => {
          key2Out.v1s.push(bv1)
        })
        key1Out.key2s.push(key2Out)
      })
      out.push(key1Out)
    })
    return out
  }

  public async addPropToServer(prop: BookPropDTO) {
    const e = prop.get()
    await fetch(`${config.apiPrefix}book/${this.id}/addProp/${e.key1}/${e.key2}/${parseStringToServer(e.value1)}/${parseStringToServer(e.value2)}`)
  }

  public async addProp(d: IBookProp) {
    const prop = new BookPropDTO({
      id: -1,
      key1: d.key1,
      key2: d.key2,
      value1: d.value1,
      value2: d.value2
    })
    this.props = this.props.filter((pP) => !(pP.key1 === d.key1 && pP.key2 === d.key2))
    this.props.push(prop)
    await this.addPropToServer(prop)
  }

  public async addPropVal(key1: string, key2: string, val1: string, val2?: string) {
    await this.addProp({
      key1: key1,
      key2: key2,
      value1: val1,
      value2: val2 || '-'
    } as IBookProp)
  }

  public async removeProp(key1: string, key2: string) {
    this.props = this.props.filter((pP) => !(pP.key1 === key1 && pP.key2 === key2))
    await fetch(`${config.apiPrefix}book/${this.id}/removeProp/${key1}/${key2}`)
  }

  public getProp(key1: string, key2: string) {
    return this.props.find((pP) => pP.key1 === key1 && pP.key2 === key2)
  }

  public getPropVal1(key1: string, key2: string, standard?: string) {
    if (standard === undefined) { standard = '-' }
    const prop = this.getProp(key1, key2)
    return (prop) ? prop.value1 : standard
  }

  public getCssClass(): string {
    return this.props.filter((p) => p.key1 === 'colorClass').map((p) => `set-${p.key2}-${p.value1}`).join(' ') || ''
  }

  public async setPicture(id: number) {
    const sR = await fetch(`${config.apiPrefix}book/${this.id}/setPicture/${id}`)
    const picture = await sR.json()
    this.picture = new FileDTO(picture)
  }

  public async removePicture() {
    await fetch(`${config.apiPrefix}book/${this.id}/removePicture`)
    this.picture = undefined
  }

  public getCssBGPicture(): string | false {
    if (!this.picture) { return false }
    return this.picture.getCSSBackground()
  }

  public getPictureId() {
    if (this.picture) {
      return this.picture.id
    }
    return -1
  }

  public getPicture() {
    if (this.picture) {
      return this.picture
    }
    return false
  }

  private getElementByV1V2(v1: string, v2: string): PageElementDTO | false {
    let out: PageElementDTO | false  = false
    this.doublePages.some((dP) => {
      return dP.pages.some((p) => {
        return p.pageElements.some((pE) => {
          const found = (pE.value1 === v1 && pE.value2 === v2)
          if (found) {
            out = pE
            return true
          }
          return false
        })
      })
    })
    return out
  }

  private getDoublePageContentByElementByV1V2(v1: string, v2: string): string {
    const doublePage = this.doublePages.find((dP) => {
      return dP.pages.some((p) => {
        return p.pageElements.some((pE) => {
          return (pE.value1 === v1 && pE.value2 === v2)
        })
      })
    })
    if (!doublePage) { return '' }
    let out = ''
    doublePage.pages.forEach((p) => {
      p.pageElements.forEach((pE) => {
        if (pE.elementType.id === 1) {
          out += pE.value1
        }
      })
    })
    return out
  }

  /*
  private isSectionInChildList(sections: Section[], doublePageId: number): boolean {
    return sections.some((s) => {
      if (s.doublePageId === doublePageId) {
        return true
      }
      return this.isSectionInChildList(s.child, doublePageId)
    })
  }

  public getSectionByDoublePageId(doublePageId: number, fresh?: boolean): Section[] {
    const sections = this.getSections(fresh)
    const mainSection = sections.find((s) => s.doublePageId === doublePageId)
    if (mainSection) { return [mainSection]}
    return []
  }
  */

  public getSections(fresh?: boolean): Section[] {
    if (!fresh && this.sections.length > 0) {
      return this.sections
    }
    this.sectionMap = new Map()
    let out: Section[] = []
    let sectionCounter = 1
    let currentSection: Section | undefined = undefined
    let currentSectionB: Section | undefined = undefined
    let currentSubsection: Section | undefined = undefined
    let currentSubsectionB: Section | undefined = undefined
    this.doublePages.forEach((dP) => {
      let dPSections: Section[] = []
      dP.pages.forEach((p) => {
        p.pageElements.forEach((pE) => {
          const sectionType = pE.getPropVal1('section', 'type', '')
          let entry = {
            name: extractFirstHeadline(pE.value1),
            doublePageId: dP.id,
            child: []
          }
          let sectionNumberString
          if (sectionType === 'section') {
            sectionNumberString = `${sectionCounter++}`
            currentSectionB = {...entry, ...{sectionNumberString: sectionNumberString}}
            out.push(currentSectionB)
            currentSubsection = undefined
            currentSubsectionB = undefined
            // currentSection = undefined
            currentSection = currentSectionB
          } else if(sectionType === 'subsection') {
            let lastEl = out[out.length - 1]
            if (lastEl) {
              sectionNumberString = lastEl.sectionNumberString + '.' + (lastEl.child.length + 1)
              currentSubsectionB = {...entry, ...{sectionNumberString: sectionNumberString}}
              lastEl.child.push(currentSubsectionB)
              // currentSubsection = undefined
              currentSubsection = currentSubsectionB
            }
          }
          // Mark if interactive Section:
          if (pE.widget) {
            if (currentSubsection) {
              currentSubsection.interactive = true
            } else if (currentSubsectionB) {
              currentSubsectionB.interactive = true
            } else if (currentSection) {
              currentSection.interactive = true
            } else if (currentSectionB) {
              currentSectionB.interactive = true
            }
          }
        })
      })
      if (currentSubsection) {
        dPSections.push(currentSubsection)
      } else if (currentSection) {
        dPSections.push(currentSection)
      }
      this.sectionMap.set(dP.id, dPSections)
      currentSection = currentSectionB
      currentSubsection = currentSubsectionB
    })
    this.sections = out
    return out
  }

  public setRight(right: RightDTO, groupId?: number) {
    this.setRightId(right.id, groupId || 1)
    this.right = right
  }

  public setRightId(rightId: number, groupId?: number) {
    Getter(`book/${this.id}/group/${groupId || 1}/right/add/${rightId}`)
  }

  public async addCollection(d: {
    bookId: number,
    collectionId: number,
    sortNumber: number
  }) {
    return await Getter(`book/${d.bookId}/collection/add/${d.collectionId}/${d.sortNumber}`)
  }

  public async integrateCollection(anchorId: number) {
    return await Getter(`book/${this.id}/collection/integrate/${anchorId}`)
  }

  public async exportDoublePagesAsCollection(name: string, doublePages: number[]) {
    return await Setter(`book/${this.id}/exportCollection`, {
      name: name,
      doublePages: doublePages
    }, {retryName: `${name}, ${doublePages.join(',')}`})
  }

  /*
  public getSectionByPage(pageId: number): Section {
    let section = {
      name: '',
      doublePageId: -1,
      child: [],
      sectionNumberString: ''
    } as Section
    let sectionCounter = 1
    this.doublePages.some((dP) => {
      return dP.pages.some((p) => {
        p.pageElements.forEach((pE) => {
          const sectionType = pE.getPropVal1('section', 'type', '')
          let entry = {
            name: extractFirstHeadline(pE.value1),
            doublePageId: dP.id,
            child: []
          }
          let sectionNumberString
          if (sectionType === 'section') {
            sectionNumberString = `${sectionCounter++}`
            section = {...entry, ...{sectionNumberString: sectionNumberString}}
          } else if(sectionType === 'subsection') {
            sectionNumberString = section.sectionNumberString + '.' + (section.child.length + 1)
            section.child.push(entry)
          }
        })
        if (p.id === pageId) {
          return true
        }
      })
    })
    return section
  }
  */

  public async addToCart() {
    await Setter('shop/addBooks', {
      bookIds: [this.id],
      kind: '',
      hash: ''
    }, {
      retryName: `addToCart-${this.id}`
    })
  }

  public getCurrentPrice(): number {
    const prices = this.prices
    return prices[0]?.value || 0
  }

  public getCurrentPriceRender(): string {
    return CurrencyFormatter(this.getCurrentPrice() + '')
  }

  public async setPrice(priceRaw: string) {
    const price = stringToFloat(priceRaw)
    await Setter('book/setPrice', {
      value: price,
      bookId: this.id,
    })
    ArrayAddUnique(this.prices, [{
      value: price,
      start: null,
      end: null,
      kind: 'default',
    }], ['start', 'end', 'kind'])
  }

  public realDPPosition(id: number) {
    console.log('realDPPosition id', id)
    const arr = this.doublePages || []
    let pos = 0
    let remember: number[] = []
    for (let i = 0, m = arr.length; i < m; i++) {
      let dp = arr[i]
      console.log('realDPPosition id', id, ' finding', dp)
      if (
        dp.sourceBookId === -1 ||
        remember.every((r) => r !== dp.sourceBookId)
      ) {
        pos += 1
      } else {
        remember.push(dp.sourceBookId)
      }
      if (dp.id === id) {
        console.log('realDPPosition id', id, 'found! p:', i+1)
        return pos
      }
    }
    console.log('realDPPosition id', id, 'nothing found')
    return -1
  }
}

export interface IBook {
  id: number
  name: string
  hyphenName?: string
  description?: string
  props?: any[]
  doublePages?: IDoublePage[]
  picture?: IFile | {id: number} | undefined
  right?: IRight
  teaser?: boolean
  lastPosition?: LastPosition
  furthestPosition?: LastPosition
  doublePageCount?: number
  prices?: Price[]
}

export type IBooksDTO = {
  books: IBook[],
  user: null | {id: number, cartBooks: []},
  rights: IRight[],
  teaserids: number[]
}

export async function GetBooks() {
  const r: IBooksDTO = await Getter('books')
  mainservice.loginService.user.setCartBooks(r.user?.cartBooks || [])
  let bookRegister = new Map()
  const books = r.books.filter((b) => {
    // We want each book to be available exactly once:
    if (bookRegister.has(b.id)) { return false }
    bookRegister.set(b.id, true)
    return true
  }).map((book: IBook) => {
    const isTeaser = r.teaserids.some((ti) => ti === book.id)
    return new BookDTO({...book, ...{teaser: isTeaser}})
  })
  return books
}

export type Price = {
  value: number,
  modified: Date | null,
  start: Date | null,
  end: Date | null,
  kind: string,
}